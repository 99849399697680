import { Link } from "react-router-dom";

export function StepNav({
  prevLink,
  nextLink,
  nextLinkDisabled,
  label,
  onClick,
}) {
  return (
    <div className="flex justify-between mt-8">
      {prevLink ? (
        <Link to={prevLink}>
          <button className="p-2 px-4 bg-gradient-to-r from-blue-500 to-blue-500 rounded-lg">
            Prev
          </button>
        </Link>
      ) : (
        <div></div>
      )}
      {nextLink ? (
        <Link to={nextLink} disabled={nextLinkDisabled}>
          <button
            disabled={nextLinkDisabled}
            className={`${nextLinkDisabled ? "bg-gray-500" : "bg-gradient-to-r from-blue-500 to-blue-500"} p-2 px-4 rounded-lg`}
            onClick={onClick}
          >
            {label ? label : "Next"}
          </button>
        </Link>
      ) : (
        <div></div>
      )}
    </div>
  );
}
