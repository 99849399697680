import { useEffect, useState } from "react";

export function useLocalStorage(key, initialValue) {
	const [state, setInternalState] = useState(initialValue);

	const onStorageUpdate = (e) => {
		const { key: newKey, newValue } = e;
		if (newKey === key) {
			setInternalState(JSON.parse(newValue));
		}
	};

	useEffect(() => {
		if (key in localStorage) {
			const value = localStorage.getItem(key);
			if (value !== undefined) {
				setInternalState(JSON.parse(value));
				window.addEventListener("storage", onStorageUpdate);
				return () => {
					window.removeEventListener("storage", onStorageUpdate);
				};
			}
		}
		// eslint-disable-next-line
	}, [key]);

	const setState = (value) => {
		localStorage.setItem(key, JSON.stringify(value));
		setInternalState(value);
	};

	return [state, setState];
}
