import React, { useState } from "react";
import {
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import { CloseIcon, SearchIcon } from "@chakra-ui/icons";

import { Button, Checkbox } from "../../componentLibrary";
import { InputField } from "../InputFields";

const _placeHolder = {
  color: "rgba(235, 235, 235, 0.6) !important",
};

const moreTableFields = [
  "Service Type",
  // "Shipment Date",
  "IsDelivered",
  // "Actual Delivery Date",
];

const AddTableFieldPopover = ({
  onClose,
  setAdditionalTabelFields,
  setSelectedFields,
  selectedFields,
}) => {
  const [isShowMore, setIsShowMore] = useState(false);
  const [tableFields, setTableFields] = useState(moreTableFields);
  const [searchFilter, setSearchFilter] = useState("");

  const onSearchFilterChange = (e) => {
    setSearchFilter(e.target.value);

    if (e.target.value) {
      setTableFields(() =>
        moreTableFields.filter((field) =>
          field.toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
    } else {
      setTableFields(moreTableFields);
    }
  };

  const handleCheckboxChange = (isChecked, value) => {
    if (isChecked) {
      setSelectedFields((prevFields) => [...prevFields, value]);
    } else {
      setSelectedFields((prevFields) =>
        prevFields.filter((option) => option !== value)
      );
    }
  };

  return (
    <div
      style={{
        background: "linear-gradient(180deg, #003F72 0%, #043155 100%)",
      }}
      className="py-2.5 px-5 w-[324px] flex flex-col gap-1.5"
    >
      <p className="text-base font-medium py-1">Column Preference</p>
      <InputGroup>
        <InputField
          placeholder={"Search"}
          type={"text"}
          className="!h-11 !px-12"
          name={"zip"}
          _placeholder={_placeHolder}
          value={searchFilter}
          onChange={(e) => onSearchFilterChange(e)}
        />
        <InputLeftElement top={0.5} left={1}>
          <SearchIcon w={5} h={5} color={"#fff"} />
        </InputLeftElement>
        <InputRightElement
          top={0.5}
          right={1}
          onClick={() => {
            setSearchFilter("");
            setTableFields(moreTableFields);
          }}
        >
          <CloseIcon w={3} h={3} />
        </InputRightElement>
      </InputGroup>
      <div className="flex flex-col gap-1">
        {(isShowMore ? tableFields : tableFields.slice(0, 3)).map(
          (field, i) => (
            <div key={i}>
              <div className="flex items-center text-base font-medium">
                <Checkbox
                  checked={selectedFields && selectedFields.includes(field)}
                  onCheckedChange={(isChecked) =>
                    handleCheckboxChange(isChecked, field)
                  }
                />
                {field}
              </div>
            </div>
          )
        )}
      </div>
      <div>
        {tableFields.length > 3 && (
          <Button
            context="text"
            className="text-sm font-medium"
            onClick={() => {
              setIsShowMore(!isShowMore);
            }}
          >
            {isShowMore ? "Show Less" : "Show More"}
          </Button>
        )}
      </div>
      <div className="w-full h-[1px] bg-[#D9D9E0] opacity-50"></div>
      <div className="flex gap-[15px] py-4 justify-center">
        <Button
          className="w-[81px] text-sm font-medium !text-[#EBEBEB]"
          context="outlined"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          className="w-[81px] text-sm font-medium"
          onClick={() => {
            setAdditionalTabelFields(selectedFields);
            onClose();
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default AddTableFieldPopover;
