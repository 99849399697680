import React from "react";

const Tracker = ({ tracking }) => {
  return (
    <div className="mt-4 flex bg-[#21232E]">
      <div className="flex flex-col mt-4">
        <div className="flex">
          <div className="flex ml-[390px]">
            <img alt="flag" src="/images/track/flag.svg" />
            <div className="text-sm ml-1 items-end">{`Dwell time: ${tracking.dwellTime}`}</div>
          </div>
          <div className="ml-36">
            <img alt="marker" src="/images/track/marker.svg"></img>
          </div>
          <div className="flex ml-16">
            <img alt="warning" src="/images/track/warning.svg" />
            <div className="text-sm ml-1 items-start whitespace-nowrap">
              {`${tracking.delay} delay`}
            </div>
          </div>
        </div>
        <div className="mt-0 ml-6">
          <div className="bg-[#EBEBEB]/48 border-4 rounded-lg flex">
            <div className="bg-[#4FD4A2] border-4 border-r-2 border-white rounded-l-lg w-[95px] h-[16px]"></div>
            <div className="bg-[#9EA9F1] border-4 border-l-2 border-r-2 border-white rounded-none w-[254px] h-[16px]"></div>
            <div className="bg-[#263238] border-4 border-l-2 border-r-2 border-white rounded-none w-[42px] h-[16px]">
              <div className="w-[14px] h-[14px] -mt-1 ml-4 bg-white rounded-full border-4 border-[#2F7DDB]"></div>
            </div>
            <div className="bg-[#2F70D8] border-4 border-l-2 border-r-2 border-white rounded-none w-[249px] h-[16px]"></div>
            <div className="bg-[#8C52E4] border-4 border-l-2 border-r-2 border-white rounded-none w-[119px] h-[16px]"></div>
            <div className="border-4 border-l-2 border-r-2 border-white rounded-none w-[245px] h-[16px]">
              <div className="bg-white w-[100%] h-[100%] border-2 border-[#7D7D7D]"></div>
            </div>
            <div className="border-4 border-l-2 border-white rounded-r-lg w-[91px] h-[16px]">
              <div className="bg-white w-[100%] h-[100%] border-2 rounded-r-lg border-[#7D7D7D]"></div>
            </div>
          </div>
          <div className="flex flex-row mb-6">
            <div className="flex flex-col text-xs mt-2 ml-3">
              <div>Label Created</div>
              <div>
                <time dateTime="16:18">16:18 CST</time>
              </div>
            </div>
            <div className="text-xs mt-2 ml-3">
              <div>Acceptance</div>
            </div>
            <div className="text-xs mt-2 ml-52">
              <div>{tracking.originCarrier}</div>
            </div>
            <div className="flex flex-col text-xs mt-2 ml-52">
              <div>{`Loading: ${tracking.loading}`}</div>
            </div>
            <div className="text-xs mt-2 ml-8">
              <div>{tracking.middleMileCarrier}</div>
            </div>
            <div className="flex flex-col text-xs mt-2 ml-40">
              <div>Est Delivery</div>
              <div>
                <time dateTime="13:04">13:04 CST</time>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tracker;
