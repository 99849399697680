import { Link } from "react-router-dom";
import { Button } from "@chakra-ui/react";

import style from "../../css-files/Component-CSS/salesToolsCards.module.css";

export function RateCardCalculatorCard() {
  // This is s personal low point for the sake of haste

  return (
    <div className={style.div}>
      <div className={style.div2}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="68"
          height="68"
          viewBox="0 0 68 68"
          fill="none"
        >
          <circle cx="34" cy="34" r="34" fill="#6AC2E2" />
          <path
            d="M19.0596 17.9627C19.4814 17.9627 19.7835 17.6014 19.7835 17.24V11.5743C19.7835 11.1526 19.421 10.8516 19.0596 10.8516C18.6983 10.8516 18.3369 11.213 18.3369 11.5743V17.24C18.2765 17.6618 18.6378 17.9627 19.0596 17.9627Z"
            fill="white"
          />
          <path
            d="M23.1578 15.1299C23.5796 15.1299 23.8817 14.7686 23.8817 14.4072V7.4762C23.8817 7.05441 23.5192 6.75348 23.1578 6.75348C22.7965 6.75348 22.4351 7.11484 22.4351 7.4762V14.4072C22.3747 14.829 22.736 15.1299 23.1578 15.1299Z"
            fill="white"
          />
          <path
            d="M27.3164 15.13C27.7382 15.13 28.0391 14.7686 28.0391 14.4073V10.8517C28.0391 10.4299 27.6777 10.1278 27.3164 10.1278C26.955 10.1278 26.5925 10.4904 26.5925 10.8517V14.4073C26.5925 14.829 26.8946 15.13 27.3164 15.13Z"
            fill="white"
          />
          <path
            d="M33.5839 16.4557C34.0057 16.4557 34.3066 16.0943 34.3066 15.733V6.57221C34.3066 6.15042 33.9453 5.84949 33.5839 5.84949C33.2226 5.84949 32.8612 6.21085 32.8612 6.57221V15.6726C32.8612 16.1548 33.1621 16.4557 33.5839 16.4557Z"
            fill="white"
          />
          <path
            d="M26.653 34.596C31.6551 34.596 35.6929 30.5582 35.6929 25.556C35.6929 20.5539 31.6551 16.5161 26.653 16.5161C21.6508 16.5161 17.613 20.5539 17.613 25.556C17.613 30.5582 21.6508 34.596 26.653 34.596ZM23.4588 27.7254C23.4588 27.3036 23.8213 27.0027 24.1827 27.0027C24.544 27.0027 24.9054 27.364 24.9054 27.7254V28.268C24.9054 28.8106 25.3272 29.2324 25.8698 29.2324H27.256C27.7986 29.2324 28.2204 28.8106 28.2204 28.268V27.1235C28.2204 26.9422 28.16 26.6413 27.7382 26.46L24.845 25.9778C23.941 25.6769 23.3391 24.8333 23.3391 23.9293V22.7232C23.3391 21.3974 24.4232 20.3134 25.749 20.2529V19.7103C25.749 19.2885 26.1115 18.9876 26.4729 18.9876C26.8342 18.9876 27.1956 19.3489 27.1956 19.7103V20.1925C28.5214 20.2529 29.6066 21.3382 29.6066 22.664V23.2054C29.6066 23.6272 29.2453 23.9293 28.8827 23.9293C28.5214 23.9293 28.16 23.568 28.16 23.2054V22.664C28.16 22.1213 27.7382 21.6996 27.1956 21.6391H25.8094C25.2668 21.6391 24.845 22.0609 24.845 22.6035V23.8085C24.845 24.049 25.0262 24.2907 25.2668 24.4115L28.2204 24.953C29.1244 25.2551 29.7275 26.0987 29.6671 27.0631V28.2076C29.6671 29.5334 28.5818 30.6186 27.256 30.6791V31.2809C27.256 31.7027 26.8947 32.0048 26.5333 32.0048C26.1707 32.0048 25.8094 31.6435 25.8094 31.2809V30.6791C24.4836 30.6186 23.3995 29.5334 23.3995 28.2076L23.4588 27.7254Z"
            fill="white"
          />
          <path
            d="M49.3132 52.1331C49.3132 52.5549 49.6745 52.9163 50.0963 52.9163C50.5181 52.9163 50.8795 52.5549 50.8795 52.1331C50.8795 51.7113 50.5181 51.35 50.0963 51.35C49.6745 51.4104 49.3132 51.7113 49.3132 52.1331Z"
            fill="white"
          />
          <mask
            id="mask0_372_10334"
            style={{maskType:'luminance'}}
            maskUnits="userSpaceOnUse"
            x="5"
            y="32"
            width="52"
            height="26"
          >
            <path
              d="M5.11829 32.8437H56.9361V57.0323H5.11829V32.8437Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0_372_10334)">
            <path
              d="M56.1838 55.6283H46.6616V38.9951H54.6163C55.0381 38.9951 55.3402 38.6338 55.3402 38.2724C55.3402 37.911 54.9776 37.5485 54.6163 37.5485H46.6616V35.1386C46.6616 34.7169 46.3003 34.4147 45.9377 34.4147C45.5764 34.4147 45.215 34.7773 45.215 35.1386V37.5485H40.8763C40.4545 37.5485 40.1524 37.8506 40.1524 38.2724V40.4418H38.7662L34.3671 36.5853C34.2463 36.4644 34.0662 36.404 33.8849 36.404H21.8925C21.5311 36.404 21.1698 36.7049 21.1698 37.0675C21.1698 37.1267 21.1093 37.5485 21.1093 37.5485C21.1093 38.6338 21.4707 39.6586 22.0738 40.5022H21.229C21.229 40.5022 17.9152 35.3792 17.7943 35.1991C16.709 33.6316 14.8418 32.5475 12.9734 33.1494L11.7673 33.5712C11.4059 33.7524 11.1654 34.1742 11.3455 34.5356L11.6476 35.0782C9.9605 34.1742 7.78997 34.0534 6.40377 35.4396L5.49977 36.404C5.25926 36.7654 5.31969 37.2476 5.62062 37.4893L16.4081 44.9013L33.3423 51.2292C33.4631 51.2292 40.092 51.2292 40.092 51.2292V52.4342C40.092 52.8559 40.3941 53.1581 40.8159 53.1581H45.0942V56.3522C45.0942 56.774 45.4567 57.075 45.8181 57.075H56.0629C56.4847 57.075 56.7856 56.7136 56.7856 56.3522C56.7856 55.9897 56.5451 55.6283 56.1838 55.6283ZM13.3952 34.5356C14.721 34.1138 16.1676 35.1991 16.8299 36.5248L18.4578 39.0556L14.1179 36.5248L13.0338 34.6564L13.3952 34.5356ZM40.1524 49.7222H33.7652L17.132 43.5755L7.1881 36.7049L7.42861 36.4644C8.45345 35.4396 10.201 35.8009 11.4059 36.6445L17.8547 40.3813L20.3854 41.8884C20.5063 41.9488 20.6271 42.0092 20.7468 42.0092H31.5343C31.9572 42.0092 32.2582 41.6467 32.2582 41.2853C32.2582 40.8635 31.8968 40.5626 31.5343 40.5626H24.6649C23.5192 40.3813 22.6756 39.116 22.5548 37.9715H33.5235L37.9226 41.828C38.0435 41.9488 38.2248 42.0092 38.4049 42.0092H40.092L40.1524 49.7222Z"
              fill="white"
            />
          </g>
        </svg>
      </div>
      <div className={style.div3}>
        <div className={style.div4}></div>
        <div className={style.div5}>Network Rate Card</div>
        <div className={style.div6}>Compare rate with other networks</div>
        <Button
          as={Link}
          to="/app/sales-tools/network-rate-card-calculator"
          mb={9}
          className="chakra-primary-button"
        >
          Compare Rates
        </Button>
        <div className={style.div7}>
          <img
            alt=""
            loading="lazy"
            src="/images/icons/truckIcon.svg"
            className={style.img}
          />
          <div>Add First mile, Middle mile and Last Mile cost</div>
        </div>
        <div className={style.div7}>
          <img
            alt=""
            loading="lazy"
            src="/images/icons/calculateIcon.svg"
            className={style.img}
          />
          <div>Calculate rates based on weight, Discount etc</div>
        </div>
      </div>
    </div>
  );
};
