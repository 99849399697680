export const LabelLayers = [
  "national-park",
  "landuse",
  "water-shadow",
  "waterway",
  "water",
  "hillshade",
  "land-structure-polygon",
  "land-structure-line",
  "aeroway-polygon",
  "aeroway-line",
  "building-outline",
  "building",
  "tunnel-street-minor-low",
  "tunnel-street-minor-case",
  "tunnel-primary-secondary-tertiary-case",
  "tunnel-major-link-case",
  "tunnel-motorway-trunk-case",
  "tunnel-construction",
  "tunnel-path",
  "tunnel-steps",
  "tunnel-major-link",
  "tunnel-pedestrian",
  "tunnel-street-minor",
  "tunnel-primary-secondary-tertiary",
  "tunnel-motorway-trunk",
  "road-pedestrian-case",
  "road-minor-low",
  "road-street-low",
  "road-minor-case",
  "road-street-case",
  "road-secondary-tertiary-case",
  "road-primary-case",
  "road-major-link-case",
  "road-motorway-trunk-case",
  "road-construction",
  "road-path",
  "road-steps",
  "road-major-link",
  "road-pedestrian",
  "road-minor",
  "road-street",
  "road-secondary-tertiary",
  "road-primary",
  "road-motorway-trunk",
  "road-rail",
  "bridge-pedestrian-case",
  "bridge-street-minor-low",
  "bridge-street-minor-case",
  "bridge-primary-secondary-tertiary-case",
  "bridge-major-link-case",
  "bridge-motorway-trunk-case",
  "bridge-construction",
  "bridge-path",
  "bridge-steps",
  "bridge-major-link",
  "bridge-pedestrian",
  "bridge-street-minor",
  "bridge-primary-secondary-tertiary",
  "bridge-motorway-trunk",
  "bridge-rail",
  "bridge-major-link-2-case",
  "bridge-motorway-trunk-2-case",
  "bridge-major-link-2",
  "bridge-motorway-trunk-2",
  "road-label",
  "waterway-label",
  "natural-line-label",
  "natural-point-label",
  "water-line-label",
  "water-point-label",
  "poi-label",
  "airport-label",
  "settlement-subdivision-label",
  "settlement-label",
  "state-label",
  "country-label",
];
