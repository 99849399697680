export function ShipmentIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentcolor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.9998 3V8.53846" />
      <path d="M3 8.53847H21V19.6154C21 19.9826 20.8541 20.3348 20.5944 20.5945C20.3348 20.8541 19.9826 21 19.6154 21H4.38462C4.01739 21 3.66521 20.8541 3.40554 20.5945C3.14588 20.3348 3 19.9826 3 19.6154V8.53847Z" />
      <path d="M3 8.53846L5.07692 4.53692C5.30083 4.08591 5.64347 3.70449 6.06798 3.43368C6.49248 3.16288 6.98279 3.01294 7.48615 3H16.5138C17.0291 3.00027 17.5339 3.14426 17.9718 3.41577C18.4097 3.68728 18.763 4.07556 18.9923 4.53692L21 8.53846" />
      <path d="M15.4617 14.7692H8.53857" />
    </svg>
  );
}
