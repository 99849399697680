import React from "react";
import PropTypes from "prop-types";
import { Text, Heading } from "@chakra-ui/react";
import { renderDate, renderTime } from "../../utilities";
import classes from "./TrackingHistory.module.css";

const TrackingHistory = ({ data }) => {
  return (
    <div className={classes.circleContainer}>
      {data &&
        data.map((item, index) => (
          <div className={classes.flexRow} key={index}>
            <div className={classes.outerCircle}>
              <div
                className={`${classes.innerCircle} ${
                  index === 0 ||
                  item.status.toLowerCase() === "delivered" ||
                  item.status.toLowerCase() === "d"
                    ? classes.innerCircleGreen
                    : classes.innerCircleGrey
                }`}
              ></div>
              {index !== data.length - 1 && (
                <div className={classes.line}></div>
              )}
            </div>
            <div className={classes.data}>
              <Heading as={"h4"} size={"sm"} mt={1} mb={0}>
                {item.city}, {item.state}, {item.zip}
              </Heading>
              <Text fontSize={"sm"} opacity={0.6}>
                Time: <strong>{renderTime(item.timestamp)}</strong>
              </Text>
              <Text fontSize={"sm"} opacity={0.6}>
                Date: <strong>{renderDate(item.timestamp)}</strong>
              </Text>
              <Text fontSize={"sm"} opacity={0.6}>
                Carrier: <strong>{item.carrierName || "-"}</strong>
              </Text>
              <Text fontSize={"sm"} opacity={0.6}>
                Network track code:{" "}
                <strong>{item.networkTrackCode || "-"}</strong>
              </Text>
              <Text fontSize={"sm"} opacity={0.6}>
                Status: <strong>{item.status || "-"}</strong>
              </Text>
              <Text fontSize={"sm"} opacity={0.6}>
                Delivery Signature:{" "}
                <strong>{item.deliverySignature || "-"}</strong>
              </Text>
              <Text fontSize={"sm"} opacity={0.6}>
                Comments: <strong>{item.comments || "-"}</strong>
              </Text>
            </div>
          </div>
        ))}
    </div>
  );
};

export default TrackingHistory;

TrackingHistory.defaultProps = {
  data: [],
};

TrackingHistory.propTypes = {
  data: PropTypes.array,
};
