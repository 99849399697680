export const dummyKpi = [
	{
		id: "1001",
		label: "Parcels Intake",
		amount: 88.6,
		type: "percent",
		trend: "down",
		value: 10519,
		total: 11867,
	},
	{
		id: "1002",
		label: "Dummy In Sort-Center",
		amount: 104.7,
		type: "percent",
		trend: "up",
		value: 44391,
		total: 42384,
	},
	{
		id: "1003",
		label: "Parcels In Transit",
		amount: 96.9,
		type: "percent",
		trend: "up",
		value: 9171,
		total: 9463,
	},
	{
		id: "1004",
		label: "Parcels Delivered",
		amount: 96.2,
		type: "percent",
		trend: "up",
		value: 7703,
		total: 8007,
	},
];

export const dummyChart = {
	carriers: [
		{
			period: "Today",
			key: "",
			value: "11894",
		},
		{
			period: "Today",
			key: "",
			value: "7894",
		},
		{
			period: "Today",
			key: "",
			value: "9856",
		},
		{
			period: "Today",
			key: "",
			value: "8556",
		},
		{
			period: "Today",
			key: "",
			value: "8745",
		},
	],
	zips: [
		{
			period: "Today",
			key: "",
			value: "11456",
		},
		{
			period: "Today",
			key: "",
			value: "7895",
		},
		{
			period: "Today",
			key: "",
			value: "9875",
		},
		{
			period: "Today",
			key: "",
			value: "12500",
		},
		{
			period: "Today",
			key: "",
			value: "8944",
		},
	],
};
