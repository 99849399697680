import { Link } from "react-router-dom";

import { StepPage } from ".";
export function Step8() {
  return (
    <StepPage>
      <div className="flex flex-col w-full text-white font-['Montserrat'] pt-8">
        <h2 className="text-white text-3xl font-light font-['Montserrat'] leading-10 tracking-wide mb-2">
          Welcome to the
        </h2>
        <h3 className="text-white text-4xl font-light font-['Montserrat'] leading-10 tracking-wide">
          Orchestro Open Logistics Network
        </h3>

        <div className="mt-12 opacity-80 bg-slate-900/75 rounded-lg overflow-clip flex-col justify-start items-start gap-2.5 inline-flex">
          <h4 className="px-8 py-5 w-full bg-slate-950/75 text-white text-2xl font-normal font-['Montserrat'] leading-10 tracking-wide">
            Benefits of being an Orchestro Member:
          </h4>
          <div className="flex gap-4 w-full px-8 mb-6 py-3">
            <div className="w-1/3 text-white text-xl font-normal font-['Montserrat'] leading-10 tracking-wide">
              • On time delivery:
              <br />
              • Reliability:
              <br />
              • US Coverage:
              <br />
            </div>
            <div className="w-1/3 text-white text-xl font-medium font-['Montserrat'] leading-10 tracking-wide">
              99.95%
              <br />
              100%
              <br />
              98%
              <br />
            </div>
            <div className="w-1/3 text-white text-xl font-normal font-['Montserrat'] leading-10 tracking-wide">
              • End-to-end tracking​
              <br />
              • Easy onboarding
              <br />
            </div>
          </div>
        </div>

        <div className="flex gap-4 items-center mt-12 px-8 py-5 opacity-80 bg-slate-950/75 rounded-lg overflow-clip">
          <p className="text-white text-xl font-semibold font-['Montserrat'] leading-9">
            PLD Calculator to see savings with Orchestro:
          </p>
          <Link
            to="../step/9"
            className="hover:border-sky-400 w-40 h-11 px-4 rounded-lg border border-zinc-200 justify-center items-center gap-2 inline-flex"
          >
            <div className="text-sky-400 text-base font-semibold font-['Montserrat'] leading-snug tracking-tight">
              Calculate PLD
            </div>
          </Link>
        </div>

        <div className="mt-[150px]">
          <p className="text-white text-lg font-normal font-['Montserrat'] leading-relaxed tracking-tight">
            For support, please reach out to{" "}
            <a href="mailto:support@orchestro.ai" className="text-sky-400">
              support@orchestro.ai
            </a>
          </p>
        </div>
      </div>
    </StepPage>
  );
}
