export function titleCase(string) {
  string = string
    .toLowerCase()
    .split(" ")
    .map((word) => {
      let w = word.charAt(0).toUpperCase() + word.slice(1);
      if (w === "Fedex") w = "FedEx";
      if (w === "Ups") w = "UPS";
      return w;
    })
    .join(" ");

  return string;
}
