export const downloadFileFromUrl = (url, fileName = "") => {
  // Create a hidden anchor element
  const link = document.createElement("a");

  // Set the href and download attributes to trigger the download
  link.href = url;
  link.download = fileName;

  // Append the anchor element to the body
  document.body.appendChild(link);

  // Trigger a click on the anchor element to start the download
  link.click();

  // Remove the anchor element from the body
  document.body.removeChild(link);
};