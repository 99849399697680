import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { getBidConfigById } from "../../services";
import { TableLoading } from "../TableLoading";
import BiddingTierModal from "./BiddingTierModal";

const BidConfigurationTable = ({
  bidConfigs,
  handleOpenConfigurationBar,
  setBidConfigValue,
  bidConfigsLoading,
  getBidConfigs,
}) => {
  const {
    isOpen: isOpenBiddingTierModal,
    onOpen: onBiddingTierModalOpen,
    onClose: onBiddingTierModalClose,
  } = useDisclosure();
  const [selectedBid, setSelectedBid] = useState(null);

  const handleOpenBiddingTierModal = useCallback(
    (bidConfig) => {
      onBiddingTierModalOpen();
      setSelectedBid(bidConfig);
    },
    [onBiddingTierModalOpen]
  );

  const handleOpenBidConfig = useCallback(
    async (uuid) => {
      handleOpenConfigurationBar();
      try {
        const config = await getBidConfigById(uuid);
        setBidConfigValue(config);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    [handleOpenConfigurationBar, setBidConfigValue]
  );

  return (
    <div className="mt-5">
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Risk</Th>
              <Th>Range Spread</Th>
              <Th>Bidding Threshold</Th>
              <Th>Bidding Tiers</Th>
              <Th>Time Window</Th>
              <Th>Loss Guardrail</Th>
              <Th>Win Guardrail</Th>
            </Tr>
          </Thead>
          <Tbody>
            {bidConfigsLoading && <TableLoading data={bidConfigs} />}
            {!bidConfigsLoading && bidConfigs && bidConfigs.length === 0 && (
              <Tr>
                <Td colSpan="100">No data available</Td>
              </Tr>
            )}
            {bidConfigs &&
              bidConfigs.map((bidConfig, index) => {
                return (
                  <Tr key={index}>
                    <Td
                      className="!py-3 text-[#2294FF] cursor-pointer"
                      onClick={() => {
                        handleOpenBidConfig(bidConfig.uuid);
                      }}
                    >
                      {bidConfig?.name ?? "-"}
                    </Td>
                    <Td className="capitalize">
                      {bidConfig.risk.toLowerCase()}
                    </Td>
                    <Td>{bidConfig?.rangeSpread}</Td>
                    <Td>{bidConfig?.adjustments?.initialBiddingThreshold}</Td>
                    <Td
                      className="!py-3 text-[#2294FF] cursor-pointer"
                      onClick={() => {
                        handleOpenBiddingTierModal(bidConfig);
                      }}
                    >
                      {bidConfig?.adjustments?.biddingTier}
                    </Td>
                    <Td>{bidConfig?.adjustments?.timeWindow} min</Td>
                    <Td>{bidConfig?.adjustments?.minNoLastLossDecrement}</Td>
                    <Td>{bidConfig?.adjustments?.minNoLastWonIncrement}</Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>

      <BiddingTierModal
        selectedBid={selectedBid}
        isOpen={isOpenBiddingTierModal}
        onClose={onBiddingTierModalClose}
        getBidConfigs={getBidConfigs}
      />
    </div>
  );
};

export default BidConfigurationTable;

BidConfigurationTable.defaultProps = {
  bidConfigs: [],
  handleOpenConfigurationBar: () => {},
  setBidConfigValue: () => {},
  bidConfigsLoading: false,
  getBidConfigs: () => {},
};

BidConfigurationTable.propTypes = {
  bidConfigs: PropTypes.array,
  handleOpenConfigurationBar: PropTypes.func,
  setBidConfigValue: PropTypes.func,
  bidConfigsLoading: PropTypes.bool,
  getBidConfigs: PropTypes.func,
};
