
export function InvoicesIcon() {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentcolor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" xmlns="http://www.w3.org/2000/svg">
			<path d="M12.2797 8.84639C12.1986 8.6171 12.0736 8.40857 11.9147 8.23101C11.5766 7.85331 11.0854 7.61563 10.5386 7.61563H9.10961C8.19948 7.61563 7.46167 8.35344 7.46167 9.26356C7.46167 10.038 8.00091 10.7079 8.75745 10.8734L10.9331 11.3494C11.7806 11.5348 12.3847 12.2858 12.3847 13.1534C12.3847 14.173 11.5582 15.0002 10.5386 15.0002H9.30782C8.50399 15.0002 7.82016 14.4865 7.56672 13.7695"/>
			<path d="M9.92334 7.61538V5.76923"/>
			<path d="M9.92334 16.8463V15.0001"/>
			<path d="M18.9231 3H5.76923C5.03479 3 4.33042 3.29176 3.81109 3.81109C3.29176 4.33042 3 5.03479 3 5.76923V21L6.46154 18.9231L9.92308 21L13.3846 18.9231L16.8462 21V5.07692C16.8462 4.5261 17.0649 3.99781 17.4544 3.60832C17.8439 3.21882 18.3723 3 18.9231 3ZM18.9231 3C19.4739 3 20.0022 3.21882 20.3917 3.60832C20.7812 3.99781 21 4.5261 21 5.07692V9.92308H16.8462"/>
		</svg>
	)
}
