import React, { useEffect, useState } from "react";
import "../css-files/style.css";
import {
	getEventsBasedOnTrackerIdPublic,
	getParcelsBasedOnId,
} from "../services";
import { geocodeLocation } from "../utilities";
import Nav from "../interfaces/PublicTracking/Nav";
import BackgroundLayer from "../interfaces/PublicTracking/BackgroundLayer";
import Tracker from "../interfaces/TrackTrace/MultiTrack/Tracker";
import { useToast, Box } from "@chakra-ui/react";

const PublicTracking = () => {
	const [trackingData, setTrackingData] = useState([]);
	const [trackingId, setTrackingId] = useState("");
	const [locationData, setLocationData] = useState(null);
	const [parcelData, setParcelData] = useState(null);
	const [isValidId, setIsValidId] = useState(false);
	const [isMapLoading, setIsMapLoading] = useState({
		routes: false,
		origin: false,
		destination: false,
	});
	const [route, setRoute] = useState({
		origin: [0, 0],
		destination: [0, 0],
		routes: [],
	});
	const [loading, setLoading] = useState(false);
	const [trackerStatus, setTrackerStatus] = useState(false);
	const [calledOnce, setCalledOnce] = useState(false);
	const [error, setError] = useState(false);
	const MIN_INPUT_LENGTH = 6;
	const MAX_INPUT_LENGTH = 30;

	const toast = useToast();

	const triggerToast = () => {
		toast({
			status: "error",
			duration: 5000,
			isClosable: true,
			variant: "solid",
			position: "bottom",
			render: () => (
				<Box
					display="grid"
					placeItems="center"
					bg="#EF4444"
					color="white"
					p={4}
					ml={4}
					borderRadius="md"
					maxW={["60vw", "md:max-width: 400px"]}
					textAlign="center"
				>
					<strong>Invalid Tracking Number</strong>
					<p>The tracking number you entered was not found.</p>
				</Box>
			),
		});
	};

	const handleInputChange = (e) => {
		setError(false);
		const tValue = e.target.value;
		if (tValue.length > MIN_INPUT_LENGTH && tValue.length < MAX_INPUT_LENGTH) {
			setIsValidId(true);
			setTrackingId(tValue);
		} else if (tValue.length === 0) {
			setTrackingId("");
			setIsValidId(false);
		} else {
			setIsValidId(false);
		}
	};

	const getEvents = async () => {
		setLoading(true);
		try {
			const data = await getEventsBasedOnTrackerIdPublic(trackingId);
			if (data.results.length === 0) {
				setError(true);
				setLoading(false);
				setTrackerStatus(false);
				setCalledOnce(false);
				triggerToast();
			} else {
				const { results, ...rest } = data;
				setError(false);
				setTrackingData(results);
				setLocationData(rest);
				setTrackerStatus(true);
				setCalledOnce(true);
			}
			setLoading(false);
		} catch (error) {
			console.error("Error fetching data:", error);
			setLoading(false);
			setCalledOnce(false);
			setTrackerStatus(false);
			triggerToast();
		}
	};

	useEffect(() => {
		if (trackingId && isValidId) {
			getParcelsBasedOnId(trackingId)
				.then((response) => {
					if (response && response.parcels) {
						setParcelData(response.parcels);
					} else {
						console.error("Unexpected response format:", response);
					}
				})
				.catch((error) => {
					console.error("Error fetching data:", error);
				});
		}
	}, [trackingId, isValidId]);

	useEffect(() => {
		if (locationData) {
			if (locationData?.shipFrom) {
				setIsMapLoading((loadings) => ({
					...loadings,
					origin: true,
				}));
				geocodeLocation({
					street: locationData.shipFrom.address1 ?? "",
					city: locationData.shipFrom.city ?? "",
					state: locationData.shipFrom.state ?? "",
					zip: locationData.shipFrom.zip ?? "",
				})
					.then((coords) => {
						if (coords) {
							setRoute((route) => ({
								...route,
								origin: coords.coords.reverse(),
							}));
						} else {
							console.error("Geocoding failed:", coords);
							setIsMapLoading((loadings) => ({ ...loadings, origin: false }));
						}
					})
					.catch((error) => {
						console.error("Geocoding failed:", error);
						setIsMapLoading((loadings) => ({ ...loadings, origin: false }));
					})
					.finally(() => {
						setIsMapLoading((loadings) => ({ ...loadings, origin: false }));
					});
			}
		}
	}, [locationData]);

	useEffect(() => {
		if (trackingData?.length > 0) {
			setIsMapLoading((loadings) => ({ ...loadings, routes: true }));
			const events = [...trackingData].reverse();
			if (route.origin[0] === 0 && route.origin[1] === 0) {
				if (events[0].city || events[0].state || events[0].zip) {
					geocodeLocation({
						city: events[0].city ?? "",
						state: events[0].state ?? "",
						zip: events[0].zip ?? "",
					})
						.then((coords) => {
							if (coords) {
								setRoute((route) => ({
									...route,
									destination: coords.coords.reverse(),
								}));
							} else {
								console.error("Geocoding failed:", coords);
								setIsMapLoading((loadings) => ({
									...loadings,
									routes: false,
									destination: false,
								}));
							}
						})
						.catch((error) => {
							console.error("Geocoding failed:", error);
							setIsMapLoading((loadings) => ({
								...loadings,
								routes: false,
								destination: false,
							}));
						})
						.finally(() => {
							setIsMapLoading((loadings) => ({
								...loadings,
								routes: false,
								destination: false,
							}));
						});
				} else if (events[0]?.latitude && events[0]?.longitude) {
					setRoute((route) => ({
						...route,
						origin: [events[0].latitude, events[0].longitude],
					}));
				} else {
					setIsMapLoading((loadings) => ({
						...loadings,
						routes: false,
						destination: false,
					}));
				}
				events.shift();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [trackingData]);

	useEffect(() => {
		if (route.destination[0] === 0 && route.destination[1] === 0) {
			route.destination = route.origin;
		}

		setRoute(route);
	}, [route]);

	return (
		<div className="pb-20">
			<div className="min-h-screen flex flex-col">
				<BackgroundLayer trackingData={trackingData} />
				<div className="content-layer">
					<Nav />
					<section id="tracking">
						{!calledOnce && (
							<div className="flex flex-col mx-10 md:flex-row items-center md:items-start">
								<div className="w-full md:w-1/2 p-4 ml-6">
									<h2 className="text-[24px] md:text-[30px] font-normal mb-4 md:mb-[27px] leading-tight w-1/2 mx-auto md:mx-0 md:text-left text-center">
										Track your package with Orchestro.AI
									</h2>
									<p className="text-[16px] font-normal w-3/4 mx-auto md:mx-0 md:text-left text-center">
										Stay informed with Orchestro.AI, offering visibility and
										reliability for peace of mind with every package.
									</p>
								</div>
								<div className="w-full flex items-center justify-center md:justify-right md:w-1/2 p-0 pt-10 text-left">
									<img
										src="/images/tracking/TrackingImage.png"
										alt="Illustration of a Parcel"
										className="w-[261px] h-auto"
									/>
								</div>
							</div>
						)}

						<Tracker
							key="tracker1"
							isPublicTracking={true}
							setRoute={setRoute}
							handleInputChange={handleInputChange}
							getEvents={getEvents}
							error={error}
							setTrackerStatus={setTrackerStatus}
							setTrackingId={setTrackingId}
							locationData={locationData}
							trackerStatus={trackerStatus}
							trackingId={trackingId}
							trackingData={trackingData}
							isMapLoading={isMapLoading}
							route={route}
							parcelData={parcelData}
							loading={loading}
						/>

						{/* {message && (
							<div className="mt-[15px]">
								<h4 className=" text-[22px]  text-center font-semibold">
									{message}
								</h4>
							</div>
						)} */}
					</section>
				</div>
			</div>
		</div>
	);
};

export default PublicTracking;
