import React, { useState } from "react";
import History from "../../interfaces/TrackTrace/History";
import Map from "../../interfaces/TrackTrace/Map";

const Tabs = ({ route }) => {
  const [activeTab, setActiveTab] = useState("tab1");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="mt-8 flex flex-col bg-[#21232E]">
      <div className="flex mt-10">
        <button
          className={`tab-button font-semibold pb-2 px-4 ${activeTab === "tab1" ? "text-[#EBEBEB] border-b-4 border-[#3BA0E6]" : "text-[#EBEBEB]/60"}`}
          onClick={() => handleTabClick("tab1")}
        >
          Map
        </button>
        <button
          className={`ml-10 tab-button font-semibold pb-2 px-4 ${activeTab === "tab2" ? "text-[#EBEBEB] border-b-4 border-[#3BA0E6]" : "text-[#EBEBEB]/60"}`}
          onClick={() => handleTabClick("tab2")}
          >
          Tracking History
        </button>
      </div>
      <div className="m-2 mt-0 rounded-md bg-[#191A23]">
        {activeTab === "tab1" && (
          <div>
            <Map route={route} />
          </div>
        )}
        {activeTab === "tab2" && (
          <div>
            <History />
          </div>
        )}
      </div>
    </div>
  );
};

export default Tabs;
