
export function StepCounter({ step }) {
	return (
		<div className="flex items-center justify-center">
			<div className="flex items-center justify-between h-14 w-96">
				<div className="">
					<div className="w-14 h-14 rounded-full border-4 border-sky-600 flex justify-center items-center">
						<p className="text-base font-normal leading-10 tracking-wide">
							1
						</p>
					</div>
				</div>
				<div>
					<div className={`${step === 1 ? 'opacity-50' : ''} w-14 h-14 rounded-full border-4 border-sky-600 flex justify-center items-center`}>
						<p className="text-base font-normal leading-10 tracking-wide">
							2
						</p>
					</div>
				</div>
				<div>
					<div className={`${step === 1 || step === 2 ? 'opacity-50' : ''} w-14 h-14 rounded-full border-4 border-sky-600 flex justify-center items-center`}>
						<p className="text-base font-normal leading-10 tracking-wide">
							3
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}