import React from "react";
import PropTypes from "prop-types";
import { Card, Divider, Flex, Text } from "@chakra-ui/react";

const Summary = ({ data }) => {
  return (
    <Card
      boxShadow={"0px 2px 4px 0px #00396b33"}
      bg={"#21232E !important"}
      marginTop={5}
      padding={"20px 32px 30px"}
    >
      {Object.keys(data).length > 0 ? (
        <>
          <Text className="text-base text-white font-medium	leading-6">
            {data?.heading}
          </Text>
          <div className="mt-7">
            <Flex wrap={"wrap"}>
              <Text className="text-white font-medium mb-2 w-[200px]">
                Shipper 1 Spend:
              </Text>
              <Text className="text-white font-semibold mb-4">
                {data?.["Shipper 1 Spend"]}
              </Text>
            </Flex>
            <Flex wrap={"wrap"}>
              <Text className="text-white font-medium mb-2 w-[200px]">
                Top 3 Carriers:
              </Text>
              <div>
                {Object.entries(data["Top 3 Carrier Mix"]).map(
                  ([carrier, amount], i) => (
                    <Flex key={i} className="mb-2">
                      <img
                        alt=""
                        className="mr-2 h-[24px] w-24px]"
                        src={`/images/demo/${carrier.split(" ")[0].toLowerCase()}.png`}
                      />
                      <Text className="text-white font-semibold mb-2 w-[80px]">
                        {carrier}
                      </Text>
                      <Text className="text-white font-semibold mb-2">
                        {amount}
                      </Text>
                    </Flex>
                  )
                )}
              </div>
            </Flex>
          </div>
          <Divider className="my-5" />
          <Text className="text-base text-white font-medium	leading-6 mb-8">
            Opportunities
          </Text>
          {data?.Opportunities?.opp1?.message && (
            <div className="mb-5">
              <Flex alignItems={"center"}>
                <img
                  alt="acceptance"
                  src="/images/track/accepted.svg"
                  className="w-7"
                />
                <Text className="font-bold leading-7 text-white ml-4">
                  {data?.Opportunities?.opp1?.message}
                </Text>
              </Flex>
            </div>
          )}
          {data?.Opportunities?.opp2?.message1 &&
            data?.Opportunities?.opp2?.message2 && (
              <div className="mb-5">
                <Flex alignItems={"center"}>
                  <img
                    alt="acceptance"
                    src="/images/track/accepted.svg"
                    className="w-7"
                  />
                  <Text className="font-bold leading-7 text-white ml-4">
                    {data?.Opportunities?.opp2?.message1}
                  </Text>
                </Flex>
                <div className="mt-4 ml-10 sm:ml-24 md:ml-48">
                  {Object.entries(data?.Opportunities?.opp2?.message2).map(
                    ([carrier, amount], i) => (
                      <Flex key={i}>
                        <img
                          alt=""
                          className="mr-2 h-[24px] w-24px]"
                          src={`/images/demo/${carrier.split(" ")[0].toLowerCase()}.png`}
                        />
                        <Text className="text-white font-semibold mb-2 w-[80px]">
                          {carrier}
                        </Text>
                        <Text className="text-white font-semibold mb-2">
                          {amount}
                        </Text>
                      </Flex>
                    )
                  )}
                </div>
              </div>
            )}
          {data?.Opportunities?.opp3?.message1 &&
            data?.Opportunities?.opp3?.message2 && (
              <div className="mb-5">
                <Flex alignItems={"center"}>
                  <img
                    alt="acceptance"
                    src="/images/track/accepted.svg"
                    className="w-7"
                  />
                  <Text className="font-bold leading-7 text-white ml-4">
                    {data?.Opportunities?.opp3?.message1}
                  </Text>
                </Flex>
                <div className="mt-4 ml-10 sm:ml-24 md:ml-48">
                  {Object.entries(data?.Opportunities?.opp3?.message2).map(
                    ([carrier, amount], i) => (
                      <Flex key={i}>
                        <img
                          alt=""
                          className="mr-2 h-[24px] w-24px]"
                          src={`/images/demo/${carrier.split(" ")[0].toLowerCase()}.png`}
                        />
                        <Text className="text-white font-semibold mb-2 w-[80px]">
                          {carrier}
                        </Text>
                        <Text className="text-white font-semibold mb-2">
                          {amount}
                        </Text>
                      </Flex>
                    )
                  )}
                </div>
              </div>
            )}
        </>
      ) : (
        <div className="text-center mt-4">No data available</div>
      )}
    </Card>
  );
};

export default Summary;

Summary.defaultProps = {
  data: {},
};

Summary.propTypes = {
  data: PropTypes.object,
};
