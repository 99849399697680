import { useState, useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import Button from "../../../componentLibrary/components/Button";
import { LabelLayers } from "./MapLabels";
import { reverseGeocoding } from "../../../utilities/reverseGeocoding";
// import Popover from './popover'
import { MAPBOX_API_KEY } from "../../../constants";

mapboxgl.accessToken = MAPBOX_API_KEY;

function MapTrack({
	route,
	trackingId = null,
	timestamps = null,
	trackingData = null,
	isPublicTracking,
	setShowFullScreen,
}) {
	const mapContainer = useRef(null);
	const map = useRef(null);
	const [isMapLoaded, setIsMapLoaded] = useState(false);
	const [startPosition, setStartPosition] = useState(null);
	const [isAnimating, setIsAnimating] = useState(false);
	const [showOverlay, setShowOverlay] = useState(false);
	const [zoom] = useState(1.5);

	const elPackage = document.createElement("div");
	elPackage.innerHTML = '<div><img src="/images/map/marker_red.svg" /></div>';

	const onMapEnlarge = () => {
		setIsAnimating(true);
	};

	const handleAnimationEnd = () => {
		setShowOverlay(true);
		setTimeout(() => {
			setShowFullScreen(true);
			setIsAnimating(false);
		}, 300);
	};

	useEffect(() => {
		if (
			!mapContainer.current ||
			map.current ||
			(route.origin && route.origin[0] === 0 && route.origin[1] === 0)
		)
			return;

		const defaultCenter = [-74.5, 40];
		const center =
			route && route.origin
				? [route.destination[1], route.destination[0]]
				: defaultCenter;

		map.current = new mapboxgl.Map({
			container: mapContainer.current,
			style: "mapbox://styles/mapbox/dark-v10",
			center: center,
			zoom: zoom,
			attributionControl: false,
		});

		map.current.on("load", () => {
			const attributionElement = document.querySelector(
				".mapboxgl-ctrl-attrib",
			);
			if (attributionElement) {
				attributionElement.style.display = "none";
			}

			if (map.current.getLayer("land")) {
				map.current.setPaintProperty("land", "background-color", "#000000");
			}

			LabelLayers.forEach((layer) => {
				if (map.current.getLayer(layer)) {
					map.current.setLayoutProperty(layer, "visibility", "none");
				}
			});

			if (map.current.getLayer("admin-0-boundary")) {
				map.current.setPaintProperty(
					"admin-0-boundary",
					"line-color",
					"#858698",
				);
			}

			if (map.current.getLayer("admin-1-boundary")) {
				map.current.setPaintProperty(
					"admin-1-boundary",
					"line-color",
					"#858698",
				);
			}

			map.current.setPaintProperty("water", "fill-color", "#191A23");
		});

		const fitBounds = (bounds) => {
			if (map.current && bounds) {
				map.current.fitBounds(bounds, {
					padding: 180,
				});
			}
		};

		if (route.destination[0] === 0 && route.destination[1] === 0) {
			route.destination = route.origin;
		}

		const startingPoint = [route.origin[1], route.origin[0]];
		const endPoint = [route.destination[1], route.destination[0]];
		let middlePoints = "";
		if (
			route.routes &&
			Array.isArray(route.routes) &&
			route.routes.length > 0
		) {
			const firstRoute = route.routes[0];
			if (Array.isArray(firstRoute) && firstRoute.length > 0) {
				middlePoints = route.routes.reduce((middlePoint, route) => {
					if (
						Array.isArray(route) &&
						route.length >= 2 &&
						route[0] !== undefined &&
						route[1] !== undefined
					) {
						return `${middlePoint}${route[1]},${route[0]};`;
					}
					return middlePoint;
				}, "");
			}
		}

		//fitBounds([endPoint, startingPoint]);

		map.current.on("load", () => {
			map.current.resize();
			if (startingPoint[0] !== endPoint[0]) {
				fetch(
					`https://api.mapbox.com/directions/v5/mapbox/driving/${startingPoint[0]},${startingPoint[1]};${middlePoints}${endPoint[0]},${endPoint[1]}.json?geometries=geojson&access_token=${mapboxgl.accessToken}`,
				)
					.then((response) => response.json())
					.then((data) => {
						if (data.routes.length > 0) {
							const jsonGeometry = data.routes[0].geometry;
							const productPoint = Math.round(
								jsonGeometry.coordinates.length / 4,
							);

							setStartPosition(jsonGeometry.coordinates[1]);
							let flag = 0;
							const features = jsonGeometry.coordinates.reduce(
								(feature, coordinate, index) => {
									const isRouteCoordinate = data.waypoints.find(
										(point) =>
											JSON.stringify(point.location) ===
											JSON.stringify(coordinate),
									);

									if (index !== 0 && isRouteCoordinate) {
										feature.push({
											type: "LineString",
											coordinates: [
												...jsonGeometry.coordinates.slice(flag, index + 1),
											],
											markerColor:
												index === jsonGeometry.coordinates.length - 1
													? "blue"
													: "blue",
										});
										flag = index;
									}
									return feature;
								},
								[],
							);
							Promise.all(
								features.map((feature, index) => {
									const coordinates = feature.coordinates[0];
									return new Promise((resolve, reject) => {
										if (index === 0) {
											return new Promise(() => {
												resolve({ ...feature });
											});
										} else {
											reverseGeocoding({
												lat: coordinates[1],
												lng: coordinates[0],
											})
												.then((address) => {
													return new Promise(() => {
														resolve({ ...feature, address });
													});
												})
												.catch((error) => {
													return new Promise(() => {
														reject(new Error(error));
													});
												});
										}
									});
								}),
							)
								.then((response) => {
									response.forEach((feature, index) => {
										const sourceId = `route-${index}`;
										const coordinates = feature.coordinates[0];
										map.current.addSource(sourceId, {
											type: "geojson",
											data: {
												type: feature.type,
												coordinates: feature.coordinates,
											},
										});
										const color =
											feature.markerColor === "blue" ? "#3B86ED" : "#DA2C17";
										const marker =
											feature.markerColor === "blue"
												? "marker_blue"
												: "marker_red";

										const el = document.createElement("div");
										if (index === 0) {
											el.innerHTML = `<div><img src="/images/map/start.svg" /></div>`;
										} else {
											el.innerHTML = `<div><img src="/images/map/${marker}.svg" /></div>`;
										}

										// map.current.addLayer({
										//   id: `route-${index}`,
										//   type: "line",
										//   source: sourceId,
										//   layout: {
										//     "line-join": "round",
										//     "line-cap": "round",
										//   },
										//   paint: {
										//     "line-color": color,
										//     "line-width": 4,
										//     "line-dasharray": [2, 2],
										//   },
										// });
										// new mapboxgl.Marker(el)
										//   .setLngLat(coordinates)
										//   .addTo(map.current);

										//   if (index === 0) {
										//     const elOrigin = document.createElement("div");
										//     elOrigin.innerHTML =
										//       '<div><img src="/images/map/origin.svg" /></div>';
										//     elOrigin.style.top = "-30px";

										//     new mapboxgl.Marker(elOrigin)
										//       .setLngLat(coordinates)
										//       .addTo(map.current);
										//   }
										if (index === features.length - 1) {
											//let coordinateParcel = feature.coordinates[productPoint];
											//setParcelPosition(coordinateParcel);

											let coordinateEnd =
												feature.coordinates[feature.coordinates.length - 1];

											// const elEnd = document.createElement("div");
											// elEnd.innerHTML = `<div><img src="/images/map/${marker}.svg" /></div>`;

											// new mapboxgl.Marker(elEnd)
											//   .setLngLat(coordinateEnd)
											//   .addTo(map.current);

											// const elFinal = document.createElement("div");
											// elFinal.innerHTML =
											//   '<div><img src="/images/map/endroute.svg" /></div>';
											// elFinal.style.top = "-30px";

											// new mapboxgl.Marker(elFinal)
											//   .setLngLat(coordinateEnd)
											//   .addTo(map.current);
										}
									});
									setIsMapLoaded(true);
								})
								.catch((error) => {
									console.error("Geocoding failed:", error);
								});
						}
					})
					.catch((error) => {
						console.error("Error fetching data:", error);
					});
			}
			// } else {
			//   const el = document.createElement("div");
			//   el.innerHTML = `<div><img src="/images/map/start.svg" /></div>`;
			//   new mapboxgl.Marker(el).setLngLat(startingPoint).addTo(map.current);
			// }
		});
	}, [route]);

	return (
		<div className="relative w-full h-screen flex items-center justify-center">
			<div className="absolute inset-0 flex items-center justify-center z-10">
				<Button
					className="mt-2 text-base"
					onClick={onMapEnlarge}
					context="primary"
				>
					Click to view map
				</Button>
			</div>
			<div
				ref={mapContainer}
				className={`w-[50vw] h-[50vw] md:w-[20vw] md:h-[20vw] rounded-full overflow-hidden  ${isAnimating ? "animate-scale" : ""}`}
				onAnimationEnd={handleAnimationEnd}
				style={{ zIndex: "0" }}
			/>
			{showOverlay && (
				<div className={`overlay ${showOverlay ? "show" : ""}`}></div>
			)}
		</div>
	);
}

export default MapTrack;
