import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { LoadScript } from "@react-google-maps/api";
import { Box, Grid, Text, useDisclosure } from "@chakra-ui/react";

import { geocodeLocation } from "../../utilities";
import { GOOGLE_MAPS_API_KEY } from "../../constants";
import { ExpandIcon } from "../CustomIcons";
import LaneCoverageMap from "./LaneCoverageMap";
import MapModal from "./MapModal";

const laneMapFilterOptions = {
  existingLanes: {
    label: "Existing Lanes",
    color: "#FFB323",
    isChecked: true,
  },
  newLanes: {
    label: "New Lanes",
    color: "#fff623",
    isChecked: true,
  },
};

const ShipperWhatIfScenario = ({ analysisResult }) => {
  const {
    isOpen: isOpenCoverageMap,
    onOpen: onCoverageMapOpen,
    onClose: onCoverageMapClose,
  } = useDisclosure();

  const [mapData, setMapData] = useState([]);

  const impactOfShipperAddition = useMemo(() => {
    const labels = analysisResult?.impact?.value.flatMap((curr) =>
      curr.value.map(({ label }) => label)
    );

    return {
      labels: [...new Set(labels)],
      impact: analysisResult?.impact?.value,
    };
  }, [analysisResult]);

  const coverageMapData = useMemo(() => {
    return analysisResult?.laneMap?.value.flatMap((curr) => {
      return curr.value.map(({ zips, ...rest }) => ({
        value: zips,
        color:
          curr.label.toLocaleLowerCase() ===
          "Base Configurations".toLocaleLowerCase()
            ? "#FFB323"
            : "#fff623",
        ...rest,
      }));
    });
  }, [analysisResult]);

  useEffect(() => {
    if (coverageMapData && coverageMapData.length > 0) {
      Promise.all(
        coverageMapData.map(async (mapData) => {
          const promises = mapData.value.split(",").map(async (value) => {
            try {
              const { coords } = await geocodeLocation({
                zip: value ?? "",
                country: "us",
              });
              return {
                coords,
                color: mapData.color,
                lane: mapData.lane,
                parcelsPerDay: mapData.parcelsPerDay,
                carrier: mapData.carrier,
              };
            } catch (error) {
              throw new Error(error);
            }
          });
          return Promise.all(promises);
        })
      )
        .then((response) => {
          setMapData(response);
        })
        .catch((error) => {
          console.error("Geocoding failed:", error);
        });
    } else {
      setMapData([]);
    }
  }, [coverageMapData]);

  return (
    <LoadScript
      googleMapsApiKey={`${GOOGLE_MAPS_API_KEY}`}
      loadingElement={<div>Loading...</div>}
    >
      <Grid className="mt-7 grid-cols-1 xl:grid-cols-2 gap-5">
        <Box
          border={"1px solid #E0E1EC33"}
          bg={"#21232E"}
          borderRadius={"6px"}
          padding={"18px 24px 18px 28px"}
          minHeight={"346px"}
        >
          <div className="flex items-center justify-between">
            <Text fontSize={18} fontWeight={700} color={"#fff"}>
              Lane map
            </Text>
            <button onClick={onCoverageMapOpen}>
              <ExpandIcon />
            </button>
          </div>
          <Box marginTop={22}>
            <LaneCoverageMap data={mapData} height={"300px"} />
          </Box>
        </Box>

        <Box
          border={"1px solid #E0E1EC33"}
          bg={"#21232E"}
          borderRadius={"6px"}
          padding={"18px 24px 18px 28px"}
          minHeight={"346px"}
        >
          <Text fontSize={18} fontWeight={700} color={"#fff"}>
            Impact of Shipper Addition
          </Text>
          <Box className="mt-[22px]" display={"flex"}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={4}
              marginTop={"45px"}
              paddingTop={3}
            >
              {impactOfShipperAddition.labels.map((keys, i) => (
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  className="text-lg 2xl:text-xl text-[#EBEBEB]"
                  key={i}
                >
                  <div className="mr-3">
                    {keys === "Average cost/parcel" ? "CPP" : keys}
                  </div>
                  <div className="font-bold">:</div>
                </Box>
              ))}
            </Box>
            <div className="ml-4 md:ml-12 lg:ml-24 xl:ml-[42px] 2xl:ml-24">
              <Box
                display={"flex"}
                className="text-lg 2xl:text-xl text-[#EBEBEB] font-bold"
              >
                {impactOfShipperAddition.impact &&
                  impactOfShipperAddition.impact.length > 0 &&
                  impactOfShipperAddition.impact.map((impact, i) => {
                    const lastElementIndex =
                      impactOfShipperAddition.impact.length - 1;
                    return (
                      <React.Fragment key={i}>
                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          gap={4}
                          className={`${i === lastElementIndex ? "bg-[#001938] px-5 lg:px-[30px] rounded-r-md" : ""} py-3`}
                        >
                          <Box className="font-normal">{impact.label}</Box>
                          {impact.value.map((impactedValue, i) => (
                            <Box key={i}>{impactedValue.value}</Box>
                          ))}
                        </Box>
                        {i !== lastElementIndex ? (
                          <Box
                            className="px-4 lg:px-7 xl:px-4 2xl:px-7"
                            paddingTop={3}
                            fontWeight={400}
                          >
                            Vs
                          </Box>
                        ) : (
                          <></>
                        )}
                      </React.Fragment>
                    );
                  })}
              </Box>
            </div>
          </Box>
        </Box>
      </Grid>

      <Grid className="mt-5 grid-cols-1 lg:grid-cols-2 gap-5">
        <Box
          border={"1px solid #E0E1EC33"}
          bg={"#21232E"}
          borderRadius={"6px"}
          padding={"18px 24px 18px 28px"}
          minHeight={"346px"}
        >
          <Text fontSize={18} fontWeight={700} color={"#fff"}>
            Insights
          </Text>
          <Box className="mt-[22px]" display={"flex"}>
            <ul style={{ listStyleType: "disc", marginLeft: "28px" }}>
              {analysisResult?.insights?.value.map((val, i) => (
                <li className="text-[#fff] font-medium mb-6" key={i}>
                  {val}
                </li>
              ))}
            </ul>
          </Box>
        </Box>
      </Grid>

      <MapModal
        open={isOpenCoverageMap}
        onClose={onCoverageMapClose}
        data={{ label: analysisResult?.laneMap.label, value: mapData }}
        filterOptions={laneMapFilterOptions}
      />
    </LoadScript>
  );
};

export default ShipperWhatIfScenario;

ShipperWhatIfScenario.defaultProps = {
  analysisResult: {},
};

ShipperWhatIfScenario.propTypes = {
  analysisResult: PropTypes.object,
};
