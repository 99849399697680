import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import ParcelHeader from "./ParcelHeader";
import "../../../css-files/style.css";
import {
	getEventsBasedOnTrackerIdPublic,
	getParcelsBasedOnId,
} from "../../../services";
import classes from "../../../css-files/ShipmentDetails.module.css";
import ParcelDetails from "./ParcelDetails";
import EstimatedCharges from "./EstimatedCharges";

function ParcelDetailScreen({ trackingId, isPublicTracking }) {
	const [trackingData, setTrackingData] = useState([]);
	const [message, setMessage] = useState(null);

	const [parcelData, setParcelData] = useState(null);

	const [isMediumScreen, setIsMediumScreen] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			setIsMediumScreen(window.innerWidth >= 768); // Tailwind's md breakpoint starts at 768px
		};

		// Run initially to check the current screen size
		handleResize();

		// Listen to window resize events
		window.addEventListener("resize", handleResize);

		// Cleanup on unmount
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			const data = await getEventsBasedOnTrackerIdPublic(trackingId);
			setTrackingData(data.results);
			if (data.results.length === 0) {
				setMessage(
					"Sorry, We couldn't find any details for the provided Tracking Id",
				);
			}
		};
		fetchData();
	}, [trackingId]);

	useEffect(() => {
		getParcelsBasedOnId(trackingId)
			.then((response) => {
				if (response && response.parcels) {
					setParcelData(response.parcels);
				} else {
					console.error("Unexpected response format:", response);
				}
			})
			.catch((error) => {
				console.error("Error fetching data:", error);
			});
	}, [trackingId]);

	return (
		<div
			className="flex-grow h-full w-full p-8 ml-2 mt-2 bg-[#181921]"
			id="tracking-logged-in"
		>
			<ParcelHeader trackingNumber={trackingId} />
			<div className="tracking_details">
				{!message ? (
					<div>
						<div className="bg-[#21232E]"> </div>
						<div className="grid grid-cols-2"></div>
						<div className={` ${classes.flex} flex-col md:flex-row `}>
							<div className={isMediumScreen ? classes.left : ""}>
								<ParcelDetails
									data={parcelData}
									isPublicTracking={isPublicTracking}
								/>
							</div>
							{!isPublicTracking && (
								<div className={isMediumScreen ? classes.right : ""}>
									<EstimatedCharges
										data={trackingData}
										parcelData={parcelData}
									/>
								</div>
							)}
						</div>
					</div>
				) : (
					<h2 className="mt-5  text-2xl text-center">{message}</h2>
				)}
			</div>
		</div>
	);
}

export default ParcelDetailScreen;
