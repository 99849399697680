import React, { useCallback, useEffect, useState } from "react";
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { getBidPerformanceDetails } from "../../services/apiServices";
import { TableLoading } from "../";

const BidPerformanceTable = () => {
  const [bidPerformanceDetail, setBidPerformanceDetail] = useState([]);
  const [bidPerformanceDetailLoading, setBidPerformanceDetailLoading] =
    useState(true);

  useEffect(() => {
    // fetch bid performances

    getBidPerformanceDetails(10, 0)
      .then((response) => {
        setBidPerformanceDetail(response);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setBidPerformanceDetailLoading(false);
      });
  }, []);

  const getBidConfigStatus = useCallback((status) => {
    switch (status) {
      case "ENABLED":
        return "Active";

      case "DISABLED":
        return "Inactive";

      default:
        return "";
    }
  }, []);

  return (
    <div className="mt-9">
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>Bid Configuration Name</Th>
              <Th>Current Status</Th>
              <Th>Active Duration</Th>
              <Th>Total Bids</Th>
              <Th>Win %</Th>
              <Th>Loss %</Th>
            </Tr>
          </Thead>
          <Tbody>
            {bidPerformanceDetailLoading && (
              <TableLoading data={bidPerformanceDetail} />
            )}
            {!bidPerformanceDetailLoading &&
              bidPerformanceDetail &&
              bidPerformanceDetail.length === 0 && (
                <Tr>
                  <Td colSpan="100">No data available</Td>
                </Tr>
              )}
            {bidPerformanceDetail &&
              bidPerformanceDetail.map((bid, index) => {
                return (
                  <Tr key={index}>
                    <Td>{bid?.bid_config_name ?? "-"}</Td>
                    <Td>{getBidConfigStatus(bid.bid_config_status)}</Td>
                    <Td>{bid?.active_duration}</Td>
                    <Td>{bid?.total_bids}</Td>
                    <Td>{bid?.won_percent}</Td>
                    <Td>{bid?.lost_percent}</Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default BidPerformanceTable;
