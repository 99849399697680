import React, { useEffect, useState, useContext } from "react";
import { TableContainer, Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import { AccountTabs, Page, TableLoading, UserContext } from "../interfaces";
import { CARRIER_CODES, SHIPPER_CODES } from "../constants";
import { getServiceType } from "../services";

const ServiceTypes = () => {
  const [ carrierServiceTypes, setCarrierServiceTypes ] = useState([]);
  const [ shipperServiceTypes, setShipperServiceTypes ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(false);
  const { user } = useContext(UserContext);

  const isCarrier = user.Groups.find((group) => CARRIER_CODES.includes(group));
  const isShipper = user.Groups.find((group) => SHIPPER_CODES.includes(group));

  useEffect(() => {
    if (!isCarrier) return;
    setIsLoading(true);
    getServiceType(isCarrier)
      .then((response) => {
        setCarrierServiceTypes(response.serviceTypes);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => setIsLoading(false));
  }, [isCarrier]);

  useEffect(() => {
    if (!isShipper) return;
    setIsLoading(true);
    getServiceType("ORCHESTRO") // hardcoded as we are the Shipper to this user
      .then((response) => {
        setShipperServiceTypes(response.serviceTypes);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => setIsLoading(false));
  }, [isShipper]);

  return (
    <>
      <AccountTabs />
      <Page className={"!min-h-0"}>
        {isShipper && (
          <>
            <h2 className="text-xl my-5">
              Orchestro Service Types
            </h2>
            <TableContainer className="max-h-[calc(100vh_-_245px)] !overflow-y-auto w-full mb-12">
              <Table>
                <Thead>
                  <Tr>
                    <Th width={"30%"}>Network Service Type</Th>
                    <Th>Description</Th>
                    <Th>TNT</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {isLoading && <TableLoading data={shipperServiceTypes} />}
                  {!isLoading && (!shipperServiceTypes || shipperServiceTypes.length === 0) && <Tr><Td colSpan="100">No data found</Td></Tr>}
                  {shipperServiceTypes &&shipperServiceTypes.map((serviceType, i) => (
                    <Tr key={i + "-" +serviceType.code}>
                      <Td fontWeight="bold">{serviceType?.networkServiceType ?? "-"}</Td>
                      <Td>{serviceType?.description ?? "-"}</Td>
                      <Td>{serviceType?.tnt ?? "-"}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </>
        )}

        {isCarrier && (
          <>
            <h2 className="text-xl my-5">
              Carrier Service Types
            </h2>
            <TableContainer className="max-h-[calc(100vh_-_245px)] !overflow-y-auto w-full mb-4">
              <Table>
                <Thead>
                  <Tr>
                    <Th width={"30%"}>Service Type</Th>
                    <Th>Description</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {isLoading && <TableLoading data={carrierServiceTypes} />}
                  {!isLoading && (!carrierServiceTypes || carrierServiceTypes.length === 0) && <Tr><Td colSpan="100">No data found</Td></Tr>}
                  {carrierServiceTypes &&carrierServiceTypes.map((serviceType) => (
                    <Tr key={serviceType.code}>
                      <Td fontWeight="bold">{serviceType?.code ?? "-"}</Td>
                      <Td>{serviceType?.description ?? "-"}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </>
        )}
      </Page>
    </>
  );
};

export default ServiceTypes;
