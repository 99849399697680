import { FormControl, FormLabel, Grid, GridItem, Heading, Input, Text, VisuallyHidden } from "@chakra-ui/react";

export function MiddleMileCosts({ data, handleChange }) {
	return (
		<>
			<Heading as={"h3"} size={"md"} fontWeight={"normal"} my={7}>
				Middle Mile Costs
			</Heading>
			<Grid templateColumns='repeat(3, 1fr)' mb={5} gap={5} maxWidth={"800px"}>
				<GridItem w="100%">
					<Text fontSize={"18px"}>Carrier Name</Text>
				</GridItem>
				<GridItem w="100%">
					<Text fontSize={"18px"}>Carrier Cost</Text>
				</GridItem>
				<GridItem w="100%">
					<Text fontSize={"18px"}>Unit Cost</Text>
				</GridItem>
			</Grid>
			{data.map((item, i) => (
				<Grid key={i} templateColumns='repeat(3, 1fr)' gap={5} maxWidth={"800px"}>
					<GridItem w="100%">
						<Text fontWeight={"bold"} mt={2}>{item.fromCarrier}</Text>
					</GridItem>
					<GridItem w="100%">
						<Text fontWeight={"bold"} mt={2}>{item.toCarrier}</Text>
					</GridItem>
					<GridItem w="100%">
						<FormControl mb={5}>
							<VisuallyHidden>
								<FormLabel>LSO Pick Up Cost</FormLabel>
							</VisuallyHidden>
							<Input
								required
								name="unitCost"
								pattern="[0-9]+(\.[0-9]{1,2})?"
								min="0"
								max="100"
								value={item.unitCost}
								onChange={(e) => handleChange(e, i)}
							/>
						</FormControl>
					</GridItem>
				</Grid>
			))}
		</>
	);
}