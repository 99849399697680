import React from "react";
import { useOktaAuth } from "@okta/okta-react";

import { useLocalStorage } from "../../../hooks";



const coreServiceToken = process.env.REACT_APP_CORE_SERVICES_TOKEN;
const coreServiceUrl = process.env.REACT_APP_CORE_SERVICES_URL;
const coreServicePath = process.env.REACT_APP_CORE_SERVICES_PATH;
const environment = process.env.REACT_APP_ENVIRONMENT;
const apiUrl = coreServiceUrl + coreServicePath;

export const UserContext = React.createContext({
  user: null,
  isDemo: false,
  isDemoEnvironment: false,
  alert: {},
  createAlert: () => {},
  dismissAlert: () => {},
  logoutUser: () => {},
  getAccessToken: () => Promise.resolve(null), // Initialize with a default no-op function
  filters: [],
  setFilters: () => {},
});

export function UserProvider({ children }) {
  const [user, setUser] = React.useState(null);
  const [filters, setFilters] = React.useState([]);
  const [isDemoEnvironment] = React.useState(
    environment === "localhost" ||
      environment === "dev" ||
      environment === "test-e1" ||
      environment === "beta"
  ); // test-e1 and beta are the demo environments
  const [alert, setAlert] = useLocalStorage("alert", {});
  const { authState, oktaAuth } = useOktaAuth();
  const [isDemo, setIsDemo] = useLocalStorage("isDemo", false);
  const [shipmentFilters,setShipmentFilters]=React.useState(false)

  React.useEffect(() => {
    if (!authState?.isAuthenticated) {
      setUser({ Groups: [] });
    } else {
      oktaAuth
        .getUser()
        .then(setUser)
        .catch((error) => console.error("Error getting user info:", error));
    }
  }, [authState, oktaAuth]);

  const logoutUser = () => {
    // Clear local storage for filters
    localStorage.removeItem("shipmentFilters");

    // Perform Okta sign out
    oktaAuth.signOut();
  };

  // const logoutUser = () => oktaAuth.signOut();

  // Function to retrieve the access token
  const getAccessToken = async () => {
    if (!authState?.isAuthenticated || !oktaAuth.tokenManager.getTokens) {
      console.warn("User is not authenticated");
      return null;
    }
    try {
      const { accessToken } = await oktaAuth.tokenManager.getTokens();
      return accessToken?.accessToken; // Ensure you return the accessToken string
    } catch (error) {
      console.error("Error getting access token:", error);
      return null;
    }
  };

  const createAlert = () => {
    const getAlerts = async () => {
      try {
        const response = await fetch(apiUrl + "/fetch-alerts", {
          method: "GET",
          headers: {
            authorization: coreServiceToken,
            "Content-Type": "application/json",
          },
        });
        const resp = await response.json();
        if (resp.error) {
          console.error("Something went wrong, please try again later");
        } else {
          setAlert(resp);
        }
      } catch (error) {
        console.error(`Error fetching "/fetch-alerts":`, error);
      }
    };
    getAlerts();
  };

  const dismissAlert = () => {
    setAlert({});
    // Also need to PUT to /ack-alert
  };

  const setDemoMode = () => {
    if (isDemoEnvironment) {
      setIsDemo(isDemo ? false : true);
    }
  };

  const contextValue = {
    user,
    isDemo,
    isDemoEnvironment,
    alert,
    createAlert,
    dismissAlert,
    setDemoMode,
    logoutUser,
    getAccessToken,
    setFilters,
    filters,
    shipmentFilters,
    setShipmentFilters
  };

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
}
