import { Fragment } from "react";
import { Box, Heading, Flex, Text } from "@chakra-ui/react";
import { titleCase } from "../../../utilities";

import style from "./PldSavingsCard.module.css";

export function PldSavingsCard({ data }) {
  function displayNumber(number) {
    if (!data?.orchestroSavings || isNaN(data?.orchestroSavings)) {
      return "~%";
    }
    return `${number}%`;
  }

  return (
    <div className={style.PldSavingsCard}>
      <Box px={4} py={8}>
        <Flex gap={5} px={2}>
          <Heading as={"h3"} size={"24px"} mb={5}>
            Orchestro AI Savings
          </Heading>
          <Text
            fontSize={"2.5rem"}
            color={"orange"}
            fontWeight={"bold"}
            lineHeight={1}
          >
            {displayNumber(data.orchestroSavings)}
          </Text>
        </Flex>
        <hr />
        {data?.parcelCost ? (
          <>
            <Flex gap={5} justify={"space-between"} p={2}>
              <Text>Total Number of Parcels</Text>
              <Text fontWeight={"bold"}>{data.totalNumberOfParcels}</Text>
            </Flex>
            <hr />
            <Text fontSize={"12px"} pl={2} pt={2}>
              Cost Per Parcel:
            </Text>
            {data.parcelCost.map((cost) => (
              <Fragment key={cost.key}>
                {cost.key.toLowerCase() === "orchestro" ? (
                  <Flex gap={5} justify={"space-between"} p={2}>
                    <Text fontWeight={"bold"}>{titleCase(cost.key)}</Text>
                    <Text fontWeight={"bold"}>
                      {cost.value > 0 ? `${cost.value}` : `$0`}
                    </Text>
                  </Flex>
                ) : (
                  <Flex gap={5} justify={"space-between"} p={2}>
                    <Text>{titleCase(cost.key)}</Text>
                    <Text>${cost.value}</Text>
                  </Flex>
                )}
                <hr />
              </Fragment>
            ))}
          </>
        ) : (
          <Text p={2}>
            Upload a PLD <strong>.csv</strong> file to calculate
          </Text>
        )}
      </Box>
    </div>
  );
}
