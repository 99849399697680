import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { Text } from "@chakra-ui/react";

import { CARRIER_CODES, SHIPPER_CODES } from "../../constants";
import { UserContext } from "../Providers";

import classes from "./AccountTabs.module.css";

export function AccountTabs() {
	const { user } = useContext(UserContext);
	const accountPageRoot = "/app/account";
	const linkClass = ({ isActive }) => isActive ? `${classes.links} ${classes.active}` : classes.links;

	return (
		<nav role="navigation" className={classes.nav}>
			<ul className={classes.navList}>
				<li>
					<NavLink
						className={linkClass}
						to={accountPageRoot + "/information"}
					>
						<Text>Information</Text>
					</NavLink>
				</li>
				<li>
					<NavLink
						className={linkClass}
						to={accountPageRoot + "/preferences"}
					>
						<Text>Preferences</Text>
					</NavLink>
				</li>
				<li>
					<NavLink
						className={linkClass}
						to={accountPageRoot + "/service-attributes"}
					>
						<Text>Service Attributes</Text>
					</NavLink>
				</li>
				<li>
					<NavLink
						className={linkClass}
						to={accountPageRoot + "/service-types"}
					>
						<Text>Service Types</Text>
					</NavLink>
				</li>
				<li>
					<NavLink
						className={linkClass}
						to={accountPageRoot + "/tracking-codes"}
					>
						<Text>
							{[
								...CARRIER_CODES,
								...SHIPPER_CODES.filter((code) => CARRIER_CODES.includes(code)),
							].some((carrierRole) => user?.Groups.includes(carrierRole))
								? "Tracking Codes"
								: "Orchestro Tracking Codes"}
						</Text>
					</NavLink>
				</li>
				<li>
					<NavLink
						className={linkClass}
						to={accountPageRoot + "/sort-codes"}
					>
						<Text>Sort Codes</Text>
					</NavLink>
				</li>
				<li>
					<NavLink
						className={linkClass}
						to={accountPageRoot + "/zone-coverage"}
					>
						<Text>Zone Coverage</Text>
					</NavLink>
				</li>
				<li>
					<NavLink
						className={linkClass}
						to={accountPageRoot + "/rates"}
					>
						<Text>Rates</Text>
					</NavLink>
				</li>
				<li>
					<NavLink className={linkClass} to={accountPageRoot + "/surcharges"}>
						<Text>
							{[
								...CARRIER_CODES,
								...SHIPPER_CODES.filter((code) => CARRIER_CODES.includes(code)),
							].some((carrierRole) => user?.Groups.includes(carrierRole))
								? "Surcharges"
								: "Orchestro Surcharges"}
						</Text>
					</NavLink>
				</li>
			</ul>
		</nav>
	)
}