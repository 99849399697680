import React from "react";

const History = () => {
  return (
    <div className="ml-8 mt-8 flex flex-col">
      <div className="text-lg font-semibold">Full Route</div>

      <div className="flex flex-row mt-10">
        <div>
          <img alt="acceptance" src="/images/track/accepted.svg" />
        </div>
        <div className="flex ml-6">
          <div className="text-md font-semibold">
            Acceptance Complete and Route assigned
            <div className="mt-8">
              <div className="flex flex-row">
                <div className="font-light text-[#B6B6B6]">Arrival:</div>
                <div className="ml-20">
                  <time
                    dateTime={new Date("25 Apr 24 07:53:25 EST").toISOString()}
                  >
                    25 Apr 24 07:53:25 EST
                  </time>
                </div>
              </div>
              <div className="flex flex-row mt-6">
                <div className="font-light text-[#B6B6B6]">Departure:</div>
                <div className="ml-12">
                  <time
                    dateTime={new Date("25 Apr 24 08:23:00 EST").toISOString()}
                  >
                    25 Apr 24 08:23:00 EST
                  </time>
                </div>
              </div>
              <div className="flex flex-row mt-6">
                <div className="font-light text-[#B6B6B6]">Duration:</div>
                <div className="ml-14">42 mins</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-row mt-14">
        <div>
          <img alt="acceptance" src="/images/track/accepted.svg" />
        </div>
        <div className="flex ml-6">
          <div className="text-md font-semibold">
            LSO Freight
            <div className="mt-8">
              <div className="flex flex-row">
                <div className="font-light text-[#B6B6B6]">Carrier:</div>
                <div className="ml-20">{`LSO (LS9450-04)`}</div>
              </div>
              <div className="flex flex-row mt-6">
                <div className="font-light text-[#B6B6B6]">Arrival:</div>
                <div className="ml-20">
                  <time
                    dateTime={new Date("25 Apr 24 08:30:25 EST").toISOString()}
                  >
                    25 Apr 24 08:30:25 EST
                  </time>
                </div>
              </div>
              <div className="flex flex-row mt-6">
                <div className="font-light text-[#B6B6B6]">Departure:</div>
                <div className="ml-12">
                  <time
                    dateTime={new Date("25 Apr 24 08:45:00 EST").toISOString()}
                  >
                    25 Apr 24 08:45:00 EST
                  </time>
                </div>
              </div>
              <div className="flex flex-row mt-6">
                <div className="font-light text-[#B6B6B6]">Duration:</div>
                <div className="ml-14">15 min</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-row mt-14">
        <div>
          <img alt="acceptance" src="/images/track/highrisk.svg" />
        </div>
        <div className="flex ml-6">
          <div className="text-md font-semibold">
            Uber Freight
            <div className="mt-6">
              Uber Freight experiencing delay due to poor weather and
              visibility.Estimated 9hr 42m delay.
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-row mt-14">
        <div>
          <img alt="acceptance" src="/images/track/recommend.svg" />
        </div>
        <div className="flex ml-6">
          <div className="text-md font-semibold">
            Recommend MI carrier, which is available along your route and has
            similar estimated delivery
            <div className="mt-6">
              <div className="flex flex-row">
                <div className="font-light text-[#B6B6B6]">Delivery ETA:</div>
                <div className="ml-20">
                  <time
                    dateTime={new Date("25 Apr 24 10:50:00 EST").toISOString()}
                  >
                    25 Apr 24 10:50:00 EST
                  </time>
                </div>
              </div>
              <div className="flex flex-row mt-6">
                <div className="font-light text-[#B6B6B6]">Est Duration:</div>
                <div className="ml-20">1hr 18m</div>
              </div>
              <div className="flex flex-row mt-6">
                <div className="font-light text-[#B6B6B6]">
                  Estimated Savings:
                </div>
                <div className="ml-12">$1,003.58</div>
              </div>
            </div>
            <div className="mt-14 mb-10">
              <button type="button">Ignore</button>
              <button type="button" className="btn ml-6 px-6 py-2 bg-[#3BA0E6]">
                Accept
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default History;
