import React, { useEffect, useMemo, useState } from "react";
import { addHours, format, subHours } from "date-fns";
import { Grid, Spinner, useMediaQuery } from "@chakra-ui/react";
import AreaChart from "../Dashboard/areachart";
import BarChart from "../Dashboard/BarChart";
import CurrentDate from "../Dashboard/CurrentDate";
import {
  getRseStatsForCarriers,
  getAvgRseStatsForDay,
} from "../../services/apiServices";
import { useWebSocket } from "../../hooks";

const bidEngineServiceUrl = process.env.REACT_APP_BID_ENGINE_SERVICE_URL;

const chartPlugin = {
  beforeInit(chart) {
    // reference of original fit function
    const originalFit = chart.legend.fit;

    // override the fit function
    chart.legend.fit = function fit() {
      // call original function and bind scope in order to use `this` correctly inside it
      originalFit.bind(chart.legend)();
      // increase the width to add more space
      this.height += 30;
    };
  },
};

const rseStatsChartColors = [
  "#A8DDB5",
  "#EE876A",
  "#BBA4D1",
  "#FFCC00",
  "#CCEBC5",
  "#89B4EB",
  "#189DE4",
];

const chartOptions = {
  legend: {
    labels: {
      font: {
        size: 12,
        family: "Montserrat",
        weight: "normal",
      },
      color: "#fff",
      boxWidth: 20,
      boxHeight: 20,
      padding: 20,
    },
  },
  axisTitle: {
    font: {
      size: 14,
      family: "Montserrat",
      weight: 500,
    },
  },
  tooltip: {
    enabled: true,
    interaction: {
      intersect: false,
      mode: "nearest",
      axis: "xy",
    },
  },
};

const tooltipStyles = {
  backgroundColor: "#1E253F",
  borderWidth: 1,
  borderColor: "#85869833",
  displayColors: false,
  caretSize: 0,
  bodyFont: {
    size: 14,
    weight: "normal",
  },
  titleFont: {
    size: 0,
  },
};

const BidPerformanceCharts = () => {
  const [isLargerThan1200] = useMediaQuery("(min-width: 1200px)");
  const {
    data: wonAndLostBidChartData,
    isConnected,
    client,
  } = useWebSocket(
    `${bidEngineServiceUrl}/wonAndLostBidChart`,
    "/topic/wonAndLostBidChart"
  );

  const [wonLostBidDetail, setWonLostBidDetail] = useState([]);
  const [rseStatsDetail, setRseStatsDetail] = useState({});
  const [avgRseStatsDetail, setAvgRseStatsDetail] = useState({});
  const [chartsLoading, setChartsLoading] = useState({
    wonLostBidChart: true,
    rseStatsChart: true,
    avgRseStatsChart: true,
  });

  useEffect(() => {
    // fetch RSE calls based on carrier for last 3 months

    getRseStatsForCarriers()
      .then((response) => {
        setRseStatsDetail(response ?? {});
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setChartsLoading((loading) => ({ ...loading, rseStatsChart: false }));
      });

    // fetch avg rse calls based on day and time interval for last 30 days

    getAvgRseStatsForDay(format(new Date(), "yyyy-MM-dd HH:mm:ss"))
      .then((response) => {
        setAvgRseStatsDetail(response ?? {});
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setChartsLoading((loading) => ({
          ...loading,
          avgRseStatsChart: false,
        }));
      });
  }, []);

  useEffect(() => {
    client.activate();

    return () => {
      client.deactivate();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isConnected && wonAndLostBidChartData) {
      setChartsLoading((loading) => ({ ...loading, wonLostBidChart: false }));
      setWonLostBidDetail(wonAndLostBidChartData);
    }
  }, [wonAndLostBidChartData, isConnected]);

  const wonLostBidChart = useMemo(() => {
    if (!chartsLoading.wonLostBidChart) {
      let flag = "";

      const wonLostChartData = wonLostBidDetail.reverse();
      const chartDetail = wonLostChartData.reduce((prevBid, bid) => {
        if (prevBid) {
          if (`${new Date(bid.from_time)}` === `${subHours(flag, 1)}`) {
            flag = new Date(bid.from_time);
            return {
              won: [...prevBid.won, bid.won_count],
              lost: [...prevBid.lost, bid.lost_count],
              labels: [
                ...prevBid.labels,
                format(`${new Date(bid.from_time)}`, "h:mma"),
              ],
            };
          } else {
            return {
              won: [...prevBid.won, bid.won_count],
              lost: [...prevBid.lost, bid.lost_count],
              labels: [...prevBid.labels, ""],
            };
          }
        } else {
          flag = new Date(bid.from_time);
          return {
            won: [bid.won_count],
            lost: [bid.lost_count],
            labels: [format(`${new Date(bid.from_time)}`, "h:mma")],
          };
        }
      }, null);

      return {
        datasets: [
          {
            label: "Won",
            color: "#16B96B",
            value: chartDetail?.won.reverse(),
          },
          {
            label: "Loss",
            color: "#B50015",
            value: chartDetail?.lost.reverse(),
          },
        ],
        labels: chartDetail?.labels.reverse(),
      };
    }
  }, [wonLostBidDetail, chartsLoading.wonLostBidChart]);

  const rseStatsChart = useMemo(() => {
    if (!chartsLoading.rseStatsChart) {
      const rseStatsChartDetail = Object.entries(rseStatsDetail).reverse();
      const chartDetail = rseStatsChartDetail.reduce(
        (prevRseCall, [rseCallKey, rseCall]) => {
          if (prevRseCall) {
            return {
              currentMonth: [
                ...prevRseCall.currentMonth,
                rseCall.current_month_count,
              ],
              lastMonth: [...prevRseCall.lastMonth, rseCall.last_month_count],
              monthBeforeLast: [
                ...prevRseCall.monthBeforeLast,
                rseCall.month_before_last_count,
              ],
              labels: [...prevRseCall.labels, rseCallKey],
            };
          } else {
            return {
              currentMonth: [rseCall.current_month_count],
              lastMonth: [rseCall.last_month_count],
              monthBeforeLast: [rseCall.month_before_last_count],
              labels: [rseCallKey],
            };
          }
        },
        null
      );

      return {
        datasets: [
          {
            value: chartDetail?.monthBeforeLast,
            label: "2 Months Ago",
            color: "#97A44D",
          },
          {
            value: chartDetail?.lastMonth,
            label: "Last Month",
            color: "#189DE4",
          },
          {
            value: chartDetail?.currentMonth,
            label: "This Month",
            color: "#BFD2D7",
          },
        ],
        labels: chartDetail?.labels,
      };
    }
  }, [rseStatsDetail, chartsLoading.rseStatsChart]);

  const avgRseStatsChart = useMemo(() => {
    if (!chartsLoading.avgRseStatsChart) {
      const datasets = Object.entries(avgRseStatsDetail).map(
        ([avgRseStatsKey, avgRseStats], i) => ({
          value: avgRseStats.map((stat) => stat.avg_rse_calls),
          label: avgRseStatsKey,
          color: rseStatsChartColors[i],
        })
      );

      let flagForLabel = "";

      const labels = Object.values(avgRseStatsDetail)?.[0]?.map((stat) => {
        const currentDate = format(new Date(), "yyyy-MM-dd");
        const currentTime = stat["from-time"];

        if (
          `${new Date(`${currentDate} ${currentTime}`)}` ===
            `${addHours(flagForLabel, 2)}` ||
          !flagForLabel
        ) {
          flagForLabel = `${currentDate} ${currentTime}`;
          return format(new Date(`${currentDate} ${currentTime}`), "ha");
        }

        return "";
      });

      return {
        datasets,
        labels,
      };
    }
  }, [avgRseStatsDetail, chartsLoading.avgRseStatsChart]);

  return (
    <>
      <div className="active rounded-md w-full mt-5">
        {chartsLoading.wonLostBidChart ? (
          <div className="h-[480px] flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <AreaChart
            title={"Win & Loss"}
            subTitle={<CurrentDate dateFormat={"d MMM yyyy"} />}
            range={"time"}
            xAxis={"Time of Day"}
            yAxis={"Number of Parcels"}
            data={wonLostBidChart}
            isDisplayLegend={true}
            isMultiLine={true}
            options={{
              legend: {
                labels: {
                  ...chartOptions.legend.labels,
                  padding: isLargerThan1200 ? 30 : 10,
                },
              },
              padding: isLargerThan1200 ? { left: 40, right: 60 } : 1,
              titleClasses: `${isLargerThan1200 ? "mt-7 text-lg" : "text-base"} font-medium`,
              axisTitle: {
                ...chartOptions.axisTitle,
                padding: { top: 20, bottom: 20 },
              },
              tooltip: chartOptions.tooltip,
            }}
          />
        )}
      </div>
      <Grid
        gap={5}
        className="grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2"
      >
        <div className="active rounded-md w-full mt-5">
          {chartsLoading.rseStatsChart ? (
            <div className="h-full flex items-center justify-center">
              <Spinner />
            </div>
          ) : (
            <BarChart
              title={"Top RSE API Calls"}
              xAxis={"Carriers"}
              yAxis={"RSEs"}
              data={rseStatsChart}
              isDisplayLegend={true}
              isMultiBar={true}
              indexAxis={"y"}
              barPercentage={{ categoryPercentage: 0.8, barPercentage: 0.26 }}
              plugins={[chartPlugin]}
              options={{
                legend: chartOptions.legend,
                padding: { left: 10, right: 60 },
                titleClasses: `mt-7 text-lg font-medium`,
                axisTitle: chartOptions.axisTitle,
              }}
            />
          )}
        </div>
        <div className="active rounded-md w-full mt-5">
          {chartsLoading.avgRseStatsChart ? (
            <div className="h-full flex items-center justify-center">
              <Spinner />
            </div>
          ) : (
            <AreaChart
              title={"Average Volume of RSE Calls"}
              subTitle={
                <p className="text-xs text-white font-normal">Last 30 days</p>
              }
              range={"time"}
              xAxis={"Time of Day"}
              yAxis={"Number of RSE Calls"}
              data={avgRseStatsChart}
              isMultiLine={true}
              options={{
                legend: chartOptions.legend,
                padding: { left: 20, right: 60, bottom: 20 },
                titleClasses: `mt-7 text-lg font-medium`,
                axisTitle: chartOptions.axisTitle,
                tooltip: {
                  ...chartOptions.tooltip,
                  callbacks: {
                    label: function (value) {
                      return value.dataset.label;
                    },
                  },
                  ...tooltipStyles,
                },
              }}
            />
          )}
        </div>
      </Grid>
    </>
  );
};

export default BidPerformanceCharts;
