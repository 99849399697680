import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Card, Divider, Heading, Spinner, Text } from "@chakra-ui/react";
import { renderDate, renderTime } from "../../utilities";
import classes from "../../css-files/PublicTracker.module.css";

const timelineItems = [
  {
    title: "Picked up from facility",
    value: "Picked up from facility",
  },
  {
    title: "Out for delivery",
    value: "Out for delivery",
  },
  {
    title: "In-transit",
    value: "In transit",
  },
  {
    title: "Delivered",
    value: "Delivered",
  },
];

const PublicTracker = ({ isLoadingEvents, trackingData, currentStatus }) => {
  const [openDetailedTracker, setOpenDetailedTracker] = useState(false);

  useEffect(() => {
    setOpenDetailedTracker(false);
  }, [isLoadingEvents]);

  const handleToggleDetailedTracker = useCallback(() => {
    setOpenDetailedTracker(!openDetailedTracker);
  }, [openDetailedTracker]);

  return (
    <>
      {!isLoadingEvents && trackingData?.length === 0 && (
        <Card
          bg={"#fff !important"}
          w={"100%"}
          h={200}
          borderRadius={"10px"}
          boxShadow={"2px 2px 10px 0px rgba(0, 0, 0, 0.10)"}
          padding={"25px 35px"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Text color={"#000"}>No data available</Text>
        </Card>
      )}

      {!isLoadingEvents && trackingData?.length > 0 && (
        <Card
          bg={"#fff !important"}
          w={"100%"}
          borderRadius={"10px"}
          boxShadow={"2px 2px 10px 0px rgba(0, 0, 0, 0.10)"}
          padding={"25px 35px"}
        >
          <Box onClick={handleToggleDetailedTracker}>
            <Heading fontFamily={"Montserrat"} fontSize={26} fontWeight={600}>
              {currentStatus}
            </Heading>
            <Text
              color={"#5F5F5F"}
              fontSize={18}
              fontWeight={500}
              fontFamily={"Montserrat"}
              mt={2}
            >
              Estimated delivery :{" "}
              {trackingData[0]?.expectedDate || "Not Available"}
            </Text>
            <div className={classes.circleContainer}>
              {timelineItems &&
                timelineItems.map((item, index) => (
                  <React.Fragment key={index}>
                    <div className={classes.flexRow}>
                      {index !== timelineItems.length - 1 && (
                        <div
                          className={`${classes.line} ${item.value.toLowerCase() === currentStatus.toLowerCase() ? classes.grayLine : ""}`}
                        ></div>
                      )}
                      <div className={classes.outerCircle}>
                        <div className={`${classes.innerCircle}`}></div>
                        <div className={classes.data}>
                          <Text
                            fontSize={"xs"}
                            fontFamily={"Montserrat"}
                            color={"#010101"}
                            fontWeight={300}
                          >
                            {item.title}
                          </Text>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ))}
            </div>
            {openDetailedTracker && (
              <>
                <Divider margin={"18px 0 36px 0"} />
                <div className={`${classes.circleContainer} flex-col`}>
                  {trackingData &&
                    trackingData.map((item, index) => (
                      <React.Fragment key={index}>
                        <div
                          className={`${classes.flexRow} !w-full justify-between h-[100px] last:h-[0]`}
                        >
                          <div className="relative">
                            <div className={`relative z-50 w-full`}>
                              <img
                                alt="acceptance"
                                src="/images/track/accepted.svg"
                              />
                            </div>
                            {index !== trackingData.length - 1 && (
                              <div
                                className={`h-[100px] border-r-4 border-dashed border-[#080800a1] absolute top-[14px] left-3`}
                              />
                            )}
                            <div className="absolute left-11 top-1 w-max">
                              <Text
                                fontSize={"sm"}
                                fontFamily={"Montserrat"}
                                color={"#010101"}
                                fontWeight={600}
                              >
                                {item?.status}
                              </Text>
                            </div>
                          </div>
                          <Text
                            fontSize={"sm"}
                            fontFamily={"Montserrat"}
                            color={"#010101"}
                            fontWeight={400}
                            marginTop={1.5}
                          >
                            <time dateTime={item?.timestamp}>
                              {renderDate(item?.timestamp)}{" "}
                              {renderTime(item?.timestamp)}
                            </time>
                          </Text>
                          <div className="text-start w-[150px]">
                            <Text
                              fontSize={"sm"}
                              fontFamily={"Montserrat"}
                              color={"#232323"}
                              fontWeight={400}
                              marginTop={1.5}
                            >
                              {item?.city}, {item?.zip}
                            </Text>
                          </div>
                        </div>
                      </React.Fragment>
                    ))}
                </div>
              </>
            )}
          </Box>
        </Card>
      )}

      {isLoadingEvents && (
        <Card
          bg={"#fff !important"}
          w={"100%"}
          h={200}
          borderRadius={"10px"}
          boxShadow={"2px 2px 10px 0px rgba(0, 0, 0, 0.10)"}
          padding={"25px 35px"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Spinner />
        </Card>
      )}
    </>
  );
};

export default PublicTracker;

PublicTracker.defaultProps = {
  isLoadingEvents: false,
  trackingData: [],
  currentStatus: "",
};

PublicTracker.propTypes = {
  isLoadingEvents: PropTypes.bool,
  trackingData: PropTypes.array,
  currentStatus: PropTypes.string,
};
