import React, { useState, useEffect } from "react";

const History = ({
	title,
	status,
	location,
	code,
	comment,
	last,
	trackingCity,
	parcelDestination,
}) => {
	const [packageStatus, setPackageStatus] = useState("");
	const [packageTitle, setPackageTitle] = useState(title);
	useEffect(() => {
		if (status === "Delivered" && trackingCity !== parcelDestination) {
			setPackageStatus("In Transit");
			setPackageTitle("On Route");
		} else {
			setPackageStatus(status);
			setPackageTitle(title);
		}
	}, [trackingCity, parcelDestination, status]);

	return (
		<div className="bg-[#242630] w-[320px] rounded-md">
			<div className="p-3 flex w-full">
				<div className="flex flex-col w-full ml-2">
					<div className="flex flex-row w-full">
						<div className="flex items-center justify-between w-full text-xs">
							<div className="text-white font-semibold">{packageTitle}</div>
							<div
								className={` ${last === true ? "text-[#261900] bg-[#FFB323]" : "bg-[#858698]"} font-semibold rounded-full text-center p-1 px-3`}
							>
								{packageStatus}
							</div>
						</div>
					</div>
					<div className="mt-2">
						<div className="flex items-center justify-start w-full text-xs">
							<div className="text-[#858698]">Location</div>
							<div className="ml-auto text-left w-1/2">{location}</div>
						</div>
						{/* <div className="flex items-center justify-start w-full text-xs mt-1">
              <div className="text-[#858698]">Network Track Code</div>
              <div className="ml-auto text-left w-1/2">{code}</div>
            </div> */}
						<div className="flex items-center justify-start w-full text-xs mt-1">
							<div className="text-[#858698]">Comments</div>
							<div className="ml-auto text-left w-1/2">{comment}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default History;
