// src/store/store.js
import { configureStore } from '@reduxjs/toolkit';
import filtersReducer from './filtersSlice';

const loadFiltersFromLocalStorage = () => {
  const storedFilters = localStorage.getItem('shipmentFilters');
  return storedFilters ? JSON.parse(storedFilters) : undefined;
};

const preloadedState = {
  filters: loadFiltersFromLocalStorage(),
};

const store = configureStore({
  reducer: {
    filters: filtersReducer,
  },
  preloadedState,
});

export default store;
