
export function DollarIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
			<path d="M10 19.4121C14.9706 19.4121 19 15.3827 19 10.4121C19 5.44155 14.9706 1.41211 10 1.41211C5.02944 1.41211 1 5.44155 1 10.4121C1 15.3827 5.02944 19.4121 10 19.4121Z" stroke="#EBEBEB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M12.3565 7.95048C12.2754 7.72119 12.1504 7.51267 11.9914 7.3351C11.6534 6.95741 11.1621 6.71973 10.6154 6.71973H9.18639C8.27626 6.71973 7.53845 7.45753 7.53845 8.36765C7.53845 9.14208 8.07769 9.81203 8.83423 9.97753L11.0099 10.4535C11.8574 10.6389 12.4615 11.3899 12.4615 12.2575C12.4615 13.2771 11.635 14.1043 10.6154 14.1043H9.3846C8.58078 14.1043 7.89694 13.5906 7.6435 12.8736" stroke="#EBEBEB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M10 6.72017V4.87402" stroke="#EBEBEB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M10 15.9506V14.1045" stroke="#EBEBEB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	)
}
