import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  Polyline,
} from "@react-google-maps/api";
import { GOOGLE_MAPS_API_KEY } from "../constants";
import { geocodeLocation } from "../utilities";

import classes from "../css-files/DetailedTracking.module.css";

const Map = ({ trackingData }) => {
  const [mapMarkers, setMapMarkers] = useState([]);
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [selectedMarker, setSelectedMarker] = useState(null);

  const onMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  const path = mapMarkers.map((marker) => ({
    lat: marker.lat,
    lng: marker.lng,
  }));

  useEffect(() => {
    const updateMapMarkers = async (trackingData) => {
      const markers = [];
      let centerLocation = null;

      for (const location of trackingData) {
        // Create marker
        const newMarker = {
          lat: location.latitude !== 0.0 ? location.latitude : null,
          lng: location.longitude !== 0.0 ? location.longitude : null,
          city: location.city,
          state: location.state,
          zip: location.zip,
          status: location.status,
        };

        // Check for delivered status and set center location
        if (
          (location.status === "delivered" || location.status === "d") &&
          !centerLocation
        ) {
          centerLocation = newMarker;
        }

        // Geocode if latitude and longitude are not available
        if (newMarker.lat === null || newMarker.lng === null) {
          const { coords } = await geocodeLocation({
            city: location.city,
            state: location.state,
          });
          if (coords) {
            newMarker.lat = coords.lat;
            newMarker.lng = coords.lng;
          }
        }

        // Add marker to array
        if (newMarker.lat !== null && newMarker.lng !== null) {
          markers.push(newMarker);
        }
      }

      // If no delivered location found, use the first location as center
      if (!centerLocation && markers.length > 0) {
        centerLocation = markers[0];
      }

      // Update state with markers and center
      setMapMarkers(markers);
      if (centerLocation) {
        setCenter({ lat: centerLocation.lat, lng: centerLocation.lng });
      }
    };

    if (trackingData && mapMarkers.length !== 0) {
      updateMapMarkers(trackingData);
    } else {
      setMapMarkers([
        {
          lat: 0,
          lng: 0,
        },
      ]);
    }
    // eslint-disable-next-line
  }, [trackingData]);

  const containerStyle = {
    width: "100%",
    height: "350px",
  };

  return (
    <LoadScript
      googleMapsApiKey={`${GOOGLE_MAPS_API_KEY}`}
      loadingElement={<div>Loading...</div>}
    >
      <div style={{ borderRadius: "8px", overflow: "hidden" }}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={13}
          options={{
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
          }}
        >
          {mapMarkers.map((marker, index) => (
            <Marker
              key={index}
              position={{ lat: marker.lat, lng: marker.lng }}
              onClick={() => onMarkerClick(marker)}
            />
          ))}

          <Polyline
            path={path}
            options={{
              strokeColor: "#FAA808",
              strokeOpacity: 0.8,
              strokeWeight: 4,
              geodesic: true,
            }}
          />

          {selectedMarker && (
            <InfoWindow
              position={{
                lat: selectedMarker.lat,
                lng: selectedMarker.lng,
              }}
              onCloseClick={() => setSelectedMarker(null)}
            >
              <div className={classes.infoWindow}>
                <div
                  className={classes.outerCircle}
                  style={{ background: "#E8F9EE" }}
                >
                  <div
                    className={classes.innerCircle}
                    style={{ background: "#0EBC93" }}
                  ></div>
                </div>
                <p className={classes.markerText}>
                  {selectedMarker.city}, {selectedMarker.state}{" "}
                  {selectedMarker.zip}
                </p>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </div>
    </LoadScript>
  );
};

export default Map;
