import React, { Fragment, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@chakra-ui/react";
import ImageModal from "../ImageModal";

const TrackingImage = ({ data, imageWidth }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const podImages = useMemo(() => {
    if (data) {
      return data.reduce((prev, event) => {
        if (event.pod && event.pod.images) {
          event.pod.images.forEach((image) => {
            prev.add(image.imageUrl);
          });
        }
        return prev;
      }, new Set());
    }
    return new Set();
  }, [data]);

  return (
    <div className="w-full mb-10">
      <h3 className="text-sm font-bold mb-9">Proof of Delivery</h3>
      {data && data.length > 0 ? (
        <>
          {podImages.size > 0 ? (
            <Grid className="grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-3">
              {[...podImages].map((image, index) => (
                <Fragment key={index}>
                  <img
                    key={index}
                    src={image}
                    width={imageWidth}
                    alt={`Proof of delivery ${index + 1}`}
                    className="h-auto cursor-pointer mb-3 rounded-md"
                    onClick={() => {
                      setSelectedImage(index);
                      setIsModalOpen(true);
                    }}
                  />
                  <ImageModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    images={[...podImages].map((image) => image)}
                    selectedImage={selectedImage}
                    setSelectedImage={setSelectedImage}
                  />
                </Fragment>
              ))}
            </Grid>
          ) : (
            <p>No image available</p>
          )}
          <div className="mt-6 mb-6 flex font-normal text-sm">
            <div className="mr-1">
              {data[0]?.pod?.signatureName && (
                <div className="text-[#B6B6B6]">Signature name:</div>
              )}
              {data[0]?.pod?.otherDeliveryInfo && (
                <div className="text-[#B6B6B6] mt-3">Other info:</div>
              )}
            </div>
            <div className="ml-16">
              {data[0]?.pod?.signatureName && (
                <div>{data[0].pod.signatureName}</div>
              )}
              {data[0].pod?.otherDeliveryInfo && (
                <div className="mt-3">{data[0].pod.otherDeliveryInfo}</div>
              )}
            </div>
          </div>
        </>
      ) : (
        <p>No proof of delivery available</p>
      )}
    </div>
  );
};

export default TrackingImage;

TrackingImage.defaultProps = {
  data: [],
  imageWidth: 200,
};

TrackingImage.propTypes = {
  data: PropTypes.array,
  imageWidth: PropTypes.number,
};
