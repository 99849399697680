import React from "react";
import PropTypes from "prop-types";
import { Card, Divider, Flex, Spinner, Text } from "@chakra-ui/react";
import { SelectField } from "../InputFields";

const Recommendation = ({ data, shipper, setFilters, isLoading, error }) => {
  return (
    <Card
      boxShadow={"0px 2px 4px 0px #00396b33"}
      bg={"#21232E !important"}
      marginTop={7}
      padding={8}
    >
      {isLoading ? (
        <div className="h-[50px] flex items-center justify-center">
          <Spinner />
        </div>
      ) : error ? (
        <div className="h-[50px] flex items-center justify-center">
          <p className="text-base font-medium">{error}</p>
        </div>
      ) : (
        <Flex className="flex-col md:flex-row text-center md:text-start">
          <div>
            <Text className="text-base font-semibold mb-7 text-white">
              IMPACT
            </Text>
            <Text className="text-[52px] text-[#34B7A9] font-medium leading-[100%]">
              {data?.impact?.split(" ")[0]}
            </Text>
            <Text className="text-lg leading-7 text-[#34B7A9] font-medium">
              SAVINGS
            </Text>
          </div>
          <Divider className="md:hidden my-5 md:my-0" />
          <div className="md:ml-[72px]">
            <Text className="text-base font-semibold mb-7 text-white">
              RECOMMENDATION
            </Text>
            <Text className="text-base leading-7 text-white">
              {data?.message1}
              <br />
              {data?.message2}
            </Text>
            <div className="w-full lg:w-[450px] xl:w-[500px] mt-[18px]">
              <SelectField
                placeholder={"Select Shipper"}
                className={"!h-[44px]"}
                value={shipper}
                options={Object.keys(data?.dropdown ?? {}).map((key) => ({
                  value: key,
                  name: `${key}: ${data?.dropdown[key]}`,
                }))}
                onChange={(e) =>
                  setFilters((filter) => ({
                    ...filter,
                    shippers: e.target.value,
                  }))
                }
              />
            </div>
          </div>
        </Flex>
      )}
    </Card>
  );
};

export default Recommendation;

Recommendation.defaultProps = {
  data: {},
  shipper: "",
  setFilters: () => {},
  isLoading: false,
  error: "",
};

Recommendation.propTypes = {
  data: PropTypes.object,
  shipper: PropTypes.string,
  setFilters: PropTypes.func,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
};
