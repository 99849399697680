function formatDateString(dateString, dateOptions, timeOptions) {
  const options = {
    hour: "numeric",
    minute: "numeric",
    weekday: "long",
    month: "long",
    day: "numeric",
    hour12: true,
  };

  const date = new Date(dateString);
  if (dateOptions) {
    const datePart = date.toLocaleDateString("en-US", dateOptions);
    const timePart = date.toLocaleTimeString("en-US", timeOptions);
    return `${datePart}, ${timePart}`;
  } else {
    return date.toLocaleString("en-US", options);
  }
}

export default formatDateString;
