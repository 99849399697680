import { StepCounter, StepMap, StepNav, StepPage } from ".";
export function Step2({ data, setData }) {
  return (
    <StepPage>
      <StepCounter step={1} />
      <div className="mt-20 text-white ">
        <p className="text-2xl font-normal font-['Montserrat'] leading-10 tracking-wide">
          <div>Click on the states in which you are currently operating.</div>
          <div>Orchestro AI can provide custom pricing for each region.</div>
        </p>
        <div className="mt-8 mb-[-100px]">
          <StepMap data={data} setData={setData} />
        </div>
      </div>
      <StepNav
        prevLink="../step/1"
        nextLink="../step/3"
        nextLinkDisabled={!data.regions.length}
      />
    </StepPage>
  );
}
