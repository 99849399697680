import {Fragment} from "react";
import {
	Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import { formatCurrency, titleCase } from "../../../utilities";

export function PldSavingsTable({ data }) {
	if (!data) {
		return null;
	}
	return (
			<TableContainer mt={7}>
				<Table>
					<Thead>
						<Tr>
							<Th>To Zip</Th>
							<Th>From Zip</Th>
							<Th>Weight</Th>
							<Th>L</Th>
							<Th>W</Th>
							<Th>H</Th>
							{data?.parcels[0]?.rates.map((rates) => (
								<Fragment key={rates.name}>
									<Th>{titleCase(rates?.name)} Base Rate</Th>
									<Th>{titleCase(rates?.name)} Total</Th>
									<Th>{titleCase(rates?.name)} Weight</Th>
									<Th>{titleCase(rates?.name)} DAS</Th>
									<Th>{titleCase(rates?.name)} DAS Charges</Th>
								</Fragment>
							))}
						</Tr>
					</Thead>
					<Tbody>
						{data?.parcels?.map((parcel, i) => (
							<Tr key={i}>
								<Td>{parcel?.toZip}</Td>
								<Td>{parcel?.fromZip}</Td>
								<Td>{parcel?.weight.value} {parcel?.uom}</Td>
								<Td>{parcel?.dimensions?.length}</Td>
								<Td>{parcel?.dimensions?.width}</Td>
								<Td>{parcel?.dimensions?.height}</Td>
								{parcel?.rates.map((rates) => (
									<Fragment key={rates.name}>
										<Td>{formatCurrency(rates?.baseRate)}</Td>
										{rates.name.toLowerCase() === 'orchestro' ? (
											<Td><strong>{formatCurrency(rates?.totalRate)}</strong></Td>
										) : (
											<Td>{formatCurrency(rates?.totalRate)}</Td>
										)}
										<Td>{rates?.weight} {parcel?.uom}</Td>
										<Td>{rates?.das || "0"}</Td>
										<Td>{formatCurrency(rates?.dasCharge) || "0"}</Td>
									</Fragment>
								))}
							</Tr>
						))}
					</Tbody>
				</Table>
			</TableContainer>
	);
}