import React, { ReactNode } from "react";
import "./modal.css";
import * as Dialog from "@radix-ui/react-dialog";
import clsx from "clsx";
import { XMarkIcon } from "@heroicons/react/24/solid";
import IconButton from "../IconButton/IconButton";
import ScrollArea from "../ScrollArea/ScrollArea";

interface ModalProps {
  title?: string;
  type?: string;
  description?: string;
  children?: ReactNode;
  controls?: ReactNode;
  defaultOpen?: boolean;
  open?: boolean;
  onOpenChange?: Function;
  onClose?: Function;
  blurBackground?: boolean;
  className?: string;
  overlayClasses?: string;
  [key: string]: any;
}

const Modal: React.FC<ModalProps> = ({
  title,
  type = "hover",
  description,
  children,
  controls,
  defaultOpen,
  open,
  onOpenChange,
  onClose,
  blurBackground,
  className,
  overlayClasses = "",
  ...props
}) => {
  return (
    <Dialog.Root
      defaultOpen={defaultOpen}
      open={open}
      onOpenChange={onOpenChange}
      modal={blurBackground}
    >
      <Dialog.Portal>
        <Dialog.Overlay className={`oui-modal__overlay ${overlayClasses}`} />
        <Dialog.Content className={clsx("oui-modal__box", className)}>
          <Dialog.Title className="oui-modal__title">{title}</Dialog.Title>
          <Dialog.Description className="oui-modal__description">
            {description}
          </Dialog.Description>
          <ScrollArea
            type={type}
            className="oui-modal__body"
            orientation="vertical"
          >
            {children}
          </ScrollArea>
          {/* <div className="oui-modal__body">{children}</div> */}
          <div className="oui-modal__controls">{controls}</div>
          <Dialog.Close asChild>
            <IconButton
              context="secondary"
              className="oui-modal__close-button"
              aria-label="Close"
              onClick={onClose}
            >
              <XMarkIcon />
            </IconButton>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default Modal;
