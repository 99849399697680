import React, { useEffect, useState, useContext, useMemo } from "react";
import {
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import { AccountTabs, Page, TableLoading, UserContext } from "../interfaces";
import { getTrackingCodes } from "../services";
import { CARRIER_CODES, SHIPPER_CODES } from "../constants";

function TrackingCodes() {
  const [trackingCodes, setTrackingCodes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useContext(UserContext); // Use UserContext

  const isCarrierOnlyRole = useMemo(
    () =>
      [
        ...CARRIER_CODES,
        ...SHIPPER_CODES.filter((code) => CARRIER_CODES.includes(code)),
      ].some((carrierRole) => user?.Groups.includes(carrierRole)),
    [user]
  );

  useEffect(() => {
    const carrierCode = user.Groups.find((group) =>
      [...CARRIER_CODES, ...SHIPPER_CODES].includes(group)
    );

    if (!carrierCode) return;

    const fetchTrackingCodes = async () => {
      try {
        setIsLoading(true);
        const response = await getTrackingCodes(carrierCode);
        const data = await response.json();
        if (data.errors) {
          console.error("Error fetching tracking codes:", data.errors);
        } else {
          setTrackingCodes(data.trackingCodes);
        }
      } catch (error) {
        console.error("Error fetching tracking codes:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTrackingCodes();
  }, [user]); // Add user to dependency array

  return (
    <>
      <AccountTabs />
      <Page className={"!min-h-0"}>
        <h2 className="text-xl my-5">
          {isCarrierOnlyRole ? "Tracking Codes" : "Orchestro Tracking Codes"}
        </h2>
        <TableContainer className="max-h-[calc(100vh_-_240px)] !overflow-y-auto w-full mb-4">
          <Table>
            <Thead>
              <Tr>
                <Th>Code</Th>
                <Th>Description</Th>
                {isCarrierOnlyRole && (
                  <>
                    <Th>Status</Th>
                    <Th>Network Track Code</Th>
                    <Th>Network Description</Th>
                  </>
                )}
                <Th>Network Modifier Code</Th>
              </Tr>
            </Thead>
            <Tbody>
              {isLoading && <TableLoading data={trackingCodes} />}
              {!isLoading && trackingCodes.length === 0 && (
                <Tr>
                  <Td colSpan="100">No data found</Td>
                </Tr>
              )}
              {trackingCodes &&
                trackingCodes.map((code, i) => (
                  <Tr key={i}>
                    <Td fontWeight="bold">{code.code}</Td>
                    <Td>{code.description}</Td>
                    {isCarrierOnlyRole && (
                      <>
                        <Td>{code.status}</Td>
                        <Td fontWeight="bold">{code.networkTrackCode}</Td>
                        <Td>{code.networkDescription}</Td>
                      </>
                    )}
                    <Td>{code.networkModifierCode}</Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Page>
    </>
  );
}

export default TrackingCodes;
