export function getCurrentStatusFromEvent(status) {
  // Determine the status and color
  let statusText = "";
  let statusBgColor = "";
  let statusTextColor = "";
  let statusPadding = "";

  const latestStatus = status.toLowerCase();
  if (latestStatus === "delivered") {
    statusText = "Delivered";
    statusBgColor = "#03ff1c91";
    statusTextColor = "#606060";
    statusPadding = "5px 10px";
  } else {
    statusText = "In Transit"; // Default status
    statusBgColor = "transparent";
    statusTextColor = "black";
    statusPadding = "0";
  }

  return {
    status: statusText,
    backgroundColor: statusBgColor,
    textColor: statusTextColor,
    padding: statusPadding,
  };
}
