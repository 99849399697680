import { Link } from "react-router-dom";
import { Button } from "@chakra-ui/react";

import style from "../../css-files/Component-CSS/salesToolsCards.module.css";

export function PldCalculatorCard() {
  // This is s personal low point for the sake of haste

  return (
    <div className={style.div}>
      <div className={style.div2}>
        <svg width="71" height="71" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="35.5" cy="35.5" r="35.5" fill="#FF97C4"/>
          <path d="M54.9678 43.5754V26.5252C54.967 25.7777 54.7667 25.0436 54.3871 24.3964C54.0074 23.7493 53.4617 23.2119 52.8047 22.8381L37.6631 14.313C37.0055 13.9389 36.2594 13.7419 35.5 13.7419C34.7406 13.7419 33.9946 13.9389 33.3369 14.313L18.1954 22.8381C17.5384 23.2119 16.9927 23.7493 16.613 24.3964C16.2333 25.0436 16.0331 25.7777 16.0323 26.5252V43.5754C16.0331 44.3229 16.2333 45.057 16.613 45.7042C16.9927 46.3513 17.5384 46.8887 18.1954 47.2625L33.3369 55.7876C33.9946 56.1617 34.7406 56.3586 35.5 56.3586C36.2594 56.3586 37.0055 56.1617 37.6631 55.7876L52.8047 47.2625C53.4617 46.8887 54.0074 46.3513 54.3871 45.7042C54.7667 45.057 54.967 44.3229 54.9678 43.5754Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M16.6163 24.3087L35.5 35.0716L54.3837 24.3087" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M35.5 56.5335V35.0502" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M35.5 15.5825V32.3782" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M39.3172 18.6363H33.5914C32.8827 18.6363 32.2031 18.9178 31.702 19.4189C31.2009 19.92 30.9194 20.5997 30.9194 21.3083C30.9194 22.017 31.2009 22.6966 31.702 23.1978C32.2031 23.6989 32.8827 23.9804 33.5914 23.9804H37.4086C38.1173 23.9804 38.7969 24.2619 39.298 24.763C39.7991 25.2641 40.0807 25.9438 40.0807 26.6524C40.0807 27.3611 39.7991 28.0407 39.298 28.5418C38.7969 29.0429 38.1173 29.3245 37.4086 29.3245H30.9194" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </div>
      <div className={style.div3}>
        <div className={style.div4}></div>
        <div className={style.div5}>PLD Calculator</div>
        <div className={style.div6}>Savings per parcel calculator</div>
        <Button
          as={Link}
          to="/app/sales-tools/pld-calculator"
          mb={9}
          className="chakra-primary-button"
        >
          Run PLD Calculation
        </Button>
        <div className={style.div7}>
          <img
            alt=""
            loading="lazy"
            src="/images/icons/uploadIcon.svg"
            className={style.img}
          />
          <div>Upload A file with the shipment details(Excel, CSV)</div>
        </div>
        <div className={style.div7}>
          <img
            alt=""
            loading="lazy"
            src="/images/icons/calculateIcon.svg"
            className={style.img}
          />
          <div>
            We calculate all the rates including Base rate, surcharge, DIM
            Factor
          </div>
        </div>
        <div className={style.div7}>
          <img
            alt=""
            loading="lazy"
            src="/images/icons/dropDollarIcon.svg"
            className={style.img}
          />
          <div>
            Finally we compare the per parcel Cost and your potential savings
            with Orchestro
          </div>
        </div>
      </div>
    </div>
  );
};
