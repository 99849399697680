import React from "react";
import { useDisclosure } from "@chakra-ui/react";
import { Chatbot } from "../Chatbot";

const Trackbar = ({ details }) => {
  const {
    isOpen: isChatbotOpen,
    onOpen: onChatbotOpen,
    onClose: onChatbotClose,
  } = useDisclosure();

  return (
    <div className="flex bg-[#21232E]">
      <div className="m-4">
        <img alt="truck" width="223px" src="/images/track/truck.png" />
      </div>
      <div className="ml-2 mt-6">
        <div className="flex flex-col">
          <div className="font-semibold">{details.truck}</div>
          <div className="flex mt-2">
            <button
              className="text-xs px-3 h-[25px] mr-2 rounded-md border border-[#E0E1EC33] text-[#4FC1FF]"
              type="button"
            >
              Parcel list
            </button>
            <button
              className="text-xs h-[25px] px-3 mr-2 rounded-md border border-[#E0E1EC33] text-[#4FC1FF] flex justify-center items-center"
              type="button"
              onClick={onChatbotOpen}
            >
              <img alt="flag" src="/images/icons/userVoice.svg" width={14} />
              <span className="ml-1">Open Chatbot</span>
            </button>
            {/* Message Center */}
            <Chatbot
              isOpen={isChatbotOpen}
              onClose={onChatbotClose}
              variant={"drawer"}
            />
          </div>
          <div className="flex flex-row mt-8">
            <div className="flex flex-col">
              <div className="text-xs font-medium">
                <time dateTime={details.lastTracked}>
                  {details.lastTracked}
                </time>
              </div>
              <div className="text-xs text-[#7D8488]">Last tracked</div>
            </div>
            <div className="flex flex-col ml-14">
              <div className="text-xs font-medium">
                {details.currentLocation}
              </div>
              <div className="text-xs text-[#7D8488]">Current Location</div>
            </div>
            <div className="flex flex-col ml-12">
              <div className="text-xs font-medium">
                {details.currentDestination}
              </div>
              <div className="text-xs text-[#7D8488]">Current Destination</div>
            </div>
            <div className="flex flex-col ml-12">
              <div className="text-xs font-medium">
                {details.numberOfParcels}
              </div>
              <div className="text-xs text-[#7D8488]">Number of Parcels</div>
            </div>
            <div className="flex flex-col ml-12">
              <div className="text-xs font-medium text-[#FF243E]">
                {details.timeToDelivery}
              </div>
              <div className="text-xs text-[#7D8488]">Time to Delivery</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trackbar;
