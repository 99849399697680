import React, { useState, useContext } from "react";
import {
  Alert,
  Card,
  Button,
} from "@chakra-ui/react";
import { WarningTwoIcon } from "@chakra-ui/icons";

import { AccountTabs, Page, UserContext } from "../interfaces";
import { CARRIER_CODES } from "../constants";
import { downloadFileFromUrl } from "../utilities";

const coreServiceUrl = process.env.REACT_APP_CORE_SERVICES_URL;
const coreServicePath = process.env.REACT_APP_CORE_SERVICES_PATH;
const authorizationToken = process.env.REACT_APP_CORE_SERVICES_TOKEN;
const apiUrl = `${coreServiceUrl}${coreServicePath}`;

function SortCodes() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const { user } = useContext(UserContext); // Import UserContext from your context file

  const generateSortCodes = async () => {
    const carrierCode = user.Groups.find((group) =>
      CARRIER_CODES.includes(group)
    );

    if (!carrierCode) return;

    try {
      setIsLoading(true);
      setError("");
      const response = await fetch(apiUrl + "/get-sort-codes", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authorizationToken,
        },
        body: JSON.stringify({ carrierCode }),
      });

      if (response.ok) {
        const sortCodesUrl = await response.text();
        downloadFileFromUrl(sortCodesUrl);
      } else {
        console.error("Error fetching sort codes:", response.statusText);
        setError("Error fetching sort codes");
      }
    } catch (error) {
      console.error("Error fetching sort codes:", error);
      setError("Error fetching sort codes");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <AccountTabs />
      <Page>
        <Card
          mt="20px"
          border="1px"
          borderRadius="20px"
          borderWidth="1px"
          borderColor="rgba(133, 134, 152, 0.2)"
          p={8}
        >
          <h3 className="text-xl font-bold mb-2">
            Carrier Sort Codes
          </h3>
          <p className="mb-6">
            Download the file to view the information
          </p>
          <div>
            <Button
              size={"md"}
              isLoading={isLoading}
              onClick={generateSortCodes}
              className="chakra-primary-button"
            >
              Download
            </Button>
          </div>
          {error && (
            <Alert status="error" mt={6}>
              <WarningTwoIcon />
              <p>{error}</p>
            </Alert>
          )}
        </Card>
      </Page>
    </>
  );
}

export default SortCodes;
