import { Fragment } from "react";
import { StepNav, StepPage } from ".";
export function Step7({ data, setData, options }) {
  return (
    <StepPage>
      <div className="flex flex-col w-full text-white font-['Montserrat']">
        <div className=" flex flex-col items-center justify-center mt-12">
          <div className="w-[759px]">
            <span className="leading-10 text-xl tracking-wide">
              Time to test the Orchestro Network. Risk free!
            </span>
            <br />
            <span className="leading-[57px] tracking-wide text-sm">
              Select your preferred method of integration:
            </span>
          </div>
          <div className=" flex justify-start w-[759px] mt-3 flex-col">
            {options.map((option) => (
              <Fragment key={option.value}>
                <label
                  key={option.value}
                  htmlFor={option.value}
                  className="inline-flex items-center border w-[564px] border-[#C3C3C3] rounded-md gap-4 p-2 my-3 cursor-pointer"
                >
                  <div className="relative flex items-center p-3 rounded-full cursor-pointer">
                    <input
                      name="type"
                      type="radio"
                      className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-[#E0E1EC] text-[#3BA0E6] transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-[#3BA0E6] checked:before:bg-[#3BA0E6]"
                      id={option.value}
                      checked={data.preferredMethod === option.value}
                      onChange={() =>
                        setData({ ...data, preferredMethod: option.value })
                      }
                    />
                    <span className="absolute text-[#3BA0E6] transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-3.5 w-3.5"
                        viewBox="0 0 16 16"
                        fill="currentColor"
                      >
                        <circle
                          data-name="ellipse"
                          cx="8"
                          cy="8"
                          r="8"
                        ></circle>
                      </svg>
                    </span>
                  </div>
                  <span className="mt-px text-white text-xl font-normal font-['Montserrat'] tracking-wide cursor-pointer select-none">
                    {option.label}
                  </span>
                </label>
                {data?.preferredMethod === "1" &&
                  option.label === "Warehouse Management System" &&
                  option?.children?.map((option) => (
                    <div
                      key={option.value}
                      className="flex justify-start  items-center w-[564px] px-2"
                    >
                      <div className="w-1/12"></div>
                      <label
                        htmlFor={option.value}
                        className="inline-flex flex-1 cursor-pointer items-center gap-4"
                      >
                        <div className="relative flex items-center p-3 rounded-full cursor-pointer">
                          <input
                            name="type"
                            type="radio"
                            className="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none rounded-full border border-[#E0E1EC] text-[#3BA0E6] transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-[#3BA0E6] checked:before:bg-[#3BA0E6]"
                            id={option.value}
                            checked={
                              data.preferredMethodChildren === option.value
                            }
                            onChange={() =>
                              setData({
                                ...data,
                                preferredMethodChildren: option.value,
                              })
                            }
                          />
                          <span className="absolute text-[#3BA0E6] transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-2.5 w-2.5"
                              viewBox="0 0 16 16"
                              fill="currentColor"
                            >
                              <circle
                                data-name="ellipse"
                                cx="8"
                                cy="8"
                                r="8"
                              ></circle>
                            </svg>
                          </span>
                        </div>
                        <span className="mt-px text-white text-sm font-normal font-['Montserrat'] tracking-wide cursor-pointer select-none">
                          {option.label}
                        </span>
                        {data.preferredMethodChildren === "6" &&
                          option.label === "Other" && (
                            <input
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  preferredMethodOtherTextBoxValue:
                                    e.target.value,
                                })
                              }
                              className="w-2/3 border-b-2 border-indigo-500 focus:border-indigo-500 outline-none rounded text-xs p-1"
                              type="text"
                            />
                          )}
                      </label>
                    </div>
                  ))}
                {data?.preferredMethod === "2" &&
                  option.label === "Order Management System" &&
                  option?.children?.map((option) => (
                    <div
                      key={option.value}
                      className="flex justify-start  items-center w-[564px] px-2"
                    >
                      <div className="w-1/12"></div>
                      <label
                        htmlFor={option.value}
                        className="inline-flex flex-1 cursor-pointer items-center gap-4"
                      >
                        <div className="relative flex items-center p-3 rounded-full cursor-pointer">
                          <input
                            name="type"
                            type="radio"
                            className="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none rounded-full border border-[#E0E1EC] text-[#3BA0E6] transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-[#3BA0E6] checked:before:bg-[#3BA0E6]"
                            id={option.value}
                            checked={
                              data.preferredMethodChildren === option.value
                            }
                            onChange={() =>
                              setData({
                                ...data,
                                preferredMethodChildren: option.value,
                              })
                            }
                          />
                          <span className="absolute text-[#3BA0E6] transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-2.5 w-2.5"
                              viewBox="0 0 16 16"
                              fill="currentColor"
                            >
                              <circle
                                data-name="ellipse"
                                cx="8"
                                cy="8"
                                r="8"
                              ></circle>
                            </svg>
                          </span>
                        </div>
                        <span className="mt-px text-white text-sm font-normal font-['Montserrat'] tracking-wide cursor-pointer select-none">
                          {option.label}
                        </span>
                        {data.preferredMethodChildren === "6" &&
                          option.label === "Other" && (
                            <input
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  preferredMethodOtherTextBoxValue:
                                    e.target.value,
                                })
                              }
                              className="w-2/3 border-b-2 border-indigo-500 focus:border-indigo-500 outline-none rounded text-xs p-1"
                              type="text"
                            />
                          )}
                      </label>
                    </div>
                  ))}
                {data?.preferredMethod === "3" &&
                  option.label === "Rate Shopping Engine" &&
                  option?.children?.map((option) => (
                    <div
                      key={option.value}
                      className="flex justify-start  items-center w-[564px] px-2"
                    >
                      <div className="w-1/12"></div>
                      <label
                        htmlFor={option.value}
                        className="inline-flex flex-1 cursor-pointer items-center gap-4"
                      >
                        <div className="relative flex items-center p-3 rounded-full cursor-pointer">
                          <input
                            name="type"
                            type="radio"
                            className="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none rounded-full border border-[#E0E1EC] text-[#3BA0E6] transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-[#3BA0E6] checked:before:bg-[#3BA0E6]"
                            id={option.value}
                            checked={
                              data.preferredMethodChildren === option.value
                            }
                            onChange={() =>
                              setData({
                                ...data,
                                preferredMethodChildren: option.value,
                              })
                            }
                          />
                          <span className="absolute text-[#3BA0E6] transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-2.5 w-2.5"
                              viewBox="0 0 16 16"
                              fill="currentColor"
                            >
                              <circle
                                data-name="ellipse"
                                cx="8"
                                cy="8"
                                r="8"
                              ></circle>
                            </svg>
                          </span>
                        </div>
                        <span className="mt-px text-white text-sm font-normal font-['Montserrat'] tracking-wide cursor-pointer select-none">
                          {option.label}
                        </span>
                        {data.preferredMethodChildren === "6" &&
                          option.label === "Other" && (
                            <input
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  preferredMethodOtherTextBoxValue:
                                    e.target.value,
                                })
                              }
                              className="w-2/3 border-b-2 border-indigo-500 focus:border-indigo-500 outline-none rounded text-xs p-1"
                              type="text"
                            />
                          )}
                      </label>
                    </div>
                  ))}
              </Fragment>
            ))}
          </div>
        </div>
      </div>

      <StepNav
        prevLink="../step/6"
        nextLink="../step/8"
        nextLinkDisabled={
          !(data.preferredMethod || data.preferredMethodChildren)
        }
      />
    </StepPage>
  );
}
