import { useContext, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import MapView from "../interfaces/Dashboard/map";
import Podbar from "../interfaces/Dashboard/podbar";
import BarChart from "../interfaces/Dashboard/BarChart";
import { NewUserModal } from "../interfaces/Dashboard/NewUserModal";
import { NewUserPanel } from "../interfaces/Dashboard/NewUserPanel";
import Alert from "../interfaces/Dashboard/Alert";
import { UserContext } from "../interfaces";
import { dummyChart } from "../data/newUserEmptyState";

const coreServiceToken = process.env.REACT_APP_CORE_SERVICES_TOKEN;
const coreServiceUrl = process.env.REACT_APP_CORE_SERVICES_URL;
const coreServicePath = process.env.REACT_APP_CORE_SERVICES_PATH;
const apiUrl = coreServiceUrl + coreServicePath;

const Dashboard = () => {
  const { alert, dismissAlert, isDemo } = useContext(UserContext);
  const [isDummy, setIsDummy] = useState(false);
  const [charts, setCharts] = useState({
    carriers: [],
    zips: [],
  });

  // Commented below code because it has been unused for a while

  // const getCharts = async () => {
  //   try {
  //     const response = await fetch(apiUrl + "/chart", {
  //       method: "GET",
  //       headers: {
  //         authorization: coreServiceToken,
  //         "Content-Type": "application/json",
  //       },
  //     });
  //     const resp = await response.json();
  //     if (resp.error) {
  //       console.error("Something went wrong, please try again later");
  //     } else {
  //       if (resp.carriers.length > 0) {
  //         setCharts(resp);
  //         setIsDummy(false);
  //       } else {
  //         setCharts(dummyChart);
  //         setIsDummy(true);
  //       }
  //     }
  //   } catch (error) {
  //     console.error(`Error fetching "/kpi":`, error);
  //   }
  // };

  useEffect(() => {
    // Until the get-charts and get-kpi endpoints are hooked up to real data just go with dummy data
    // getCharts();
    setCharts(dummyChart);
    setIsDummy(true);
  }, []);

  const c1Colors = ["#97A44D", "#B2A4DC", "#42A140", "#FFECA3", "#FC0"];
  const c2Colors = ["#189DE4", "#89B4EB", "#626F8F", "#BFD2D7", "#6679CA"];

  const handleVisibility = () => {
    dismissAlert();
  };

  return (
    <div className="flex-grow p-5 ml-2 mt-2">
      {isDemo && (
        <Alert
          content={alert}
          visible={alert.id}
          handleVisible={handleVisibility}
        />
      )}
      <Podbar />
      {isDemo ? (
        <MapView />
      ) : isDummy ? (
        <>
          <div className="h-[350px]"></div>
          <NewUserModal>
            <p className="text-lg mb-1 max-w-[635px]">
              Soon we will be rolling out powerful data visualizations.
              <br />
              <br />
              Meanwhile, you can review your configuration under the{" "}
              <NavLink to="/app/account">Account Preferences</NavLink> section
              or review your shipments under{" "}
              <NavLink to="/app/shipments">Shipments</NavLink>, all accessible
              in the left navigation.
              <br />
              <br />
              Thanks
              <br />
              Orchestro AI
            </p>
            {/* <p className="text-lg mb-1">
              Once you have begun scanning your Orchestro shipping labels, this page will become available displaying your critical metrics.<br/>
              <br/>
              Meanwhile, you can review your configuration under the <NavLink to="/app/account">Account Preferences</NavLink> section accessible in the left navigation.<br/>
              <br/>
              Thanks<br/>
              Orchestro AI
            </p> */}
          </NewUserModal>
        </>
      ) : (
        <div className="my-5">
          <NewUserPanel>
            <p className="text-lg mb-1 max-w-[635px]">
              Soon we will be rolling out powerful data visualizations.
              <br />
              <br />
              Meanwhile, you can review your configuration under the{" "}
              <NavLink to="/app/account">Account Preferences</NavLink> section
              or review your shipments under{" "}
              <NavLink to="/app/shipments">Shipments</NavLink>, all accessible
              in the left navigation.
              <br />
              <br />
              Thanks
              <br />
              Orchestro AI
            </p>
          </NewUserPanel>
        </div>
      )}
      {(isDemo || isDummy) && (
        <div className="flex mt-4 gap-x-4">
          <div className="active rounded-md w-1/2">
            <BarChart
              title={isDummy ? "" : "Bottom 5 Carriers By Volume"}
              xAxis={isDummy ? "" : "Carriers"}
              yAxis={isDummy ? "" : "Volume (Thousands)"}
              data={charts.carriers}
              colors={c1Colors}
              isDisplayTimeRange={isDummy ? false : true}
              isDisplayLegend={isDummy ? false : true}
            />
          </div>
          <div className="active rounded-md w-1/2">
            <BarChart
              title={isDummy ? "" : "Top 5 Parcel Deliveries by Zip Code"}
              xAxis={isDummy ? "" : "Zip Codes"}
              yAxis={isDummy ? "" : "Volume of Deliveries (Thousands)"}
              data={charts.zips}
              colors={c2Colors}
              isDisplayTimeRange={isDummy ? false : true}
              isDisplayLegend={isDummy ? false : true}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
