import PldCalculator from "../../pages/PldCalculator";

export function Step9() {
  return (
    <div className="p-20 pt-12 bg-[rgb(24, 25, 33)] min-h-screen">
      <div className="flex flex-col max-w-[850px] text-white font-['Montserrat'] ml-auto mr-auto">
        <PldCalculator />
      </div>
    </div>
  );
}
