import { useEffect, useState, useCallback, useContext } from "react";
import classes from "../css-files/MiddleMileOrders.module.css";
import { Link } from "react-router-dom";
import {
  Heading,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";

import { UserContext } from "../interfaces/Providers/UserProviders/UsersProvider";
import { OrderIcon, Page, Pagination, TableLoading } from "../interfaces";

const coreServiceUrl = process.env.REACT_APP_CORE_SERVICES_URL;
const coreServicePath = process.env.REACT_APP_CORE_SERVICES_PATH;
const authorizationToken = process.env.REACT_APP_CORE_SERVICES_TOKEN;
const apiUrl = coreServiceUrl + coreServicePath;

function MiddleMileOrders() {
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);

  const PAGE_OPTIONS = [10, 25, 100];
  const [paginationConfig, setPaginationConfig] = useState({
    options: PAGE_OPTIONS,
    perPage: PAGE_OPTIONS[0],
  });
  const [originalData, setOriginalData] = useState({
    results: [],
    limit: PAGE_OPTIONS[0],
    start: 1,
    count: null,
  });
  const [displayedData, setDisplayedData] = useState([]);

  const fetchData = useCallback(() => {
    // Extract the group information from the user details
    const carrierCode = user.Groups.find((group) =>
      ["GLS", "LSO", "ADMIN"].includes(group)
    );

    if (!carrierCode) return;

    setIsLoading(true);

    // Prepare the request body
    const requestBody = {
      limit: 1000, // Initial fetch for client side pagination, then gets set in response,
      start: 1,
    };

    // Conditionally include the carrierCode in the request body
    if (carrierCode !== "ADMIN") {
      requestBody.carrierCode = carrierCode;
    }

    fetch(apiUrl + "/get-middle-mile-orders", {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: authorizationToken,
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((response) => {
        if (response) {
          setOriginalData({
            ...response,
            quickInfoData: {
              onRoute: response.onRoute || 0,
              receivedAtDestination: response.receivedAtDestination || 0,
              delayed: response.delayed || 0,
              notDispatched: response.notDispatched || 0,
              totalOrders: response.totalOrders || 0,
            },
            limit: response.count,
          });
        } else {
          console.error("Unexpected response format:", response);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [user]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleIndexChange = useCallback(
    (start, limit) => {
      const newData = originalData?.orders
        ? originalData.orders.slice(start - 1, start + limit - 1)
        : [];
      setDisplayedData(newData);
    },
    [originalData]
  );

  useEffect(() => {
    handleIndexChange(originalData.start, paginationConfig.perPage);
  }, [handleIndexChange, originalData, paginationConfig.perPage]);

  const getStatusColor = (status) => {
    switch (status) {
      case "Not Dispatched":
        return "#007AFF";
      case "On Route":
        return "#FAA808";
      case "Received at Destination":
        return "#3E8F40";
      case "Delayed":
        return "#FA2C2C";
      default:
        return "#000"; // Default color or handle other cases as needed
    }
  };

  return (
    <Page>
      <div className={classes.totalOrders}>
        <OrderIcon />
        <Text fontSize={"1rem"}>Total Orders</Text>
        {originalData.quickInfoData && (
          <Text fontSize={"1rem"} className={classes.quickInfoH4}>
            {originalData.quickInfoData.totalOrders}
          </Text>
        )}
      </div>

      <div className={classes.quickInfoContainer}>
        {originalData.quickInfoData && (
          <>
            <div className={classes.quickInfo}>
              <div className={classes.flexBox}>
                <div
                  className={classes.dot}
                  style={{ backgroundColor: "#007AFF" }}
                ></div>
                <Text fontSize={"1rem"}>Not Dispatched</Text>
              </div>
              <Text fontSize={"1rem"} className={classes.quickInfoH4}>
                {originalData.quickInfoData.notDispatched}
              </Text>
            </div>
            <div className={classes.quickInfo}>
              <div className={classes.flexBox}>
                <div
                  className={classes.dot}
                  style={{ backgroundColor: "#FAA808" }}
                ></div>
                <Text fontSize={"1rem"}>On Route</Text>
              </div>
              <Text fontSize={"1rem"} className={classes.quickInfoH4}>
                {originalData.quickInfoData.onRoute}
              </Text>
            </div>
            <div className={classes.quickInfo}>
              <div className={classes.flexBox}>
                <div
                  className={classes.dot}
                  style={{ backgroundColor: "#3E8F40" }}
                ></div>
                <Text fontSize={"1rem"}>Received at Destination</Text>
              </div>
              <Text fontSize={"1rem"} className={classes.quickInfoH4}>
                {originalData.quickInfoData.receivedAtDestination}
              </Text>
            </div>
            <div className={classes.quickInfo}>
              <div className={classes.flexBox}>
                <div
                  className={classes.dot}
                  style={{ backgroundColor: "#FA2C2C" }}
                ></div>
                <Text fontSize={"1rem"}>Delayed</Text>
              </div>
              <Text fontSize={"1rem"} className={classes.quickInfoH4}>
                {originalData.quickInfoData.delayed}
              </Text>
            </div>
          </>
        )}
      </div>
      <Heading as={"h1"} size={"md"} mt={9} mb={5}>
        Middle Mile Order Lists
      </Heading>
      <TableContainer className="max-h-[calc(100vh_-_355px)] !overflow-y-auto w-full mb-4">
        <Table>
          <Thead>
            <Tr>
              <Th>Order ID</Th>
              <Th>Carrier</Th>
              <Th>Dispatched on</Th>
              <Th>Lane</Th>
              <Th>Destination Carrier</Th>
              <Th>Containers</Th>
              <Th>Shipments</Th>
              <Th>Status</Th>
            </Tr>
          </Thead>
          <Tbody>
            {isLoading && <TableLoading data={displayedData} />}
            {!isLoading && displayedData && displayedData.length === 0 && (
              <Tr>
                <Td colSpan="100">No data available</Td>
              </Tr>
            )}
            {displayedData &&
              displayedData.map((entry, index) => (
                <Tr className={classes.tr} key={index}>
                  <Td>
                    <Text
                      as={Link}
                      to={`/app/middle-mile-orders/${entry.orderId}`}
                    >
                      {entry.orderId}
                    </Text>
                  </Td>
                  <Td>{entry.carrier}</Td>
                  <Td>
                    {entry.dispatchedOn && (
                      <time
                        dateTime={new Date(entry.dispatchedOn).toISOString()}
                      >
                        {entry.dispatchedOn}
                      </time>
                    )}
                  </Td>
                  <Td>{entry.lane}</Td>
                  <Td>{entry.destinationCarrier} </Td>
                  <Td>{entry.containers}</Td>
                  <Td>{entry.parcels}</Td>
                  <Td>
                    <div
                      className={classes.statusDot}
                      style={{
                        backgroundColor: getStatusColor(entry.status),
                      }}
                    ></div>
                    {entry.status}
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Pagination
        config={paginationConfig}
        start={originalData.start}
        count={originalData.count}
        onIndexChange={handleIndexChange}
        setPaginationConfig={setPaginationConfig}
        labelText="Number of rows"
      />
    </Page>
  );
}

export default MiddleMileOrders;
