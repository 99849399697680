// src/store/filtersSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  startDate: '',
  endDate: '',
  zip: '',
  shipperCode: '',
  carrierCode: '',
  inboundSortHub: '',
  outboundSortHub: '',
  truckId: '',
  serviceType: '',
  tntStatus: '',
  shipmentStatus: '',
};

const saveFiltersToLocalStorage = (filters) => {
  localStorage.setItem('shipmentFilters', JSON.stringify(filters));
};

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      const newFilters = { ...state, ...action.payload };
      saveFiltersToLocalStorage(newFilters);
      return newFilters;
    },
    resetFilters: () => {
      saveFiltersToLocalStorage(initialState);
      return initialState;
    },
  },
});

export const { setFilters, resetFilters } = filtersSlice.actions;

export default filtersSlice.reducer;
