import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { Card, Heading, Select } from "@chakra-ui/react";
import {
  Flex,
  Box,
  Button,
  Spinner,
  Input,
  FormControl,
  FormLabel,
  Text,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

import classes from "../css-files/OrderDetails.module.css";
import { Page } from "../interfaces";

const coreServiceUrl = process.env.REACT_APP_CORE_SERVICES_URL;
const coreServicePath = process.env.REACT_APP_CORE_SERVICES_PATH;
const authorizationToken = process.env.REACT_APP_CORE_SERVICES_TOKEN;
const apiUrl = `${coreServiceUrl}${coreServicePath}`;

const NewMMO = () => {
  const location = useLocation();
  const selectedLaneFromPreviousComponent = location.state?.selectedLane;
  const [lanes, setLanes] = useState([]);
  const [selectedLane, setSelectedLane] = useState(
    selectedLaneFromPreviousComponent
  );
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [formData, setFormData] = useState({
    lane: selectedLane,
    orderNumber: "",
    numberOfContainersOrdered: "",
    orderDate: "",
    pickupDate: "",
    middleMileCarrierCode: selectedCarrier ? selectedCarrier.code : "",
    middleMileContactName: "",
    middleMileContactPhoneNumber: "",
    status: "NOT_DISPATCHED", // Default status as NOT_DISPATCHED
    vehicleLicense: "", // Add your logic to get this value
    driverName: "", // Add your logic to get this value
    billOfLading: "",
  });
  const [carriers, setCarriers] = useState([]);
  const [loading, setLoading] = useState(true); // New loading state

  ///////////////// GET API FOR DROPDOWNS /////////////////

  const fetchData = useCallback(async () => {
    try {
      const [lanesResponse, middleMileCarriersResponse] = await Promise.all([
        fetch(apiUrl + "/get-lanes", {
          headers: {
            Authorization: authorizationToken,
          },
        }),
        fetch(apiUrl + "/get-middle-mile-carriers", {
          headers: {
            Authorization: authorizationToken,
          },
        }),
      ]);

      if (!lanesResponse.ok || !middleMileCarriersResponse.ok) {
        throw new Error("Network response was not ok");
      }

      const [lanesData, middleMileCarriersData] = await Promise.all([
        lanesResponse.json(),
        middleMileCarriersResponse.json(),
      ]);

      setLanes(lanesData || []); // Ensure that the state is updated even if the response is empty
      setCarriers(middleMileCarriersData || []);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Update formData when selectedCarrier or selectedLane changes
  useEffect(() => {
    if (selectedCarrier) {
      setFormData((prevData) => ({
        ...prevData,
        middleMileCarrierCode: selectedCarrier.code,
      }));
    }
  }, [selectedCarrier]);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      lane: selectedLane,
    }));
  }, [selectedLane]);

  const convertDatesToObjects = () => {
    const { orderDate, pickupDate, ...rest } = formData;
    return {
      ...rest,
      orderDate: new Date(orderDate).toLocaleDateString(), // Convert orderDate to Date object
      pickupDate: new Date(pickupDate).toLocaleDateString(), // Convert pickupDate to Date object
    };
  };

  ///////////////// SAVE API /////////////////

  const handleSaveClick = async () => {
    try {
      let formattedData = convertDatesToObjects();
      // Send the request to the API
      const response = await fetch(apiUrl + "/save-middle-mile-order", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authorizationToken,
        },
        body: JSON.stringify(formattedData),
      });

      if (response.ok) {
        // Show success alert
        alert("Your Order is saved");
      } else {
        // Show error alert
        const errorResponse = await response.json();
        alert(`Error saving Order: ${errorResponse.message}`);
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle other errors, e.g., show an error message
      alert("An unexpected error occurred. Please try again later.");
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" my={"10em"}>
        <Spinner />
      </Box>
    );
  }

  const getDescriptionForSelectedLane = () => {
    const selectedLaneData = lanes.find(
      (laneData) => laneData.lane === selectedLaneFromPreviousComponent
    );

    return selectedLaneData
      ? selectedLaneData.description
      : "No description available";
  };

  return (
    <Page>
      <Flex justifyContent={"space-between"} alignItems={"center"} mb={3}>
        <Heading as={"h1"} size={"md"}>
          New Middle Mile Order
        </Heading>
        <Button onClick={handleSaveClick} className="chakra-primary-button">
          Save
        </Button>
      </Flex>
      <Flex gap={8} mb={8}>
        <Card
          borderRadius="20px"
          borderWidth="1px"
          borderColor="rgba(133, 134, 152, 0.2)"
          p={8}
          flex="1"
        >
          <Heading as={"h3"} size={"sm"}>
            Order Details
          </Heading>
          <hr className={classes.hr} />
          <FormControl mt={5}>
            <FormLabel fontSize={"sm"}>Lane</FormLabel>
            <Select
              icon={<ChevronDownIcon />}
              value={selectedLane || ""}
              onChange={(e) => setSelectedLane(e.target.value)}
            >
              <option value="" disabled>
                Select a Lane
              </option>
              {lanes.map((lane) => (
                <option key={lane.lane} value={lane.lane}>
                  {lane.lane}
                </option>
              ))}
            </Select>
          </FormControl>
          <Heading as={"h4"} size={"xs"} mt={8} mb={1}>
            Description
          </Heading>
          <Text>{getDescriptionForSelectedLane()}</Text>
        </Card>

        <Card
          borderRadius="20px"
          borderWidth="1px"
          borderColor="rgba(133, 134, 152, 0.2)"
          p={8}
          flex="1"
        >
          <Heading as={"h3"} size={"sm"}>
            Status
          </Heading>
          <hr className={classes.hr} />
          <FormControl mt={5}>
            <FormLabel fontSize={"sm"}>Current Status</FormLabel>
            <Input isDisabled value={"NOT_DISPATCHED"} />
          </FormControl>
        </Card>
      </Flex>

      <Card
        borderWidth="1px"
        p={8}
        borderRadius="20px"
        borderColor="rgba(133, 134, 152, 0.2)"
      >
        <Heading as={"h3"} size="sm">
          Order Details
        </Heading>
        <hr className={classes.hr} />
        <Flex gap={8} my={5}>
          <FormControl>
            <FormLabel fontSize={"sm"}>Order Number</FormLabel>
            <Input
              name="orderNumber"
              value={formData?.orderNumber}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl>
            <FormLabel fontSize={"sm"}>Number Of Containers</FormLabel>
            <Input
              name="numberOfContainersOrdered"
              value={formData?.numberOfContainersOrdered}
              onChange={handleInputChange}
            />
          </FormControl>
        </Flex>
        <Flex gap={8} mb={5}>
          <Flex gap={8} flex="1">
            <FormControl>
              <FormLabel fontSize={"sm"}>Order Date</FormLabel>
              <Input
                type="date"
                name="orderDate"
                value={formData?.orderDate}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel fontSize={"sm"}>Pickup Date</FormLabel>
              <Input
                type="date"
                name="pickupDate"
                value={formData?.pickupDate}
                onChange={handleInputChange}
              />
            </FormControl>
          </Flex>
          <FormControl flex="1">
            <FormLabel fontSize={"sm"}>Select Middle Mile Carrier</FormLabel>
            <Select
              icon={<ChevronDownIcon />}
              value={selectedCarrier ? selectedCarrier.code : ""}
              onChange={(e) =>
                setSelectedCarrier(
                  carriers.find((carrier) => carrier.code === e.target.value)
                )
              }
            >
              <option value="" disabled className={classes.input}>
                Select a Carrier
              </option>
              {carriers.map((carrier) => (
                <option key={carrier.code} value={carrier.code}>
                  {carrier.name}
                </option>
              ))}
            </Select>
          </FormControl>
        </Flex>
        <Flex gap={8}>
          <FormControl>
            <FormLabel fontSize={"sm"}>Middle Mile Contact Name</FormLabel>
            <Input
              name="middleMileContactName"
              value={formData?.middleMileContactName}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl>
            <FormLabel fontSize={"sm"}>Middle Mile Contact Phone</FormLabel>
            <Input
              name="middleMileContactPhoneNumber"
              value={formData?.middleMileContactPhoneNumber}
              onChange={handleInputChange}
            />
          </FormControl>
        </Flex>
      </Card>
    </Page>
  );
};

export default NewMMO;
