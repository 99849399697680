// Add all fetch APIs here to use anywhere in code base

import { createFeature } from "../utilities";

const coreServiceUrl = process.env.REACT_APP_CORE_SERVICES_URL;
const coreServicePath = process.env.REACT_APP_CORE_SERVICES_PATH;
const bidEngineServiceUrl = process.env.REACT_APP_BID_ENGINE_SERVICE_URL;
const bidEngineServicePath = process.env.REACT_APP_BID_ENGINE_SERVICE_PATH;
const authorizationToken = process.env.REACT_APP_CORE_SERVICES_TOKEN;
const apiUrl = coreServiceUrl + coreServicePath;
const bidEngineService = bidEngineServiceUrl + bidEngineServicePath;

// get-all-partner onboarding

const getAllPartnerOnAccountRequest = async (status) => {
  try {
    const response = await fetch(
      `${apiUrl}/get-all-partner-onboarding?status=${status.toUpperCase()}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Authorization: authorizationToken,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Error fetching Account Request data");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching Account Request data:", error);
  }
};

// update-partner onboarding

const updatePartnerOnAccountRequestById = async (
  accountDataId,
  selectedStatus
) => {
  try {
    const response = await fetch(
      `${apiUrl}/update-partner-onboarding?partnerOnboardingId=${accountDataId}&status=${selectedStatus.toUpperCase()}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: authorizationToken,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return response;
  } catch (error) {
    console.error("Error updating status:", error);
  }
};

// getPartner onboarding

const getPartnerOnAccountRequestById = async (partnerOnboardingId) => {
  try {
    const response = await fetch(
      `${apiUrl}/get-partner-onboarding?partnerOnboardingId=${partnerOnboardingId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Authorization: authorizationToken,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching account data:", error);
  }
};

// Events

// Public Call Directly

const getEventsBasedOnTrackerIdPublic = async (trackingNumber) => {
  try {
    const response = await fetch(`${apiUrl}/get-status?trackingNumber=${trackingNumber}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};


const getEventsBasedOnTrackerId = async (trackingNumber) => {
  try {
    const response = await fetch(
      `${apiUrl}/ui-events?trackingNumber=${trackingNumber}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authorizationToken,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

// Parcels

const getParcels = async (body) => {
  try {
    const response = await fetch(apiUrl + "/get-parcels", {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: authorizationToken,
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const getParcelsBasedOnId = async (trackingNumber) => {
  try {
    const response = await fetch( apiUrl + "/get-parcels", {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: authorizationToken,
      },
      body: JSON.stringify({
        trackingNumber: trackingNumber,
        limit: 1, // Adjust the limit as needed
        start: 1,
      }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

// Parcel Intelligence

const getParcelTileDetailByPartner = async (partner = "All") => {
  try {
    const response = await fetch(
      `${bidEngineService}/parcel-tile-detail?partner=${partner}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Authorization: authorizationToken,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const getChartDataByPartner = async (partner) => {
  try {
    const response = await fetch(
      `${bidEngineService}/chart-data?partner=${partner}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Authorization: authorizationToken,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const getParcelRecommendationByPartner = async (partner = "") => {
  try {
    const response = await fetch(
      `${bidEngineService}/parcel-recommendation?partner=${partner}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Authorization: authorizationToken,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    throw new Error("Error while fetching recommendation data");
  }
};

const getAllPartners = async () => {
  try {
    const response = await fetch(bidEngineService + "/partners", {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: authorizationToken,
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const getShippersByPartner = async (partner) => {
  try {
    const response = await fetch(
      `${bidEngineService}/shippers?partner=${partner}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Authorization: authorizationToken,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const getCarriersByShipper = async (shipper) => {
  try {
    const response = await fetch(
      `${bidEngineService}/carriers?shipper=${shipper}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Authorization: authorizationToken,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

// Invoice

const getShipperInvoice = async (
  carrierCode,
  pageNumber = 1,
  pageSize = 25
) => {
  try {
    const response = await fetch(
      `${apiUrl}/get-shipper-invoice?pageNumber=${pageNumber}&pageSize=${pageSize}&carrierCode=${carrierCode}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Authorization: authorizationToken,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

// Carrier

const getCarrierInfo = async (carrierCode) => {
  try {
    const response = await fetch(apiUrl + "/get-carrier-info", {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: authorizationToken,
      },
      body: JSON.stringify({
        carrierCode,
      }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const getCarriers = async () => {
  try {
    const response = await fetch(apiUrl + "/get-carriers", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: authorizationToken,
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error(`Error fetching data:`, error);
  }
};

// Service Attributes

const getServiceAttributes = async (carrierCode) => {
  try {
    const response = await fetch(apiUrl + "/get-service-attributes", {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: authorizationToken,
      },
      body: JSON.stringify({
        carrierCode,
      }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

// Service Types

const getServiceType = async (carrierCode) => {
  try {
    const response = await fetch(apiUrl + "/get-service-type", {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: authorizationToken,
      },
      body: JSON.stringify({
        carrierCode,
      }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

// Bid Analytics

const getLiveBidCountDetail = async () => {
  try {
    const response = await fetch(
      `${bidEngineService}/bid-analytics/live-bid-count`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Authorization: authorizationToken,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const getWonLostBidDetail = async (start, end, duration) => {
  try {
    const response = await fetch(
      `${bidEngineService}/bid-analytics/won-lost-bid-chart?start=${start}&end=${end}&duration=${duration}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Authorization: authorizationToken,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const getRseStatsForCarriers = async () => {
  try {
    const response = await fetch(
      `${bidEngineService}/bid-analytics/rse-stats-for-carriers`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Authorization: authorizationToken,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const getAvgRseStatsForDay = async (date) => {
  try {
    const response = await fetch(
      `${bidEngineService}/bid-analytics/avg-rse-stats-for-day?date=${date}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Authorization: authorizationToken,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const getBidPerformanceDetails = async (limit, skip) => {
  try {
    const response = await fetch(
      `${bidEngineService}/bid-performance?limit=${limit}&skip=${skip}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Authorization: authorizationToken,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

// Bid Config

const getBidConfigDetails = async (limit, skip) => {
  try {
    const response = await fetch(
      `${bidEngineService}/bid-config?limit=${limit}&skip=${skip}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Authorization: authorizationToken,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const createBidConfig = async (body) => {
  try {
    const response = await fetch(`${bidEngineService}/bid-config/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: authorizationToken,
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const getBidConfigById = async (id) => {
  try {
    const response = await fetch(`${bidEngineService}/bid-config/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: authorizationToken,
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const updateBidConfig = async (id, body) => {
  try {
    const response = await fetch(
      `${bidEngineService}/bid-config/update/${id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Authorization: authorizationToken,
        },
        body: JSON.stringify(body),
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const getBidTierConfiguration = async (risk, biddingTier) => {
  try {
    const response = await fetch(
      `${bidEngineService}/bid-tier-configuration?risk=${risk}&biddingTier=${biddingTier}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Authorization: authorizationToken,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

// Surcharges

const getSurcharges = async (carrierCode) => {
  try {
    const response = await fetch(apiUrl + "/get-surcharges", {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: authorizationToken,
      },
      body: JSON.stringify({
        carrierCode,
      }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

// Tracking Codes

const getTrackingCodes = async (carrierCode) => {
  try {
    const response = await fetch(apiUrl + "/get-tracking-codes", {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: authorizationToken,
      },
      body: JSON.stringify({
        carrierCode,
      }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

// Preset Dashboard

const getPresetDashboard = async () => {
  try {
    const response = await fetch(`${bidEngineService}/pdashboard`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: authorizationToken,
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

// Carrier Addition

const getCarrierAddition = async () => {
  try {
    const response = await fetch(`${bidEngineService}/carriers-add`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: authorizationToken,
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const getCarrierAddCoverageMap = async (body) => {
  try {
    const response = await fetch(
      `${bidEngineService}/carriers-add/coverage-map`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Authorization: authorizationToken,
        },
        body: JSON.stringify(body),
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const getCarrierAddImpact = async (body) => {
  try {
    const response = await fetch(`${bidEngineService}/carriers-add/impact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: authorizationToken,
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const getCarrierAddInsights = async (body) => {
  try {
    const response = await fetch(`${bidEngineService}/carriers-add/insights`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: authorizationToken,
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const getCarrierAddRateCompetitiveness = async (body) => {
  try {
    const response = await fetch(
      `${bidEngineService}/carriers-add/rate-competitiveness`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Authorization: authorizationToken,
        },
        body: JSON.stringify(body),
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

// Shipper Addition

const getShipperAddition = async () => {
  try {
    const response = await fetch(`${bidEngineService}/shippers-add`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: authorizationToken,
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const getShipperAddLaneMap = async (body) => {
  try {
    const response = await fetch(`${bidEngineService}/shippers-add/lane-map`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: authorizationToken,
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const getShipperAddImpact = async (body) => {
  try {
    const response = await fetch(`${bidEngineService}/shippers-add/impact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: authorizationToken,
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const getShipperAddInsights = async (body) => {
  try {
    const response = await fetch(`${bidEngineService}/shippers-add/insights`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: authorizationToken,
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

// Partners

const getPartners = async () => {
  try {
    const response = await fetch(apiUrl + "/get-partners", {
      method: "GET",
      headers: {
        authorization: authorizationToken,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error(`Error fetching data:`, error);
  }
};

// Location Summary

const getLocationsSummary = async () => {
  try {
    const response = await fetch(apiUrl + "/node-summary", {
      method: "GET",
      headers: {
        Authorization: authorizationToken,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();

    return {
      type: "FeatureCollection",
      features: data.map(createFeature),
    };
  } catch (error) {
    console.error("Error fetching locations:", error);
  }
};

// Rate Discovery

const downloadPldData = async (pldTransactionIdVal, setIsLoadingCsv) => {
  try {
    setIsLoadingCsv(true);
    const response = await fetch(apiUrl + "/download-pld-chart-data", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: authorizationToken,
      },
      body: JSON.stringify({
        pldTransactionId: pldTransactionIdVal, // Update this as needed
      }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response;
    // file object
    data.blob().then((blob) => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = "pld.csv";
      a.click();
    });
  } catch (error) {
    console.error(`Error fetching data:`, error);
  } finally {
    setIsLoadingCsv(false);
  }
};

const downloadNetworkRates = async (payload, setIsLoading, fileName) => {
  try {
    setIsLoading(true);
    const response = await fetch(apiUrl + "/download-network-rates", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: authorizationToken,
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response;
    // file object
    data.blob().then((blob) => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      a.click();
    });
  } catch (error) {
    console.error(`Error fetching data:`, error);
  } finally {
    setIsLoading(false);
  }
};

const downloadRateForCarriers = async (payload, setIsLoading, fileName) => {
  try {
    setIsLoading(true);
    const response = await fetch(apiUrl + "/download-rate-for-carriers", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: authorizationToken,
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response;
    // file object
    data.blob().then((blob) => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      a.click();
    });
  } catch (error) {
    console.error(`Error fetching data:`, error);
  } finally {
    setIsLoading(false);
  }
};

const getPldChartData = async (fileData, data, setIsLoadingPld) => {
  if (fileData && data) {
    if (setIsLoadingPld && typeof setIsLoadingPld === "function") {
      setIsLoadingPld(true);
    }

    const formData = new FormData();
    formData.append("files", fileData);

    const json = JSON.stringify(data);
    const blob = new Blob([json], {
      type: "application/json",
    });
    formData.append("pldRequest", blob);

    try {
      const response = await fetch(apiUrl + "/get-pld-chart-data", {
        method: "POST",
        headers: {
          authorization: authorizationToken,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      if (!data.errors) return data;
    } catch (error) {
      console.error(`Error fetching data:`, error);
    } finally {
      if (setIsLoadingPld && typeof setIsLoadingPld === "function") {
        setIsLoadingPld(false);
      }
    }
  }
};

const getRateCalculator = async (postData) => {
  try {
    const response = await fetch(apiUrl + "/get-rate-calculator", {
      method: "POST",
      headers: {
        authorization: authorizationToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error(`Error fetching data:`, error);
  }
};

// Participant

const getParticipant = async (role) => {
  try {
    const response = await fetch(
      apiUrl + `/participants?participantCode=${role}`,
      {
        method: "GET",
        headers: {
          Authorization: authorizationToken,
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching participant:", error);
  }
};

// Shipment Filter

const getFilters = async (userName) => {
  try {
    const response = await fetch(apiUrl + `/get-filter?username=${userName}`, {
      method: "GET",
      headers: {
        Authorization: authorizationToken,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching filters:", error);
  }
};

const saveFilter = async (body) => {
  try {
    const response = await fetch(apiUrl + "/save-filter", {
      method: "POST",
      headers: {
        authorization: authorizationToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return response;
  } catch (error) {
    console.error(`Error fetching data:`, error);
  }
};

const deleteFilter = async (body) => {
  try {
    const response = await fetch(apiUrl + `/delete-filter`, {
      method: "POST",
      headers: {
        Authorization: authorizationToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching filters:", error);
  }
}

export {
  createBidConfig,
  deleteFilter,
  downloadNetworkRates,
  downloadPldData,
  downloadRateForCarriers,
  getAllPartnerOnAccountRequest,
  getAllPartners,
  getAvgRseStatsForDay,
  getBidConfigById,
  getBidConfigDetails,
  getBidPerformanceDetails,
  getBidTierConfiguration,
  getCarrierAddCoverageMap,
  getCarrierAddImpact,
  getCarrierAddInsights,
  getCarrierAddRateCompetitiveness,
  getCarrierAddition,
  getCarrierInfo,
  getCarriers,
  getCarriersByShipper,
  getChartDataByPartner,
  getEventsBasedOnTrackerId,
  getEventsBasedOnTrackerIdPublic,
  getFilters,
  getLiveBidCountDetail,
  getLocationsSummary,
  getParcelRecommendationByPartner,
  getParcelTileDetailByPartner,
  getParcels,
  getParcelsBasedOnId,
  getParticipant,
  getPartnerOnAccountRequestById,
  getPartners,
  getPldChartData,
  getPresetDashboard,
  getRateCalculator,
  getRseStatsForCarriers,
  getServiceAttributes,
  getServiceType,
  getShipperAddImpact,
  getShipperAddInsights,
  getShipperAddLaneMap,
  getShipperAddition,
  getShipperInvoice,
  getShippersByPartner,
  getSurcharges,
  getTrackingCodes,
  getWonLostBidDetail,
  saveFilter,
  updateBidConfig,
  updatePartnerOnAccountRequestById,
};
