import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../css-files/style.css";
import { getEventsBasedOnTrackerId, getParcelsBasedOnId } from "../services";
import { geocodeLocation } from "../utilities";
import Tracker from "../interfaces/TrackTrace/MultiTrack/Tracker";
import { useToast, Box } from "@chakra-ui/react";

function ShipmentDetails() {
	const { trackingNumber } = useParams();
	const [firstCall, setFirstCall] = useState(false);
	const [trackingId, setTrackingId] = useState("");
	const [trackingData, setTrackingData] = useState([]);
	const [locationData, setLocationData] = useState(null);
	const [parcelData, setParcelData] = useState(null);
	const [isMapLoading, setIsMapLoading] = useState({
		routes: false,
		origin: false,
		destination: false,
	});
	const [route, setRoute] = useState({
		origin: [0, 0],
		destination: [0, 0],
		routes: [],
	});

	const [loading, setLoading] = useState(false);
	const [trackerStatus, setTrackerStatus] = useState(true);
	const [error, setError] = useState(false);
	const MIN_INPUT_LENGTH = 6;
	const MAX_INPUT_LENGTH = 30;

	const toast = useToast();

	const triggerToast = () => {
		toast({
			status: "error",
			duration: 5000,
			isClosable: true,
			variant: "solid",
			position: "bottom",
			render: () => (
				<Box
					display="grid"
					placeItems="center"
					bg="#EF4444"
					color="white"
					p={4}
					ml={4}
					borderRadius="md"
					maxW={["60vw", "md:max-width: 400px"]}
					textAlign="center"
				>
					<strong>Invalid Tracking Number</strong>
					<p>The tracking number you entered was not found.</p>
				</Box>
			),
		});
	};

	useEffect(() => {
		if (trackingNumber) {
			setTrackingId(trackingNumber);
		}
	}, [trackingNumber]);

	const handleInputChange = (e) => {
		setError(false);
		const tValue = e.target.value;
		if (tValue.length > MIN_INPUT_LENGTH && tValue.length < MAX_INPUT_LENGTH) {
			setTrackingId(tValue);
		} else if (tValue.length === 0) {
			setTrackingId("");
		}
	};

	const getEvents = async () => {
		setLoading(true);
		getParcels();
		try {
			const data = await getEventsBasedOnTrackerId(trackingId);
			if (data.results.length === 0) {
				setError(true);
				setLoading(false);
				setTrackerStatus(false);
				triggerToast();
			} else {
				const { results } = data;
				setError(false);
				setTrackingData(results);
				setTrackerStatus(true);
			}
			setLoading(false);
		} catch (error) {
			console.error("Error fetching data:", error);
			setLoading(false);
			setTrackerStatus(false);
			triggerToast();
		}
	};

	const getParcels = () => {
		getParcelsBasedOnId(trackingId)
			.then((response) => {
				if (response && response.parcels) {
					const parcels = response.parcels;
					setParcelData(response.parcels);
					setLocationData({
						shipFrom: parcels[0].shipFrom,
						shipTo: parcels[0].shipTo,
					});
				} else {
					console.error("Unexpected response format:", response);
				}
			})
			.catch((error) => {
				console.error("Error fetching data:", error);
			});
	};

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			const data = await getEventsBasedOnTrackerId(trackingId);
			if (data.results.length === 0) {
				setError(true);
				setLoading(false);
				setTrackerStatus(false);
			} else {
				const { results } = data;
				setError(false);
				setTrackingData(results);
				setTrackerStatus(true);
			}
			setLoading(false);
		};
		if (trackingId && !firstCall) {
			setFirstCall(true);
			fetchData();
		}
	}, [trackingId, firstCall]);

	useEffect(() => {
		getParcelsBasedOnId(trackingNumber)
			.then((response) => {
				if (response && response.parcels) {
					const parcels = response.parcels;
					setParcelData(response.parcels);
					setLocationData({
						shipFrom: parcels[0].shipFrom,
						shipTo: parcels[0].shipTo,
					});
				} else {
					console.error("Unexpected response format:", response);
				}
			})
			.catch((error) => {
				console.error("Error fetching data:", error);
			});
	}, [trackingNumber]);

	useEffect(() => {
		const fetchGeocodeData = async () => {
			setIsMapLoading((loadings) => ({
				...loadings,
				origin: true,
				destination: true,
			}));

			const events = [...trackingData].reverse();

			try {
				const [originCoords, destinationCoords] = await Promise.all([
					geocodeLocation({
						street: locationData.shipFrom?.address1 ?? "",
						city: locationData.shipFrom?.city ?? "",
						state: locationData.shipFrom?.state ?? "",
						zip: locationData.shipFrom?.zip ?? "",
					}),
					geocodeLocation({
						city: events[0]?.city ?? "",
						state: events[0]?.state ?? "",
						zip: events[0]?.zip ?? "",
					}),
				]);

				setRoute({
					origin: originCoords ? originCoords.coords.reverse() : [0, 0],
					destination: destinationCoords
						? destinationCoords.coords.reverse()
						: [0, 0],
					routes: [],
				});
			} catch (error) {
				console.error("Geocoding failed:", error);
			} finally {
				setIsMapLoading((loadings) => ({
					...loadings,
					origin: false,
					destination: false,
				}));
			}
		};

		if (locationData?.shipFrom && trackingData.length > 0) {
			fetchGeocodeData();
		}
	}, [locationData, trackingData]);

	return (
		<div
			className="flex-grow h-[100vh] w-full pt-5 pb-10 ml-2 mt-2 bg-[#181921]"
			id="tracking-logged-in"
		>
			<div className="tracking_details">
				<Tracker
					isPublicTracking={false}
					setRoute={setRoute}
					error={error}
					handleInputChange={handleInputChange}
					setTrackerStatus={setTrackerStatus}
					setTrackingId={setTrackingId}
					getEvents={getEvents}
					locationData={locationData}
					trackerStatus={trackerStatus}
					trackingId={trackingId}
					trackingData={trackingData}
					isMapLoading={isMapLoading}
					route={route}
					parcelData={parcelData}
					loading={loading}
				/>

				{/* {message && (
					<div className="mt-[15px]">
						<h4 className=" text-[22px]  text-center font-semibold">
							{message}
						</h4>
					</div>
				)} */}
			</div>
		</div>
	);
}

export default ShipmentDetails;
