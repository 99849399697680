import React from "react";
import { useNavigate } from "react-router-dom";

const Alert = ({ content, visible, handleVisible }) => {
  const navigate = useNavigate();

  function handleLinkClick() {
    navigate("/app/shipments/track-trace");
    handleVisible();
  }

  return (
    <div
      className={`my-2 flex h-[148px] bg-[#21232e] shadow-lg shadow-[#00396b];
 items-center ${visible ? "block" : "hidden"}`}
    >
      <div className="m-4">
        <img
          alt="High Risk"
          width="73px"
          height="73px"
          src="/images/alerts/risk.svg"
        />
      </div>
      <div className="flex flex-col w-full gap-3 justify-around">
        <p className="">{content.description}</p>
        <div>
          <button onClick={handleLinkClick} className="bg-sky-700 rounded border border-slate-600 p-1 px-3">
            Truck Route Details
          </button>
        </div>
      </div>
      <div className="mr-4 -mt-24">
        <button onClick={handleVisible}>
          <img alt="close" src="/images/alerts/close.svg" />
        </button>
      </div>
    </div>
  );
};

export default Alert;
