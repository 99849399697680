import { Flex } from "@chakra-ui/react";

import {
  Page,
  PldCalculatorCard,
  RateCardCalculatorCard,
  UsCoverageMapCard,
} from "../interfaces";

function SalesTools() {
  return (
    <Page>
      <Flex justifyContent={"center"} flexBasis={"0 0 1fr"} gap={5} m={5}>
        <UsCoverageMapCard />
        <RateCardCalculatorCard />
        <PldCalculatorCard />
      </Flex>
    </Page>
  );
}

export default SalesTools;
