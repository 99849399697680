import React from "react";
import PropTypes from "prop-types";
import { BaseDrawer } from "../BaseDrawer";
import { EmbeddedDocument } from "../EmbeddedDocument";
import { BaseModal } from "../BaseModal";
import styles from "./Chatbot.module.css";

export function Chatbot({ variant, isOpen, onClose }) {

    const chatBotSrc = "https://hjgfmn.simulakrum.com";

    return (
        <>
            {variant === "drawer" && (
                <BaseDrawer
                    open={isOpen}
                    onClose={onClose}
                    size={"sm"}
                    drawerHeader={"Message Center"}
                    closeIcon={<img alt="close filters" src="/images/close-filter.svg" />}
                    variant={"deepSkyBlue"}
                    drawerBodyClasses={styles.drawerBody}
                    drawerHeaderClasses={styles.drawerHeader}
                >
                    <EmbeddedDocument
                        src={chatBotSrc}
                        title={"Message Center"}
                        height={"100%"}
                    />
                </BaseDrawer>
            )}
            {variant === "modal" && (
                <BaseModal
                    open={isOpen}
                    onClose={onClose}
                    size={"xl"}
                    modalHeader={"Message Center"}
                >
                    <EmbeddedDocument
                        src={chatBotSrc}
                        title={"Message Center"}
                        height={"700px"}
                    />
                </BaseModal>
            )}
        </>
    );
}

Chatbot.defaultProps = {
    variant: "drawer",
    isOpen: false,
    onClose: () => { },
};

Chatbot.propTypes = {
    variant: PropTypes.oneOf(["drawer", "modal"]),
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
};
