import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import getTimeDifference from "../../../utilities/getTimeDifference";
import {
	getParcelsBasedOnId,
	getEventsBasedOnTrackerId,
} from "../../../services";

const ParcelHeader = ({ trackingNumber }) => {
	const [eventsData, setEventsData] = useState({});
	const [parcelData, setParcelData] = useState({});
	const [timeDifference, setTimeDifference] = useState({
		days: 0,
		hours: 0,
		minutes: 0,
	});

	useEffect(() => {
		getParcelsBasedOnId(trackingNumber)
			.then((response) => {
				if (response && response.parcels && response.parcels.length > 0) {
					setParcelData(response.parcels[0]);
				}
			})
			.catch((error) => {
				console.error("Error fetching data:", error);
			});
	}, [trackingNumber]);

	useEffect(() => {
		getEventsBasedOnTrackerId(trackingNumber)
			.then((response) => {
				if (response && response.results && response.results.length > 0) {
					setEventsData(response.results[0]);
				}
			})
			.catch((error) => {
				console.error("Error fetching data:", error);
			});
	}, [trackingNumber]);

	useEffect(() => {
		if (eventsData?.timestamp) {
			const timeDiff = getTimeDifference(eventsData?.timestamp);
			setTimeDifference(timeDiff);
		}
	}, [eventsData]);

	return (
		<div className="flex bg-[#1A202E] shadow-lg rounded-md mb-2">
			<div className="mx-4">
				<img
					alt="parcel"
					width="240px"
					className="mt-[-15px]"
					src="/images/shipments/parcel.png"
				/>
			</div>
			<div className="ml-2 mt-12">
				<div className="flex flex-col">
					<div className="font-semibold">{trackingNumber}</div>
					<div className="flex flex-row mt-8">
						<div className="flex flex-col">
							<div className="text-xs font-medium flex">
								{eventsData?.status?.toLowerCase() === "delayed" && (
									<img
										alt="high risk"
										className="mr-1 mt-[-2px]"
										src="/images/popover/high-risk.svg"
									/>
								)}
								{eventsData?.status || "-"}
							</div>
							<div className="text-xs text-[#7D8488]">Current Status</div>
						</div>
						<div className="flex flex-col ml-14">
							<div className="text-xs font-medium capitalize">
								{eventsData?.city ? (
									eventsData?.city.toLowerCase() + ", " + eventsData?.state
								) : (
									<span>-</span>
								)}
							</div>
							<div className="text-xs text-[#7D8488]">Current Location</div>
						</div>
						<div className="flex flex-col ml-12">
							<div className="text-xs font-medium">
								{parcelData?.expectedDate ? (
									<time
										dateTime={format(parcelData?.expectedDate, "yyyy-MM-dd")}
									>
										{parcelData?.expectedDate}
									</time>
								) : (
									"n/a"
								)}
							</div>
							<div className="text-xs text-[#7D8488]">ETA</div>
						</div>
						<div className="flex flex-col ml-12">
							<div className="text-xs font-medium">
								{parcelData?.shipDate ? (
									<time dateTime={format(parcelData?.shipDate, "yyyy-MM-dd")}>
										{parcelData?.shipDate}
									</time>
								) : (
									"n/a"
								)}
							</div>
							<div className="text-xs text-[#7D8488]">Shipment Date</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ParcelHeader;
