import { useContext, useState } from "react";
import classes from "./Header.module.css";
import {
  Avatar,
  Box,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Switch,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import { MoonIcon, SunIcon } from "@chakra-ui/icons";

import { UserContext } from "../";
import SearchField from "../../utilities/searchfield";

function BlankMenuItem({ children }) {
  return (
    <Box mx={4} mb={1}>
      {children}
    </Box>
  );
}

export function Header({ handleFilter }) {
  const { colorMode, toggleColorMode } = useColorMode();
  const { isDemo, isDemoEnvironment, user, logoutUser, setDemoMode } = useContext(UserContext);
  const logout = async () => await logoutUser();
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  function MenuAvatar() {
    return <Avatar bg="#c8cacc" width={"40px"} height={"40px"} />;
  }

  return (
    <Box className={classes.header}>
      <Flex className={classes.headerContainer} justifyContent="space-between">
        <Box>
          {isDemo && (
            <SearchField onSearch={handleSearch} filterHandler={handleFilter} />
          )}
        </Box>
        <Menu>
          <MenuButton height={"44px"}>
            <MenuAvatar />
          </MenuButton>
          <MenuList>
            <MenuGroup title={user?.name}></MenuGroup>
            <BlankMenuItem>
              <Flex justifyContent={"space-between"}>
                <Text fontSize={"xs"}>{user?.email}</Text>
                {/* <IconButton
                  size={"xs"}
                  mt={"-32px"}
                  variant="ghost"
                  onClick={toggleColorMode}
                  icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />}
                  aria-label={`Change to ${colorMode === "light" ? "dark" : "light"} mode`}
                  title={`Change to ${colorMode === "light" ? "dark" : "light"} mode`}
                /> */}
              </Flex>
            </BlankMenuItem>
            {isDemoEnvironment && (
              <BlankMenuItem>
                <FormControl
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  ml="-5px"
                  onChange={setDemoMode}
                  >
                  <FormLabel m="0">
                    <Text fontSize={"xs"}>Demo mode: {isDemo ? "Active" : "Inactive"}</Text>
                  </FormLabel>
                  <Switch size="sm" name="demoFlag" defaultChecked={isDemo} />
                </FormControl>
              </BlankMenuItem>
            )}
            <MenuDivider />
            <MenuItem onClick={logout}>Log out</MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Box>
  );
}
