import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@chakra-ui/react";
import { BaseModal } from "../BaseModal";
import { Checkbox } from "../InputFields";
import { Map, LaneCoverageMap } from "../";

const checkedBoxStyle = {
  "& .chakra-checkbox__control": {
    background: "#2294FF",
    border: "0 !important",
  },
  "& .chakra-checkbox__control svg": {
    stroke: "#EBEBEB !important",
    width: "16px",
  },
};

const MapModal = ({
  data,
  open,
  onClose,
  filterOptions,
  legends,
  isCarrierAddition,
}) => {
  const [filters, setFilters] = useState(filterOptions);

  const handleChangeMapData = (key, value) => {
    setFilters((filter) => {
      return {
        ...filter,
        [key]: { ...value, isChecked: !value.isChecked },
      };
    });
  };

  return (
    <BaseModal
      open={open}
      onClose={onClose}
      modalHeader={data.label}
      size={"5xl"}
      headerClasses={"!font-bold !pb-0"}
    >
      {isCarrierAddition ? (
        <Map
          data={data.value}
          height="600px"
          zoom={13}
          legends={legends}
          filters={filters}
        />
      ) : (
        <LaneCoverageMap
          data={data.value}
          height="600px"
          zoom={4.1}
          legends={legends}
          filters={filters}
        />
      )}

      <Box marginTop={6} display={"flex"} flexDirection={"column"}>
        {Object.entries(filters).map(
          ([key, { label, color, isChecked }], i) => (
            <Checkbox
              key={i}
              isChecked={isChecked}
              onChange={() => {
                handleChangeMapData(key, { label, color, isChecked });
              }}
              label={
                <div className="flex items-center">
                  {color && (
                    <div
                      className="h-[15px] w-[15px] rounded-full ml-2 mr-4"
                      style={{ backgroundColor: color }}
                    />
                  )}
                  <div>{label}</div>
                </div>
              }
              _checked={checkedBoxStyle}
              __css={{
                "& .chakra-checkbox__control": {
                  width: "24px !important",
                  height: "24px !important",
                },
                "& .chakra-checkbox__label": {
                  fontWeight: "700",
                },
              }}
              className="flex"
            />
          )
        )}
      </Box>
    </BaseModal>
  );
};

export default MapModal;

MapModal.defaultProps = {
  data: {},
  open: false,
  onClose: () => {},
  filterOptions: {},
  legends: {},
  isCarrierAddition: false,
};

MapModal.propTypes = {
  data: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  filterOptions: PropTypes.object,
  legends: PropTypes.object,
  isCarrierAddition: PropTypes.bool,
};
