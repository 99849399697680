import { Box, Heading, useColorMode } from "@chakra-ui/react";
import { Bar } from 'react-chartjs-2';
import { LinearScale, BarController, CategoryScale, BarElement, Chart, Legend } from "chart.js";

import { getCarrierColor, titleCase } from "../../../utilities";

Chart.register(LinearScale, BarController, CategoryScale, BarElement, Legend);

export function PldSavingsGraph({ data }) {
	const carriers = Array.from(new Set(data.flatMap(item => item.averageCost.map(cost => cost.carrierCode))));
	const colors = carriers.reduce((acc, carrier) => ({ ...acc, [carrier]: getCarrierColor(carrier) }), {});
	const { colorMode } = useColorMode()
	const chartData = {
		labels: data.map(item => item.weight),
		datasets: carriers.map(carrier => ({
			label: titleCase(carrier),
			data: data.map(item => item.averageCost.find(cost => cost.carrierCode === carrier)?.cost || 0),
			backgroundColor: colors[carrier],
			borderColor: colors[carrier].replace('0.2', '1'),
			borderWidth: 1
		}))
	};

	const options = {
		scales: {
			y: {
				beginAtZero: true,
				ticks: {
					color: colorMode === 'light' ? 'currentColor' : 'white',
				},
				title: {
					display: true,
					text: 'Average Cost ($)',
					color: colorMode === 'light' ? 'currentColor' : 'white',
				}
			},
			x: {
				ticks: {
					color: colorMode === 'light' ? 'currentColor' : 'white',
				},
				title: {
					display: true,
					text: 'Billable Weight',
					color: colorMode === 'light' ? 'currentColor' : 'white',
				}
			}
		},
		plugins: {
			legend: {
				display: true,
				labels: {
					color: colorMode === 'light' ? 'currentColor' : 'white',
				}
			},
			title: {
				color: colorMode === 'light' ? 'currentColor' : 'white',
			}
		}
	};

	return (
		<Box>
			<Heading as={"h3"} size={"sm"} fontWeight={"normal"} mb={5}>
				Average Cost By Billable Weight
			</Heading>
			<Bar data={chartData} options={options} />
		</Box>
	);
}