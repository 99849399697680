import { useState, useEffect } from "react";
import { StepCounter, StepNav, StepPage } from ".";
export function Step4({ data, setData, options }) {
  const [selectedWeights, setSelectedWeights] = useState(
    data?.parcelWeight ? data?.parcelWeight : []
  );

  const toggleWeight = (value) => {
    setSelectedWeights((prevWeights) => {
      if (prevWeights.includes(value)) {
        // If the value is already selected, remove it
        return prevWeights.filter((weight) => weight !== value);
      } else {
        // Otherwise, add it to the selected weights
        return [...prevWeights, value];
      }
    });
  };

  useEffect(() => {
    // This effect will run every time selectedWeights changes
    setData({ ...data, parcelWeight: selectedWeights });
  }, [selectedWeights]);

  return (
    <StepPage>
      <div className="flex flex-col w-full text-white font-['Montserrat']">
        <StepCounter step={3} />
        <div className=" flex flex-col items-center justify-center mt-12">
          <div className="w-[759px]">
            <p className="text-xl leading-8 tracking-wide">
              Select your average daily <strong>parcel weight</strong> range?
            </p>
            <p className="text-xl leading-8 tracking-wide">
              With weight information, Orchestro AI can fine-tune your pricing
              for maximum savings!
            </p>
          </div>
          <div className=" flex justify-start w-[759px] mt-5 flex-col">
            {options.map((option) => (
              <label
                key={`weight-${option.value}`}
                htmlFor={option.value}
                className="inline-flex items-center border w-[564px] border-[#C3C3C3] rounded-md gap-4 p-2 my-3 cursor-pointer"
              >
                <div className="relative flex items-center p-3 rounded-full cursor-pointer">
                  <input
                    name="type"
                    type="checkbox"
                    className="w-4 h-4 text-[#3BA0E6] bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    id={option.value}
                    checked={selectedWeights.includes(option.value)}
                    onChange={() => toggleWeight(option.value)}
                  />
                </div>
                <span className="mt-px text-xl font-normal font-['Montserrat'] tracking-wide cursor-pointer select-none">
                  {option.label}
                </span>
              </label>
            ))}
          </div>
        </div>
      </div>
      <StepNav
        prevLink="../step/3"
        nextLink="../step/5"
        nextLinkDisabled={data.parcelWeight.length <= 0}
      />
    </StepPage>
  );
}
