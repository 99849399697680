import { StepNav, StepCounter, StepPage } from ".";
export function Step1({ data, setData }) {
  return (
    <StepPage>
      <StepCounter step={1} />
      <div className="mt-20">
        <h2 className="text-4xl font-light tracking-wide mb-2">
          Expand Your Reach & Reduce Your Costs!
        </h2>
        <p className="text-2xl tracking-wide mt-10">
          Please enter the zip code from which you ship:
        </p>
        <div className="flex items-center justify-start w-[759px] gap-3 mt-8">
          <input
            defaultValue={data.zipcode}
            type="text"
            required
            pattern="\d{5,5}(-\d{4,4})?"
            onChange={(e) => setData({ ...data, zipcode: e.target.value })}
            className="w-2/3 border-b-2 border-indigo-500 h-12 focus:bg-transparent bg-[#85869833] focus:border-indigo-500 outline-none rounded text-base p-1"
          />
          {data.zipcode && (
            <div class="w-8 h-8 rounded-full border border-blue-400 items-center flex justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M6 12L10.2426 16.2426L18.727 7.75732"
                  stroke="#6FB0F0"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          )}
        </div>
      </div>
      <div className="mt-40">
        <StepNav
          prevLink="../start"
          nextLink="../step/2"
          nextLinkDisabled={!data.zipcode}
        />
      </div>
    </StepPage>
  );
}
