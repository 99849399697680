import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { camelCase } from "lodash";
import {
  AddIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  CloseIcon,
  DeleteIcon,
  MinusIcon,
  SearchIcon,
} from "@chakra-ui/icons";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Box,
  IconButton,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Textarea,
  Tooltip,
} from "@chakra-ui/react";
import "react-datepicker/dist/react-datepicker.css";

import {
  Button,
  Checkbox,
  Modal,
  Radio,
  RadioGroup,
} from "../../componentLibrary";
import { getFilters } from "../../services";
import { DatePicker, InputField, SelectField } from "../InputFields";
import { UserContext } from "../Providers";

const _placeHolder = {
  color: "rgba(235, 235, 235, 0.6) !important",
};

const shipmentDateSubFilters = [
  "Within the last",
  "More than",
  "Between",
  "In the range",
];

const actualDeliveryDateSubFilters = [
  "Now overdue",
  "Within the last",
  "More than",
  "Between",
  "In the range",
];

const moreFilters = [
  "Service Type",
  // "ETA",
  "To Zip",
  "From Zip",
  // "Dimension",
  // "Weight",
];

const ShipmentListFilterPanel = ({
  formik,
  participant,
  additionalFilterFields,
  setAdditionalFilterFields,
  handleClearFilter,
  handleSaveFilter,
  selectedFilter,
  onClose,
  onDeleteFilter,
}) => {
  const { values, setFieldValue, handleChange, setValues } = formik;

  const { user, setFilters,setShipmentFilters,shipmentFilters } = useContext(UserContext);

  const [isExpandSubFilters, setIsExpandSubFilters] = useState({
    shipmentDate: false,
    actualDeliveryDate: false,
    originCarrier: false,
    destinationCarrier: false,
    addField: false,
  });
  const [isShowMore, setIsShowMore] = useState(false);
  const [isShowMoreSavedFilter, setIsShowMoreSavedFilter] = useState(false);

  const [searchFilter, setSearchFilter] = useState("");
  const [additionalFilters, setFilteredAdditionalFilters] =
    useState(moreFilters);
  const [isOpenSaveFilter, setIsOpenSaveFilter] = useState(false);
  const [filter, setFilter] = useState({ name: "", description: "" });
  const [fetchedFilters, setFetchedFilters] = useState([]);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [filterToDelete, setFilterToDelete] = useState("");
  const cancelRef = useRef();

  const fetchFilters = async () => {
    try {
      const response = await getFilters(user.email);
      const sortedFilters = response.sort((a, b) => a.name.localeCompare(b.name));
      setFetchedFilters(sortedFilters);
      setFilters(sortedFilters);
    } catch (error) {
      console.error("Error fetching filters:", error);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchFilters();
    })();
    // eslint-disable-next-line
  }, [shipmentFilters]);

  // To populate the form with the selected filter
  const onSelectSavedFilter = useCallback((filterName) => {
    const filter = fetchedFilters.find(item => item.name === filterName);
    if (filter) {
      setValues({
        ...values,
        selectedSavedFilter: filterName,
        destinationCarriers: filter.filterState.destinationCarriers || [],
        originCarriers: filter.filterState.originCarriers || [],
        trackingNumber: filter.filterState.trackingNumber,
        serviceType:
          filter.filterState.serviceType === "GRD"
            ? "Ground"
            : filter.filterState.serviceType === "DGRD"
              ? "Deferred Ground"
              : filter.filterState.serviceType === "RETURN"
                ? "RETURN"
                : "",
        tntStatus: filter.filterState.tntStatus,
        shipmentStatus: filter.filterState.deliveryStatus,
        toZip: filter.filterState.toZip,
        fromZip: filter.filterState.fromZip,
        shipmentDateOption: filter.filterState.shipmentDateOption,
        deliveryDateOption: filter.filterState.deliveryDateOption,
        shipmentDateDetails:
          filter.filterState.shipmentDateValues.length &&
          ["Within the last", "More than"].includes(
            filter.filterState.shipmentDateOption
          )
            ? {
                [filter.filterState.shipmentDateOption]: {
                  n: filter.filterState.shipmentDateValues[0],
                  type: filter.filterState.shipmentDateValues[1],
                },
              }
            : ["Between", "In the range"].includes(
                  filter.filterState.shipmentDateOption
                )
              ? {
                  [filter.filterState.shipmentDateOption]: {
                    startDate: filter.filterState.shipmentDateValues[0],
                    endDate: filter.filterState.shipmentDateValues[1],
                  },
                }
              : {},
        deliveryDateDetails:
          filter.filterState.deliveryDateValues.length &&
          ["Within the last", "More than", "Now overdue"].includes(
            filter.filterState.deliveryDateOption
          )
            ? {
                [filter.filterState.deliveryDateOption]: {
                  n: filter.filterState.deliveryDateValues[0],
                  type: filter.filterState.deliveryDateValues[1],
                },
              }
            : ["Between", "In the range"].includes(
                  filter.filterState.deliveryDateOption
                )
              ? {
                  [filter.filterState.deliveryDateOption]: {
                    startDate: filter.filterState.deliveryDateValues[0],
                    endDate: filter.filterState.deliveryDateValues[1],
                  },
                }
              : {},
      });
    }
    // eslint-disable-next-line
  }, [fetchedFilters]);

  const handleExpandSubFilters = (key) => {
    setIsExpandSubFilters((filter) => ({ ...filter, [key]: !filter[key] }));
  };

  const handleRadioButtonChange = (value, key) => {
    setFieldValue(key, value);
  };

  const handleCheckboxChange = (isChecked, key, value) => {
    if (isChecked) {
      setFieldValue(key, [...(values[key] || []), value]);
    } else {
      setFieldValue(
        key,
        (values[key] || []).filter((option) => option !== value)
      );
    }
  };

  const onSearchFilterChange = (e) => {
    setSearchFilter(e.target.value);

    if (e.target.value) {
      setFilteredAdditionalFilters(() =>
        moreFilters.filter((filter) =>
          filter.toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
    } else {
      setFilteredAdditionalFilters(moreFilters);
    }
  };

  const onOpenSaveFilter = () => {
    setIsOpenSaveFilter(true);
  };

  const onCloseSaveFilter = () => {
    setIsOpenSaveFilter(false);
  };

  const handleOpenSaveFilterModal = () => {
    onOpenSaveFilter();
  };

  const onCloseAlertDialog = () => {
    setShowDeleteAlert(false);
    setFilterToDelete("");
  };

  const deleteFilterHandler = async () => {
    setShowDeleteAlert(false);
    await onDeleteFilter(filterToDelete);
    setShipmentFilters(true)
    setFilterToDelete("");
  };

  return (
    <div>
      <div className="px-6 py-2">
        <div
          className="flex items-center w-full p-2 mb-1 cursor-pointer hover:bg-[#85869833] rounded"
          onClick={() => {
            handleExpandSubFilters("shipmentDate");
          }}
        >
          <div className="font-medium w-[200px]">Shipment Date</div>
          {isExpandSubFilters.shipmentDate ? (
            <ChevronUpIcon w={6} h={6} />
          ) : (
            <ChevronDownIcon w={6} h={6} />
          )}
        </div>

        {isExpandSubFilters.shipmentDate && (
          <div className="text-sm font-medium flex flex-col">
            <RadioGroup
              className="!gap-0"
              onValueChange={(e) =>
                handleRadioButtonChange(e, "shipmentDateOption")
              }
              value={values.shipmentDateOption}
            >
              {shipmentDateSubFilters.map((option, i) => (
                <div key={i}>
                  <div className="rounded flex items-center">
                    <Radio value={option} id={option} />
                    <label htmlFor={option} className="cursor-pointer">{option}</label>
                  </div>
                  {option === values.shipmentDateOption && (
                    <>
                      {(values.shipmentDateOption === "Within the last" ||
                        values.shipmentDateOption === "More than") && (
                        <div className={`pl-10 gap-2 flex filter-select`}>
                          <InputField
                            placeholder={"days"}
                            type={"number"}
                            className="!h-[30px] !w-[132px]"
                            value={
                              values?.shipmentDateDetails?.[
                                values.shipmentDateOption
                              ]?.n
                            }
                            onChange={(e) =>
                              setFieldValue(
                                `shipmentDateDetails[${values.shipmentDateOption}].n`,
                                e.target.value
                              )
                            }
                          />
                          <SelectField
                            defaultValue={"Days"}
                            type={"text"}
                            className="!h-[30px] !w-[132px] text-[#ebebeb99]"
                            iconColor={"#ebebeb99"}
                            // name={"originCarriers"}
                            options={[
                              { value: "day", name: "Days" },
                              { value: "week", name: "Week" },
                              { value: "month", name: "Month" },
                            ]}
                            value={
                              values.shipmentDateDetails?.[
                                values.shipmentDateOption
                              ]?.type
                            }
                            onChange={(e) =>
                              setFieldValue(
                                `shipmentDateDetails[${values.shipmentDateOption}].type`,
                                e.target.value
                              )
                            }
                          />
                        </div>
                      )}
                      {(values.shipmentDateOption === "Between" ||
                        values.shipmentDateOption === "In the range") && (
                        <div className={`pl-10 flex filter-select`}>
                          <DatePicker
                            selected={
                              values.shipmentDateDetails?.[
                                values.shipmentDateOption
                              ]?.startDate
                            }
                            onChange={(date) => {
                              setFieldValue(
                                `shipmentDateDetails[${values.shipmentDateOption}].startDate`,
                                date
                              );
                            }}
                            isClearable={values?.shipStartDate ? true : false}
                            placeholderText="MM/DD/YYYY"
                            className="!h-[30px] !w-[132px]"
                          />
                          <DatePicker
                            selected={
                              values.shipmentDateDetails?.[
                                values.shipmentDateOption
                              ]?.endDate
                            }
                            onChange={(date) => {
                              setFieldValue(
                                `shipmentDateDetails[${values.shipmentDateOption}].endDate`,
                                date
                              );
                            }}
                            isClearable={values?.shipEndDate ? true : false}
                            placeholderText="MM/DD/YYYY"
                            className="!h-[30px] !w-[132px]"
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              ))}
            </RadioGroup>
          </div>
        )}
      </div>
      <div className="px-6 py-2">
        <div
          className="flex items-center w-full p-2 mb-1 cursor-pointer hover:bg-[#85869833] rounded"
          onClick={() => {
            handleExpandSubFilters("actualDeliveryDate");
          }}
        >
          <div className="font-medium w-[200px]">Actual Delivery Date</div>
          {isExpandSubFilters.actualDeliveryDate ? (
            <ChevronUpIcon w={6} h={6} />
          ) : (
            <ChevronDownIcon w={6} h={6} />
          )}
        </div>

        {isExpandSubFilters.actualDeliveryDate && (
          <div className="text-sm font-medium flex flex-col">
            <RadioGroup
              className="!gap-0"
              onValueChange={(e) =>
                handleRadioButtonChange(e, "deliveryDateOption")
              }
              value={values.deliveryDateOption}
            >
              {actualDeliveryDateSubFilters.map((option, i) => (
                <div key={i}>
                  <div className="rounded flex items-center">
                    <Radio value={option} id={`dd_${option}`} />
                    <label htmlFor={`dd_${option}`} className="cursor-pointer">{option}</label>
                  </div>

                  {option === values.deliveryDateOption && (
                    <>
                      {(values.deliveryDateOption === "Now overdue" ||
                        values.deliveryDateOption === "Within the last" ||
                        values.deliveryDateOption === "More than") && (
                        <div className={`pl-10 gap-2 flex filter-select`}>
                          <InputField
                            placeholder={"days"}
                            type={"number"}
                            className="!h-[30px] !w-[132px]"
                            value={
                              values.deliveryDateDetails?.[
                                values.deliveryDateOption
                              ]?.n
                            }
                            onChange={(e) =>
                              setFieldValue(
                                `deliveryDateDetails[${values.deliveryDateOption}].n`,
                                e.target.value
                              )
                            }
                          />
                          <SelectField
                            defaultValue={"Days"}
                            type={"text"}
                            className="!h-[30px] !w-[132px] text-[#ebebeb99]"
                            iconColor={"#ebebeb99"}
                            options={[
                              { value: "day", name: "Days" },
                              { value: "week", name: "Week" },
                              { value: "month", name: "Month" },
                            ]}
                            value={
                              values?.deliveryDateDetails?.[
                                values.deliveryDateOption
                              ]?.type
                            }
                            onChange={(e) =>
                              setFieldValue(
                                `deliveryDateDetails[${values.deliveryDateOption}].type`,
                                e.target.value
                              )
                            }
                          />
                        </div>
                      )}
                      {(values.deliveryDateOption === "Between" ||
                        values.deliveryDateOption === "In the range") && (
                        <div className={`pl-10 flex filter-select`}>
                          <DatePicker
                            selected={
                              values.deliveryDateDetails?.[
                                values.deliveryDateOption
                              ]?.startDate
                            }
                            onChange={(date) => {
                              setFieldValue(
                                `deliveryDateDetails[${values.deliveryDateOption}].startDate`,
                                date
                              );
                            }}
                            isClearable={
                              values?.deliveryStartDate ? true : false
                            }
                            placeholderText="MM/DD/YYYY"
                            className="!h-[30px] !w-[132px]"
                          />
                          <DatePicker
                            selected={
                              values.deliveryDateDetails?.[
                                values.deliveryDateOption
                              ]?.endDate
                            }
                            onChange={(date) => {
                              setFieldValue(
                                `deliveryDateDetails[${values.deliveryDateOption}].endDate`,
                                date
                              );
                            }}
                            isClearable={values?.deliveryEndDate ? true : false}
                            placeholderText="MM/DD/YYYY"
                            className="!h-[30px] !w-[132px]"
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              ))}
            </RadioGroup>
          </div>
        )}
      </div>
      <div className="px-6 py-2">
        <div
          className="flex items-center w-full p-2 mb-1 cursor-pointer hover:bg-[#85869833] rounded"
          onClick={() => handleExpandSubFilters("originCarrier")}
        >
          <div className="font-medium w-[200px]">Origin Carrier</div>
          {isExpandSubFilters.originCarrier ? (
            <ChevronUpIcon w={6} h={6} />
          ) : (
            <ChevronDownIcon w={6} h={6} />
          )}
        </div>
        {isExpandSubFilters.originCarrier && (
          <div className="flex gap-[10px] flex-wrap px-2">
            {participant.shippers.length &&
              participant.shippers.map(({ code }, i) => (
                <Box
                  key={i}
                  className="w-max-content px-4 min-w-[114px] h-[40px] border-2 border-[#E0E1EC1A] rounded-2xl flex items-center justify-center text-sm text-[#fff] cursor-pointer hover:opacity-75"
                  style={{
                    background: values.originCarriers?.includes(code)
                      ? "linear-gradient(90deg, #3BC5C5 0%, #008080 100%)"
                      : "",
                    boxShadow: values.originCarriers?.includes(code)
                      ? "0px 4px 4px 0px rgba(0, 0, 0, 0.50)"
                      : "",
                    border: values.originCarriers?.includes(code)
                      ? "2px solid #008080"
                      : "",
                  }}
                  onClick={() => {
                    if (values.originCarriers?.includes(code)) {
                      setFieldValue(
                        "originCarriers",
                        values.originCarriers.filter(
                          (originCarriers) => originCarriers !== code
                        )
                      );
                    } else {
                      setFieldValue("originCarriers", [
                        ...(values.originCarriers || []),
                        code,
                      ]);
                    }
                  }}
                >
                  {code}
                </Box>
              ))}
          </div>
        )}
      </div>
      <div className="px-6 py-2">
        <div
          className="flex items-center w-full p-2 mb-1 cursor-pointer hover:bg-[#85869833] rounded"
          onClick={() => handleExpandSubFilters("destinationCarrier")}
        >
          <div className="font-medium w-[200px]">Destination Carrier</div>
          {isExpandSubFilters.destinationCarrier ? (
            <ChevronUpIcon w={6} h={6} />
          ) : (
            <ChevronDownIcon w={6} h={6} />
          )}
        </div>
        {isExpandSubFilters.destinationCarrier && (
          <div className="flex gap-[10px] flex-wrap px-2">
            {participant.carriers.length &&
              participant.carriers.map(({ code }, i) => (
                <Box
                  key={i}
                  className="w-max-content px-4 min-w-[114px] h-[40px] border-2 border-[#E0E1EC1A] rounded-2xl flex items-center justify-center text-sm text-[#fff] cursor-pointer hover:opacity-75"
                  style={{
                    background: values.destinationCarriers?.includes(code)
                      ? "linear-gradient(90deg, #3BC5C5 0%, #008080 100%)"
                      : "",
                    boxShadow: values.destinationCarriers?.includes(code)
                      ? "0px 4px 4px 0px rgba(0, 0, 0, 0.50)"
                      : "",
                    border: values.destinationCarriers?.includes(code)
                      ? "2px solid #008080"
                      : "",
                  }}
                  onClick={() => {
                    if (values.destinationCarriers?.includes(code)) {
                      setFieldValue(
                        "destinationCarriers",
                        values.destinationCarriers.filter(
                          (destinationCarriers) => destinationCarriers !== code
                        )
                      );
                    } else {
                      setFieldValue("destinationCarriers", [
                        ...(values.destinationCarriers || []),
                        code,
                      ]);
                    }
                  }}
                >
                  {code}
                </Box>
              ))}
          </div>
        )}
      </div>
      <div className="px-6 py-2">
        <div className="font-medium px-2 pt-2 pb-4">TNT Status</div>
        <RadioGroup
          className="px-2"
          orientation="horizontal"
          onValueChange={(e) => handleRadioButtonChange(e, "tntStatus")}
          value={values.tntStatus}
        >
          <div className="flex items-center gap-2 w-1/2">
            <Radio value="On Time" id="on-time" />
            <label htmlFor="on-time" className="font-medium cursor-pointer">On Time</label>
          </div>
          <div className="flex items-center gap-2 w-1/2">
            <Radio value="Delayed" id="delayed" />
            <label htmlFor="delayed" className="font-medium cursor-pointer">Delayed</label>
          </div>
        </RadioGroup>
      </div>
      <div className="px-6 py-2">
        <div className="font-medium px-2 pt-2 pb-4">Delivery Status</div>
        <RadioGroup
          className="px-2"
          orientation="horizontal"
          onValueChange={(e) => handleRadioButtonChange(e, "shipmentStatus")}
          value={values.shipmentStatus}
        >
          <div className="cursor-pointer flex items-center gap-2 w-1/2">
            <Radio value="Delivered" id="delivered" />
            <label htmlFor="delivered" className="font-medium cursor-pointer">Delivered</label>
          </div>
          <div className="cursor-pointer flex items-center gap-2 w-1/2">
            <Radio value="Not Delivered" id="not-delivered" />
            <label htmlFor="not-delivered" className="font-medium cursor-pointer">Not Delivered</label>
          </div>
        </RadioGroup>
      </div>
      {additionalFilterFields.filters && additionalFilterFields.filters.length ? (
        additionalFilterFields.filters.map((field, i) => (
          <div className="px-6 py-2" key={i}>
            <div className="font-medium px-2 pt-2 pb-4">{field}</div>
            <div className="px-2">
              {(field === "To Zip" ||
                field === "From Zip" ||
                field === "Dimension" ||
                field === "Weight") && (
                <InputField
                  placeholder={`Enter ${field}`}
                  type={"text"}
                  className="!h-11 !rounded-s-0"
                  name={camelCase(field)}
                  _placeholder={_placeHolder}
                  value={values?.[camelCase(field)]}
                  onChange={handleChange}
                />
              )}
              {field === "ETA" && (
                <DatePicker
                  // selected={values?.startDate}
                  // onChange={(date) => {
                  //   setFieldValue("startDate", date);
                  // }}
                  // isClearable={values?.startDate ? true : false}
                  placeholderText="00/00/00"
                  className="!h-11 w-full"
                />
              )}
              {field === "Service Type" && (
                <RadioGroup
                  orientation="vertical"
                  onValueChange={(e) =>
                    handleRadioButtonChange(e, "serviceType")
                  }
                  value={values.serviceType}
                >
                  {additionalFilterFields.serviceType.map((types, i) => (
                    <div
                      className="cursor-pointer flex items-center w-1/2"
                      key={i}
                    >
                      <Radio value={types} />
                      <p className="font-medium">{types}</p>
                    </div>
                  ))}
                </RadioGroup>
              )}
            </div>
          </div>
        ))
      ) : (
        <></>
      )}
      <div className="px-6 py-2">
        <div
          className="flex items-center w-full p-2 mb-1 cursor-pointer hover:bg-[#85869833] rounded"
          onClick={() => {
            handleExpandSubFilters("addField");
          }}
        >
          <div className="font-medium w-[200px]">Add Field</div>
          {isExpandSubFilters.addField ? (
            <MinusIcon w={4} h={4} />
          ) : (
            <AddIcon w={4} h={4} />
          )}
        </div>
        {isExpandSubFilters.addField && (
          <div>
            <div className="px-2 relative">
              <InputGroup>
                <InputField
                  placeholder={"Search"}
                  type={"text"}
                  className="!h-11 !rounded-s-0 !px-12"
                  name={"zip"}
                  _placeholder={_placeHolder}
                  value={searchFilter}
                  onChange={(e) => onSearchFilterChange(e)}
                />
                <InputLeftElement top={0.5} left={1}>
                  <SearchIcon w={5} h={5} color={"#fff"} />
                </InputLeftElement>
                <InputRightElement
                  top={0.5}
                  right={1}
                  onClick={() => {
                    setSearchFilter("");
                    setFilteredAdditionalFilters(moreFilters);
                  }}
                >
                  <CloseIcon w={3} h={3} />
                </InputRightElement>
              </InputGroup>
            </div>

            <div className="px-1 mt-1 font-medium">
              {(isShowMore
                ? additionalFilters
                : additionalFilters.slice(0, 4)
              ).map((filter, i) => (
                <div key={i}>
                  <div className="flex items-center">
                    <Checkbox
                      checked={
                        values.additionalFilters &&
                        values.additionalFilters.includes(filter)
                      }
                      onCheckedChange={(isChecked) =>
                        handleCheckboxChange(
                          isChecked,
                          "additionalFilters",
                          filter
                        )
                      }
                    />
                    {filter}
                  </div>
                  {values.additionalFilters &&
                    values.additionalFilters.includes(filter) && (
                      <div className="px-9">
                        {filter === "Service Type" &&
                          ["Ground", "Deferred Ground", "RETURN"].map(
                            (option, i) => (
                              <div className="flex items-center" key={i}>
                                <Checkbox
                                  checked={
                                    values.selectedServiceType &&
                                    values.selectedServiceType.includes(option)
                                  }
                                  onCheckedChange={(isChecked) =>
                                    handleCheckboxChange(
                                      isChecked,
                                      "selectedServiceType",
                                      option
                                    )
                                  }
                                />
                                {option}
                              </div>
                            )
                          )}
                      </div>
                    )}
                </div>
              ))}
            </div>
            {additionalFilters.length > 4 && (
              <Button
                context="text"
                className="text-sm font-medium"
                onClick={() => {
                  setIsShowMore(!isShowMore);
                }}
              >
                {isShowMore ? "Show Less" : "Show More"}
              </Button>
            )}
            {values.additionalFilters?.length ||
            additionalFilterFields.filters?.length ? (
              <Button
                className="text-sm font-medium w-[82px] ml-4 mt-4"
                onClick={() => {
                  setAdditionalFilterFields((fields) => ({
                    ...fields,
                    filters: values.additionalFilters,
                    serviceType: values.selectedServiceType,
                  }));
                }}
                context={
                  values.additionalFilters.includes("Service Type")
                    ? values.selectedServiceType?.length
                      ? "primary"
                      : "outlinedSecondary"
                    : "primary"
                }
                disabled={
                  values.additionalFilters.includes("Service Type")
                    ? !values.selectedServiceType?.length
                    : false
                }
              >
                Save
              </Button>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
      {selectedFilter ? (
        <div className="flex justify-center gap-4 mt-12 pb-6">
          <Button
            className="text-sm font-medium !text-[#fff]"
            context="text"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            className="text-sm font-medium"
            onClick={async () => {
              await handleSaveFilter({
                ...filter,
                name: selectedFilter?.name || "",
                description: selectedFilter?.description || "",
              }, true);
              onCloseSaveFilter();
              await fetchFilters();
            }}
          >
            Save Filter
          </Button>
          <Button
            context="outlined"
            className="text-sm font-medium"
            onClick={handleOpenSaveFilterModal}
          >
            Save as
          </Button>
        </div>
      ) : (
        <>
          <div className="flex justify-center gap-2.5 mt-2 border-b border-b-[#d9d9e080] pb-6">
            <Button
              className="text-sm font-medium w-[142px]"
              context="text"
              onClick={handleClearFilter}
            >
              Clear Filter
            </Button>
            <Button
              className="text-sm font-medium w-[142px]"
              onClick={handleOpenSaveFilterModal}
            >
              Save Filter
            </Button>
          </div>
          <div className="px-6 py-3">
            <div className="text-xl font-bold pt-2 pb-3">Saved Filters</div>
            {fetchedFilters && fetchedFilters.length ? (
              <>
                <div className="max-h-[240px] overflow-auto saved-filter">
                  <RadioGroup value={values.selectedSavedFilter} onValueChange={(e) => onSelectSavedFilter(e)}>
                    {(isShowMoreSavedFilter
                      ? fetchedFilters
                      : fetchedFilters.slice(0, 4)
                    ).map((filter, i) => (
                      <div className="flex justify-between items-center" key={i}>
                        <div className="flex items-center overflow-hidden">
                          <Radio value={filter.name} id={filter.name} />
                          <label className="flex flex-1 overflow-hidden cursor-pointer" htmlFor={filter.name}>
                            <Tooltip label={filter.name} openDelay={1000}>
                              <span className="flex-1 truncate">{filter.name}</span>
                            </Tooltip>
                          </label>
                        </div>
                        <IconButton
                          variant="ghost"
                          icon={<DeleteIcon fontSize={16} />}
                          aria-label={"Delete Filter"}
                          onClick={() => {
                            setFilterToDelete(filter.name);
                            setShowDeleteAlert(true);
                          }}
                        />
                      </div>
                    ))}
                  </RadioGroup>
                </div>
                {fetchedFilters && fetchedFilters.length > 4 && (
                  <Button
                    context="text"
                    className="text-sm font-medium"
                    onClick={() => {
                      setIsShowMoreSavedFilter(!isShowMoreSavedFilter);
                    }}
                  >
                    {isShowMoreSavedFilter ? "Show Less" : "Show More"}
                  </Button>
                )}
              </>
            ) : (
              <div>No saved filter available</div>
            )}
          </div>
        </>
      )}

      <Modal
        open={isOpenSaveFilter}
        onClose={onCloseSaveFilter}
        title="Save Filter"
        blurBackground={true}
        className="save-filter-modal !max-w-[490px] !px-[28px] !pt-2.5 !pb-8 !z-[1500] !rounded-none"
        overlayClasses="!z-[1400]"
        controls={
          <div className="flex gap-4 mt-2">
            <Button
              context="outlined"
              className="!w-[81px] !text-[#EBEBEB]"
              onClick={onCloseSaveFilter}
            >
              Cancel
            </Button>
            <Button
              context={filter.name ? "primary" : "outlinedSecondary"}
              className="!w-[81px]"
              disabled={!filter.name}
              onClick={async () => {
                onCloseSaveFilter();
                await handleSaveFilter(filter);
                await fetchFilters();
              }}
            >
              Save
            </Button>
          </div>
        }
      >
        <div className="px-1">
          <div className="w-full h-[1px] bg-[#D9D9E0] opacity-50 my-2" />
          <div className="font-medium text-sm mb-2">
            Name<span className="text-[#FF263F]">*</span>
          </div>
          <InputField
            placeholder={`Enter Name`}
            type={"text"}
            className="!h-11"
            name={"filterName"}
            _placeholder={_placeHolder}
            maxLength={50}
            onChange={(e) => {
              setFilter((filter) => ({ ...filter, name: e.target.value }));
            }}
          />
          <div className="font-medium text-sm mb-2 mt-3">Description</div>
          <Textarea
            placeholder={`Enter Description`}
            type={"text"}
            className="!min-h-11 !border-0 !bg-[#85869833]"
            name={"filterDescription"}
            _placeholder={_placeHolder}
            onChange={(e) => {
              setFilter((filter) => ({
                ...filter,
                description: e.target.value,
              }));
            }}
            rows={3}
          />
        </div>
      </Modal>

      <AlertDialog
        isOpen={showDeleteAlert}
        leastDestructiveRef={cancelRef}
        onClose={onCloseAlertDialog}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogBody>
              <p className="mt-4">
                Do you want to delete the filter {filterToDelete}?
              </p>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                context="text"
                ref={cancelRef}
                onClick={onCloseAlertDialog}
              >
                Cancel
              </Button>
              <Button onClick={deleteFilterHandler} className="ml-3">
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </div>
  );
};

export default ShipmentListFilterPanel;
