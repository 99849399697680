
export const LaneIcon = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentcolor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" xmlns="http://www.w3.org/2000/svg">
			<path d="M2 22L6.61538 2"/>
			<path d="M12 2V5.07692"/>
			<path d="M12 10.4616V13.5385"/>
			<path d="M12 18.9231V22"/>
			<path d="M22.0002 22L17.3848 2"/>
		</svg>
	)
}
