import React, { useState, useEffect } from "react";
import Pod from "./pod";
import CurrentDate from "./CurrentDate";
import { dummyKpi } from "../../data/newUserEmptyState";

const coreServiceToken = process.env.REACT_APP_CORE_SERVICES_TOKEN;
const coreServiceUrl = process.env.REACT_APP_CORE_SERVICES_URL;
const coreServicePath = process.env.REACT_APP_CORE_SERVICES_PATH;
const apiUrl = coreServiceUrl + coreServicePath;

const Podbar = () => {
  const [pods, setPods] = useState(dummyKpi);

  const getPods = async () => {
    try {
      const response = await fetch(apiUrl + "/kpi", {
        method: "GET",
        headers: {
          authorization: coreServiceToken,
          "Content-Type": "application/json",
        },
      });
      const resp = await response.json();
      if (resp.error) {
        console.error("Something went wrong, please try again later");
      } else {
        if (resp.length > 0) {
          setPods(resp);
        } else {
          setPods(dummyKpi);
        }
      }
    } catch (error) {
      console.error(`Error fetching "/kpi":`, error);
    }
  };

  useEffect(() => {
    // getPods();
  }, []);

  return (
    <>
      <div className="flex items-center">
        <CurrentDate />
        <div className="flex items-center ml-auto">
        </div>
      </div>
      <div className="mt-3 flex gap-4">
        {pods.map((pod) => (
          <div key={pod.id} className="flex-1 flex-basis-0">
            <Pod pod={pod} />
          </div>
        ))}
      </div>
    </>
  );
};

  export default Podbar;
