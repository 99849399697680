import React, { useState, useEffect } from "react";
import {
  Alert,
  Box,
  Button,
  Heading,
  Flex,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { WarningTwoIcon } from "@chakra-ui/icons";
import { MiddleMileCosts, Page, RateCard, FirstMileCosts } from "../interfaces";
import { getRateCalculator } from "../services";

const coreServiceToken = `${process.env.REACT_APP_CORE_SERVICES_TOKEN}`;
const coreServiceUrl = `${process.env.REACT_APP_CORE_SERVICES_URL}`;
const coreServicePath = `${process.env.REACT_APP_CORE_SERVICES_PATH}`;
const apiUrl = coreServiceUrl + coreServicePath;

export function RateCardCalculator() {
  const [isLoading, setIsLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [showRateCard, setShowRateCard] = useState(false);
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({});
  const [formDirty, setFormDirty] = useState(false);

  const postSaveRates = async (postData) => {
    const endpoint = "/save-rates";
    setFormDirty(false);
    setIsSending(true);
    try {
      await fetch(apiUrl + endpoint, {
        method: "POST",
        headers: {
          authorization: coreServiceToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      });
    } catch (error) {
      console.error(`Error fetching ${endpoint}:`, error);
    } finally {
      setShowRateCard(true);
      setIsSending(false);
    }
  };

  useEffect(() => {
    const DEFAULT_CARRIERS = {
      carriers: [
        {
          code: "GLS",
          name: "General Logistics",
        },
        {
          code: "LSO",
          name: "Lone Star Overnight",
        },
      ],
    };
    (async function () {
      const resp = await getRateCalculator(DEFAULT_CARRIERS);
      if (resp) {
        setFormData(resp);
        setIsLoading(false);
      } else {
        setError("Something went wrong, please try again later");
        setIsLoading(false);
      }
    })();
  }, []);

  function handleChange(e, i) {
    const nextFormData = { ...formData };
    if (e.target.name === "unitCost") {
      nextFormData.costComparison[i][e.target.name] = e.target.value;
    } else {
      nextFormData.shipmentCost.carrierCosts[i][e.target.name] = e.target.value;
    }
    setFormDirty(true);
    setFormData(nextFormData);
  }

  function handleBack() {
    setShowRateCard(false);
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (formDirty) {
      postSaveRates(formData);
    } else {
      setShowRateCard(true);
    }
  }

  return (
    <Page>
      {!showRateCard && (
        <>
          <Flex as={"header"} gap={5} mb={5}>
            <svg
              width="71"
              height="71"
              viewBox="0 0 93 93"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="46.5" cy="46.5" r="46.5" fill="#6AC2E2" />
              <path
                d="M31.0668 28.5666C31.6437 28.5666 32.0569 28.0724 32.0569 27.5782V19.8296C32.0569 19.2528 31.561 18.8412 31.0668 18.8412C30.5726 18.8412 30.0784 19.3354 30.0784 19.8296V27.5782C29.9958 28.1551 30.49 28.5666 31.0668 28.5666Z"
                fill="white"
              />
              <path
                d="M36.6713 24.6923C37.2482 24.6923 37.6614 24.1981 37.6614 23.7039V14.2248C37.6614 13.6479 37.1655 13.2363 36.6713 13.2363C36.1771 13.2363 35.6829 13.7305 35.6829 14.2248V23.7039C35.6003 24.2808 36.0945 24.6923 36.6713 24.6923Z"
                fill="white"
              />
              <path
                d="M42.3592 24.6924C42.936 24.6924 43.3476 24.1982 43.3476 23.704V18.8412C43.3476 18.2644 42.8534 17.8512 42.3592 17.8512C41.865 17.8512 41.3691 18.347 41.3691 18.8412V23.704C41.3691 24.2808 41.7823 24.6924 42.3592 24.6924Z"
                fill="white"
              />
              <path
                d="M50.9308 26.5055C51.5077 26.5055 51.9192 26.0113 51.9192 25.5171V12.9884C51.9192 12.4116 51.425 12 50.9308 12C50.4366 12 49.9424 12.4942 49.9424 12.9884V25.4345C49.9424 26.094 50.354 26.5055 50.9308 26.5055Z"
                fill="white"
              />
              <path
                d="M41.4523 51.315C48.2935 51.315 53.8157 45.7928 53.8157 38.9516C53.8157 32.1104 48.2935 26.5882 41.4523 26.5882C34.6111 26.5882 29.0889 32.1104 29.0889 38.9516C29.0889 45.7928 34.6111 51.315 41.4523 51.315ZM37.0838 41.9185C37.0838 41.3417 37.5796 40.9301 38.0738 40.9301C38.568 40.9301 39.0622 41.4243 39.0622 41.9185V42.6606C39.0622 43.4028 39.6391 43.9796 40.3812 43.9796H42.2771C43.0192 43.9796 43.596 43.4028 43.596 42.6606V41.0954C43.596 40.8474 43.5134 40.4359 42.9365 40.188L38.9796 39.5285C37.7433 39.1169 36.9201 37.9632 36.9201 36.7268V35.0773C36.9201 33.2641 38.4028 31.7815 40.2159 31.6988V30.9567C40.2159 30.3799 40.7118 29.9683 41.206 29.9683C41.7002 29.9683 42.1944 30.4625 42.1944 30.9567V31.6162C44.0076 31.6988 45.4919 33.1831 45.4919 34.9963V35.7368C45.4919 36.3137 44.9977 36.7268 44.5018 36.7268C44.0076 36.7268 43.5134 36.2326 43.5134 35.7368V34.9963C43.5134 34.2542 42.9365 33.6773 42.1944 33.5947H40.2986C39.5565 33.5947 38.9796 34.1715 38.9796 34.9137V36.5616C38.9796 36.8905 39.2275 37.2211 39.5565 37.3863L43.596 38.1268C44.8324 38.54 45.6571 39.6937 45.5745 41.0127V42.578C45.5745 44.3912 44.0903 45.8755 42.2771 45.9581V46.7812C42.2771 47.3581 41.7828 47.7713 41.2886 47.7713C40.7928 47.7713 40.2986 47.2771 40.2986 46.7812V45.9581C38.4854 45.8755 37.0028 44.3912 37.0028 42.578L37.0838 41.9185Z"
                fill="white"
              />
              <path
                d="M72.4434 75.2997C72.4434 75.8766 72.9376 76.3708 73.5144 76.3708C74.0913 76.3708 74.5855 75.8766 74.5855 75.2997C74.5855 74.7229 74.0913 74.2286 73.5144 74.2286C72.9376 74.3113 72.4434 74.7229 72.4434 75.2997Z"
                fill="white"
              />
              <mask
                id="mask0_814_2954"
                style={{ maskType: "luminance" }}
                maskUnits="userSpaceOnUse"
                x="12"
                y="48"
                width="71"
                height="34"
              >
                <path d="M12 48.9185H82.8685V82H12V48.9185Z" fill="white" />
              </mask>
              <g mask="url(#mask0_814_2954)">
                <path
                  d="M81.8399 80.0799H68.817V57.3315H79.6962C80.273 57.3315 80.6862 56.8373 80.6862 56.3431C80.6862 55.8489 80.1904 55.353 79.6962 55.353H68.817V52.0572C68.817 51.4804 68.3228 51.0672 67.827 51.0672C67.3328 51.0672 66.8385 51.563 66.8385 52.0572V55.353H60.9047C60.3279 55.353 59.9147 55.7662 59.9147 56.3431V59.31H58.0189L52.0024 54.0357C51.8372 53.8704 51.5909 53.7878 51.3429 53.7878H34.9416C34.4473 53.7878 33.9531 54.1993 33.9531 54.6952C33.9531 54.7762 33.8705 55.353 33.8705 55.353C33.8705 56.8373 34.3647 58.2389 35.1895 59.3926H34.0342C34.0342 59.3926 29.502 52.3861 29.3367 52.1398C27.8524 49.9961 25.2987 48.5135 22.7434 49.3366L21.0939 49.9135C20.5997 50.1614 20.2707 50.7382 20.517 51.2324L20.9302 51.9746C18.6228 50.7382 15.6543 50.573 13.7585 52.4688L12.5221 53.7878C12.1932 54.282 12.2758 54.9415 12.6874 55.272L27.4409 65.4091L50.6008 74.0634C50.7661 74.0634 59.8321 74.0634 59.8321 74.0634V75.7114C59.8321 76.2882 60.2453 76.7014 60.8221 76.7014H66.6733V81.0699C66.6733 81.6468 67.1691 82.0584 67.6633 82.0584H81.6746C82.2515 82.0584 82.6631 81.5641 82.6631 81.0699C82.6631 80.5741 82.3341 80.0799 81.8399 80.0799ZM23.3203 51.2324C25.1335 50.6556 27.1119 52.1398 28.0177 53.953L30.2441 57.4142L24.3087 53.953L22.8261 51.3977L23.3203 51.2324ZM59.9147 72.0023H51.1793L28.4309 63.5959L14.8311 54.1993L15.1601 53.8704C16.5617 52.4688 18.9517 52.963 20.5997 54.1167L29.4193 59.2273L32.8804 61.2885C33.0457 61.3711 33.211 61.4537 33.3747 61.4537H48.1281C48.7066 61.4537 49.1182 60.9579 49.1182 60.4637C49.1182 59.8868 48.624 59.4753 48.1281 59.4753H38.7332C37.1663 59.2273 36.0126 57.4968 35.8473 55.9315H50.8487L56.8652 61.2058C57.0304 61.3711 57.2784 61.4537 57.5247 61.4537H59.8321L59.9147 72.0023Z"
                  fill="white"
                />
              </g>
            </svg>
            <div>
              <Heading as="h1" size="md" mt={3}>
                Network Rate Card Calculator
              </Heading>
              <Text>
                Enter first and middle mile costs to calculate national rate
                card{" "}
              </Text>
            </div>
          </Flex>
          <hr />
          {isLoading && (
            <Box display="flex" justifyContent="center" my={12}>
              <Spinner />
            </Box>
          )}
          {error && (
            <Alert status="error">
              <WarningTwoIcon />
              <Text>{error}</Text>
            </Alert>
          )}
          <form onSubmit={(e) => handleSubmit(e)}>
            {formData.shipmentCost && (
              <>
                <FirstMileCosts
                  handleChange={handleChange}
                  data={formData.shipmentCost.carrierCosts}
                />
                <hr />
                <MiddleMileCosts
                  handleChange={handleChange}
                  data={formData.costComparison}
                />
                <Button
                  type="submit"
                  isLoading={isSending}
                  loadingText="Calculating..."
                  className="chakra-primary-button"
                >
                  Calculate Rate
                </Button>
              </>
            )}
          </form>
        </>
      )}
      {showRateCard && (
        <RateCard setShowRateCard={setShowRateCard} handleBack={handleBack} />
      )}
    </Page>
  );
}
