import { useCallback, useContext, useEffect, useState } from "react";
import {
  Flex,
  Heading,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

import Button from "../componentLibrary/components/Button";
import { SHIPPER_CODES } from "../constants";
import { Page, Pagination, TableLoading, UserContext } from "../interfaces";
import { getShipperInvoice } from "../services";
import { downloadFileFromBlob, renderDate } from "../utilities";

const coreServiceUrl = process.env.REACT_APP_CORE_SERVICES_URL;
const coreServicePath = process.env.REACT_APP_CORE_SERVICES_PATH;
const authorizationToken = process.env.REACT_APP_CORE_SERVICES_TOKEN;
const apiUrl = coreServiceUrl + coreServicePath;

const Invoices = () => {
  const PAGE_OPTIONS = [25, 50, 100];

  const { user } = useContext(UserContext);

  const [data, setData] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isInvoiceDownloading, setIsInvoiceDownloading] = useState({});
  const [invoiceDownloaded, setInvoiceDownloaded] = useState([]);
  const [paginationConfig, setPaginationConfig] = useState({
    options: PAGE_OPTIONS,
    perPage: PAGE_OPTIONS[0],
  });

  const callGetShipperInvoice = useCallback(
    async (pageNumber, limit) => {
      if (!user || !user.Groups) {
        console.error("User data or Groups not available");
        return;
      }
      let userGroupCode = user.Groups.find((group) =>
        ["ADMIN", ...SHIPPER_CODES].includes(group)
      );
      if (!userGroupCode) return;

      userGroupCode = userGroupCode === "ADMIN" ? "" : userGroupCode; // If ADMIN endpoint expects "";

      setIsDataLoading(true);

      const data = await getShipperInvoice(userGroupCode, pageNumber, limit);
      setData(data);

      setIsDataLoading(false);
    },
    [user]
  );

  useEffect(() => {
    callGetShipperInvoice(1, paginationConfig.perPage);
    // eslint-disable-next-line
  }, []);

  const handleIndexChange = useCallback(
    (start, limit) => {
      if (
        (start === data.currentPage && limit === paginationConfig.perPage) ||
        !start ||
        !limit
      ) {
        return;
      }
      // Switch from index based pagination to page based pagination
      const pageNumber = (start - 1) / paginationConfig.perPage + 1;
      callGetShipperInvoice(pageNumber, limit);
    },
    // eslint-disable-next-line
    [data.currentPage, paginationConfig.perPage]
  );

  const downloadInvoice = useCallback(async (invoiceNumber) => {
    setIsInvoiceDownloading((isInvoiceDownloading) => ({
      ...isInvoiceDownloading,
      [invoiceNumber]: true,
    }));

    await downloadFileFromBlob(
      `${apiUrl}/invoice-download?invoiceNumber=${invoiceNumber}`,
      `Orchestro_invoice_${invoiceNumber}.xlsx`,
      authorizationToken
    );

    setIsInvoiceDownloading((isInvoiceDownloading) => ({
      ...isInvoiceDownloading,
      [invoiceNumber]: false,
    }));
  }, []);

  return (
    <Page>
      <Flex justifyContent={"space-between"} alignItems={"center"} mb={3}>
        <Heading as={"h1"} size={"md"}>
          Invoices
        </Heading>
      </Flex>
      <TableContainer className="max-h-[calc(100vh_-_220px)] !overflow-y-auto w-full mb-4">
        <Table>
          <Thead>
            <Tr>
              <Th>Shipper</Th>
              <Th>Invoice ID</Th>
              <Th>From</Th>
              <Th>To</Th>
              <Th>Total Amount</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {isDataLoading && <TableLoading />}
            {((!isDataLoading &&
              data?.invoices &&
              data.invoices.length === 0) ||
              !data) && (
              <Tr>
                <Td colSpan="100">No data available</Td>
              </Tr>
            )}
            {data?.invoices &&
              data.invoices.map((invoice, index) => {
                let isInvoiceDownloaded = invoiceDownloaded.includes(
                  invoice.invoiceNumber
                );
                return (
                  <Tr key={invoice?.invoiceNumber ?? index}>
                    <Td>{invoice?.shipperName ?? "-"}</Td>
                    <Td>{invoice?.invoiceNumber ?? "-"}</Td>
                    <Td>
                      {invoice?.billingStartDate ? (
                        <time dateTime={invoice.billingStartDate}>
                          {renderDate(invoice.billingStartDate, "MM/dd/yyyy")}
                        </time>
                      ) : (
                        "-"
                      )}
                    </Td>
                    <Td>
                      {invoice?.billingEndDate ? (
                        <time dateTime={invoice.billingEndDate}>
                          {renderDate(invoice.billingEndDate, "MM/dd/yyyy")}
                        </time>
                      ) : (
                        "-"
                      )}
                    </Td>
                    <Td>
                      {invoice?.totalAmount ? `$${invoice.totalAmount}` : "-"}
                    </Td>
                    <Td>
                      {invoice?.sharedFilesTrackerId &&
                      invoice?.invoiceNumber ? (
                        <Button
                          context={isInvoiceDownloaded ? "text" : "primary"}
                          className="text-[14px] !px-6 py-2 w-[130px]"
                          onClick={(e) => {
                            downloadInvoice(invoice.invoiceNumber);
                            setInvoiceDownloaded([
                              ...invoiceDownloaded,
                              invoice.invoiceNumber,
                            ]);
                          }}
                        >
                          {isInvoiceDownloading[invoice.invoiceNumber] ? (
                            <Spinner />
                          ) : isInvoiceDownloaded ? (
                            "Re-download"
                          ) : (
                            "Download"
                          )}
                        </Button>
                      ) : (
                        <p className="text-sm py-[10px] text-[#2489ff] cursor-default">
                          No Invoice File
                        </p>
                      )}
                    </Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>
      {data?.totalItems && data?.totalItems > 0 ? (
        <Pagination
          config={paginationConfig}
          start={data.currentPage}
          count={data.totalItems}
          onIndexChange={handleIndexChange}
          setPaginationConfig={setPaginationConfig}
          labelText="Number of invoices"
        />
      ) : null}
    </Page>
  );
};

export default Invoices;
