import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { StepCounter, StepPage } from ".";
import { Spinner } from "@chakra-ui/react";
export function Step5() {
  const navigate = useNavigate();

  useEffect(() => {
    // Fake loading, after 3 seconds, go to step 3 using react-router
    setTimeout(() => {
      navigate("../step/6");
    }, 3000);
  }, [navigate]);

  return (
    <StepPage>
      <div className="flex flex-col w-full text-white font-['Montserrat']">
        <StepCounter step={5} />
        <div className="flex flex-col items-center justify-center mt-[100px]">
          <h3 className="mb-4 text-lg font-light leading-10 tracking-wide">
            Calculating
          </h3>
          <Spinner />
        </div>
      </div>
    </StepPage>
  );
}
