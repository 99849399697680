import "../../css-files/PublicTracking.css";

const BackgroundLayer = ({ trackingData }) => {
  return (
    <div className="background-layer">
      <div className="glow-up"></div>
      <div className="right-glow"></div>
      <div className="blue-glow"></div>
      {/* {trackingData.length > 0 && <div className="yellow-glow"></div>} */}
    </div>
  );
};

export default BackgroundLayer;
