import React, { useState, useEffect, useContext } from "react";
import { TableContainer, Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import { AccountTabs, Page, TableLoading, UserContext } from "../interfaces";
import { CARRIER_CODES, SHIPPER_CODES } from '../constants';
import { getServiceAttributes } from "../services";

const ServiceAttributes = () => {
  const [ carrierServiceAttributes, setCarrierServiceAttributes ] = useState([]);
  const [ shipperServiceAttributes, setShipperServiceAttributes ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(false);
  const { user } = useContext(UserContext);

  const isCarrier = user.Groups.find((group) => CARRIER_CODES.includes(group));
  const isShipper = user.Groups.find((group) => SHIPPER_CODES.includes(group));

  useEffect(() => {
    if (!isCarrier) return;
    setIsLoading(true);
    getServiceAttributes(isCarrier)
      .then((response) => {
        setCarrierServiceAttributes(response.serviceAttributes);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => setIsLoading(false));
  }, [isCarrier]);

  useEffect(() => {
    if (!isShipper) return;
    setIsLoading(true);
    getServiceAttributes("ORCHESTRO")
      .then((response) => {
        setShipperServiceAttributes(response.ServiceAttributes);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => setIsLoading(false));
  }, [isShipper]);

  return (
    <>
      <AccountTabs />
      <Page className={"!min-h-0"}>
        {isShipper && !isCarrier ? (
          <>
            <h2 className="text-xl my-5">
              Orchestro Service Attributes
            </h2>
            <TableContainer className="max-h-[calc(100vh_-_245px)] !overflow-y-auto w-full mb-4">
              <Table>
                <Thead>
                  <Tr>
                    <Th>Network Service Code</Th>
                    <Th>Description</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {isLoading && <TableLoading data={shipperServiceAttributes} />}
                  {!isLoading && (!shipperServiceAttributes || shipperServiceAttributes.length === 0) && <Tr><Td colSpan={4}>No service attributes found</Td></Tr>}
                  {shipperServiceAttributes && shipperServiceAttributes.map((attribute, i) => (
                    <Tr key={i + "-" + attribute.code}>
                      <Td>{attribute.networkServiceCode}</Td>
                      <Td>{attribute.description}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <>
            <h2 className="text-xl my-5">
              Service Attributes
            </h2>
            <TableContainer className="max-h-[calc(100vh_-_245px)] !overflow-y-auto w-full mb-4">
              <Table>
                <Thead>
                  <Tr>
                    <Th>Code</Th>
                    <Th>Description</Th>
                    <Th>Network Service Code</Th>
                    <Th>Network Description</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {isLoading && <TableLoading data={carrierServiceAttributes} />}
                  {!isLoading && (!carrierServiceAttributes || carrierServiceAttributes.length === 0) && <Tr><Td colSpan={4}>No service attributes found</Td></Tr>}
                  {carrierServiceAttributes && carrierServiceAttributes.map((attribute) => (
                    <Tr key={attribute.code}>
                      <Td fontWeight="bold">{attribute.code}</Td>
                      <Td>{attribute.description}</Td>
                      <Td>{attribute.networkServiceCode}</Td>
                      <Td>{attribute.networkDescription}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </>
        )}
      </Page>
    </>
  );
};

export default ServiceAttributes;