// Function to generate a color
export const getCarrierColor = (carrier) => {
  if (carrier.toLowerCase() === "orchestro") {
    return "rgba(255, 165, 0, 1)"; // Orange
  } else if (carrier.toLowerCase() === "fedex") {
    return "#4D148C"; // Navy blue
  } else {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return `rgba(${r}, ${g}, ${b}, 1)`;
  }
};

export default getCarrierColor;
