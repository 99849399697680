const buttons = [
  {
    id: "delivery",
    text: "Delivery",
    color: "#C5E3FF",
    position: { top: "-10rem", left: "-32rem" },
  },
  {
    id: "dimensionsMismatch",
    text: "Dimensions mismatch ",
    color: "#C6F8E0",
    position: { top: "-15rem", left: "-22.8rem" },
  },
  {
    id: "duplicateInvoice",
    text: "Duplicate invoice",
    color: "#EED2FD",
    position: { top: "-11rem", left: "-14rem" },
  },
  {
    id: "contractRateChanged",
    text: "Contract rate changed",
    color: "#FFEBC6",
    position: { top: "9rem", left: "-32rem" },
  },
  {
    id: "delay",
    text: "Delay",
    color: "#FFC9CF",
    position: { top: "13rem", left: "-22.8rem" },
  },
  {
    id: "lossDamage",
    text: "Loss/Damage",
    color: "#FFD1A6",
    position: { top: "9rem", left: "-14rem" },
  },
];

export default buttons;
