import React, { useState, useContext } from "react";
import {
  Alert,
  Card,
  Button,
} from "@chakra-ui/react";
import { WarningTwoIcon } from "@chakra-ui/icons";

import { AccountTabs, Page, UserContext } from "../interfaces";
import { CARRIER_CODES, SHIPPER_CODES } from "../constants";
import { downloadFileFromUrl } from "../utilities";

const coreServiceUrl = process.env.REACT_APP_CORE_SERVICES_URL;
const coreServicePath = process.env.REACT_APP_CORE_SERVICES_PATH;
const authorizationToken = process.env.REACT_APP_CORE_SERVICES_TOKEN;
const apiUrl = `${coreServiceUrl}${coreServicePath}`;

function Rates() {
  const [isLoading, setIsLoading] = useState({
    carrier: false,
    shipper: false,
  });
  const [error, setError] = useState({
    carrier: "",
    shipper: "",
  });
  const { user } = useContext(UserContext);

  const isCarrier = user.Groups.find((group) => CARRIER_CODES.includes(group));
  const isShipper = user.Groups.find((group) => SHIPPER_CODES.includes(group));


  const generateSortCodes = async (userCode, typeKey) => {

    if (!userCode) return;

    try {
      setIsLoading((prevLoading) => ({
        ...prevLoading,
        [typeKey]: true,
      }));
      setError((prevError) => ({
        ...prevError,
        [typeKey]: "",
      }));
      const response = await fetch(apiUrl + "/get-rates", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authorizationToken,
        },
        body: JSON.stringify({ carrierCode: userCode }),
      });

      if (response.ok) {
        const carrierRatesUrl = await response.text();
        downloadFileFromUrl(carrierRatesUrl);
      } else {
        console.error("Error fetching rate card:", response.statusText);
        setError((prevError) => ({
          ...prevError,
          [typeKey]: "Error fetching rate card",
        }));
      }
    } catch (error) {
      console.error("Error fetching rate card:", error);
      setError((prevError) => ({
        ...prevError,
        [typeKey]: "Error fetching rate card",
      }));
    } finally {
      setIsLoading((prevLoading) => ({
        ...prevLoading,
        [typeKey]: false,
      }));
    }
  };

  return (
    <>
      <AccountTabs />
      <Page>
        {isShipper && (
          <Card
            mt="20px"
            border="1px"
            borderRadius="20px"
            borderWidth="1px"
            borderColor="rgba(133, 134, 152, 0.2)"
            p={8}
          >
            <h3 className="text-xl font-bold mb-2">
              Orchestro Rate Card
            </h3>
            <p className="mb-6">
              Download file to view the Orchestro network rate information based on the weight and zone.
            </p>
            <small><em>Coming soon for shippers</em></small>
            {/* <div>
              <Button
                size={"md"}
                isLoading={isLoading.shipper}
                onClick={() => generateSortCodes("ORCHESTRO", "shipper")}
                className="chakra-primary-button"
              >
                Download
              </Button>
            </div>
            {error?.shipper && (
              <Alert status="error" mt={6}>
                <WarningTwoIcon />
                <p>{error.shipper}</p>
              </Alert>
            )} */}
          </Card>
        )}

        {isShipper && isCarrier && (
          <hr className="my-6" />
        )}

        {isCarrier && (
          <Card
            mt="20px"
            border="1px"
            borderRadius="20px"
            borderWidth="1px"
            borderColor="rgba(133, 134, 152, 0.2)"
            p={8}
          >
            <h3 className="text-xl font-bold mb-2">
              Carrier Rate Card
            </h3>
            <p className="mb-6">
              Download file to view the Carrier rate information based on the weight and zone.
            </p>
            <div>
              <Button
                size={"md"}
                isLoading={isLoading.carrier}
                onClick={() => generateSortCodes(isCarrier, "carrier")}
                className="chakra-primary-button"
              >
                Download
              </Button>
            </div>
            {error?.carrier && (
              <Alert status="error" mt={6}>
                <WarningTwoIcon />
                <p>{error.carrier}</p>
              </Alert>
            )}
          </Card>
        )}
      </Page>
    </>
  );
}

export default Rates;
