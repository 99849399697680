import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { Flex } from "@chakra-ui/react";

import { Header, Nav, FilterPanel } from "../interfaces";
import styles from "../css-files/Layout.module.css";

function Layout() {
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const handleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  return (
    <>
      <FilterPanel isOpen={isFilterOpen} onClose={handleFilter} />
      <Flex>
        <Nav />
        <div className={styles.appContent}>
          <Header handleFilter={handleFilter} />
          <main className={styles.main}>
            <Outlet />
          </main>
        </div>
      </Flex>
    </>
  );
}

export default Layout;
