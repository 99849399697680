import { StepNav, StepPage } from "./";
export function Start() {
  return (
    <StepPage>
      <h2 className="text-4xl font-light tracking-wide mb-2">
        Expand Your Reach & Reduce Your Costs!
      </h2>
      <p className="text-2xl tracking-wide">
        Orchestro delivers nationwide coverage with this special introductory
        deal.
      </p>
      <div className="flex gap-11 mt-16 mb-16 justify-center items-center">
        <div>
          <div className="w-48 h-48 rounded-full border-4 border-sky-600 flex justify-center items-center">
            <p className="text-4xl font-normal leading-10 tracking-wide">
              99.95%
            </p>
          </div>
          <p className="mt-5 text-center text-base tracking-tight">
            ON-TIME GUARANTEE
          </p>
        </div>
        <p className="w-11 mb-12 text-center text-5xl font-extrabold leading-10 tracking-wide">
          +
        </p>
        <div>
          <div className="w-48 h-48 rounded-full border-4 border-sky-600 flex justify-center items-center">
            <p className="text-4xl font-normal leading-10 tracking-wide">
              -30%
            </p>
          </div>
          <p className="mt-5 text-center text-base tracking-tight">
            DISCOUNTED PRICE
          </p>
        </div>
        <p className="w-11 mb-12 text-center text-5xl font-extrabold leading-10 tracking-wide">
          =
        </p>
        <div>
          <div className="w-48 h-48 flex-col rounded-full border-4 border-sky-600 flex justify-center items-center p-2">
            <p className="w-36 h-[60px] pt-1 text-center text-white text-lg font-normal font-['Montserrat'] leading-relaxed tracking-wide">
              Expanded Reach
            </p>
            <p className="text-lg font-normal leading-7 tracking-wide">&</p>
            <p className="w-36 h-[60px] text-center text-white text-lg font-normal font-['Montserrat'] leading-relaxed tracking-wide">
              Increased Volume
            </p>
          </div>
          <p className="mt-5 text-center text-base tracking-tight">
            WITH ORCHESTRO
          </p>
        </div>
      </div>
      <p className="text-2xl font-normal tracking-wide">
        Plus, we’re going to set you up with a{" "}
        <strong>test account and labels</strong> to try us out! Now, just a
        couple quick questions to customize your set-up...
      </p>
      <StepNav nextLink="../step/1" />
    </StepPage>
  );
}
