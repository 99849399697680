import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import * as turf from "@turf/turf";
import { geocodeLocation } from "../../../utilities";
import "mapbox-gl/dist/mapbox-gl.css";
import { MAPBOX_API_KEY } from "../../../constants";
import TrackHistoryPanel from "./TrackHistoryPanel";
import { LabelLayers } from "./MapLabels";

const carrierColors = {
	Carrier: "#FFB323",
};

mapboxgl.accessToken = MAPBOX_API_KEY;

const HistoryMap = ({
	route,
	trackingData,
	locationData,
	parcelData,
	isPublicTracking = false,
}) => {
	const mapContainerRef = useRef();
	const mapRef = useRef();
	const [isMapLoaded, setIsMapLoaded] = useState(false);
	const [lines, setLines] = useState([]);
	const [boundaries, setBoundaries] = useState([]);
	const [trackingHistory, setTrackingHistory] = useState([]);
	const [geojsonFeatures, setGeojsonFeatures] = useState(null);
	const [markers, setMarkers] = useState([]);
	const [ogMarkers, setOgMarkers] = useState([]);

	useEffect(() => {
		if (trackingData.length > 0) {
			trackingData.forEach((item) => {
				item.carrier = "Carrier";
			});
			setTrackingHistory(trackingData);
		}
	}, [trackingData]);

	function generateLineColorExpression(carrierColors) {
		const expression = ["case"];
		for (const [carrier, color] of Object.entries(carrierColors)) {
			expression.push(["==", ["get", "name"], carrier], color);
		}
		expression.push("#FFB323");
		return expression;
	}

	const lineColorExpression = generateLineColorExpression(carrierColors);

	const createMarkerElement = (index) => {
		const el = document.createElement("div");
		el.innerHTML = `<img src="/images/map/marker_2.png" />`;
		return el;
	};

	const addCarrierMarkers = () => {
		const addedLocations = [];
		const carrierMarkers = [];

		const addMarkerIfNotPresent = (longitude, latitude) => {
			const isPresent = addedLocations.some(
				(coord) => coord[0] === longitude && coord[1] === latitude,
			);
			if (!isPresent) {
				const el = createMarkerElement(addedLocations.length);
				addedLocations.push([longitude, latitude]);
				const marker = new mapboxgl.Marker({ element: el })
					.setLngLat([longitude, latitude])
					.addTo(mapRef.current);
				carrierMarkers.push(marker);
			}
		};

		lines.forEach((line) => {
			addMarkerIfNotPresent(line.toLongitude, line.toLatitude);
		});

		if (ogMarkers.length > 0) {
			ogMarkers.forEach((markerData) => {
				addMarkerIfNotPresent(markerData.position.lng, markerData.position.lat);
			});
		}

		setMarkers(carrierMarkers);
	};

	const fitBounds = (bounds) => {
		const clientWidth = mapContainerRef.current.clientWidth;
		const historyWidth = clientWidth - 520;
		const calcWidth = clientWidth / 3;

		if (mapRef.current && bounds && bounds.length === 2) {
			mapRef.current.fitBounds(bounds, {
				padding: {
					top: 40,
					bottom: 40,
					left: 40,
					right: historyWidth < calcWidth ? historyWidth : calcWidth,
				},
				maxZoom: 4,
				duration: 1000,
			});
		}
	};

	useEffect(() => {
		if (geojsonFeatures?.length && !mapRef.current) {
			mapRef.current = new mapboxgl.Map({
				container: mapContainerRef.current,
				style: "mapbox://styles/mapbox/dark-v10",
				center: [-96.53732525590827, 39.0383480494191],
				zoom: 1,
				//projection: "globe",
			});

			mapRef.current.on("load", () => {
				setIsMapLoaded(true);

				if (mapRef.current.getLayer("land")) {
					mapRef.current.setPaintProperty(
						"land",
						"background-color",
						"#191A23",
					);
				}

				LabelLayers.forEach((layer) => {
					if (mapRef.current.getLayer(layer)) {
						mapRef.current.setLayoutProperty(layer, "visibility", "none");
					}
				});

				if (mapRef.current.getLayer("road")) {
					mapRef.current.setPaintProperty("road", "line-color", "#FF5733");
				}
				if (mapRef.current.getLayer("admin-0-boundary")) {
					mapRef.current.setPaintProperty(
						"admin-0-boundary",
						"line-color",
						"#858698",
					);
				}

				if (mapRef.current.getLayer("admin-1-boundary")) {
					mapRef.current.setPaintProperty(
						"admin-1-boundary",
						"line-color",
						"#858698",
					);
				}

				mapRef.current.setPaintProperty("water", "fill-color", "#191A23");
				mapRef.current.addSource("multiple-lines-source", {
					type: "geojson",
					data: {
						type: "FeatureCollection",
						features: geojsonFeatures,
					},
				});

				if (trackingHistory.length > 1) {
					mapRef.current.addLayer({
						type: "line",
						source: "multiple-lines-source",
						id: "line-glow",
						paint: {
							"line-width": 4,
							"line-opacity": 0.4,
							"line-color": "rgba(0,0,0,0.6)",
							"line-offset": 4,
						},
					});

					mapRef.current.addLayer({
						type: "line",
						source: "multiple-lines-source",
						id: "line-background",
						paint: {
							"line-width": 2,
							"line-dasharray": [5, 5],
							"line-color": lineColorExpression,
						},
					});
				}

				addCarrierMarkers();
				setTimeout(() => {
					fitBounds(boundaries);
				}, 300);
			});
		}
	}, [geojsonFeatures, boundaries]);

	const createArc = (start, end) => {
		const mid = [(start[0] + end[0]) / 2, (start[1] + end[1]) / 2];

		const distance = turf.distance(turf.point(start), turf.point(end));

		let arcHeightFactor = 3;
		if (distance * 0.5 < 100) {
			arcHeightFactor = 1;
		} else if (distance * 0.5 < 200) {
			arcHeightFactor = 2;
		}

		const controlPoint = [mid[0], mid[1] + arcHeightFactor];

		const line = turf.lineString([start, controlPoint, end]);
		const curved = turf.bezierSpline(line, {
			resolution: 10000,
			sharpness: 1,
		});
		const totalLength = turf.length(curved);

		const midpointDistance = totalLength / 2;

		const midpoint = turf.along(curved, midpointDistance);
		const midpointCoordinates = midpoint.geometry.coordinates;
		const offsetLatitude = 0.4;
		const offsetLongitude = 0.4;

		const adjustedCoordinates = [
			midpointCoordinates[0] + offsetLongitude,
			midpointCoordinates[1] + offsetLatitude,
		];
		return {
			coordinates: curved.geometry.coordinates,
			mid: adjustedCoordinates,
		};
	};

	useEffect(() => {
		if (!isMapLoaded) return;

		mapRef.current.flyTo({
			zoom: 1,
			duration: 1000,
			essential: true,
		});
		let features = lines.map((line, index) => {
			const { coordinates } = createArc(
				[line.fromLongitude, line.fromLatitude],
				[line.toLongitude, line.toLatitude],
			);
			return {
				type: "Feature",
				properties: {
					width: 2,
				},
				geometry: {
					type: "LineString",
					coordinates: coordinates,
				},
			};
		});
		markers.forEach((marker) => marker.remove());
		mapRef.current.getSource("multiple-lines-source").setData({
			type: "FeatureCollection",
			features: features,
		});

		setTimeout(() => {
			addCarrierMarkers();
		}, 1000);
	}, [isMapLoaded, lines]);

	useEffect(() => {
		if (route && trackingHistory.length > 0) {
			trackingHistory.forEach((item, index) => {
				if (index === 0 || item.status === "Created") {
					if (item.city === null) {
						item.city = locationData.shipFrom.city;
						item.state = locationData.shipFrom.state;
					}
					item.latitude = route.origin[0];
					item.longitude = route.origin[1];
				} else if (index === trackingHistory.length - 1) {
					item.latitude =
						route.destination[0] !== 0 ? route.destination[0] : route.origin[0];
					item.longitude =
						route.destination[1] !== 0 ? route.destination[1] : route.origin[1];
				} else {
					if (item.city || item.state || item.zip) {
						geocodeLocation({
							city: item.city ?? "",
							state: item.state ?? "",
							zip: item.zip ?? "",
						})
							.then((coords) => {
								if (coords) {
									item.latitude = coords.coords[1];
									item.longitude = coords.coords[0];
								} else {
									console.error("Geocoding failed:", coords);
								}
							})
							.catch((error) => {
								console.error("Geocoding failed:", error);
							});
					}
				}
			});

			setTimeout(() => {
				const filteredTrackingHistory = trackingHistory.filter(
					(point) => point.latitude !== 0 && point.longitude !== 0,
				);

				if (filteredTrackingHistory.length > 0) {
					const startPoint = [
						filteredTrackingHistory[0].longitude,
						filteredTrackingHistory[0].latitude,
					];
					const endPoint =
						filteredTrackingHistory.length > 1
							? [
									filteredTrackingHistory[filteredTrackingHistory.length - 1]
										.longitude,
									filteredTrackingHistory[filteredTrackingHistory.length - 1]
										.latitude,
								]
							: startPoint;

					setBoundaries([startPoint, endPoint]);

					const lines =
						filteredTrackingHistory.length > 1
							? filteredTrackingHistory.map((point, index, arr) => {
									if (index < arr.length - 1) {
										return {
											fromLatitude: point.latitude,
											fromLongitude: point.longitude,
											toLatitude: arr[index + 1].latitude,
											toLongitude: arr[index + 1].longitude,
											carrierName: point,
											parcelCount: 1,
										};
									}
									return null;
								})
							: [
									{
										fromLatitude: filteredTrackingHistory[0].latitude,
										fromLongitude: filteredTrackingHistory[0].longitude,
										toLatitude: filteredTrackingHistory[0].latitude,
										toLongitude: filteredTrackingHistory[0].longitude,
										carrierName: filteredTrackingHistory[0].carrierName,
										parcelCount: 1,
									},
								];

					setLines(lines.filter(Boolean));

					let features =
						filteredTrackingHistory.length > 1
							? lines.filter(Boolean).map((line) => {
									const { coordinates } = createArc(
										[line.fromLongitude, line.fromLatitude],
										[line.toLongitude, line.toLatitude],
									);

									return {
										type: "Feature",
										properties: {
											width: 2,
											name: line?.carrier,
										},
										geometry: {
											type: "LineString",
											coordinates: coordinates,
										},
									};
								})
							: [
									{
										type: "Feature",
										properties: {
											width: 2,
											name: filteredTrackingHistory[0]?.carrierName,
										},
										geometry: {
											type: "Point",
											coordinates: [
												filteredTrackingHistory[0].longitude,
												filteredTrackingHistory[0].latitude,
											],
										},
									},
								];

					const originMarkers = lines.filter(Boolean).map((line) => ({
						position: {
							lat: line.fromLatitude,
							lng: line.fromLongitude,
						},
						parcelCount: line.parcelCount,
					}));

					setGeojsonFeatures(features);
					setOgMarkers(originMarkers);
				}
			}, 1000);
		}
	}, [trackingHistory]);

	return (
		<div className="fullMap-container relative w-full h-[100vh]">
			<div id="map" ref={mapContainerRef} className="w-full h-full" />;
			<TrackHistoryPanel data={trackingHistory} parcelData={parcelData} />
		</div>
	);
};

export default HistoryMap;
