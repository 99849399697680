import React, { useState, useEffect, useMemo } from "react";
import { Spinner } from "@chakra-ui/react";
import ArcSection from "./ArcSection";
import MapTrack from "./MapTrack";
import DataSlide from "./DataSlide";
import TrackingModal from "./TrackingModal";
import MainMap from "./MainMap";
import HistoryMap from "./HistoryMap";
import ParcelDetailScreen from "./ParcelDetailScreen";
import ExceptionDetails from "./ExceptionDetails";
import Button from "../../../componentLibrary/components/Button";

const Tracker = ({
	isPublicTracking,
	setRoute,
	handleInputChange,
	setTrackingId,
	getEvents,
	trackingId,
	locationData,
	loading,
	trackingData,
	parcelData,
	isMapLoading,
	route,
	trackerStatus,
	setTrackerStatus,
	error,
}) => {
	const [trackingOpen, setTrackingOpen] = useState(false);
	const [trackAgain, setTrackAgain] = useState(false);
	const [parcelOpen, setParcelOpen] = useState(false);
	const [exceptionOpen, setExceptionOpen] = useState(false);
	const [sortedTrackingData, setSortedTrackingData] = useState([]);
	const [parcelInfo, setParcelInfo] = useState([]);
	const [showFullScreen, setShowFullScreen] = useState(false);
	const [updatedSlides, setUpdatedSlides] = useState([]);

	const inputChangeHandler = (e) => {
		handleInputChange(e);
		setTrackingId(e.target.value);
		setTrackAgain(true);
	};

	const handleTrackAgain = () => {
		setTrackerStatus(false);
		setTrackingId("");
		setTrackAgain(true);
		setRoute({
			origin: [0, 0],
			destination: [0, 0],
			routes: [],
		});
		setSortedTrackingData([]);
		setParcelInfo([]);
	};

	const openTrackingHistory = () => {
		setTrackingOpen(true);
	};

	const closeTrackingHistory = () => {
		setTrackingOpen(false);
	};

	const openParcelDetails = () => {
		setParcelOpen(true);
	};

	const closeParcelDetails = () => {
		setParcelOpen(false);
	};

	const openExceptionDetails = () => {
		setExceptionOpen(true);
	};

	const closeExceptionDetails = () => {
		setExceptionOpen(false);
	};

	const closeMainMap = () => {
		setShowFullScreen(false);
	};

	useEffect(() => {
		if (trackAgain) {
			setSortedTrackingData([]);
		}
	}, [trackAgain]);

	useEffect(() => {
		if (isPublicTracking === true) {
			setTrackAgain(true);
		}
	}, [isPublicTracking]);

	const isMobile = window.innerWidth < 768;

	const slideItems = useMemo(
		() =>
			[
				{
					type: "id",
					title: "Tracking ID",
					data: sortedTrackingData,
					event: handleTrackAgain,
				},
				{
					type: "destination",
					title: "Destination",
					data: parcelData,
					compareData: sortedTrackingData,
				},
				{
					type: "status",
					title: "Current Status",
					data: sortedTrackingData,
					compareData: parcelData,
					event: openTrackingHistory,
				},
				{
					type: "details",
					title: "Parcel Details",
					data: sortedTrackingData,
					event: openParcelDetails,
				},
				{
					type: "edt",
					title: "Estimated Delivery",
					data: parcelInfo,
				},
				// !isMobile && {
				// 	type: "exceptions",
				// 	title: "Exception",
				// 	event: openExceptionDetails,
				// 	data: parcelInfo,
				// },
			].filter(Boolean),
		[parcelData, sortedTrackingData, parcelInfo],
	);

	useEffect(() => {
		if (trackingData.length > 0) {
			const trackingSorted = trackingData.sort((a, b) => {
				if (a.status === "Created" && b.status !== "Created") {
					return -1; // a comes before b
				}
				if (a.status !== "Created" && b.status === "Created") {
					return 1; // b comes before a
				}
				return new Date(a.timestamp) - new Date(b.timestamp);
			});
			setSortedTrackingData(trackingSorted);
		}
	}, [trackingData]);

	useEffect(() => {
		if (
			parcelData &&
			sortedTrackingData.length > 0 &&
			sortedTrackingData[sortedTrackingData.length - 1]?.city
		) {
			setParcelInfo(parcelData);

			const trimParcelCity = parcelData[0]?.shipTo?.city
				.replace(/,$/, "")
				.trim();
			const sortedCity = sortedTrackingData[sortedTrackingData.length - 1]?.city
				.replace(/,$/, "")
				.trim();

			const isCityMatch =
				trimParcelCity && sortedCity && trimParcelCity === sortedCity;

			const newSlideItems = [...slideItems];
			newSlideItems[4].title =
				parcelData[0]?.actualDeliveryDate && isCityMatch
					? "Delivered"
					: "Estimated Delivery";

			setUpdatedSlides(newSlideItems);
		} else {
			setUpdatedSlides(slideItems);
		}
	}, [parcelData, sortedTrackingData, slideItems]);

	const inputClassName = `w-full max-w-[264px] h-[63px] text-center bg-black border-2 ${
		error
			? "border-red-500 text-red-500 focus:border-red-500 focus:text-red-500"
			: "border-[#EBEBEB] text-white focus:border-[#06D8D8]"
	} border-opacity-40 focus:border-2 focus:bg-black focus:border-[#06D8D8] focus:border-opacity-20 focus:outline-none placeholder-white placeholder-opacity-60 text-[11px] md:text-base font-semibold tracking-normal p-2 px-6 mb-4`;

	return (
		<>
			{loading ? (
				<div className="flex justify-center my-12">
					<Spinner />
				</div>
			) : (
				<div
					className={`tracking relative flex flex-col lg:flex-row justify-center items-center flex-grow pt-10`}
				>
					<div
						className={`flex flex-col h-full left-0 pb-20 md:pb-12 -mt-24 md:-mt-12 lg:pb-0 justify-start ml-0 md:ml-20`}
					>
						{sortedTrackingData.length > 0 && (
							<>
								{updatedSlides.map((slide, index) => (
									<DataSlide
										isPublicTracking={isPublicTracking}
										key={index}
										direction="left"
										type={slide.type}
										data={slide.data}
										compareData={slide.compareData}
										title={slide.title}
										event={slide.event}
									/>
								))}
							</>
						)}
					</div>
					{/* Centered Content */}
					<div
						className={`flex relative mx-auto sm:w-[78%] w-[85%] h-auto justify-center items-center ${!isPublicTracking && !trackAgain ? "-ml-14" : "pl-4"} pr-6 pb-14 sm:mt-10`}
						style={{ marginLeft: "auto", marginRight: "auto" }}
					>
						<div
							className={` relative w-[85%] sm:w-[78%] md:w-[80%] lg:w-[65%] md:max-w-md `}
						>
							{/* Base Dial Image */}
							{sortedTrackingData.length === 0 &&
							(isPublicTracking === true || trackAgain === true) ? (
								<img
									src="/images/tracker/dials.png"
									alt="Tracker Dial"
									className="w-full h-auto"
								/>
							) : (
								<img
									src="/images/tracker/dials-selected-a.png"
									alt="Tracker Dial"
									className="w-full h-auto"
								/>
							)}

							{/* Rotating Dial (*/}
							<img
								src="/images/tracker/dial_lines.png"
								alt="Dial Lines"
								className={`absolute top-0 left-0 w-full h-auto ${sortedTrackingData.length === 0 && isPublicTracking ? "animate-spin-slow" : ""}`}
							/>

							{/* Curved Segments */}
							<div className="absolute -inset-14 flex items-center justify-center">
								<ArcSection
									data={sortedTrackingData}
									parcelData={parcelData}
									status={trackerStatus}
									trackAgain={trackAgain}
									isPublicTracking={isPublicTracking}
								/>
							</div>

							{/* Input field */}
							<div className="absolute inset-0 flex items-center justify-center">
								{sortedTrackingData.length === 0 &&
								(isPublicTracking === true ||
									error === true ||
									trackAgain === true) ? (
									<div className="rounded-full w-[80%] max-w-[320px] h-auto bg-[#000000] border-[#E0E1EC] border-2 border-opacity-40 z-10 flex items-center justify-center aspect-square p-4">
										<div className="flex flex-col mt-10">
											<style>
												{`
                    input:-webkit-autofill {
                      -webkit-box-shadow: 0 0 0px 1000px black inset !important;
                      box-shadow: 0 0 0px 1000px black inset !important;
                      -webkit-text-fill-color: white !important;
                    }
                  `}
											</style>
											<input
												type="text"
												id="trackingIdInput"
												value={trackingId}
												className={inputClassName}
												placeholder="Enter Your Tracking ID"
												onChange={inputChangeHandler}
												onKeyDown={(e) => {
													if (e.key === "Enter") {
														setTrackAgain(false);
														getEvents();
													}
												}}
											/>
											<div className="h-10 w-full flex items-center justify-center">
												{trackingId && (
													<Button
														className="w-32 h-full mx-auto text-white font-semibold"
														type="submit"
														context="text"
														style={{
															borderRadius: "8px",
															opacity: "60",
														}}
														onClick={() => {
															setTrackAgain(false);
															getEvents();
														}}
													>
														Track
													</Button>
												)}
											</div>
										</div>
									</div>
								) : (
									<>
										{isMapLoading.origin ||
										isMapLoading.destination ||
										isMapLoading.routes ? (
											<div className="flex justify-center my-12">
												<Spinner />
											</div>
										) : (
											<div>
												{showFullScreen ? (
													<TrackingModal
														open={true}
														type="none"
														blurBackground={true}
														onClose={closeMainMap}
														onOpenChange={(open) => {
															setTrackingOpen(open);
														}}
													>
														<MainMap
															route={route}
															trackingId={trackingId}
															locationData={locationData}
															trackingData={sortedTrackingData}
															parcelData={parcelData}
															isPublicTracking={true}
														/>
													</TrackingModal>
												) : (
													<MapTrack
														route={route}
														trackingId={trackingId}
														trackingData={sortedTrackingData}
														isPublicTracking={isPublicTracking}
														setShowFullScreen={setShowFullScreen}
													/>
												)}
											</div>
										)}
									</>
								)}
							</div>
						</div>
					</div>

					<TrackingModal
						open={trackingOpen}
						type="none"
						blurBackground={true}
						onClose={closeTrackingHistory}
						onOpenChange={(open) => {
							setTrackingOpen(open);
						}}
					>
						<HistoryMap
							route={route}
							trackingId={trackingId}
							locationData={locationData}
							trackingData={sortedTrackingData}
							parcelData={parcelData}
							isPublicTracking={true}
						/>
					</TrackingModal>

					<TrackingModal
						open={parcelOpen}
						blurBackground={true}
						onClose={closeParcelDetails}
						onOpenChange={(open) => {
							setParcelOpen(open);
						}}
					>
						<ParcelDetailScreen
							trackingId={trackingId}
							isPublicTracking={isPublicTracking}
						/>
					</TrackingModal>

					<TrackingModal
						open={exceptionOpen}
						type="none"
						blurBackground={true}
						classProp="mid-modal"
						onClose={closeExceptionDetails}
						onOpenChange={(open) => {
							setExceptionOpen(open);
						}}
					>
						<ExceptionDetails trackingId={trackingId} />
					</TrackingModal>
				</div>
			)}
		</>
	);
};

export default Tracker;
