// Popover.js
import PropTypes from "prop-types";

const Popover = ({ content, isVisible, position }) => {
  if (!isVisible) return null;

  const style = {
    position: "absolute",
    width: "300px",
    top: `${position.y}px`,
    left: `${position.x}px`,
    transform: "translate(-50%, -100%)",
    zIndex: 1,
    backgroundColor: "rgba(30, 37, 63, 1)",
    paddingTop: "10px",
    paddingBottom: "10px",
    borderRadius: "8px",
    boxShadow: "0px 4px 8px 0px rgba(0, 57, 107, 0.32)",
  };

  const titleStyle = {
    fontSize: "14px",
    paddingBottom: "10px",
    paddingLeft: "20px",
    width: "100%",
    borderBottom: "1px solid rgba(255, 255, 255, 0.6)",
    color: "#fff",
  };

  const container = {
    display: "flex",
    fontSize: "13px",
    marginTop: "10px",
    marginBottom: "10px",
    marginLeft: "20px",
    justifyContent: "space-between",
  };

  const labelContainer = {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    color: "#fff",
  };

  const shipmentContainer = {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  };

  const label = {
    color: "#fff",
  };

  const imgContainer = {
    marginRight: "10px",
  };

  const shipmentValue = {
    color: "#74BCFF",
    textAlign: "right",
    marginRight: "20px",
  };

  return (
    <div style={style}>
      <div style={titleStyle}>{content.title}</div>
      <div>
        <div style={container}>
          <div style={label}>Parcels in Sort Center</div>
          <div style={shipmentValue}>{content.shipments.parcels}</div>
        </div>
        <div style={container}>
          <div style={labelContainer}>Intake</div>
          <div style={shipmentContainer}>
            <div style={imgContainer}>
              <img alt="high risk" src="/images/popover/high-risk.svg" />
            </div>
            <div style={shipmentValue}>{content.shipments.intake}</div>
          </div>
        </div>
        <div style={container}>
          <div style={labelContainer}>In Transit</div>
          <div style={shipmentContainer}>
            <div style={imgContainer}>
              <img alt="medium risk" src="/images/popover/medium-risk.svg" />
            </div>
            <div style={shipmentValue}>{content.shipments.midmile}</div>
          </div>
        </div>
        <div style={container}>
          <div style={labelContainer}>Delivered</div>
          <div style={shipmentValue}>{content.shipments.delivered}</div>
        </div>
        <div style={container}>
          <div style={labelContainer}>Sort Center Utilization</div>
          <div style={shipmentValue}>{content.shipments.utilization}</div>
        </div>
      </div>
    </div>
  );
};

Popover.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    shipments: PropTypes.object.isRequired, // Assuming shipments is an object
  }).isRequired,
  isVisible: PropTypes.bool.isRequired,
  position: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }).isRequired,
};

export default Popover;
