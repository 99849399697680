import React, { useState, useEffect, useRef, useMemo } from "react";
import { Bar } from "react-chartjs-2";
import PropTypes from "prop-types";
import styles from "../../css-files/charts.module.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Filler,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-date-fns";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Filler,
  TimeScale
);

const BarChart = ({
  title,
  xAxis,
  yAxis,
  data,
  colors,
  isMultiBar,
  isDisplayLegend,
  isDisplayTimeRange,
  options,
  indexAxis,
  barPercentage,
  plugins,
}) => {
  const [timeRange, setTimeRange] = useState("Today");
  const chartRef = useRef(null);
  const [chartOptions, setChartOptions] = useState({});

  const chartData = useMemo(() => {
    if (data) {
      const dataSet = generateChartData(
        timeRange,
        colors,
        data,
        isMultiBar,
        barPercentage
      );
      return dataSet;
    }
    return {
      labels: [],
      datasets: [{ data: [] }],
    };
  }, [timeRange, data, isMultiBar, colors, barPercentage]);

  useEffect(() => {
    setChartOptions({
      indexAxis,
      scales: {
        x: {
          stacked: false,
          title: {
            color: "white",
            font: {
              size: 12,
            },
            ...options?.axisTitle,
            display: true,
            text: `${xAxis}`,
          },
          ticks: {
            padding: 10,
            color: "white",
            font: {
              size: 12,
            },
            display: true,
          },
          grid: {
            display: false,
          },
        },
        y: {
          stacked: false,
          title: {
            padding: 20,
            display: true,
            color: "white",
            font: {
              size: 12,
            },
            ...options?.axisTitle,
            text: `${yAxis}`,
          },
          ticks: {
            padding: 10,
            color: "white",
            font: {
              size: 12,
            },
            display: true,
          },
          grid: {
            display: false,
          },
        },
      },
      animation: {
        duration: 0,
      },
      plugins: {
        legend: {
          display: isDisplayLegend,
          ...options?.legend,
        },
      },
      layout: {
        padding: {
          ...options?.padding,
        },
      },
    });
  }, [xAxis, yAxis, isDisplayLegend, options, indexAxis]);

  const handleChangeTimeRange = (range) => {
    setTimeRange(range);
  };

  return (
    <div className="flex flex-col justify-center items-center">
      <h3 className={`mt-4 ${options?.titleClasses}`}>{title}</h3>
      {isDisplayTimeRange && (
        <div className="mt-6">
          <button
            className={`text-white-400 text-xs mx-2 ${
              timeRange === "Today" ? styles.underlineOffset : ""
            }`}
            onClick={() => handleChangeTimeRange("Today")}
          >
            Today
          </button>
          <button
            className={`text-white-400 text-xs mx-6 ${
              timeRange === "Last 7 Days" ? styles.underlineOffset : ""
            }`}
            onClick={() => handleChangeTimeRange("Last 7 Days")}
          >
            Last 7 Days
          </button>
          <button
            className={`text-white-400 text-xs mx-2  ${
              timeRange === "Last 30 Days" ? styles.underlineOffset : ""
            }`}
            onClick={() => handleChangeTimeRange("Last 30 Days")}
          >
            Last 30 Days
          </button>
        </div>
      )}
      <Bar
        ref={chartRef}
        data={chartData}
        options={chartOptions}
        plugins={plugins}
      />
    </div>
  );
};

const generateChartData = (range, colors, data, isMultiBar, barPercentage) => {
  if (isMultiBar) {
    /* use below data format for multi bar chart
      {
        datasets: [
          {
            value: ["1", "2", "3"],
            label: "",
            color: ""
          },
          {
            value: ["1", "2", "3"],
            label: "",
            color: ""
          }
        ],
        labels: ["1", "2, "3"]
      }
    */

    const datasets =
      data.datasets?.length > 0 &&
      data.datasets.map((d) => ({
        label: d.label,
        data: d.value,
        backgroundColor: d.color,
        borderColor: d.color,
        borderWidth: 1,
        borderRadius: 15,
        borderSkipped: false,
        categoryPercentage: barPercentage.categoryPercentage,
        barPercentage: barPercentage.barPercentage * data.datasets.length,
      }));

    return {
      labels: data.labels, // Reverse labels for proper display (latest data first)
      datasets: datasets ?? [],
    };
  } else {
    const filteredData = data.filter((d) => d.period === range);
    const datasets = [];
    datasets.push({
      data: filteredData.map((d) => d.value),
      backgroundColor: colors,
      borderColor: colors,
      borderWidth: 1,
      borderRadius: 15,
      borderSkipped: false,
      categoryPercentage: 0.3,
      barPercentage: 0.3,
    });

    return {
      labels: filteredData.map((d) => d.key), // Reverse labels for proper display (latest data first)
      datasets: datasets,
    };
  }
};

export default BarChart;

BarChart.defaultProps = {
  title: "",
  xAxis: "",
  yAxis: "",
  data: [],
  colors: [],
  isMultiBar: false,
  isDisplayLegend: false,
  isDisplayTimeRange: false,
  options: {},
  indexAxis: "",
  barPercentage: { categoryPercentage: 0.1, barPercentage: 0.3 },
  plugins: [],
};

BarChart.propTypes = {
  title: PropTypes.string,
  xAxis: PropTypes.string,
  yAxis: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  colors: PropTypes.array,
  isMultiBar: PropTypes.bool,
  isDisplayLegend: PropTypes.bool,
  isDisplayTimeRange: PropTypes.bool,
  options: PropTypes.object,
  indexAxis: PropTypes.string,
  barPercentage: PropTypes.object,
  plugins: PropTypes.array,
};
