export const createFeature = (item) => ({
  type: "Feature",
  geometry: {
    type: "Point",
    coordinates: [item.lng, item.lat],
  },
  properties: {
    title: `${item.nodeCity} Distribution Center`,
    value: item.sortCenterParcelCount,
    badge: Number(item.highRiskValue) + Number(item.mediumRiskValue),
    highRisk: item.highRisk,
    mediumRisk: item.mediumRisk,
    shipments: {
      parcels: item.sortCenterParcelCount,
      intake: item.intakeCount,
      midmile: item.middleMileCount,
      delivered: item.deliveredCount,
      utilization: item.nodeUtilization,
    },
  },
});
