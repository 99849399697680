
export const NewUserPanel = ({ children }) => {
  return (
    <div className="active rounded-md p-8 flex justify-center items-center gap-16">
      <img src="/images/dashboard-new-user.svg" alt="" className="w-1/3 max-w-[350px] p-3" />
      <div className="w-2/3">
        <h1 className="mt-2 mb-3 text-[40px] font-bold">Welcome to Orchestro!</h1>
        {children}
      </div>
    </div>
  );
};
