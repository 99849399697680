import React, { useState, useEffect } from "react";
import formatDateString from "../../../utilities/formatDateString";
import History from "./History";

const TrackHistoryPanel = ({ data, parcelData }) => {
	const [historyData, setHistoryData] = useState([]);

	useEffect(() => {
		if (data.length > 0) {
			setHistoryData(data);
		}
	}, [data]);

	const dateOptions = {
		month: "long",
		day: "numeric",
		year: "numeric",
	};

	const timeOptions = {
		hour: "numeric",
		minute: "numeric",
		hour12: true,
	};

	return (
		<div className="absolute top-0 right-0 bg-[#1E2028] w-[520px] h-[85vh] text-white p-4 m-4 shadow-lg rounded-sm">
			<h2 className="text-lg font-semibold mb-4">Tracking History</h2>
			<div className="absolute w-[1px] bg-[#D2D3E0] left-[9rem] top-14 bottom-8"></div>
			<div className="mt-10 overflow-y-auto max-h-[70vh]">
				{historyData.map((item, index) => (
					<div
						key={index}
						className="relative flex flex-row items-center justify-start mb-6"
					>
						<div
							className={`${index === historyData.length - 1 ? "text-[#FFB323]" : "text-[#EBEBEB]"} text-xs w-20 text-right mx-4`}
						>
							{formatDateString(item.timestamp, dateOptions, timeOptions)}
						</div>

						<div className="flex pl-[13px]" id="connecter">
							<img src="/images/map/connector.svg" alt="connector" />
						</div>
						<History
							title={item.status}
							status={item.uiStatus}
							parcelDestination={parcelData[0]?.shipTo?.city
								.replace(/,$/, "")
								.trim()}
							trackingCity={item.city.replace(/,$/, "").trim()}
							location={` ${item.city}, ${item.state}`}
							code={item.networkTrackCode}
							comment={item.comments}
							last={index === historyData.length - 1 ? true : false}
						/>
					</div>
				))}
			</div>
		</div>
	);
};

export default TrackHistoryPanel;
