export const setTileAmountPostfix = (amount = 0, key = "") => {
  if (amount >= 1000000) {
    return "M";
  } else if (amount >= 1000) {
    return "K";
  } else if (["averageWeight"].includes(key)) {
    return "lb";
  } else {
    return "";
  }
};
