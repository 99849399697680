import { MAPBOX_API_KEY } from "../constants";

export const geocodeLocation = async ({
  street = "",
  city = "",
  state = "",
  zip = "",
  country = "",
}) => {
  let address = "";

  if (street) {
    address = street;
  }

  if (city) {
    address = address ? `${address}, ${city}` : city;
  }

  if (state) {
    address = address ? `${address}, ${state}` : state;
  }

  if (zip) {
    address = address ? `${address}, ${zip}` : zip;
  }

  if (country) {
    address = address ? `${address}, ${country}` : country;
  }

  try {
    const response = await fetch(
      //`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${GOOGLE_GEOCODE_API_KEY}`
      `https://api.mapbox.com/search/geocode/v6/forward?q=${address}&proximity=ip&access_token=${MAPBOX_API_KEY}`
    );
    const data = await response.json();
    if (data.features.length > 0) {
      return {
        coords: data.features[0].geometry.coordinates,
        place_id: data.features[0].id,
      };
    } else {
      throw new Error("No results found for geocoding");
    }
  } catch (error) {
    console.error("Geocoding failed:", error);
  }
};
