import React, { useState, useEffect, useRef } from "react";
import { Line } from "react-chartjs-2";
import PropTypes from "prop-types";
import styles from "../../css-files/charts.module.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-date-fns";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  TimeScale
);

// interface ChartProps {
//   title: string;
// }

// interface ChartData {
//   labels: Date[];
//   datasets: {
//     data: number[];
//     tension: number;
//     backgroundColor?: string;
//     borderColor?: string;
//   }[];
// }

const AreaChart = ({
  title,
  subTitle,
  range,
  xAxis,
  yAxis,
  isDisplayTimeRange,
  data,
  color,
  options,
  isDisplayLegend,
  isMultiLine,
}) => {
  const [timeRange, setTimeRange] = useState(range);
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [{ data: [], tension: 0.4 }],
  });
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    const dataSets = generateChartData(data, color, isMultiLine);
    setChartData(dataSets);

    if (chartRef.current) {
      const chart = chartRef.current;
      const ctx = chart.ctx; // Get the drawing context
      const gradient = ctx.createLinearGradient(0, 0, 0, 400);
      gradient.addColorStop(0, "rgba(14,141,255,0.5)");
      gradient.addColorStop(0.4, "rgba(51,158,255,0)");
    }

    setChartOptions({
      scales: {
        x: {
          time: {
            unit:
              timeRange === "week"
                ? "day"
                : timeRange === "month"
                  ? "week"
                  : "month",
          },
          title: {
            color: "white",
            font: {
              size: 12,
            },
            display: true,
            text: xAxis,
            ...options?.axisTitle,
          },
          ticks: {
            color: "white",
            font: {
              size: 12,
            },
            display: true,
            padding: 16,
          },
          grid: {
            display: false,
          },
        },
        y: {
          title: {
            display: true,
            color: "white",
            font: {
              size: 12,
            },
            text: yAxis,
            ...options?.axisTitle,
          },
          ticks: {
            color: "white",
            font: {
              size: 12,
            },
            display: true,
            padding: 16,
          },
          grid: {
            display: false,
          },
        },
      },
      elements: {
        point: {
          radius: 0,
        },
      },
      plugins: {
        legend: {
          display: isDisplayLegend,
          ...options?.legend,
        },
        tooltip: {
          ...options?.tooltip,
        },
      },
      layout: {
        padding: {
          ...options?.padding,
        },
      },
    });
  }, [
    timeRange,
    xAxis,
    yAxis,
    data,
    options,
    color,
    isDisplayLegend,
    isMultiLine,
  ]);

  const handleChangeTimeRange = (range) => {
    setTimeRange(range);
  };

  return (
    <div className="flex flex-col justify-center items-center">
      <h3 className={`mt-4 ${options?.titleClasses}`}>{title}</h3>
      {subTitle && <div className="mt-2 font-normal">{subTitle}</div>}
      {isDisplayTimeRange && (
        <div className="mt-6">
          <button
            className={`text-white-400 text-xs mx-2 ${
              timeRange === "week" ? styles.underlineOffset : ""
            }`}
            onClick={() => handleChangeTimeRange("week")}
          >
            Last Week
          </button>
          <button
            className={`text-white-400 text-xs mx-6 ${
              timeRange === "month" ? styles.underlineOffset : ""
            }`}
            onClick={() => handleChangeTimeRange("month")}
          >
            Month
          </button>
          <button
            className={`text-white-400 text-xs mx-2  ${
              timeRange === "year" ? styles.underlineOffset : ""
            }`}
            onClick={() => handleChangeTimeRange("year")}
          >
            Year
          </button>
        </div>
      )}
      <Line ref={chartRef} data={chartData} options={chartOptions} />
    </div>
  );
};

const generateChartData = (data, color, isMultiLine) => {
  if (isMultiLine) {
    /* use below data format for multi line chart
      {
        datasets: [
          {
            value: ["1", "2", "3"],
            label: "",
            color: ""
          },
          {
            value: ["1", "2", "3"],
            label: "",
            color: ""
          }
        ],
        labels: ["1", "2, "3"]
      }
    */
    const datasets =
      data?.datasets?.length > 0 &&
      data.datasets.map((dataset) => ({
        label: dataset.label,
        data: dataset.value,
        tension: 0.4,
        borderColor: dataset.color,
        backgroundColor: dataset.color,
        borderWidth: 2,
      }));

    return {
      labels: data.labels,
      datasets: datasets ?? [],
    };
  } else {
    return {
      labels: data.map((d) => d.key),
      datasets: [
        {
          data: data.map((d) => d.value),
          tension: 0.4,
          borderColor: color,
          backgroundColor: color,
          borderWidth: 2,
        },
      ],
    };
  }
};

export default AreaChart;

AreaChart.defaultProps = {
  title: "",
  subTitle: "",
  range: "",
  xAxis: "",
  yAxis: "",
  isDisplayTimeRange: false,
  data: [],
  color: "",
  options: {},
  isDisplayLegend: false,
  isMultiLine: false,
};

AreaChart.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  range: PropTypes.string,
  xAxis: PropTypes.string,
  yAxis: PropTypes.string,
  isDisplayTimeRange: PropTypes.bool,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  color: PropTypes.string,
  options: PropTypes.object,
  isDisplayLegend: PropTypes.bool,
  isMultiLine: PropTypes.bool,
};
