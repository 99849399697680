export const exceptions = [
  {
    disputeId: "e8016823-0faf-4f34-a811-5214844efed7",
    trackingId: "P100000018",
    invoiceNumber: "999",
    amount: 64.74,
    dueDate: "2024-09-13",
    disputeCreationType: "AUTOMATIC",
    disputeCategory: "Dimensions_Mismatch",
    status: "IN_PROGRESS",
    assignedTo: "",
    partyName: null,
    position: "top",
    description:
      "The exception for tracking id P100000018 is due to a discrepancy between the contract terms and invoice details. The number of tracking IDs provided is less than 10, which violates the first contract term and raises a Contract_Dispute. However, there is no min_volume charge mentioned in the invoice. The weight and dimensions of each parcel do not meet the requirements of the second and third contract terms respectively. The tracking id is also not mentioned for rates.",
  },
  {
    disputeId: "8178ee5c-9959-4e88-908c-6c3d5222e113",
    trackingId: "P100000018",
    invoiceNumber: "999",
    amount: 64.74,
    dueDate: "2024-09-13",
    disputeCreationType: "AUTOMATIC",
    disputeCategory: "Loss_/Damage",
    status: "IN_PROGRESS",
    assignedTo: "",
    partyName: null,
    position: "right",
    description:
      "The exception for tracking id P100000018 is due to discrepancies identified in the contract terms and invoice details. The issues include a violation of the first contract term due to the number of tracking IDs being less than 10 and no min_volume charge mentioned in the invoice. The weight and dimensions of each tracking number do not meet the requirements of the second and third contract terms respectively. The only contract dispute identified is the lack of a min_volume charge when the number of tracking IDs is less than 10. Additionally, the tracking id is not mentioned for rates.",
  },
  {
    disputeId: "54cbc0bd-02c3-47cb-b479-f1d799ad91c2",
    trackingId: "P100000018",
    invoiceNumber: "999",
    amount: 64.74,
    dueDate: "2024-09-13",
    disputeCreationType: "AUTOMATIC",
    disputeCategory: "Duplicate_Invoice",
    status: "IN_PROGRESS",
    assignedTo: "",
    partyName: null,
    position: "right",
    description:
      "The exception for tracking id P100000018 is due to discrepancies identified in the contract terms and invoice details. The issues include a violation of the first contract term due to the number of tracking IDs being less than 10 and no min_volume charge mentioned in the invoice. The weight and dimensions of each tracking number do not meet the requirements of the second and third contract terms respectively. The only contract dispute identified is the lack of a min_volume charge when the number of tracking IDs is less than 10. Additionally, the tracking id is not mentioned for rates.",
  },
];
