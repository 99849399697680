import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
  Text,
} from "@chakra-ui/react";
import {
  CheckCircleIcon,
  CheckIcon,
  ChevronDownIcon,
  CloseIcon,
  TimeIcon,
  WarningIcon,
} from "@chakra-ui/icons";
import AccountValidationSidePanel from "../interfaces/AccountValidationSidePanel/AccountValidationSidePanel";
import { Page, TableLoading } from "../interfaces";
import {
  getAllPartnerOnAccountRequest,
  updatePartnerOnAccountRequestById,
} from "../services";

const AccountRequest = () => {
  const [isAccountValidationSidePanel, setIsAccountValidationSidePanel] =
    useState(false);
  const [selectedStatus, setSelectedStatus] = useState("Pending");
  const [toastStatus, setToastStatus] = useState("");
  const [data, setData] = useState([]);
  const [undoData, setUndoData] = useState(null);
  const [undoModal, setIsUndoModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [partnerOnboardingId, setPartnerOnboardingId] = useState("");
  const [statusChangeId, setStatusChangeId] = useState("");
  const [isAccountSidePanelClose, SetIsAccountSidePanelClose] = useState({});
  const [searchTerm, setSearchTerm] = useState(""); // Added search term state
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalData, setModalData] = useState({ id: "", status: "" });
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });

  const handleOpenPanel = (id) => {
    setIsAccountValidationSidePanel(true);
    setPartnerOnboardingId(id);
    setIsUndoModal(false);
  };

  const sortedData = useMemo(() => {
    if (!sortConfig.key) return data;
    return [...data].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });
  }, [data, sortConfig]);

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const fetchData = async (status) => {
    setIsLoading(true);
    getAllPartnerOnAccountRequest(status)
      .then((res) => {
        if (!res) {
          setData([]);
          return;
        }
        setData(res);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleUndo = () => {
    handleStatusChange(statusChangeId, selectedStatus, "undo");
  };

  useEffect(() => {
    fetchData(selectedStatus);
  }, [selectedStatus, isAccountSidePanelClose]);

  useEffect(() => {
    let timer;
    if (toastStatus) {
      timer = setTimeout(() => {
        setToastStatus("");
        setIsUndoModal(false);
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [toastStatus]);

  const handleStatusChange = async (id, newStatus, isUndo, selected) => {
    if (newStatus === selectedStatus) {
      if (!isUndo) return;
    }
    setStatusChangeId(id);
    setIsUndoModal(false);
    try {
      setIsLoading(true);
      const response = await updatePartnerOnAccountRequestById(id, newStatus);
      if (response.ok) {
        setUndoData(selected);
        setData((prevData) => {
          let updatedData;
          if (isUndo) {
            updatedData = [...prevData, undoData];
          } else {
            updatedData = prevData.filter((item) => item.id !== id);
          }
          return updatedData;
        });
        if (!isUndo) {
          setIsUndoModal(true);
        }
        setToastStatus(newStatus);
      } else {
        console.error("Error updating status:", response.statusText);
      }
    } catch (error) {
      console.error("Error updating status:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const filteredData = useMemo(() => {
    return sortedData.filter((item) =>
      ["companyName", "companyURL", "firstName", "email", "contactNumber"].some(
        (key) => item[key].toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [sortedData, searchTerm]);

  const getFilteredData = (status) =>
    filteredData.filter(
      (item) => item?.status.toLowerCase() === status.toLowerCase()
    );

  const renderTableRows = (status) => {
    const filteredRows = getFilteredData(status);
    if (filteredRows.length === 0) {
      return (
        <Tr>
          <Td colSpan="100%">
            <Text align="center" color="gray.500">
              No data found
            </Text>
          </Td>
        </Tr>
      );
    }

    return filteredRows?.map((item, index) => {
      return (
        <Tr key={index} className="cursor-pointer">
          <Td onClick={() => handleOpenPanel(item.id)}>
            <div className="flex gap-2 items-center">
              {item?.companyName}
              {item?.duplicateCompanyName ? (
                <WarningIcon color="#DA2C17" />
              ) : (
                <CheckCircleIcon color="#05944F" />
              )}
            </div>
          </Td>
          <Td onClick={() => handleOpenPanel(item.id)}>
            <div className="flex gap-2 items-center">
              {item?.companyURL}{" "}
              {item?.duplicateCompanyURL ? (
                <WarningIcon color="#DA2C17" />
              ) : (
                <CheckCircleIcon color="#05944F" />
              )}
            </div>
          </Td>
          <Td onClick={() => handleOpenPanel(item.id)}>
            {`${item.firstName} ${item.lastName}`}
          </Td>
          <Td onClick={() => handleOpenPanel(item.id)}>
            <div className="flex gap-2 items-center">
              {item.email}{" "}
              {item?.verifiedEmail ? (
                <CheckCircleIcon color="#05944F" />
              ) : (
                <WarningIcon color="#DA2C17" />
              )}
            </div>
          </Td>
          <Td onClick={() => handleOpenPanel(item.id)}>
            <div className="flex gap-2 items-center">
              {item.contactNumber}
              {item?.verifiedContactNumber ? (
                <CheckCircleIcon color="#05944F" />
              ) : (
                <WarningIcon color="#DA2C17" />
              )}
            </div>
          </Td>
          <Td>
            <div className="flex items-center !font-bold">
              <div
                className={`${selectedStatus.toLowerCase() !== "approved" ? "group" : ""} flex items-center gap-2 p-2`}
              >
                <div
                  className={`flex items-center gap-2 p-2 rounded-md ${
                    item.status.toLowerCase() === "approved"
                      ? "custom-green-opacity-table"
                      : item.status.toLowerCase() === "rejected"
                        ? "custom-red-opacity"
                        : "custom-yellow-opacity"
                  }`}
                  onClick={() => handleOpenPanel(item.id)}
                >
                  {item.status.toLowerCase() === "pending" ? (
                    <TimeIcon color="#FFC252" className="!font-bold" />
                  ) : item.status.toLowerCase() === "approved" ? (
                    <CheckIcon color="#4BEA9E" />
                  ) : item.status.toLowerCase() === "rejected" ? (
                    <CloseIcon color="#FF7B80" />
                  ) : null}
                  <span
                    className={`!font-bold ${
                      item.status.toLowerCase() === "approved"
                        ? "!text-green-600 !opacity-100"
                        : item.status.toLowerCase() === "rejected"
                          ? "!text-red-600 !opacity-100"
                          : "!text-yellow-600 !opacity-100"
                    }`}
                  >
                    {item.status}
                  </span>
                </div>
                {item.status.toLowerCase() !== "approved" && (
                  <Menu>
                    <MenuButton
                      as={Button}
                      isDisabled={statusChangeId === item.id && isLoading}
                      rightIcon={<ChevronDownIcon />}
                      className="!bg-transparent text-yellow-500 flex items-center space-x-2 px-4 py-2 rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                    ></MenuButton>
                    <MenuList className="bg-gray-800 text-white border-0 shadow-lg !min-w-44 !p-4">
                      {["Approved", "Rejected", "Pending"].map((status) => {
                        if (selectedStatus === status) {
                          return null;
                        }
                        return (
                          <MenuItem
                            key={status}
                            className={`flex items-center space-x-2 px-4 py-2 mb-2 last:mb-0 rounded-md ${
                              status === "Approved"
                                ? "custom-green-opacity"
                                : status === "Rejected"
                                  ? "custom-red-opacity"
                                  : "custom-yellow-opacity"
                            }`}
                            onClick={() =>
                              openStatusChangeModal(item.id, status, item)
                            }
                          >
                            <div className="flex gap-2 items-center">
                              {status.toLowerCase() === "pending" ? (
                                <TimeIcon color="#FFC252" />
                              ) : status.toLowerCase() === "approved" ? (
                                <CheckIcon color="#4BEA9E" />
                              ) : status.toLowerCase() === "rejected" ? (
                                <CloseIcon color="#FF7B80" />
                              ) : null}
                            </div>
                            <span>{status}</span>
                          </MenuItem>
                        );
                      })}
                    </MenuList>
                  </Menu>
                )}
              </div>
            </div>
          </Td>
        </Tr>
      );
    });
  };

  const renderToast = () => {
    if (!toastStatus) return null;
    return (
      <Flex
        p="4"
        borderRadius="md"
        alignItems="center"
        className={`shadow-lg ${
          toastStatus === "Approved"
            ? "!bg-[#22E5881A]"
            : toastStatus === "Rejected"
              ? "!bg-[#FF263F1A]"
              : "!bg-[#FFB3231A]"
        } transition-opacity duration-500 w-full mb-4 p-5 !mt-4 flex !justify-end mr-6`}
      >
        <span
          className={`mr-2 text-right text-${
            toastStatus === "Approved"
              ? "!text-[#4BEA9E]"
              : toastStatus === "Rejected"
                ? "!text-[#FF7B80]"
                : "!text-[#FFC252]"
          } font-bold`}
        >
          Moved to {toastStatus}
        </span>
      </Flex>
    );
  };

  const renderUndoModal = () => {
    if (!undoModal) return null;

    return (
      <Flex
        position="fixed"
        bottom="10"
        left="60%"
        transform="translateX(-50%)"
        p="4"
        borderRadius="md"
        width={"680px"}
        alignItems="center"
        className="shadow-lg !bg-[#1E253F]"
      >
        <span className="text-sm mr-2">
          {`Approved the account for ${undoData.firstName + undoData.lastName}
          from ${undoData.companyName}`}
        </span>
        <div className="flex gap-3">
          <Button
            ml={3}
            className="!bg-[#1E253F] border"
            onClick={() => setIsUndoModal(false)}
          >
            Dismiss
          </Button>
          <Button ml={2} className="!bg-[#3BA0E6]" onClick={handleUndo}>
            Undo
          </Button>
        </div>
      </Flex>
    );
  };

  const openStatusChangeModal = (id, status, data) => {
    setUndoData(data);
    setModalData({ id, status });
    onOpen();
  };

  const closeStatusChangeModal = () => {
    onClose();
    setModalData({ id: "", status: "" });
    setUndoData(null);
  };

  const handleStatusChangeConfirmation = () => {
    handleStatusChange(modalData.id, modalData.status, "", undoData);
    closeStatusChangeModal();
  };

  const renderStatusChangeModal = () => {
    const statusText =
      modalData.status === "Approved"
        ? "approve"
        : modalData.status === "Rejected"
          ? "reject"
          : "mark as pending";

    return (
      <Modal isOpen={isOpen} onClose={closeStatusChangeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{`Confirm ${modalData.status}`}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column" alignItems="center">
              <p>{`Are you sure you want to ${statusText} this account request?`}</p>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={closeStatusChangeModal}>
              No
            </Button>
            <Button
              colorScheme="blue"
              ml={3}
              onClick={handleStatusChangeConfirmation}
            >
              Yes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  return (
    <Page>
      <Tabs
        onChange={(index) => {
          setIsUndoModal(false);
          setToastStatus("");
          setSelectedStatus(
            index === 0 ? "Pending" : index === 1 ? "Approved" : "Rejected"
          );
        }}
      >
        <TabList className="mb-4 !text-white">
          {["Pending", "Approved", "Rejected"].map((status) => (
            <Tab key={status} className="bg-gray-200 rounded-t-md px-4 py-2">
              {status}
            </Tab>
          ))}
        </TabList>
        <Input
          placeholder="search here..."
          type="search"
          width="auto"
          className="ml-3"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <TabPanels>
          {["Pending", "Approved", "Rejected"].map((status) => (
            <TabPanel key={status}>
              <TableContainer className="max-h-[calc(100vh_-_294px)] !overflow-y-auto w-full mb-4">
                <Table>
                  <Thead>
                    <Tr>
                      <Th onClick={() => requestSort("companyName")}>
                        <div className="flex gap-2">
                          Company
                          <img
                            src="/images/icons/filter-icon.svg"
                            alt="Filter Icon"
                          />
                        </div>
                      </Th>
                      <Th onClick={() => requestSort("companyURL")}>
                        Company URL
                      </Th>
                      <Th onClick={() => requestSort("firstName")}>
                        <div className="flex gap-2">
                          Person
                          <img
                            src="/images/icons/filter-icon.svg"
                            alt="Filter Icon"
                          />
                        </div>
                      </Th>
                      <Th onClick={() => requestSort("email")}>
                        <div className="flex gap-2">
                          Email
                          <img
                            src="/images/icons/filter-icon.svg"
                            alt="Filter Icon"
                          />
                        </div>
                      </Th>
                      <Th onClick={() => requestSort("contactNumber")}>
                        Number
                      </Th>
                      <Th>Status</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {isLoading && <TableLoading />}
                    {!isLoading && renderTableRows(status)}
                  </Tbody>
                </Table>
              </TableContainer>
              {renderToast()}
              {getFilteredData(status).length > 0 && (
                <AccountValidationSidePanel
                  isOpen={isAccountValidationSidePanel}
                  setIsAccountSidePanelClose={SetIsAccountSidePanelClose}
                  onClose={() => setIsAccountValidationSidePanel(false)}
                  partnerOnboardingId={partnerOnboardingId}
                  currentTab={selectedStatus}
                />
              )}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
      {renderUndoModal()}
      {renderStatusChangeModal()}
    </Page>
  );
};

export default AccountRequest;
