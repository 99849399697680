import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { GoogleMap, Polyline } from "@react-google-maps/api";
import { useDisclosure } from "@chakra-ui/react";

const ellipseIcon = {
  path: "M -25, 0 a 25,25 0 1,1 50,0 a 25,25 0 1,1 -50,0",
  fillOpacity: 1,
  scale: 0.2,
};

const LaneCoverageMap = ({ data, height, zoom, legends, filters }) => {
  const containerStyle = {
    width: "100%",
    height: height,
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [popover, setPopover] = useState({
    content: {},
    position: { x: 0, y: 0 },
  });
  const [filteredMapData, setFilteredMapData] = useState(data);

  useEffect(() => {
    if (filters) {
      const allFiltersChecked = Object.values(filters).every(
        (option) => option.isChecked
      );
      const allFiltersUnchecked = Object.values(filters).every(
        (option) => !option.isChecked
      );

      if (allFiltersChecked) {
        setFilteredMapData(data);
      } else if (allFiltersUnchecked) {
        setFilteredMapData([]);
      } else {
        setFilteredMapData((prevMapData) => {
          const filteredValues = prevMapData.length > 0 ? prevMapData : data;

          const filteredData = filteredValues.filter((value) =>
            Object.values(filters)
              .filter((option) => option.isChecked)
              .some((filter) => filter.color === value[0].color)
          );

          return filteredData;
        });
      }
    }
  }, [filters, data]);

  const handlePolyLineClick = (e, data) => {
    setPopover((popover) => ({
      ...popover,
      position: { x: e.domEvent.clientX - 450, y: e.domEvent.clientY - 100 },
      content: { ...data },
    }));
    onOpen();
  };

  return (
    <div style={{ borderRadius: "8px", overflow: "hidden" }}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={{ lat: 38.907, lng: -100.04 }}
        onClick={onClose}
        zoom={zoom}
        options={{
          scrollwheel: false,
          streetViewControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
          zoomControl: false,
          mapTypeId: "hybrid",
        }}
      >
        {filteredMapData &&
          filteredMapData.length > 0 &&
          filteredMapData.map((data, i) => {
            const icon = {
              ...ellipseIcon,
              fillColor: data[0]?.color,
            };

            return (
              <Polyline
                key={i}
                onClick={(e) => handlePolyLineClick(e, data[0])}
                options={{
                  strokeColor: data[0]?.color,
                  strokeOpacity: 1.0,
                  strokeWeight: 4,
                  geodesic: true,
                  path:
                    data && data.length > 0 && data.map(({ coords }) => coords),
                  icons: [
                    {
                      icon,
                      offset: "0%",
                    },
                    {
                      icon,
                      offset: "100%",
                    },
                  ],
                }}
              />
            );
          })}

        {legends && Object.keys(legends).length > 0 && (
          <div className="absolute bottom-8 left-[18px] bg-[#191a23bf] py-3 pl-4 pr-10 rounded-lg">
            <div className="text-sm font-bold mb-1">{legends.title}</div>
            {legends.value.map(({ color, label }, i) => (
              <div className="flex items-center gap-3 mt-2" key={i}>
                <div
                  className="h-[17px] w-5"
                  style={{ backgroundColor: color }}
                />
                <div className="text-xs font-bold">{label}</div>
              </div>
            ))}
          </div>
        )}

        {isOpen && (
          <div
            className="bg-[#1E253F] absolute p-4 rounded-lg border border-[#85869833] flex flex-col items-start gap-2"
            style={{
              top: `${popover.position.y}px`,
              left: `${popover.position.x}px`,
            }}
          >
            <div className="flex px-1 items-center gap-1">
              <div className="text-sm font-medium text-[#EBEBEB] w-[130px]">
                Lane
              </div>
              <div className="text-sm font-semibold text-[#74BCFF]">
                {popover.content.lane}
              </div>
            </div>
            <div className="flex px-1 items-center gap-1">
              <div className="text-sm font-medium text-[#EBEBEB] w-[130px]">
                Carrier
              </div>
              <div className="text-sm font-semibold text-[#74BCFF]">
                {popover.content.carrier}
              </div>
            </div>

            <div className="flex px-1 items-center gap-1">
              <div className="text-sm font-medium text-[#EBEBEB] w-[130px]">
                Parcels Per Day
              </div>
              <div className="text-sm font-semibold text-[#74BCFF]">
                {popover.content.parcelsPerDay}
              </div>
            </div>
          </div>
        )}
      </GoogleMap>
    </div>
  );
};

export default LaneCoverageMap;

LaneCoverageMap.defaultProps = {
  data: {},
  height: "500px",
  zoom: 4,
  legends: {},
  filters: {},
};

LaneCoverageMap.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  height: PropTypes.string,
  zoom: PropTypes.number,
  legends: PropTypes.object,
  filters: PropTypes.object,
};
