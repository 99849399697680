import { useState, useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import Popover from "./popover";
import { MAPBOX_API_KEY } from "../../constants";

mapboxgl.accessToken = MAPBOX_API_KEY;

function MapView({ route }) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [zoom] = useState(7);
  const [popover, setPopover] = useState({
    isVisible: false,
    content: {
      title: "",
      shipments: {},
    },
    position: { x: 0, y: 0 },
  });

  useEffect(() => {
    if (route.length > 0) {

      if (map.current) return;
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v12",
        center: [route[0].longitude, route[0].latitude],
        zoom: zoom,
        scrollZoom: false,
      });

      function isFullscreen() {
        return document.fullscreenElement !== null;
      }

      function requestFullscreen(element) {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen();
        }
      }

      function exitFullscreen() {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      }

      function getUserLocation() {
        return new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(
            (position) => resolve(position.coords),
            (error) => reject(error)
          );
        });
      }

      function zoomIn() {
        map.current.zoomIn();
      }

      function zoomOut() {
        map.current.zoomOut();
      }

      const fitBounds = (bounds) => {
        if (map.current && bounds) {
          map.current.fitBounds(bounds, {
            padding: 100,
          });
        }
      };

      // Create custom control elements
      const zoomInButton = document.createElement("button");
      zoomInButton.innerHTML = "<img src='/images/map/zoomin.svg'/>";
      zoomInButton.addEventListener("click", zoomIn);

      const zoomOutButton = document.createElement("button");
      zoomOutButton.innerHTML = "<img src='/images/map/zoomout.svg'/>";
      zoomOutButton.addEventListener("click", zoomOut);

      const nearButton = document.createElement("button");
      nearButton.innerHTML = "<img src='/images/map/near.svg'/>";
      nearButton.addEventListener("click", async () => {
        try {
          const coords = await getUserLocation();
          map.current.flyTo({
            center: [coords.longitude, coords.latitude],
            zoom: 15,
          });
        } catch (error) {
          console.error("Error getting location:", error);
        }
      });

      const fullScreenButton = document.createElement("button");
      fullScreenButton.innerHTML = "<img src='/images/map/maxzoom.svg'/>";
      fullScreenButton.addEventListener("click", () => {
        if (isFullscreen()) {
          exitFullscreen();
        } else {
          requestFullscreen(mapContainer);
        }
      });

      const toolbar = document.createElement("div");
      toolbar.className = "map-toolbar";

      toolbar.appendChild(nearButton);
      toolbar.appendChild(zoomInButton);
      toolbar.appendChild(zoomOutButton);
      toolbar.appendChild(fullScreenButton);

      // Add the toolbar to the map container
      mapContainer.current.appendChild(toolbar);

      const startingPoint = [route[0].longitude, route[0].latitude];
      const endPoint = [
        route[route.length - 1].longitude,
        route[route.length - 1].latitude,
      ];

      fitBounds([endPoint, startingPoint]);

      map.current.on("click", () =>
        setPopover((popover) => ({ ...popover, isVisible: false }))
      );

      map.current.on("load", () => {
        fetch(
          `https://api.mapbox.com/directions/v5/mapbox/driving/${startingPoint[0]},${startingPoint[1]};${endPoint[0]},${endPoint[1]}.json?geometries=geojson&access_token=${mapboxgl.accessToken}`
        )
          .then((response) => response.json())
          .then((data) => {
            const jsonGeometry = data.routes[0].geometry;
            const route1 = {
              type: "LineString",
              coordinates: [...jsonGeometry.coordinates.slice(0, 14)],
            };
            const route2 = {
              type: "LineString",
              coordinates: [...jsonGeometry.coordinates.slice(13)],
            };
            const features = [route1, route2];
            features.forEach((feature, index) => {
              const sourceId = `route-${index}`;
              const coordinates = feature.coordinates[0];
              map.current.addSource(sourceId, {
                type: "geojson",
                data: feature,
              });
              const color = index === 0 ? "#3B86ED" : "#DA2C17";
              const marker = index === 0 ? "start" : "marker_red";

              const el = document.createElement("div");
              el.innerHTML = `<div><img src="/images/map/${marker}.svg" /></div>`;

              map.current.addLayer({
                id: `route-${index}`,
                type: "line",
                source: sourceId,
                layout: {
                  "line-join": "round",
                  "line-cap": "round",
                },
                paint: {
                  "line-color": color,
                  "line-width": 4,
                  "line-dasharray": [2, 2],
                },
              });
              new mapboxgl.Marker(el).setLngLat(coordinates).addTo(map.current);

              if (index === 0) {
                const elOrigin = document.createElement("div");
                elOrigin.innerHTML =
                  '<div><img src="/images/map/origin.svg" /></div>';

                let coordinatesOrigin = [...coordinates];
                coordinatesOrigin[1] = coordinatesOrigin[1] + 0.15;

                new mapboxgl.Marker(elOrigin)
                  .setLngLat(coordinatesOrigin)
                  .addTo(map.current);
              }

              if (index === 1) {
                let coordinateEnd =
                  feature.coordinates[feature.coordinates.length - 1];

                const elEnd = document.createElement("div");
                elEnd.innerHTML = `<div><img src="/images/map/${marker}.svg" /></div>`;

                new mapboxgl.Marker(elEnd)
                  .setLngLat(coordinateEnd)
                  .addTo(map.current);

                const elFinal = document.createElement("div");
                elFinal.innerHTML =
                  '<div><img src="/images/map/endroute.svg" /></div>';

                let coordinatesFinal = [...coordinateEnd];
                coordinatesFinal[1] = coordinatesFinal[1] + 0.15;

                new mapboxgl.Marker(elFinal)
                  .setLngLat(coordinatesFinal)
                  .addTo(map.current);
              }
            });
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      });
    }
  }, [route, zoom]);

  return (
    <div className="mt-4 rounded-lg">
      <div ref={mapContainer} style={{ height: "600px" }} />
      <Popover
        isVisible={popover.isVisible}
        content={popover.content}
        position={popover.position}
      />
    </div>
  );
}

export default MapView;
