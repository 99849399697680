import { Link } from "react-router-dom";
import { Button } from "@chakra-ui/react";

import style from "../../css-files/Component-CSS/salesToolsCards.module.css";

export function UsCoverageMapCard() {
  // This is s personal low point for the sake of haste

  return (
    <div className={style.div}>
      <div className={style.div2}>
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="32" cy="32" r="32" fill="#94E396"/>
          <path d="M45 29.6818C45 39.5455 32 48 32 48C32 48 19 39.5455 19 29.6818C19 26.3184 20.3696 23.0927 22.8076 20.7144C25.2456 18.3361 28.5522 17 32 17C35.4478 17 38.7544 18.3361 41.1924 20.7144C43.6304 23.0927 45 26.3184 45 29.6818Z" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M32 34C34.2091 34 36 31.9853 36 29.5C36 27.0147 34.2091 25 32 25C29.7909 25 28 27.0147 28 29.5C28 31.9853 29.7909 34 32 34Z" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </div>
      <div className={style.div3}>
        <div className={style.div4}></div>
        <div className={style.div5}>US Coverage Map</div>
        <div className={style.div6}>Network Coverage</div>
        <Button
          as={Link}
          mb={9}
          to="/app/sales-tools/us-coverage-map"
          className="chakra-primary-button"
        >
          View Coverage
        </Button>
        <div className={style.div7}>
          <img
            alt=""
            loading="lazy"
            src="/images/icons/planetIcon.svg"
            className={style.img}
          />
          <div>MSA by Population</div>
        </div>
        <div className={style.div7}>
          <img
            alt=""
            loading="lazy"
            src="/images/icons/fastTruck.svg"
            className={style.img}
          />
          <div>MSA by Carrier</div>
        </div>
      </div>
    </div>
  );
};
