import React, { useState, useEffect } from "react";
import Xarrow from "react-xarrows";
import Popup from "./PopUp";

// Function to process the dispute data from the API
const processDisputes = (disputesList) => {
  const disputeCategories = {};

  disputesList?.forEach((dispute) => {
    const { disputeCategory, partyName, dueDate, description } = dispute;

    if (!disputeCategories[disputeCategory]) {
      disputeCategories[disputeCategory] = {
        category: disputeCategory,
        parties: new Set(),
        dueDate,
        description,
      };
    }

    if (partyName) {
      disputeCategories[disputeCategory].parties.add(partyName);
    }
  });

  // Convert sets to arrays for easier usage
  Object.keys(disputeCategories).forEach((category) => {
    disputeCategories[category].parties = Array.from(
      disputeCategories[category].parties
    );
  });

  return disputeCategories;
};

// Predefined button positions based on index
const buttonPositions = [
  { top: "-13rem", left: "-20rem" },
  { top: "-4rem", left: "-4rem" },
  { top: "3rem", left: "-4rem" },
];

const HexagonSection = ({
  activeButton,
  handleButtonClick,
  deselectButton,
  disputesList,
}) => {
  // Process disputes
  const disputeCategories = processDisputes(disputesList);

  // convert the object to an array
  const disputeCategoriesArray = Object.keys(disputeCategories).map(
    (category) => disputeCategories[category]
  );

  // State to manage gridBreak value
  const [gridBreak, setGridBreak] = useState("40%");

  // Effect to update gridBreak based on screen size
  useEffect(() => {
    const updateGridBreak = () => {
      if (window.innerWidth >= 1400) {
        setGridBreak("50%");
      } else if (window.innerWidth >= 768) {
        setGridBreak("30%");
      } else {
        setGridBreak("30%");
      }
    };

    // Initial check
    updateGridBreak();

    // Add event listener
    window.addEventListener("resize", updateGridBreak);

    // Cleanup event listener
    return () => window.removeEventListener("resize", updateGridBreak);
  }, []);

  return (
    <div className="relative">
      <div className="font-semibold m-10">Exception Details</div>
      <img
        id="hexagon"
        src="/images/exceptions/cube.png"
        alt="Hexagon with Cube"
        className="w-[310px] h-[360px] mt-[100px] ml-[135px]"
      />
      <div className="absolute left-[530px] top-[60%] transform -translate-y-1/2">
        <div className="flex flex-col space-y-4 gap-[20px] text-[13px]">
          {Object.keys(disputeCategories).map((category, index) => {
            const buttonPosition =
              buttonPositions[index % buttonPositions.length]; // Assign position based on index
            return (
              <div
                key={category}
                id={category}
                style={{
                  position: "absolute",
                  top: buttonPosition.top,
                  left: buttonPosition.left,
                }}
              >
                <button
                  onClick={() => handleButtonClick(category)}
                  className={`min-w-[190px] max-w-max px-2 h-[40px] rounded-full flex justify-center items-center border border-[#292A35] text-center whitespace-nowrap ${
                    activeButton === category
                      ? "bg-[#FF263F] text-white"
                      : "bg-[#21232E] text-white"
                  }`}
                  style={{
                    boxShadow:
                      "4px 4px 8px rgba(0, 0, 0, .25), -2px -2px 8px rgba(0, 0, 0, .4)",
                    zIndex: 10,
                  }}
                >
                  {category
                    .replace(/_/g, " ")
                    .replace(/\b\w/g, (char) => char.toUpperCase())}
                </button>
              </div>
            );
          })}
        </div>
      </div>

      {activeButton && (
        <>
          <Popup
            activeButton={activeButton}
            buttonDetails={disputeCategories[activeButton]}
            deselectButton={deselectButton}
            isFirstChip={activeButton === disputeCategoriesArray[0].category}
          />
          {/* <Xarrow
            start={activeButton}
            end="popup"
            color="#EED2FD"
            dashness
            strokeWidth={2}
            showHead={false}
            startAnchor={
              activeButton === disputeCategoriesArray[0].category
                ? "top"
                : "right"
            }
            endAnchor={
              activeButton === disputeCategoriesArray[0].category
                ? "top"
                : "bottom"
            }
            path="grid"
            gridBreak={gridBreak}
          /> */}
        </>
      )}

      {disputeCategoriesArray.length >= 1 && (
        <Xarrow
          start="hexagon"
          end={disputeCategoriesArray[0].category}
          color="rgba(255, 221, 157, 0.6)"
          dashness
          startAnchor={{
            position: "top",
            offset: { x: 13, y: 60 },
          }}
          strokeWidth={1}
          showHead={false}
          endAnchor={{
            position: "bottom",
            offset: { x: -1, y: 0 },
          }}
        />
      )}

      {/* Keeping the same Xarrow connections as before */}
      {disputeCategoriesArray.length >= 2 && (
        <Xarrow
          start="hexagon"
          end={disputeCategoriesArray[1].category}
          color="rgba(255, 221, 157, 0.6)"
          dashness
          startAnchor={{
            position: "right",
            offset: { x: -34, y: -58 },
          }}
          endAnchor={{
            position: "left",
            offset: { x: 0, y: 0 },
          }}
          strokeWidth={1}
          showHead={false}
        />
      )}

      {disputeCategoriesArray.length >= 3 && (
        <Xarrow
          start="hexagon"
          end={disputeCategoriesArray[2].category}
          color="rgba(255, 221, 157, 0.6)"
          dashness
          startAnchor={{
            position: "right",
            offset: { x: -34, y: 55 },
          }}
          endAnchor={{
            position: "left",
            offset: { x: 0, y: 0 },
          }}
          strokeWidth={1}
          showHead={false}
          zIndex={10}
        />
      )}

      {/* {disputeCategoriesArray.length >= 4 && (
        <Xarrow
          start="hexagon"
          end={disputeCategoriesArray[3].category}
          color={
            activeButton === disputeCategoriesArray[3].category
              ? "#EED2FD"
              : "rgba(255, 221, 157, 0.3)"
          }
          dashness
          startAnchor={{
            position: "bottom",
            offset: { x: -55, y: -30 },
          }}
          endAnchor={{
            position: "top",
            offset: { x: 0, y: 0 },
          }}
          strokeWidth={2}
          showHead={false}
          zIndex={10}
        />
      )}

      {disputeCategoriesArray.length >= 5 && (
        <Xarrow
          start="hexagon"
          end={disputeCategoriesArray[4].category}
          color={
            activeButton === disputeCategoriesArray[4].category
              ? "#EED2FD"
              : "rgba(255, 221, 157, 0.3)"
          }
          dashness
          startAnchor={{
            position: "bottom",
            offset: { x: 0, y: 0 },
          }}
          strokeWidth={2}
          showHead={false}
          zIndex={10}
        />
      )}

      {disputeCategoriesArray.length >= 6 && (
        <Xarrow
          start="hexagon"
          end={disputeCategoriesArray[5].category}
          color={
            activeButton === disputeCategoriesArray[5].category
              ? "#EED2FD"
              : "rgba(255, 221, 157, 0.3)"
          }
          dashness
          startAnchor={{
            position: "bottom",
            offset: { x: 55, y: -30 },
          }}
          endAnchor={{
            position: "top",
            offset: { x: 0, y: 0 },
          }}
          strokeWidth={2}
          showHead={false}
          zIndex={10}
        />
      )} */}
    </div>
  );
};

export default HexagonSection;
