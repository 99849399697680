import { Page } from "../interfaces";
import {
  Card,
  Heading,
  Select,
  Flex,
  Image,
  Text,
  Spacer,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { useState } from "react";

function UsCoverageMap() {
  const [selectedValue, setSelectedValue] = useState("25");

  const handleChange = (event) => setSelectedValue(event.target.value);

  return (
    <Page>
      <Flex as={"header"} gap={5} mb={5}>
        <svg
          width="71"
          height="71"
          viewBox="0 0 93 93"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="46.5" cy="46.5" r="46.5" fill="#94E396" />
          <path
            d="M66 42.8182C66 57.4545 47 70 47 70C47 70 28 57.4545 28 42.8182C28 37.8273 30.0018 33.0408 33.565 29.5117C37.1282 25.9826 41.9609 24 47 24C52.0391 24 56.8718 25.9826 60.435 29.5117C63.9982 33.0408 66 37.8273 66 42.8182Z"
            stroke="white"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M47 49C50.3137 49 53 46.3137 53 43C53 39.6863 50.3137 37 47 37C43.6863 37 41 39.6863 41 43C41 46.3137 43.6863 49 47 49Z"
            stroke="white"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <div>
          <Heading as="h1" size="md" mt={3}>
            US Coverage Map
          </Heading>
          <Text>Coverage of the top MSAs by carrier and population</Text>
        </div>
        <Spacer />
        <Flex as={FormControl} width="300px" alignItems={"center"} gap={1}>
          <FormLabel width={"250px"} mb={0}>
            Number of MSAs
          </FormLabel>
          <Select defaultValue="25" onChange={handleChange}>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </Select>
        </Flex>
      </Flex>
      <hr />

      <Card
        border="1px"
        borderRadius="20px"
        borderWidth="1px"
        borderColor="rgba(133, 134, 152, 0.2)"
        p={8}
        mt={4}
      >
        <Heading as={"h3"} size={"md"} mb={3}>
          MSA Sorted by Population
        </Heading>
        <Image src={`/images/sales/${selectedValue}/newplot-2.png`} />
      </Card>

      <Card
        border="1px"
        borderRadius="20px"
        borderWidth="1px"
        borderColor="rgba(133, 134, 152, 0.2)"
        p={8}
        mt={4}
      >
        <Heading as={"h3"} size={"md"} mb={3}>
          MSA Sorted by Carrier
        </Heading>
        <Image src={`/images/sales/${selectedValue}/newplot-3.png`} />
      </Card>

      <Card
        border="1px"
        borderRadius="20px"
        borderWidth="1px"
        borderColor="rgba(133, 134, 152, 0.2)"
        p={8}
        mt={4}
      >
        <Heading as={"h3"} size={"md"} mb={3}>
          MSA Sorted by Carrier
        </Heading>
        <Image src={`/images/sales/${selectedValue}/newplot-4.png`} />
      </Card>
    </Page>
  );
}

export default UsCoverageMap;
