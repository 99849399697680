import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as yup from "yup";
import { FormControl, FormErrorMessage, Text } from "@chakra-ui/react";
import Button from "../../componentLibrary/components/Button";
import {
  InputField,
  NumberField,
  RadioGroup,
  SelectField,
} from "../InputFields";
import {
  createBidConfig,
  getBidTierConfiguration,
  updateBidConfig,
} from "../../services";

const BidConfigurationPanel = ({
  bidConfigValue,
  getBidConfigs,
  handleCloseConfigurationBar,
}) => {
  const BidConfigSchema = yup.object({
    name: yup.string().required(),
    risk: yup.string().required(),
    adjustments: yup.object({
      biddingTier: yup.string().required(),
    }),
  });

  const handleConfigurationSubmit = useCallback(
    async (values) => {
      const configuration = await getBidTierConfiguration(
        values.risk,
        values.adjustments.biddingTier
      );

      const adjustments = {
        ...values.adjustments,
        configuration: configuration.configuration,
      };
      try {
        if (bidConfigValue) {
          await updateBidConfig(values.uuid, {
            ...values,
            adjustments,
          });
        } else {
          await createBidConfig({
            ...values,
            adjustments,
          });
        }
        getBidConfigs();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      handleCloseConfigurationBar();
    },
    [bidConfigValue, handleCloseConfigurationBar, getBidConfigs]
  );

  const {
    values,
    errors,
    handleChange,
    setFieldValue,
    handleSubmit,
    handleBlur,
    touched,
    setValues,
  } = useFormik({
    initialValues: {
      name: "",
      risk: "",
      rangeSpread: 0,
      adjustments: {
        initialBiddingThreshold: 0,
        biddingTier: "",
        minNoLastLossDecrement: 0,
        minNoLastWonIncrement: 0,
        timeWindow: 0,
      },
    },
    validationSchema: BidConfigSchema,
    onSubmit: handleConfigurationSubmit,
  });

  useEffect(() => {
    if (bidConfigValue) {
      setValues(bidConfigValue);
    }
  }, [bidConfigValue, setValues]);

  return (
    <>
      <div className="pl-8 pr-6 pb-7 pt-2.5 border-t border-b border-[#D9D9E0]/50">
        <div className="text-sm font-medium pt-2 pb-3.5">Name</div>
        <FormControl isInvalid={!!errors.name && touched.name}>
          <InputField
            placeholder={"Configuration Name"}
            className="!h-11"
            _placeholder={{ color: "rgba(235, 235, 235, 0.6) !important" }}
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            name={"name"}
          />
          <FormErrorMessage className="!mt-3">
            Configuration Name is required
          </FormErrorMessage>
        </FormControl>
      </div>
      <div className="pl-8 pr-6 pb-5 pt-2.5 border-b border-[#D9D9E0]/50">
        <div className="text-sm font-medium pt-2 pb-6">Risk</div>
        <div className="mb-1.5">
          <FormControl isInvalid={!!errors.risk && touched.risk}>
            <RadioGroup
              options={[
                { value: "HIGH", name: "High" },
                { value: "LOW", name: "Low" },
              ]}
              value={values.risk}
              onChange={(value) => setFieldValue("risk", value)}
              name={"risk"}
            />
            <FormErrorMessage className="!mt-3">
              Bid Configuration Risk is required
            </FormErrorMessage>
          </FormControl>
        </div>
      </div>
      <div className="pl-8 pr-6 pb-7 pt-2.5 border-b border-[#D9D9E0]/50">
        <div className="text-sm font-medium pt-2 pb-3.5">Bid Range/Spread</div>
        <div className="flex items-center">
          <div className="w-[60px]">
            <NumberField
              value={values.rangeSpread}
              onChange={(value) => setFieldValue("rangeSpread", value)}
              name={"rangeSpread"}
              allowMouseWheel
              min={0}
            />
          </div>
          <Text as={"p"} className="ml-3 font-medium text-[#EBEBEB]">
            %
          </Text>
        </div>
      </div>
      <div className="pl-8 pr-6 pb-7 pt-2.5 border-b border-[#D9D9E0]/50">
        <div className="text-sm font-medium pt-2 pb-3.5">
          Initial Bidding Threshold
        </div>
        <div className="w-[90px]">
          <NumberField
            value={values.adjustments.initialBiddingThreshold}
            onChange={(value) =>
              setFieldValue("adjustments.initialBiddingThreshold", value)
            }
            name={"adjustments.initialBiddingThreshold"}
            allowMouseWheel
            min={0}
          />
        </div>
      </div>
      <div className="pl-8 pr-6 pb-7 pt-2.5 border-b border-[#D9D9E0]/50">
        <div className="text-sm font-medium pt-2 pb-3.5">Bidding Tier</div>
        <div className="w-2/3">
          <FormControl
            isInvalid={
              !!errors?.adjustments?.biddingTier &&
              touched?.adjustments?.biddingTier
            }
          >
            <SelectField
              placeholder={"Select Bidding Tier"}
              className="!h-11 text-[#ebebeb99]"
              iconColor={"#ebebeb99"}
              value={values.adjustments.biddingTier}
              onChange={handleChange}
              name={"adjustments.biddingTier"}
              options={[
                { value: "10", name: "10" },
                { value: "5", name: "5" },
              ]}
            />
            <FormErrorMessage className="!mt-3">
              Bidding Tier is required
            </FormErrorMessage>
          </FormControl>
        </div>
      </div>
      <div className="pl-8 pr-6 pb-7 pt-2.5 border-b border-[#D9D9E0]/50">
        <div className="text-sm font-medium pt-2 pb-3.5">Time Window</div>
        <div className="flex items-center">
          <div className="w-[90px]">
            <NumberField
              value={values.adjustments.timeWindow}
              onChange={(value) =>
                setFieldValue("adjustments.timeWindow", value)
              }
              name={"adjustments.timeWindow"}
              allowMouseWheel
              min={0}
            />
          </div>
          <Text as={"p"} className="ml-4 font-medium text-[#EBEBEB]">
            Min
          </Text>
        </div>
      </div>
      <div className="pl-8 pr-6 pb-7 pt-2.5 border-b border-[#D9D9E0]/50">
        <div className="text-sm font-medium pt-2 pb-3.5">Loss Guardrail</div>
        <div className="w-[90px]">
          <NumberField
            value={values.adjustments.minNoLastLossDecrement}
            onChange={(value) =>
              setFieldValue("adjustments.minNoLastLossDecrement", value)
            }
            name={"adjustments.minNoLastLossDecrement"}
            allowMouseWheel
            min={0}
          />
        </div>
      </div>
      <div className="pl-8 pr-6 pb-7 pt-2.5 border-b border-[#D9D9E0]/50">
        <div className="text-sm font-medium pt-2 pb-3.5">Win Guardrail</div>
        <div className="w-[90px]">
          <NumberField
            value={values.adjustments.minNoLastWonIncrement}
            onChange={(value) =>
              setFieldValue("adjustments.minNoLastWonIncrement", value)
            }
            name={"adjustments.minNoLastWonIncrement"}
            allowMouseWheel
            min={0}
          />
        </div>
      </div>
      <div className="flex my-8 py-4 justify-center gap-4">
        <Button
          context="text"
          className="!px-6 py-[18px] !h-[56px] !rounded text-[14px] !text-white font-medium hover:!rounded"
          onClick={handleCloseConfigurationBar}
        >
          Cancel
        </Button>
        <Button
          className="!px-6 py-[18px] !gap-0 !h-[56px] !rounded text-[14px] !text-white font-semibold hover:!rounded after:!rounded"
          onClick={handleSubmit}
          type="submit"
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default BidConfigurationPanel;

BidConfigurationPanel.defaultProps = {
  bidConfigValue: null,
  setBidConfigs: () => {},
  getBidConfigs: () => {},
  handleCloseConfigurationBar: () => {},
};

BidConfigurationPanel.propTypes = {
  bidConfigValue: PropTypes.any,
  setBidConfigs: PropTypes.func,
  getBidConfigs: PropTypes.func,
  handleCloseConfigurationBar: PropTypes.func,
};
