import React, { useCallback, useEffect, useMemo, useState } from "react";
import { components } from "react-select";
import { Box, Text, Divider, Spinner } from "@chakra-ui/react";

import CurrentDate from "../interfaces/Dashboard/CurrentDate";
import { MultiSelect, ShipperWhatIfScenario, Checkbox } from "../interfaces";
import {
  getShipperAddition,
  getShipperAddLaneMap,
  getShipperAddImpact,
  getShipperAddInsights,
} from "../services";
import { Button } from "../componentLibrary";

const checkedBoxStyle = {
  "& .chakra-checkbox__control": {
    background: "#2294FF",
    border: "0 !important",
  },
  "& .chakra-checkbox__control svg": {
    stroke: "#EBEBEB !important",
  },
};

const Menu = ({ children, onSubmit, ...props }) => {
  return (
    <components.Menu {...props}>
      {children}
      <div className="p-2 flex flex-col items-center gap-[10px]">
        <Divider className="my-2 !border-b-2" />
        <Button
          context={props.getValue().length === 0 ? "outlined" : "primary"}
          className="!w-max !px-4 !h-10 !text-sm !font-medium"
          onClick={onSubmit}
          disabled={props.getValue().length === 0}
        >
          Apply
        </Button>
      </div>
    </components.Menu>
  );
};

const ValueContainer = ({ children, ...props }) => {
  let [values, input] = children;

  const isAllSelected = props.selectProps.value.find(
    (option) => option.value === "all"
  );

  return (
    <components.ValueContainer {...props}>
      {isAllSelected
        ? isAllSelected.label
        : props.selectProps.value?.length > 5
          ? `${props.selectProps.value?.length} Shipper Selected`
          : values}
      {input}
    </components.ValueContainer>
  );
};

const ShipperAddition = () => {
  const [isOpenMenu, setIsOpenMenu] = useState({
    baseConfig: false,
    newPld: false,
    newShippers: false,
  });

  const [shipperConfig, setShipperConfig] = useState({
    baseConfig: [],
    newPld: [],
    newShippers: [],
  });
  const [selectedShipper, setSelectedShipper] = useState({
    baseConfig: [],
    newPld: [],
    newShippers: [],
  });

  const [shippers, setShippers] = useState({
    baseConfig: [],
    newPld: [],
    newShippers: [],
  });
  const [isLoadingShippers, setIsLoadingShippers] = useState(true);

  const [isLoadingResult, setIsLoadingResult] = useState(false);
  const [isResultDisabled, setIsResultDisabled] = useState(true);
  const [analysisResult, setAnalysisResult] = useState(null);
  const [filteredNewShippers, setFilteredNewShippers] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const shippers = await getShipperAddition();

        const baseConfigOption = ["All Shippers", ...shippers[0].value].map(
          (shipper) => ({
            value: shipper === "All Shippers" ? "all" : shipper,
            label: shipper,
          })
        );

        const newShipperOptions = ["All Shippers", ...shippers[1].value].map(
          (shipper) => ({
            value: shipper === "All Shippers" ? "all" : shipper,
            label: shipper,
          })
        );

        const newPld = shippers[1].value.map((shipper) => ({
          value: shipper,
          label: shipper,
        }));

        setShippers({
          baseConfig: baseConfigOption,
          newPld,
          newShippers: newShipperOptions,
        });

        setFilteredNewShippers(newPld);

        setShipperConfig((config) => ({
          ...config,
          newShippers: newShipperOptions,
        }));
        setSelectedShipper((shippers) => ({
          ...shippers,
          newShippers: newShipperOptions,
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoadingShippers(false);
      }
    })();
  }, []);

  const isDisabledShipperConfig = useMemo(
    () => !isLoadingResult && analysisResult,
    [isLoadingResult, analysisResult]
  );

  const handleOpenMenu = useCallback((key) => {
    setIsOpenMenu((menus) => ({ ...menus, [key]: true }));
  }, []);

  const handleCloseMenu = useCallback(
    (key, isApplyButtonClicked) => {
      if (!isApplyButtonClicked) {
        setShipperConfig((config) => ({
          ...config,
          [key]: selectedShipper[key],
        }));
      }
      setIsOpenMenu((menus) => ({ ...menus, [key]: false }));
    },
    [selectedShipper]
  );

  const handleShipperChange = useCallback((data, event, key, opt, label) => {
    const isAllOptSelected = event.option.value === "all";
    if (event.action === "select-option") {
      if (isAllOptSelected) {
        setShipperConfig((config) => ({ ...config, [key]: opt }));
      } else {
        if (opt && opt.length - 1 === data.length) {
          setShipperConfig((config) => ({
            ...config,
            [key]: [{ value: "all", label }, ...data],
          }));
        } else {
          setShipperConfig((config) => ({ ...config, [key]: data }));
        }
      }
    } else {
      if (isAllOptSelected) {
        setShipperConfig((config) => ({ ...config, [key]: [] }));
      } else {
        const clonedData = JSON.parse(JSON.stringify(data));
        if (clonedData.find((option) => option.value === "all")) {
          clonedData.splice(0, 1);
        }
        setShipperConfig((config) => ({ ...config, [key]: clonedData }));
      }
    }
  }, []);

  const handleSubmitShippers = useCallback(
    (key) => {
      setSelectedShipper((selectedShippers) => {
        if (
          shipperConfig.baseConfig.length === shippers.baseConfig.length ||
          shipperConfig.newPld.length > 0
        ) {
          setIsResultDisabled(false);
        } else {
          setIsResultDisabled(true);
        }

        if (
          key === "baseConfig" &&
          shipperConfig.baseConfig.length === shippers.baseConfig.length
        ) {
          setShipperConfig((config) => ({ ...config, newPld: [] }));
          return { ...selectedShippers, [key]: shipperConfig[key], newPld: [] };
        }

        return {
          ...selectedShippers,
          [key]: shipperConfig[key],
        };
      });

      if (key === "baseConfig") {
        setFilteredNewShippers(() => {
          const selectedShipper = shipperConfig.baseConfig.map(
            (shipper) => shipper.value
          );

          return shippers.newPld.filter(
            (shipper) => !selectedShipper.includes(shipper.value)
          );
        });
      }

      handleCloseMenu(key, true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shipperConfig]
  );

  const handleViewResults = useCallback(async () => {
    setIsLoadingResult(true);
    try {
      const coverageMapPayload = [
        {
          label: "Base configurations",
          value: selectedShipper.baseConfig
            .map((shipper) => shipper.value)
            .filter((shipper) => shipper !== "all"),
        },
        {
          label: "New shippers",
          value: selectedShipper.newPld.map((pld) => pld.value),
        },
      ];
      const laneMap = await getShipperAddLaneMap(coverageMapPayload);
      const impact = await getShipperAddImpact(coverageMapPayload);
      const insights = await getShipperAddInsights(coverageMapPayload);

      setAnalysisResult({
        laneMap,
        impact,
        insights,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoadingResult(false);
      setIsResultDisabled(true);
    }
  }, [selectedShipper]);

  if (isLoadingShippers) {
    return (
      <Box display="flex" justifyContent="center" my={"10em"}>
        <Spinner />
      </Box>
    );
  }

  return (
    <div className="flex-grow p-5 ml-2 mt-2">
      <CurrentDate className={"font-medium"} dateFormat={"d MMM yyyy HH:mm"} />

      <Text className="mt-11 text-lg font-semibold text-[#F9F9FF]">
        What-if Analysis When a New Shipper is Added to the Orchestro Network
      </Text>

      <Box
        marginTop={3}
        display={"flex"}
        flexWrap={"wrap"}
        alignItems={"flex-end"}
        className="gap-x-6 xl:gap-x-10"
      >
        <div className="mb-3">
          <Text
            className={`pb-[10px] text-sm font-medium tracking-[0.28px] ${isDisabledShipperConfig ? "text-[#EBEBEB99]" : "text-[#EBEBEB]"}`}
          >
            Existing Shippers - Base Configuration
          </Text>

          <div className="w-[250px] md:w-[300px] lg:w-[340px] relative">
            <MultiSelect
              placeholder={"Select onboarded shippers"}
              options={shippers.baseConfig}
              components={{
                Menu: (props) => (
                  <Menu
                    {...props}
                    onSubmit={() => handleSubmitShippers("baseConfig")}
                  />
                ),
                ValueContainer,
              }}
              value={shipperConfig.baseConfig}
              onChange={(data, action) => {
                handleShipperChange(
                  data,
                  action,
                  "baseConfig",
                  shippers.baseConfig,
                  "All Shippers"
                );
              }}
              onMenuClose={() => handleCloseMenu("baseConfig")}
              onMenuOpen={() => handleOpenMenu("baseConfig")}
              menuIsOpen={isOpenMenu.baseConfig}
            />
          </div>
        </div>

        <div className="mb-3">
          <Text
            className={`pb-[10px] text-sm font-medium tracking-[0.28px] ${isDisabledShipperConfig ? "text-[#EBEBEB99]" : "text-[#EBEBEB]"}`}
          >
            New Shipper
          </Text>

          <div className="w-[250px] md:w-[300px] lg:w-[340px] relative">
            <MultiSelect
              placeholder={"Select new shipper"}
              options={filteredNewShippers}
              components={{
                Menu: (props) => (
                  <Menu
                    {...props}
                    onSubmit={() => handleSubmitShippers("newPld")}
                  />
                ),
                ValueContainer,
              }}
              value={shipperConfig.newPld}
              onChange={(data, action) => {
                handleShipperChange(data, action, "newPld");
              }}
              onMenuClose={() => handleCloseMenu("newPld")}
              onMenuOpen={() => handleOpenMenu("newPld")}
              menuIsOpen={isOpenMenu.newPld}
            />
          </div>
        </div>

        {/* {analysisResult && (
          <div className="mb-3">
            <Text className="pb-[10px] text-sm font-medium tracking-[0.28px] text-[#EBEBEB]">
              Shipper
            </Text>

            <div className="w-[250px] md:w-[300px] lg:w-[340px] relative">
              <MultiSelect
                placeholder={"Select shippers"}
                options={shippers.newShippers}
                components={{
                  Menu: (props) => (
                    <Menu
                      {...props}
                      onSubmit={() => handleSubmitShippers("newShippers")}
                    />
                  ),
                  ValueContainer,
                }}
                value={shipperConfig.newShippers}
                onChange={(data, action) => {
                  handleShipperChange(
                    data,
                    action,
                    "newShippers",
                    shippers.newShippers,
                    "All Shippers"
                  );
                }}
                onMenuClose={() => handleCloseMenu("newShippers")}
                onMenuOpen={() => handleOpenMenu("newShippers")}
                menuIsOpen={isOpenMenu.newShippers}
              />
            </div>
          </div>
        )} */}

        <div className="mb-3">
          <Button
            context={!isResultDisabled ? "primary" : "outlined"}
            className="!px-5 !h-[44px] text-sm font-medium"
            disabled={isResultDisabled}
            onClick={handleViewResults}
          >
            View Results
          </Button>
        </div>
      </Box>

      {isLoadingResult ? (
        <Box display={"flex"} justifyContent={"center"} mt={52}>
          <Spinner />
        </Box>
      ) : (
        analysisResult && (
          <ShipperWhatIfScenario analysisResult={analysisResult} />
        )
      )}
    </div>
  );
};

export default ShipperAddition;
