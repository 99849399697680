import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Text,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import { AccountTabs, Page, TableLoading, UserContext } from "../interfaces";
import { getSurcharges } from "../services";
import { CARRIER_CODES, SHIPPER_CODES } from "../constants";

const Surcharges = () => {
  const [surcharges, setSurcharges] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useContext(UserContext);

  const isCarrierRole = useMemo(
    () =>
      [
        ...CARRIER_CODES,
        ...SHIPPER_CODES.filter((code) => CARRIER_CODES.includes(code)),
      ].some((carrierRole) => user?.Groups.includes(carrierRole)),
    [user]
  );

  useEffect(() => {
    const carrierCode = user.Groups.find((group) =>
      [...CARRIER_CODES, ...SHIPPER_CODES].includes(group)
    );

    if (!carrierCode) return;

    const fetchData = async () => {
      setIsLoading(true);

      const data = await getSurcharges(carrierCode);
      setIsLoading(false);
      setSurcharges(data.surcharges);
    };

    fetchData().catch(console.error);
  }, [user]);

  return (
    <>
      <AccountTabs />
      <Page className={"!min-h-0"}>
        <h2 className="text-xl my-5">
          {isCarrierRole ? "Surcharges" : "Orchestro Surcharges"}
        </h2>
        <TableContainer className="max-h-[calc(100vh_-_220px)] !overflow-y-auto w-full mb-4">
          <Table>
            <Thead>
              <Tr>
                <Th>Code</Th>
                <Th>Description</Th>
                <Th>Rate</Th>
                {isCarrierRole && (
                  <>
                    <Th>Network Surcharge Code</Th>
                    <Th>Network Surcharge Description</Th>
                    <Th>Network Rate</Th>
                  </>
                )}
              </Tr>
            </Thead>
            <Tbody>
              {isLoading && <TableLoading data={surcharges} />}
              {!isLoading && surcharges.length === 0 && (
                <Tr>
                  <Td colSpan="100">No data found</Td>
                </Tr>
              )}
              {surcharges &&
                surcharges.map((surcharge, i) => (
                  <Tr key={i + "-" + surcharge.code}>
                    <Td fontWeight="bold">{surcharge?.code}</Td>
                    <Td>{surcharge?.description}</Td>
                    <Td fontWeight="bold">
                      {surcharge?.rate === 0 ? "N/A" : `$${surcharge?.rate}`}
                    </Td>
                    {isCarrierRole && (
                      <>
                        <Td>{surcharge?.networkSurchargeCode}</Td>
                        <Td>{surcharge?.networkSurchargeDescription}</Td>
                        <Td>
                          {surcharge?.networkRate === 0 ? (
                            "N/A"
                          ) : (
                            <Text fontWeight="bold">
                              ${surcharge?.networkRate}
                            </Text>
                          )}
                        </Td>
                      </>
                    )}
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Page>
    </>
  );
};

export default Surcharges;
