import React, { useState, useEffect, useContext } from "react";
import {
  Alert,
  Card,
  Flex,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Box,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";

import { AccountTabs, Page, UserContext } from "../interfaces";
import { CARRIER_CODES, SHIPPER_CODES } from "../constants";
import { getCarrierInfo } from "../services";

const AccountInfo = () => {
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);

  // Update the state structure to accommodate the API response
  const [carrierInfo, setCarrierInfo] = useState();

  useEffect(() => {
    const userTypeCode = user.Groups.find((group) =>
      [...CARRIER_CODES, ...SHIPPER_CODES].includes(group)
    );

    if (!userTypeCode) return;

    setIsLoading(true);
    // Make the API call to fetch carrier information
    getCarrierInfo(userTypeCode)
      .then((data) => {
        setCarrierInfo(data); // Update the state with the API response
      })
      .catch((error) => console.error("Error fetching carrier info:", error))
      .finally(() => setIsLoading(false));
  }, [user]);

  return (
    <>
      <AccountTabs />
      <Page>
        <h2 className="text-xl my-5">
          Information
        </h2>

        <Alert status="info" mb={8}>
          <EditIcon />
          <Text>
            To edit information please contact{" "}
            <a href="mailto:carrier.integration@orchestro.ai">
              carrier.integration@orchestro.ai
            </a>
          </Text>
        </Alert>

        {isLoading ? (
          <Box display="flex" justifyContent="center" my={"10em"}>
            <Spinner />
          </Box>
        ) : !carrierInfo ? (
          <Text>No account information</Text>
        ) : (
          <Card
            mb={7}
            borderRadius="20px"
            borderWidth="1px"
            borderColor="rgba(133, 134, 152, 0.2)"
            p={8}
          >
            <Heading size="md">General</Heading>
            <Flex gap={7} my={5}>
              <FormControl>
                <FormLabel fontSize={"sm"}>Carrier Name</FormLabel>
                <Input isDisabled value={carrierInfo.name ?? ""} />
              </FormControl>
              <FormControl>
                <FormLabel fontSize={"sm"}>Carrier Account Number</FormLabel>
                <Input isDisabled value={carrierInfo.accountNumber ?? ""} />
              </FormControl>
            </Flex>
            <Flex gap={7} my={5}>
              <FormControl>
                <FormLabel fontSize={"sm"}>Address</FormLabel>
                <Input isDisabled value={carrierInfo.address.address1 ?? ""} />
              </FormControl>
              <FormControl>
                <FormLabel fontSize={"sm"}>Account Status</FormLabel>
                <Input isDisabled value={carrierInfo.status ?? ""} />
              </FormControl>
            </Flex>
            <Flex gap={7} my={5} width={"calc(50% - 1rem)"}>
              <FormControl>
                <FormLabel fontSize={"sm"}>Zip Code</FormLabel>
                <Input isDisabled value={carrierInfo.address.zip ?? ""} />
              </FormControl>
              <FormControl>
                <FormLabel fontSize={"sm"}>City</FormLabel>
                <Input isDisabled value={carrierInfo.address.city ?? ""} />
              </FormControl>
            </Flex>
            <Flex gap={7} my={5} width={"calc(50% - 1rem)"}>
              <FormControl>
                <FormLabel fontSize={"sm"}>State</FormLabel>
                <Input isDisabled value={carrierInfo.address.state ?? ""} />
              </FormControl>
              <FormControl>
                <FormLabel fontSize={"sm"}>Country</FormLabel>
                <Input isDisabled value={carrierInfo.address.country ?? ""} />
              </FormControl>
            </Flex>
          </Card>
        )}

        {carrierInfo && carrierInfo.contacts.length > 0 && (
          <Card
            mb={7}
            borderRadius="20px"
            borderWidth="1px"
            borderColor="rgba(133, 134, 152, 0.2)"
            p={8}
          >
            <Heading as={"h2"} size={"md"}>
              Contact Information
            </Heading>
            {carrierInfo.contacts.map((contact, index) => (
              contact.primary && <ContactFields key={index} contact={contact} title="Primary Contact" />
            ))}
            {carrierInfo.contacts.map((contact, index) => (
              !contact.primary && <ContactFields key={index} contact={contact} title="Secondary Contact" />
            ))}
          </Card>
        )}
      </Page>
    </>
  );
};

function ContactFields({ contact }) {
  return (
    <Flex gap={7} my={5}>
      <FormControl>
        <FormLabel fontSize={"sm"}>
          {contact.primary ? "Primary Contact" : "Secondary Contact"}
        </FormLabel>
        <Input isDisabled value={contact.name ?? ""} />
      </FormControl>
      <FormControl width={"75%"}>
        <FormLabel fontSize={"sm"}>Designation</FormLabel>
        <Input isDisabled value={contact.designation ?? ""} />
      </FormControl>
      <FormControl width={"75%"}>
        <FormLabel fontSize={"sm"}>Phone Number</FormLabel>
        <Input isDisabled value={contact.phone ?? ""} />
      </FormControl>
      <FormControl>
        <FormLabel fontSize={"sm"}>Email</FormLabel>
        <Input isDisabled value={contact.email ?? ""} />
      </FormControl>
    </Flex>
  )
}


export default AccountInfo;
