import React, { useCallback, useEffect, useState } from "react";
import { Heading } from "@chakra-ui/react";
import Button from "../componentLibrary/components/Button";
import { BaseDrawer } from "../interfaces";
import CurrentDate from "../interfaces/Dashboard/CurrentDate";
import { PlusIcon } from "../interfaces/CustomIcons";
import {
  BidConfigurationPanel,
  BidConfigurationTable,
} from "../interfaces/BidConfiguration";
import { getBidConfigDetails } from "../services";

const fetchMaxParcels = 2000; // Initial fetch for client side pagination,

const BidConfiguration = () => {
  const [isOpenConfigurationBar, setIsOpenConfigurationBar] = useState(false);
  const [bidConfigs, setBidConfigs] = useState([]);
  const [bidConfigsLoading, setBidConfigsLoading] = useState(true);
  const [bidConfigValue, setBidConfigValue] = useState(null);

  const getBidConfigs = useCallback(async () => {
    try {
      const configs = await getBidConfigDetails(fetchMaxParcels, 0);
      setBidConfigs(configs);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setBidConfigsLoading(false);
  }, []);

  useEffect(() => {
    getBidConfigs();
  }, [getBidConfigs]);

  const handleOpenConfigurationBar = useCallback(() => {
    setIsOpenConfigurationBar(true);
  }, []);

  const handleCloseConfigurationBar = useCallback(() => {
    setIsOpenConfigurationBar(false);
    setBidConfigValue(null);
  }, []);

  return (
    <div className="p-5 ml-2 mt-2">
      <div className="flex justify-between items-center font-medium">
        <CurrentDate dateFormat={"d MMM yyyy HH:mm"} />
        <Button
          context="outlined"
          className="text-sm [&>svg]:!w-[20px] [&>svg]:!h-[20px]"
          prefixIcon={<PlusIcon />}
          onClick={handleOpenConfigurationBar}
        >
          New Configuration
        </Button>
      </div>
      <Heading
        fontFamily={"Montserrat"}
        fontSize={24}
        fontWeight={400}
        marginTop={4}
      >
        Bid Configuration List
      </Heading>
      <BidConfigurationTable
        bidConfigs={bidConfigs}
        handleOpenConfigurationBar={handleOpenConfigurationBar}
        setBidConfigValue={setBidConfigValue}
        bidConfigsLoading={bidConfigsLoading}
        getBidConfigs={getBidConfigs}
      />
      <BaseDrawer
        open={isOpenConfigurationBar}
        onClose={handleCloseConfigurationBar}
        drawerHeader={
          bidConfigValue ? "Manage Configuration" : "Create Configuration"
        }
        closeIcon={<img alt="close filters" src="/images/close-filter.svg" />}
        variant={"deepNavyBlue"}
        size={"sm"}
        drawerHeaderClasses={
          "!mt-4 [&>button]:!translate-y-full font-bold !pb-5"
        }
        drawerBodyClasses={"!p-0 !overflow-visible"}
      >
        <BidConfigurationPanel
          bidConfigValue={bidConfigValue}
          setBidConfigs={setBidConfigs}
          getBidConfigs={getBidConfigs}
          handleCloseConfigurationBar={handleCloseConfigurationBar}
        />
      </BaseDrawer>
    </div>
  );
};

export default BidConfiguration;
