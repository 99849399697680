import React from "react";
import PropTypes from "prop-types";
import { Checkbox } from "../InputFields";
import { DASHBOARD_FILTER_GROUP } from "../../constants";
import styles from "./FilterPanel.module.css";

const checkedBoxStyle = {
  "& .chakra-checkbox__control": {
    background: "linear-gradient(90deg, #3BA0E6 0%, #3B6EF3 100%) !important",
    border: 0,
  },
};

const DashboardFilterPanel = ({ handleCheckboxChange }) => {
  return (
    <>
      <div className="pl-8 pt-7 pb-4 border-b border-[#D9D9E0]/50">
        <div className={styles.subFilterTitle}>
          {DASHBOARD_FILTER_GROUP?.carriers?.label}
        </div>
        <div className="flex py-4 flex-wrap">
          {DASHBOARD_FILTER_GROUP?.carriers?.filters?.length > 0 &&
            DASHBOARD_FILTER_GROUP?.carriers?.filters?.map((filter, index) => (
              <div className="w-1/2" key={index}>
                <Checkbox
                  label={filter?.label}
                  name={filter?.name}
                  onChange={handleCheckboxChange}
                  _checked={checkedBoxStyle}
                />
              </div>
            ))}
        </div>
      </div>
      <div className="pl-8 pt-7 pb-4 mb-14 border-b border-[#D9D9E0]/50">
        <div className={styles.subFilterTitle}>
          {DASHBOARD_FILTER_GROUP?.shippers?.label}
        </div>
        <div className="flex py-4 flex-wrap">
          {DASHBOARD_FILTER_GROUP?.shippers?.filters?.length > 0 &&
            DASHBOARD_FILTER_GROUP?.shippers?.filters?.map((filter, index) => (
              <div className="w-1/2" key={index}>
                <Checkbox
                  label={filter?.label}
                  name={filter?.name}
                  onChange={handleCheckboxChange}
                  _checked={checkedBoxStyle}
                />
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default DashboardFilterPanel;

DashboardFilterPanel.defaultProps = {
  handleCheckboxChange: () => {},
};

DashboardFilterPanel.propTypes = {
  handleCheckboxChange: PropTypes.func,
};
