export const CARRIER_CODES = [
	"ACI",
	"ADMIN",
	"BT",
	"CDL",
	"DHL",
	"HFY",
	"OPTIMA",
	"PGS",
	"PL",
	"QD",
	"UPSMI",
	"UPSSP",
];

export const SHIPPER_CODES = [
	"ASA",
	"ASB",
	"ASD",
	"ASE",
	"CR",
	"GPS",
	"ORCHESTRO",
	"SPL",
	"ST",
];

export const FILTER_PATHS = ["/app/dashboard"];
export const DASHBOARD_FILTER_GROUP = {
	carriers: {
		label: "Carriers",
		filters: [
			{ label: "All", name: "allcarriers" },
			{ label: "Hallify", name: "hallify" },
			{ label: "CDL", name: "cdl" },
			{ label: "UPS", name: "ups" },
			{ label: "Optima", name: "optima" },
			{ label: "GLS", name: "gls" },
		],
	},
	shippers: {
		label: "Shippers",
		filters: [
			{ label: "All", name: "allshippers" },
			{ label: "Old", name: "oldnavy" },
			{ label: "GAP", name: "gap" },
			{ label: "Macy's", name: "macys" },
			{ label: "Target", name: "target" },
			{ label: "Costco", name: "costco" },
		],
	},
};

// Move to .env if become env specific
export const GOOGLE_GEOCODE_API_KEY = "AIzaSyBCg9lTd_4No3kFh_D7OVjzHzJ__vgNzhE";
export const GOOGLE_MAPS_API_KEY = "AIzaSyBCg9lTd_4No3kFh_D7OVjzHzJ__vgNzhE";
export const MAPBOX_API_KEY =
	"pk.eyJ1IjoicGF1bGZvcmFpIiwiYSI6ImNsdjJucnFxaDBqODgybWxod2FwNTc3YWMifQ.KPtovxb_Mb1uLOgLlL4x_Q";
