import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { GoogleMap } from "@react-google-maps/api";

const featureStyleOptions = {
  strokeColor: "#810FCB",
  strokeOpacity: 1.0,
  strokeWeight: 3.0,
  fillColor: "#810FCB",
  fillOpacity: 0.5,
};

const Map = ({ data, height, zoom, legends, filters }) => {
  const containerStyle = {
    width: "100%",
    height: height,
  };

  const [center, setCenter] = useState({ lat: 38.907, lng: -100.04 });
  const [map, setMap] = useState(null);
  const [filteredData, setFilteredData] = useState(data);
  const [selectedLegends, setSelectedLegends] = useState([]);
  const [filteredLegends, setFilteredLegends] = useState(data);

  useEffect(() => {
    if (legends?.value?.length > 0) {
      setSelectedLegends(
        legends.value.map((legend) => ({ ...legend, isChecked: true }))
      );
    }
  }, [legends]);

  useEffect(() => {
    setFilteredData(data);
    setFilteredLegends(data);
  }, [data]);

  useEffect(() => {
    if (selectedLegends?.length > 0) {
      setFilteredLegends(() => {
        return data.filter(({ color }) =>
          selectedLegends
            .filter((legend) => legend.isChecked)
            .some((filter) => filter.color === color)
        );
      });
    }
  }, [selectedLegends, data]);

  useEffect(() => {
    if (filters) {
      const allFiltersChecked = Object.values(filters).every(
        (option) => option.isChecked
      );
      const allFiltersUnchecked = Object.values(filters).every(
        (option) => !option.isChecked
      );

      if (allFiltersChecked) {
        setFilteredData(filteredLegends);
      } else if (allFiltersUnchecked) {
        setFilteredData([]);
      } else {
        setFilteredData((prevMapData) => {
          const filteredValues =
            prevMapData.length > 0 ? prevMapData : filteredLegends;

          const filteredData = filteredValues.filter((value) =>
            Object.values(filters)
              .filter((option) => option.isChecked)
              .some((filter) => filter.color === value.color)
          );

          return filteredData;
        });
      }
    }
  }, [filters, filteredLegends]);

  useEffect(() => {
    if (map) {
      const featureLayer = map.getFeatureLayer("POSTAL_CODE");

      if (filteredData.length > 0) {
        const placeIds = filteredData.map(({ place_id }) => place_id);

        setCenter(filteredData[0]?.coords);

        featureLayer.style = (options) => {
          if (placeIds.includes(options.feature.placeId)) {
            const { color } = filteredData.find(
              (data) => data.place_id === options.feature.placeId
            );

            return {
              ...featureStyleOptions,
              strokeColor: color,
              fillColor: color,
            };
          }
        };
      } else {
        featureLayer.style = null;
      }
    }
  }, [map, filteredData]);

  const handleMapLoad = (map) => {
    setMap(map);
  };

  const handleClickLegend = (label, isChecked) => {
    setSelectedLegends((legends) =>
      legends.map((legend) => {
        if (legend.label === label) {
          return { ...legend, isChecked: !isChecked };
        } else {
          return legend;
        }
      })
    );
  };

  return (
    <div style={{ borderRadius: "8px", overflow: "hidden" }}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={zoom}
        options={{
          streetViewControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
          zoomControl: false,
          mapId: "5e49786736515708",
        }}
        onLoad={handleMapLoad}
      >
        {selectedLegends && selectedLegends.length > 0 && (
          <div className="absolute bottom-8 left-[18px] bg-[#191a23bf] py-3 pl-4 pr-10 rounded-lg">
            <div className="text-sm font-bold mb-1">{legends.title}</div>
            {selectedLegends.map(({ color, label, isChecked }, i) => (
              <div
                className="flex items-center gap-3 mt-2"
                key={i}
                onClick={() => handleClickLegend(label, isChecked)}
              >
                <div
                  className="h-[17px] w-5"
                  style={{ backgroundColor: color }}
                />
                <div className="text-xs font-bold">{label}</div>
              </div>
            ))}
          </div>
        )}
      </GoogleMap>
    </div>
  );
};

export default Map;

Map.defaultProps = {
  data: {},
  height: "500px",
  zoom: 4,
  legends: {},
  filters: {},
};

Map.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  height: PropTypes.string,
  zoom: PropTypes.number,
  legends: PropTypes.object,
  filters: PropTypes.object,
};
