function getTimeDifference(timestamp) {
  const now = new Date();
  const givenDate = new Date(timestamp);

  const diffInMs = now - givenDate;

  const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));

  return {
    days,
    hours,
    minutes,
  };
}

export default getTimeDifference;
