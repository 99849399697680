import React from "react";
import { createRoot } from "react-dom/client";
import { ColorModeScript, ChakraProvider } from "@chakra-ui/react";
import { Security } from "@okta/okta-react";
import { OktaAuth } from "@okta/okta-auth-js";
import "./index.css";
import App from "./App";
import theme from "./chakraTheme/theme";
import { UserProvider } from "./interfaces";

import { Provider } from "react-redux";
import store from "./interfaces/redux/store/store";

const issuer = process.env.REACT_APP_OKTA_ISSUER;
const clientId = process.env.REACT_APP_OKTA_CLIENT_ID;
const redirectUri = `${process.env.REACT_APP_ORCHESTRO_BASE_URL}${process.env.REACT_APP_CONTEXT_URI}/app${process.env.REACT_APP_OKTA_REDIRECT_URI}`;

const oktaAuth = new OktaAuth({
  issuer: issuer,
  clientId: clientId,
  redirectUri: redirectUri,
  pkce: process.env.REACT_APP_OKTA_PKCE === "true", // Assuming it's a boolean
  scopes: ["openid", "email", "profile", "Groups"], // Add additional scopes here
});

async function restoreOriginalUri(oktaAuth, originalUri) {
  oktaAuth.setOriginalUri(originalUri);
}

const root = createRoot(document.getElementById("root"));

root.render(
  <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
    <Provider store={store}>
      <UserProvider>
        <ChakraProvider theme={theme}>
          {/* <ColorModeScript initialColorMode={theme.config.initialColorMode} /> */}
          <ColorModeScript initialColorMode="dark" />
          <App />
        </ChakraProvider>
      </UserProvider>
    </Provider>
  </Security>
);
