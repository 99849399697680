import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Grid, GridItem, Spinner } from "@chakra-ui/react";
import { getLiveBidCountDetail } from "../services/apiServices";
import Button from "../componentLibrary/components/Button";
import Pod from "../interfaces/Dashboard/pod";
import CurrentDate from "../interfaces/Dashboard/CurrentDate";
import {
  BidPerformanceCharts,
  BidPerformanceTable,
} from "../interfaces/BidPerformance";

const BidPerformance = () => {
  const [liveBidDetail, setLiveBidDetail] = useState({});
  const [liveBidDetailLoading, setLiveBidDetailLoading] = useState(true);

  useEffect(() => {
    // fetch todays live bid details and last 30 days avg for comparision to get trend

    getLiveBidCountDetail()
      .then((response) => {
        setLiveBidDetail(response ?? {});
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLiveBidDetailLoading(false);
      });
  }, []);

  const bidDetailPods = useMemo(() => {
    if (!liveBidDetailLoading) {
      return [
        {
          label: "Today’s Bid Count",
          amount: liveBidDetail.bid_count,
        },
        {
          label: "Average Bids By Customers",
          amount: liveBidDetail.avg_bid_request_per_customer,
          trend:
            liveBidDetail.avg_bid_request_per_customer >=
            liveBidDetail.avg_30_days_bid_request_per_customer
              ? "up"
              : "down",
        },
        {
          label: "Daily Bids Won",
          amount: (
            (liveBidDetail.won_bid_count / liveBidDetail.bid_count) *
            100
          ).toFixed(2),
          type: "percent",
          trend:
            liveBidDetail.won_bid_count >= liveBidDetail.avg_30_days_bid_count
              ? "up"
              : "down",
          value: liveBidDetail.won_bid_count,
          total: liveBidDetail.bid_count,
        },
        {
          label: "Daily Bids Lost",
          amount: (
            (liveBidDetail.lost_bid_count / liveBidDetail.bid_count) *
            100
          ).toFixed(2),
          type: "percent",
          trend:
            liveBidDetail.lost_bid_count >=
            liveBidDetail.avg_30_days_lost_bid_count
              ? "up"
              : "down",
          value: liveBidDetail.lost_bid_count,
          total: liveBidDetail.bid_count,
        },
      ];
    }
  }, [liveBidDetail, liveBidDetailLoading]);

  return (
    <div className="p-5 ml-2 mt-2">
      {liveBidDetailLoading ? (
        <Box display="flex" justifyContent="center" my={"10em"}>
          <Spinner />
        </Box>
      ) : (
        <>
          <div className="flex justify-between items-center font-medium">
            <CurrentDate dateFormat={"d MMM yyyy HH:mm"} />
            <Link to="/app/bid-configuration">
              <Button context="outlined" className="text-sm">
                View Configuration
              </Button>
            </Link>
          </div>
          <Grid
            gap={4}
            marginTop={7}
            className="grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
          >
            {bidDetailPods.map((pod, index) => (
              <GridItem key={index}>
                <Pod pod={pod} />
              </GridItem>
            ))}
          </Grid>
          <BidPerformanceCharts />
          <BidPerformanceTable />
        </>
      )}
    </div>
  );
};

export default BidPerformance;
