import { useContext } from "react";
import { BrowserRouter, useRoutes, Navigate } from "react-router-dom";
import { LoginCallback, useOktaAuth } from "@okta/okta-react";
import { Box, Spinner } from "@chakra-ui/react";

import { RolesAuth as Only, UserContext } from "./interfaces";
import { useDarkMode } from "./hooks";
import { CARRIER_CODES, SHIPPER_CODES } from "./constants";

import { RateCardCalculator } from "./pages/RateCardCalculator";
import AccountInfo from "./pages/AccountInfo";
import BidConfiguration from "./pages/BidConfiguration";
import BidPerformance from "./pages/BidPerformance";
import CarrierAddition from "./pages/CarrierAddition";
import Containers from "./pages/Containers";
import Dashboard from "./pages/Dashboard";
import Demo from "./pages/Demo";
import DetailedTracking from "./pages/DetailedTracking";
import DispatchDetails from "./pages/DispatchDetails";
import Invoices from "./pages/Invoices";
import Layout from "./pages/Layout";
import LayoutDemo from "./pages/LayoutDemo";
import MiddleMileLanes from "./pages/MiddleMileLanes";
import MiddleMileOrders from "./pages/MiddleMileOrders";
import NetworkInsights from "./pages/NetworkInsights";
import NewMMO from "./pages/NewMMO";
import OrderEvents from "./pages/OrderEvents";
import PageNotFound from "./pages/PageNotFound";
import ParcelIntelligence from "./pages/ParcelIntelligence";
import PldCalculator from "./pages/PldCalculator";
import Preferences from "./pages/Preferences";
import Rates from "./pages/Rates";
import RateDiscovery from "./pages/RateDiscovery";
import SalesTools from "./pages/SalesTools";
import ServiceAttributes from "./pages/ServiceAttributes";
import ServiceTypes from "./pages/ServiceTypes";
import ShipmentDetails from "./pages/ShipmentDetails";
import Shipments from "./pages/Shipments";
import ShipperAddition from "./pages/ShipperAddition";
import SortCodes from "./pages/SortCodes";
import Surcharges from "./pages/Surcharges";
import TrackTrace from "./pages/TrackTrace";
import TrackingCodes from "./pages/TrackingCodes";
import UsCoverageMap from "./pages/UsCoverageMap";
import ZoneCoverage from "./pages/ZoneCoverage";
import AccountRequest from "./pages/AccountRequest";
import PublicTracking from "./pages/PublicTracking";

const baseURL = process.env.REACT_APP_ORCHESTRO_BASE_URL;
const appContext = process.env.REACT_APP_CONTEXT_URI;
console.log("baseURL:", baseURL);
console.log("appContext:", appContext);

const RoutesComponent = () => {
	const { isDemo } = useContext(UserContext);

	return useRoutes([
		{ path: "/", element: <Navigate to={"app"} /> },
		{ path: "/app/login", element: <Login /> },
		{ path: "/app/logout", element: <Logout /> },
		{ path: "/app/callback", element: <LoginCallback /> },
		{
			path: "/tracking",
			element: <PublicTracking />,
		},
		{
			path: "demo",
			element: (
				<SecureRoute>
					<LayoutDemo />
				</SecureRoute>
			),
			children: [
				{
					index: true,
					element: (
						<Only flags={[isDemo]} roles={["ADMIN"]}>
							<Demo />
						</Only>
					),
				},
				{
					path: "rate-discovery",
					children: [
						{
							index: true,
							element: <Navigate to={"start"} />,
						},
						{
							path: "start",
							element: (
								<Only flags={[isDemo]} roles={["ADMIN"]}>
									<RateDiscovery step={"start"} />
								</Only>
							),
						},
						{
							path: "step/1",
							element: (
								<Only flags={[isDemo]} roles={["ADMIN"]}>
									<RateDiscovery step={"1"} />
								</Only>
							),
						},
						{
							path: "step/2",
							element: (
								<Only flags={[isDemo]} roles={["ADMIN"]}>
									<RateDiscovery step={"2"} />
								</Only>
							),
						},
						{
							path: "step/3",
							element: (
								<Only flags={[isDemo]} roles={["ADMIN"]}>
									<RateDiscovery step={"3"} />
								</Only>
							),
						},
						{
							path: "step/4",
							element: (
								<Only flags={[isDemo]} roles={["ADMIN"]}>
									<RateDiscovery step={"4"} />
								</Only>
							),
						},
						{
							path: "step/5",
							element: (
								<Only flags={[isDemo]} roles={["ADMIN"]}>
									<RateDiscovery step={"5"} />
								</Only>
							),
						},
						{
							path: "step/6",
							element: (
								<Only flags={[isDemo]} roles={["ADMIN"]}>
									<RateDiscovery step={"6"} />
								</Only>
							),
						},
						{
							path: "step/7",
							element: (
								<Only flags={[isDemo]} roles={["ADMIN"]}>
									<RateDiscovery step={"7"} />
								</Only>
							),
						},
						{
							path: "step/8",
							element: (
								<Only flags={[isDemo]} roles={["ADMIN"]}>
									<RateDiscovery step={"8"} />
								</Only>
							),
						},
						{
							path: "step/9",
							element: (
								<Only flags={[isDemo]} roles={["ADMIN"]}>
									<RateDiscovery step={"9"} />
								</Only>
							),
						},
						{
							path: "step/10",
							element: (
								<Only flags={[isDemo]} roles={["ADMIN"]}>
									<RateDiscovery step={"10"} />
								</Only>
							),
						},
					],
				},
			],
		},
		{
			path: "/app",
			element: (
				<SecureRoute>
					<Layout />
				</SecureRoute>
			),
			children: [
				{
					index: true,
					element: <Navigate to={"dashboard"} />,
				},
				{
					path: "dashboard",
					element: (
						<Only roles={["ADMIN", ...CARRIER_CODES, ...SHIPPER_CODES]}>
							<Dashboard />
						</Only>
					),
				},
				{
					path: "containers",
					element: (
						<Only
							flags={[isDemo]}
							roles={["ADMIN", ...CARRIER_CODES, ...SHIPPER_CODES]}
						>
							<Containers />
						</Only>
					),
				},
				{
					path: "shipments",
					children: [
						{
							index: true,
							element: (
								<Only roles={["ADMIN", ...CARRIER_CODES, ...SHIPPER_CODES]}>
									<Shipments />
								</Only>
							),
						},
						{
							path: ":trackingNumber",
							element: (
								<Only roles={["ADMIN", ...CARRIER_CODES, ...SHIPPER_CODES]}>
									<ShipmentDetails />
								</Only>
							),
						},
						{
							path: "track-trace",
							element: (
								<Only roles={["ADMIN", ...CARRIER_CODES, ...SHIPPER_CODES]}>
									<TrackTrace />
								</Only>
							),
						},
					],
				},
				{
					path: "middle-mile-lanes",
					children: [
						{
							index: true,
							element: (
								<Only flags={[isDemo]} roles={["ADMIN"]}>
									<MiddleMileLanes />
								</Only>
							),
						},
						{
							path: ":selectedOpenOrder",
							element: (
								<Only flags={[isDemo]} roles={["ADMIN"]}>
									<DispatchDetails />
								</Only>
							),
						},
						{
							path: "new-order",
							element: (
								<Only flags={[isDemo]} roles={["ADMIN"]}>
									<NewMMO />
								</Only>
							),
						},
					],
				},
				{
					path: "middle-mile-orders",
					children: [
						{
							index: true,
							element: (
								<Only flags={[isDemo]} roles={["ADMIN"]}>
									<MiddleMileOrders />
								</Only>
							),
						},
						{
							path: ":orderId",
							element: (
								<Only flags={[isDemo]} roles={["ADMIN"]}>
									<OrderEvents />
								</Only>
							),
						},
						{
							path: ":trackingNumber/tracking",
							element: (
								<Only flags={[isDemo]} roles={["ADMIN"]}>
									<DetailedTracking />
								</Only>
							),
						},
					],
				},
				{
					path: "account",
					children: [
						{
							index: true,
							element: <Navigate to={"information"} />,
						},
						{
							path: "information",
							element: (
								<Only roles={["ADMIN", ...CARRIER_CODES, ...SHIPPER_CODES]}>
									<AccountInfo />
								</Only>
							),
						},
						{
							path: "preferences",
							element: (
								<Only roles={["ADMIN", ...CARRIER_CODES, ...SHIPPER_CODES]}>
									<Preferences />
								</Only>
							),
						},
						{
							path: "service-attributes",
							element: (
								<Only roles={["ADMIN", ...CARRIER_CODES, ...SHIPPER_CODES]}>
									<ServiceAttributes />
								</Only>
							),
						},
						{
							path: "service-types",
							element: (
								<Only roles={["ADMIN", ...CARRIER_CODES, ...SHIPPER_CODES]}>
									<ServiceTypes />
								</Only>
							),
						},
						{
							path: "tracking-codes",
							element: (
								<Only roles={["ADMIN", ...CARRIER_CODES, ...SHIPPER_CODES]}>
									<TrackingCodes />
								</Only>
							),
						},
						{
							path: "sort-codes",
							element: (
								<Only roles={["ADMIN", ...CARRIER_CODES, ...SHIPPER_CODES]}>
									<SortCodes />
								</Only>
							),
						},
						{
							path: "zone-coverage",
							element: (
								<Only roles={["ADMIN", ...CARRIER_CODES, ...SHIPPER_CODES]}>
									<ZoneCoverage />
								</Only>
							),
						},
						{
							path: "rates",
							element: (
								<Only roles={["ADMIN", ...CARRIER_CODES, ...SHIPPER_CODES]}>
									<Rates />
								</Only>
							),
						},
						{
							path: "surcharges",
							element: (
								<Only roles={["ADMIN", ...CARRIER_CODES, ...SHIPPER_CODES]}>
									<Surcharges />
								</Only>
							),
						},
					],
				},
				{
					path: "invoices",
					element: (
						<Only roles={["ADMIN", ...SHIPPER_CODES]}>
							<Invoices />
						</Only>
					),
				},
				{
					path: "account-request",
					element: (
						<Only flags={[isDemo]} roles={["ADMIN"]}>
							<AccountRequest />
						</Only>
					),
				},
				{
					path: "network-insights/",
					children: [
						{
							path: ":path",
							element: (
								<Only flags={[isDemo]} roles={["ADMIN"]}>
									<NetworkInsights />
								</Only>
							),
						},
					],
				},
				{
					path: "sales-tools",
					element: (
						<Only flags={[isDemo]} roles={["ADMIN"]}>
							<SalesTools />
						</Only>
					),
				},
				{
					path: "sales-tools/",
					children: [
						{
							index: true,
							element: (
								<Only flags={[isDemo]} roles={["ADMIN"]}>
									<SalesTools />
								</Only>
							),
						},
						{
							path: "pld-calculator",
							element: (
								<Only flags={[isDemo]} roles={["ADMIN"]}>
									<PldCalculator />
								</Only>
							),
						},
						{
							path: "network-rate-card-calculator",
							element: (
								<Only flags={[isDemo]} roles={["ADMIN"]}>
									<RateCardCalculator />,
								</Only>
							),
						},
						{
							path: "us-coverage-map",
							element: (
								<Only flags={[isDemo]} roles={["ADMIN"]}>
									<UsCoverageMap />
								</Only>
							),
						},
					],
				},
				{
					path: "parcel-intelligence",
					element: (
						<Only flags={[isDemo]} roles={["ADMIN"]}>
							<ParcelIntelligence />
						</Only>
					),
				},
				{
					path: "carrier-addition",
					element: (
						<Only flags={[isDemo]} roles={["ADMIN"]}>
							<CarrierAddition />
						</Only>
					),
				},
				{
					path: "shipper-addition",
					element: (
						<Only flags={[isDemo]} roles={["ADMIN"]}>
							<ShipperAddition />
						</Only>
					),
				},
				{
					path: "bid-performance",
					element: (
						<Only flags={[isDemo]} roles={["ADMIN"]}>
							<BidPerformance />
						</Only>
					),
				},
				{
					path: "bid-configuration",
					element: (
						<Only flags={[isDemo]} roles={["ADMIN"]}>
							<BidConfiguration />
						</Only>
					),
				},
			],
		},
		{
			path: "*",
			element: <Layout />,
			children: [
				{
					path: "*",
					element: <PageNotFound code={"404"} />,
				},
			],
		},
	]);
};

function AppLoading() {
	return (
		<Box display="flex" justifyContent="center" my={"10em"}>
			<Spinner />
		</Box>
	);
}

function SecureRoute({ children }) {
	const { authState, oktaAuth } = useOktaAuth();

	if (!authState || authState.isPending) {
		return <AppLoading />;
	}

	if (!authState.isAuthenticated) {
		oktaAuth.signInWithRedirect();
		return null;
	}

	return children;
}

function Login() {
	const { oktaAuth } = useOktaAuth();
	oktaAuth.signInWithRedirect();
	return null;
}

function Logout() {
	const { oktaAuth } = useOktaAuth();
	oktaAuth.signOut();
	return null;
}

function App() {
	useDarkMode();
	return (
		<BrowserRouter basename={appContext}>
			<RoutesComponent />
			<LoginCallback />
		</BrowserRouter>
	);
}

export default App;
