import React, { useState } from "react";

import {
  Start,
  Step1,
  Step2,
  Step3,
  Step4,
  Step5,
  Step6,
  Step7,
  Step8,
  Step9,
} from "../interfaces";

function RateDiscovery({ step }) {
  // State for the wizard

  const parcelVolumeOptions = [
    { value: "1", label: "0 - 100" },
    { value: "2", label: "100 - 500" },
    { value: "3", label: "500 - 2000" },
    { value: "4", label: "2k - 10k" },
    { value: "5", label: "10k - 50k" },
    { value: "6", label: "> 50k" },
  ];

  const parcelWeightOptions = [
    { value: "1", label: "1 - 2 lbs" },
    { value: "2", label: "1 - 3 lbs" },
    { value: "3", label: "2 - 5 lbs" },
    { value: "4", label: "3 - 5 lbs" },
  ];

  const preferredMethodOfIntegrationOptions = [
    {
      value: "1",
      label: "Warehouse Management System",
      children: [
        { value: "1-1", label: "Oracle NetSuite WMS" },
        { value: "1-2", label: "Manhattan Associates WMS" },
        { value: "1-3", label: "SAP Extended Warehouse Management(EWM)" },
        { value: "1-4", label: "HighJump Warehouse Management System" },
        { value: "1-5", label: "Blue Yonder Warehouse Management" },
        { value: "1-6", label: "Other" },
      ],
    },
    {
      value: "2",
      label: "Order Management System",
      children: [
        { value: "2-1", label: "Oracle Order Management Cloud" },
        { value: "2-2", label: "SAP Order Management" },
        { value: "2-3", label: "IBM Sterling Order Management" },
        { value: "2-4", label: "Magento Order Management" },
        { value: "2-5", label: "Salesforce Order Management" },
        { value: "2-6", label: "Other" },
      ],
    },
    {
      value: "3",
      label: "Rate Shopping Engine",
      children: [
        { value: "3-1", label: "ShipStation" },
        { value: "3-2", label: "Shippo" },
        { value: "3-3", label: "ShipHawk" },
        { value: "3-4", label: "EasyPost" },
        { value: "3-5", label: "ParcelBright" },
        { value: "3-6", label: "Other" },
      ],
    },
    { value: "4", label: "Custom Developed Software" },
  ];

  const [data, setData] = useState({
    stepIndex: 1,
    zipcode: "",
    parcelVolume: "",
    parcelWeight: [],
    preferredMethod: "",
    preferredMethodChildren: "",
    preferredMethodOtherTextBoxValue: "",
    rateCardEmail: "",
    regions: [],
  });

  if (step === "start") return <Start />;
  if (step === "1") return <Step1 data={data} setData={setData} />;
  if (step === "2") return <Step2 data={data} setData={setData} />;
  if (step === "3")
    return (
      <Step3 data={data} setData={setData} options={parcelVolumeOptions} />
    );
  if (step === "4")
    return (
      <Step4 data={data} setData={setData} options={parcelWeightOptions} />
    );
  if (step === "5") return <Step5 />;
  if (step === "6") return <Step6 data={data} setData={setData} />;
  if (step === "7")
    return (
      <Step7
        data={data}
        setData={setData}
        options={preferredMethodOfIntegrationOptions}
      />
    );
  if (step === "8") return <Step8 />;
  if (step === "9") return <Step9 />;

  return null;
}

export default RateDiscovery;
