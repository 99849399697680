import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import * as turf from "@turf/turf";
import "mapbox-gl/dist/mapbox-gl.css";
import { MAPBOX_API_KEY } from "../../../constants";
import { reverseGeocoding } from "../../../utilities/reverseGeocoding";
import { LabelLayers } from "./MapLabels";
import "./css/mainmap.css";

const defaultCenter = [-100, 40];

let origin = {
	center: defaultCenter,
	zoom: 16,
	pitch: 60,
	bearing: -10,
	antialias: true,
};

mapboxgl.accessToken = MAPBOX_API_KEY;

const MainMap = ({
	route,
	trackingId = null,
	timestamps = null,
	locationData,
	trackingData,
	parcelData,
	isPublicTracking = false,
}) => {
	const mapContainerRef = useRef();
	const mapRef = useRef();
	const markerRef = useRef();
	const [startPosition, setStartPosition] = useState(null);
	const [isMapLoaded, setIsMapLoaded] = useState(false);

	useEffect(() => {
		if (!mapContainerRef.current || mapRef.current) return;
		origin.center =
			route && route.origin
				? [route.origin[1], route.origin[0]]
				: defaultCenter;

		mapRef.current = new mapboxgl.Map({
			container: mapContainerRef.current,
			style: "mapbox://styles/mapbox/dark-v10",
			...origin,
		});

		mapRef.current.on("load", () => {
			mapRef.current.addSource("mapbox-dem", {
				type: "raster-dem",
				url: "mapbox://mapbox.mapbox-terrain-dem-v1",
				tileSize: 512,
				maxzoom: 14,
			});

			// LabelLayers.forEach((layer) => {
			//   if (mapRef.current.getLayer(layer)) {
			//     mapRef.current.setLayoutProperty(layer, "visibility", "none");
			//   }
			// });

			mapRef.current.setTerrain({ source: "mapbox-dem", exaggeration: 1.5 });

			if (mapRef.current.getLayer("land")) {
				mapRef.current.setPaintProperty("land", "background-color", "#191A23");
			}

			if (mapRef.current.getLayer("land")) {
				mapRef.current.setPaintProperty("land", "background-color", "#191A23");
			}

			if (mapRef.current.getLayer("admin-0-boundary")) {
				mapRef.current.setPaintProperty(
					"admin-0-boundary",
					"line-color",
					"#858698",
				);
			}

			if (mapRef.current.getLayer("admin-1-boundary")) {
				mapRef.current.setPaintProperty(
					"admin-1-boundary",
					"line-color",
					"#858698",
				);
			}

			mapRef.current.setPaintProperty("water", "fill-color", "#191A23");

			mapRef.current.addLayer({
				id: "3d-buildings",
				source: "composite",
				"source-layer": "building",
				filter: ["==", "extrude", "true"],
				type: "fill-extrusion",
				minzoom: 12,
				paint: {
					"fill-extrusion-color": "#858698",

					// Use an 'interpolate' expression to add a smooth transition effect to the
					// buildings as the user zooms in.
					"fill-extrusion-height": [
						"interpolate",
						["linear"],
						["zoom"],
						12,
						["get", "height"],
						15,
						["get", "height"],
					],
					"fill-extrusion-base": [
						"interpolate",
						["linear"],
						["zoom"],
						12,
						["get", "min_height"],
						16.05,
						["get", "min_height"],
					],
					"fill-extrusion-opacity": 0.8,
				},
			});

			// setTimeout(() => {
			//   startFlyover();
			// }, 1000);
		});

		const startingPoint = [route.origin[1], route.origin[0]];
		const endPoint = [route.destination[1], route.destination[0]];
		let middlePoints = "";
		if (
			route.routes &&
			Array.isArray(route.routes) &&
			route.routes.length > 0
		) {
			const firstRoute = route.routes[0];
			if (Array.isArray(firstRoute) && firstRoute.length > 0) {
				middlePoints = route.routes.reduce((middlePoint, route) => {
					if (
						Array.isArray(route) &&
						route.length >= 2 &&
						route[0] !== undefined &&
						route[1] !== undefined
					) {
						return `${middlePoint}${route[1]},${route[0]};`;
					}
					return middlePoint;
				}, "");
			}
		}

		mapRef.current.on("load", () => {
			fetch(
				`https://api.mapbox.com/directions/v5/mapbox/driving/${startingPoint[0]},${startingPoint[1]};${middlePoints}${endPoint[0]},${endPoint[1]}.json?geometries=geojson&access_token=${mapboxgl.accessToken}`,
			)
				.then((response) => response.json())
				.then((data) => {
					if (data.routes.length > 0) {
						const jsonGeometry = data.routes[0].geometry;
						// const productPoint = Math.round(
						//   jsonGeometry.coordinates.length / 4
						// );

						setStartPosition(jsonGeometry.coordinates[1]);
						let flag = 0;
						const features = jsonGeometry.coordinates.reduce(
							(feature, coordinate, index) => {
								const isRouteCoordinate = data.waypoints.find(
									(point) =>
										JSON.stringify(point.location) ===
										JSON.stringify(coordinate),
								);

								if (index !== 0 && isRouteCoordinate) {
									feature.push({
										type: "LineString",
										coordinates: [
											...jsonGeometry.coordinates.slice(flag, index + 1),
										],
										markerColor:
											index === jsonGeometry.coordinates.length - 1
												? "blue"
												: "blue",
									});
									flag = index;
								}
								return feature;
							},
							[],
						);
						Promise.all(
							features.map((feature, index) => {
								const coordinates = feature.coordinates[0];
								return new Promise((resolve, reject) => {
									if (index === 0) {
										return new Promise(() => {
											resolve({ ...feature });
										});
									} else {
										reverseGeocoding({
											lat: coordinates[1],
											lng: coordinates[0],
										})
											.then((address) => {
												return new Promise(() => {
													resolve({ ...feature, address });
												});
											})
											.catch((error) => {
												return new Promise(() => {
													reject(new Error(error));
												});
											});
									}
								});
							}),
						)
							.then((response) => {
								response.forEach((feature, index) => {
									const sourceId = `route-${index}`;
									const coordinates = feature.coordinates[0];
									let coordIndex = feature.coordinates.length - 1;
									mapRef.current.addSource(sourceId, {
										type: "geojson",
										data: {
											type: feature.type,
											coordinates: feature.coordinates,
										},
									});
									const color =
										feature.markerColor === "blue" ? "#3B86ED" : "#DA2C17";
									const marker =
										feature.markerColor === "blue"
											? "marker_blue"
											: "marker_red";

									const el = document.createElement("div");
									if (index === 0) {
										el.innerHTML = `<div><img src="/images/map/start.svg" /></div>`;
									} else {
										el.innerHTML = `<div><img src="/images/map/${marker}.svg" /></div>`;
									}

									mapRef.current.addLayer({
										id: `route-${index}`,
										type: "line",
										source: sourceId,
										layout: {
											"line-join": "round",
											"line-cap": "round",
										},
										paint: {
											"line-color": color,
											"line-width": 4,
											"line-dasharray": [2, 2],
										},
									});
									new mapboxgl.Marker(el)
										.setLngLat(coordinates)
										.addTo(mapRef.current);

									//   if (index === 0) {
									//     const elOrigin = document.createElement("div");
									//     elOrigin.innerHTML =
									//       '<div><img src="/images/map/origin.svg" /></div>';
									//     elOrigin.style.top = "-30px";

									//     new mapboxgl.Marker(elOrigin)
									//       .setLngLat(coordinates)
									//       .addTo(map.current);
									//   }
									if (index === features.length - 1) {
										//let coordinateParcel = feature.coordinates[productPoint];
										//setParcelPosition(coordinateParcel);

										let coordinateEnd =
											feature.coordinates[feature.coordinates.length - 1];

										const elEnd = document.createElement("div");
										elEnd.innerHTML = `<div><img src="/images/map/${marker}.svg" /></div>`;

										new mapboxgl.Marker(elEnd)
											.setLngLat(coordinateEnd)
											.addTo(mapRef.current);

										const elFinal = document.createElement("div");
										elFinal.innerHTML =
											'<div><img src="/images/map/endroute.svg" /></div>';
										elFinal.style.top = "-30px";

										new mapboxgl.Marker(elFinal)
											.setLngLat(coordinateEnd)
											.addTo(mapRef.current);

										// const currentPoint = document.createElement("div");
										// currentPoint.innerHTML =
										//   "<div><img src='/images/map/marker_red.svg' /></div>";

										// new mapboxgl.Marker(currentPoint)
										//   .setLngLat(feature.coordinates[coordIndex])
										//   .addTo(mapRef.current);

										const movingEl = document.createElement("div");
										movingEl.innerHTML =
											"<div><img src='/images/map/box.png' /></div>";

										markerRef.current = new mapboxgl.Marker(movingEl)
											.setLngLat(feature.coordinates[coordIndex])
											.addTo(mapRef.current);

										mapRef.current.setCenter(feature.coordinates[coordIndex]);

										setTimeout(() => {
											animateCamera(feature.coordinates, coordIndex);
										}, 500);
									}
								});
								setIsMapLoaded(true);
							})
							.catch((error) => {
								console.error("Geocoding failed:", error);
							});
					}
				})
				.catch((error) => {
					console.error("Error fetching data:", error);
				});
		});
	});

	const addPopup = (parcelDelivery, finalCoord) => {
		const popup = new mapboxgl.Popup({
			closeOnClick: true,
			offset: { bottom: [0, -50] },
		})
			.setLngLat(finalCoord)
			.setHTML(
				`${parcelDelivery.city?.trim() || ""}${parcelDelivery.city ? "," : ""} ${parcelDelivery.state || ""}${parcelDelivery.zip ? "," : ""} ${parcelDelivery.zip || ""}`.trim(),
			)
			.addTo(mapRef.current);
	};

	const animateCamera = (coordinates, coordIndex) => {
		let index = coordIndex;
		const baseSpeed = 0.01;
		const constantPitch = 70;
		const constantZoom = 16;

		trackingData.forEach((item, index) => {
			if (item.status === "Created") {
				if (item.city === null) {
					item.city = locationData.shipFrom.city;
					item.state = locationData.shipFrom.state;
					item.zip = locationData.shipFrom.zip;
				}
			}
		});

		const parcelDelivery = trackingData[trackingData.length - 1];
		const finalCoord = coordinates[coordinates.length - 1];

		if (index >= coordinates.length - 1) {
			addPopup(parcelDelivery, finalCoord);
			console.error("Starting index is too high, no animation possible.");
			return;
		}

		const initialCoord = coordinates[index];
		markerRef.current.setLngLat(initialCoord);
		mapRef.current.jumpTo({
			center: initialCoord,
			bearing: turf.bearing(
				turf.point(coordinates[index]),
				turf.point(coordinates[index + 1]),
			),
			pitch: constantPitch,
			zoom: constantZoom,
		});

		function animate() {
			if (index < coordinates.length - 1) {
				let start = coordinates[index];
				let end = coordinates[index + 1];
				let distance = turf.distance(turf.point(start), turf.point(end));
				let bearing = turf.bearing(turf.point(start), turf.point(end));

				const speed = baseSpeed * Math.log(1 + 1 / distance);

				let progress = 0;

				function move() {
					progress += speed;
					if (progress >= 1) {
						index++;
						if (index >= coordinates.length - 1) {
							addPopup(parcelDelivery, finalCoord);
							return;
						}
						start = coordinates[index];
						end = coordinates[index + 1];
						distance = turf.distance(turf.point(start), turf.point(end));
						bearing = turf.bearing(turf.point(start), turf.point(end));
						progress = 0; // Reset progress for the next segment
					}

					const currentCoord = turf.along(
						turf.lineString([start, end]),
						progress * distance,
					).geometry.coordinates;

					// Move the marker to the interpolated position
					markerRef.current.setLngLat(currentCoord);

					const remainingDistance = distance * (1 - progress);
					const segmentDuration = remainingDistance / speed;

					// Center the camera on the marker (icon) position
					mapRef.current.easeTo({
						center: currentCoord,
						bearing: bearing,
						pitch: constantPitch,
						zoom: constantZoom,
						easing: (t) => t * t * (3 - 2 - t),
						duration: segmentDuration,
					});

					requestAnimationFrame(move);
				}

				move();
			}
		}

		animate();
	};

	return (
		<div className="fullMap-container relative w-full h-[100vh]">
			<div id="map" ref={mapContainerRef} className="w-full h-full" />;
		</div>
	);
};

export default MainMap;
