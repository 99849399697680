import React, { useState, useEffect } from "react";

import { Card, Heading, Flex, Text } from "@chakra-ui/react";

function EstimatedCharges({ data, parcelData }) {
	const [latest, setLatest] = useState(null);
	const [parcel, setParcel] = useState(null);

	useEffect(() => {
		if (data && data.length > 0) {
			setLatest(data[0]);
		}
	}, [data]);

	useEffect(() => {
		if (parcelData && parcelData.length > 0) {
			setParcel(parcelData[0]);
		}
	}, [parcelData]);

	const hrStyle = {
		border: "0",
		height: "1px",
		width: "100%",
		background: "rgba(133, 134, 152, 0.6)",
		margin: "15px 0 10px",
	};

	return (
		<Card
			borderRadius="20px"
			borderWidth="1px"
			borderColor="rgba(133, 134, 152, 0.36)"
			p={6}
			mb={4}
		>
			<Heading as={"h3"} color="#EBEBEB" size="sm">
				Initial Estimated Charges
			</Heading>
			<hr style={hrStyle} />

			<Flex my={2}>
				<Text flex="1" opacity="0.8">
					Service Type
				</Text>
				<Text flex="1">{parcel?.serviceType || "-"}</Text>
			</Flex>

			<Flex my={2}>
				<Text flex="1" opacity="0.8">
					Zone
				</Text>
				<Text flex="1">{parcel?.zone || "-"}</Text>
			</Flex>

			<Flex my={2}>
				<Text flex="1" opacity="0.8">
					Billable Rate
				</Text>
				<Text flex="1">{parcel?.billableRate || "-"}</Text>
			</Flex>

			<Flex my={2}>
				<Text flex="1" opacity="0.8">
					Base Rate
				</Text>
				<Text flex="1">{parcel?.baseRate || "-"}</Text>
			</Flex>

			<Flex my={2}>
				<Text flex="1" opacity="0.8">
					Total Rate
				</Text>
				<Text flex="1">{parcel?.totalRate || "-"}</Text>
			</Flex>

			<Flex my={2}>
				<Text flex="1" opacity="0.8">
					Invoice Status
				</Text>
				<Text flex="1">{parcel?.invoiceStatus || "-"}</Text>
			</Flex>

			<Flex my={2}>
				<Text flex="1" opacity="0.8">
					Validity
				</Text>
				<Text flex="1">{parcel?.validity || "-"}</Text>
			</Flex>

			<Flex my={2}>
				<Text flex="1" fontWeight="semibold">
					Additional Charges:
				</Text>
			</Flex>

			<Flex my={2}>
				<Text flex="1" opacity="0.8">
					Description
				</Text>
				<Text flex="1">{parcel?.description || "-"}</Text>
			</Flex>

			<Flex my={2}>
				<Text flex="1" opacity="0.8">
					Code
				</Text>
				<Text flex="1">{parcel?.code || "-"}</Text>
			</Flex>

			<Flex my={2}>
				<Text flex="1" opacity="0.8">
					Fee
				</Text>
				<Text flex="1">{parcel?.fee || "-"}</Text>
			</Flex>
		</Card>
	);
}

export default EstimatedCharges;
