import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { exceptions } from "../../../data/exceptionDetails";

import HexagonSection from "./HexagonSection";
import buttons from "./Buttons";
// import {
//   getDisputesForDetails,
//   getEvents,
//   getSpecificDisputeDetailsById,
// } from "../services/getDisputes";

const ExceptionDetails = () => {
	const { disputeId: trackId } = useParams();
	const [activeButton, setActiveButton] = useState("Loss_/Damage");
	const [exceptionDetails, setExceptionsDetails] = useState(exceptions);

	const [disputesList, setDisputesList] = useState(null);
	const [specificDispute, setSpecificDispute] = useState(null);

	useEffect(() => {
		if (!trackId) return;
		// const getDisputesList = async () => {
		//   try {
		//     const disputes = await getDisputesForDetails(trackId);
		//     console.log("disputes", disputes);
		//     setDisputesList(disputes.invoiceDisputes);
		//   } catch (error) {
		//     console.error("Error fetching disputes list:", error);
		//   }
		// };
		// console.log("trackId", trackId);
		// getDisputesList();
	}, [trackId]);

	useEffect(() => {
		if (!trackId) return;

		// const getTrackingEvents = async () => {
		//   try {
		//     const data = await getEvents(trackId);
		//     console.log("DATATATA events", data);
		//     setTrackingEvents(data.eventDtos);
		//   } catch (error) {
		//     console.error("Error fetching tracking events:", error);
		//   }
		// };

		// getTrackingEvents();
	}, [trackId]);

	const number =
		exceptionDetails?.[exceptionDetails.length - 1]?.disputeId ?? null;

	useEffect(() => {
		if (!number) return;
		// const getSpecificDispute = async () => {
		//   try {
		//     const dispute = await getSpecificDisputeDetailsById(number);
		//     setSpecificDispute(dispute);
		//   } catch (error) {
		//     console.error("Error fetching specific dispute:", error);
		//   }
		// };
		// getSpecificDispute();
	}, [number]);

	const handleButtonClick = (id) => {
		// console.log("id: ", id);
		setActiveButton(id);
	};

	const deselectButton = () => {
		setActiveButton(null);
	};

	//   const openTrackingModal = () => {
	//     setIsTrackingModalOpen(true);
	//   };

	//   const closeTrackingModal = () => {
	//     setIsTrackingModalOpen(false);
	//   };

	//   const openModal = () => {
	//     setIsModalOpen(true);
	//   };

	//   const closeModal = () => {
	//     setIsModalOpen(false);
	//   };

	return (
		<div className="min-h-[100vh] bg-[#21232E] pb-[20px]">
			<img
				src="/images/exceptions/Glow.svg"
				alt="Glow"
				className="absolute inset-0 w-full h-full object-cover pointer-events-none"
			/>

			<div className="flex mt-[20px] justify-start items-center mb-10">
				<HexagonSection
					buttons={buttons}
					activeButton={activeButton}
					handleButtonClick={handleButtonClick}
					deselectButton={deselectButton}
					trackId={trackId}
					disputesList={exceptionDetails}
				/>
			</div>
		</div>
	);
};

export default ExceptionDetails;
