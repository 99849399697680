import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { LoadScript } from "@react-google-maps/api";
import { Box, Grid, Spinner, Text, useDisclosure } from "@chakra-ui/react";

import { geocodeLocation } from "../../utilities";
import { GOOGLE_MAPS_API_KEY } from "../../constants";
import { ExpandIcon } from "../CustomIcons";
import { Map, MapModal } from "..";

const coverageMapFilterOptions = {
  existingCarriers: {
    label: "Existing Orchestro Carriers",
    color: "#FFB323",
    isChecked: true,
  },
  newCarriers: {
    label: "New Carriers",
    color: "#980AE5",
    isChecked: true,
  },
};

const rateCompetitivenessLegends = {
  title: "Rate compare to OCN",
  value: [
    {
      label: "Competitive",
      color: "#00FF0A",
    },
    {
      label: "0 to 10%",
      color: "#E9FA81",
    },
    {
      label: ">10%",
      color: "#ED001B",
    },
  ],
};

const CarrierWhatIfScenario = ({ analysisResult }) => {
  const {
    isOpen: isOpenCoverageMap,
    onOpen: onCoverageMapOpen,
    onClose: onCoverageMapClose,
  } = useDisclosure();

  const {
    isOpen: isOpenRateMap,
    onOpen: onRateMapOpen,
    onClose: onRateMapClose,
  } = useDisclosure();

  const [coverageMap, setCoverageMap] = useState([]);
  const [coverageMapLoading, setCoverageMapLoading] = useState(true);
  const [rateCompetitiveness, setRateCompetitiveness] = useState([]);
  const [rateCompetitivenessLoading, setRateCompetitivenessLoading] =
    useState(true);

  const impactOfCarrierAddition = useMemo(() => {
    const labels = analysisResult?.impact?.value.flatMap((curr) =>
      curr.value.map(({ label }) => label)
    );

    return {
      labels: [...new Set(labels)],
      impact: analysisResult?.impact?.value,
    };
  }, [analysisResult]);

  const coverageMapData = useMemo(() => {
    return analysisResult?.coverageMap?.value.flatMap((curr) => {
      return curr.value.flatMap(({ value }) =>
        value.flatMap((zip) => ({
          value: zip,
          color:
            curr.label.toLocaleLowerCase() ===
            "Base Configurations".toLocaleLowerCase()
              ? "#FFB323"
              : "#980AE5",
        }))
      );
    });
  }, [analysisResult]);

  const rateCompetitivenessData = useMemo(() => {
    return analysisResult?.rateCompetitiveness?.value.flatMap((curr) => {
      return curr.value.flatMap(({ value, label }) =>
        value.flatMap((zip) => ({
          value: zip,
          color:
            label === "competitive"
              ? "#00FF0A"
              : label === "0 to 10% higher"
                ? "#E9FA81"
                : "#ED001B",
          label: curr.label,
        }))
      );
    });
  }, [analysisResult]);

  const rateCompetitivenessFilterOptions = useMemo(
    () =>
      analysisResult?.rateCompetitiveness?.value.reduce((acc, curr) => {
        return { ...acc, [curr.label]: { label: curr.label, isChecked: true } };
      }, {}),
    [analysisResult]
  );

  useEffect(() => {
    if (coverageMapData && coverageMapData.length > 0) {
      // Map each data item to a promise that resolves with place_id
      const geocodePromises = coverageMapData.map(async (mapData) => {
        try {
          const location = await geocodeLocation({
            zip: mapData.value ?? "",
            country: "us",
          });

          return {
            place_id: location?.place_id,
            color: mapData.color,
            coords: location?.coords,
          };
        } catch (error) {
          console.error("Geocoding failed:", error);
        }
      });

      // Execute all promises concurrently
      Promise.all(geocodePromises)
        .then((results) => {
          setCoverageMap(results);
        })
        .catch((error) => {
          console.error("Geocoding failed:", error);
        })
        .finally(() => {
          setCoverageMapLoading(false);
        });
    } else {
      setCoverageMap([]);
    }
  }, [coverageMapData]);

  useEffect(() => {
    if (rateCompetitivenessData && rateCompetitivenessData.length > 0) {
      // Map each data item to a promise that resolves with place_id
      const geocodePromises = rateCompetitivenessData.map(async (mapData) => {
        try {
          const location = await geocodeLocation({
            zip: mapData.value ?? "",
            country: "us",
          });

          return {
            place_id: location?.place_id,
            color: mapData.color,
            coords: location?.coords,
          };
        } catch (error) {
          console.error("Geocoding failed:", error);
        }
      });

      // Execute all promises concurrently
      Promise.all(geocodePromises)
        .then((results) => {
          setRateCompetitiveness(results);
        })
        .catch((error) => {
          console.error("Geocoding failed:", error);
        })
        .finally(() => {
          setRateCompetitivenessLoading(false);
        });
    } else {
      setRateCompetitiveness([]);
    }
  }, [rateCompetitivenessData]);

  return (
    <LoadScript
      googleMapsApiKey={`${GOOGLE_MAPS_API_KEY}`}
      loadingElement={<div>Loading...</div>}
    >
      <Grid className="mt-7 grid-cols-1 xl:grid-cols-2 gap-5">
        <Box
          border={"1px solid #E0E1EC33"}
          bg={"#21232E"}
          borderRadius={"6px"}
          padding={"18px 24px 18px 28px"}
          minHeight={"346px"}
        >
          <div className="flex items-center justify-between">
            <Text fontSize={18} fontWeight={700} color={"#fff"}>
              Coverage map
            </Text>
            <button onClick={onCoverageMapOpen}>
              <ExpandIcon />
            </button>
          </div>
          <Box marginTop={22}>
            {coverageMapLoading ? (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                height={"300px"}
              >
                <Spinner />
              </Box>
            ) : (
              <Map data={coverageMap} height={"300px"} zoom={11} />
            )}
          </Box>
        </Box>

        <Box
          border={"1px solid #E0E1EC33"}
          bg={"#21232E"}
          borderRadius={"6px"}
          padding={"18px 24px 18px 28px"}
          minHeight={"346px"}
        >
          <Text fontSize={18} fontWeight={700} color={"#fff"}>
            Impact of Carrier Addition
          </Text>
          <Box className="mt-[22px]" display={"flex"}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={4}
              marginTop={"45px"}
              paddingTop={3}
            >
              {impactOfCarrierAddition.labels.map((keys, i) => (
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  className="2xl:text-lg text-[#EBEBEB]"
                  key={i}
                >
                  <div className="mr-3">{keys}</div>
                  <div className="font-bold">:</div>
                </Box>
              ))}
            </Box>
            <div className="ml-4 md:ml-12 lg:ml-24 xl:ml-[42px] 2xl:ml-24">
              <Box
                display={"flex"}
                className="2xl:text-lg text-[#EBEBEB] font-bold"
              >
                {impactOfCarrierAddition.impact &&
                  impactOfCarrierAddition.impact.length > 0 &&
                  impactOfCarrierAddition.impact.map((impact, i) => {
                    const lastElementIndex =
                      impactOfCarrierAddition.impact.length - 1;
                    return (
                      <React.Fragment key={i}>
                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          gap={4}
                          className={`${i === lastElementIndex ? "bg-[#001938] px-5 lg:px-[30px] rounded-r-md" : ""} py-3`}
                        >
                          <Box className="font-normal">{impact.label}</Box>
                          {impact.value.map((impactedValue, i) => (
                            <Box key={i}>
                              {/* This is just because of bad dummy data and yet another demo bodge */}
                              {impactedValue.label === "Population"
                                ? new Intl.NumberFormat("en-US").format(
                                    Math.round(impactedValue.value / 1000)
                                  )
                                : impactedValue.label === "Average Rate"
                                  ? `$ ${Number(new Intl.NumberFormat("en-US").format(impactedValue.value)).toFixed(2)}`
                                  : isNaN(impactedValue.value)
                                    ? impactedValue.value
                                    : new Intl.NumberFormat("en-US").format(
                                        Math.round(impactedValue.value)
                                      )}
                            </Box>
                          ))}
                        </Box>
                        {i !== lastElementIndex ? (
                          <Box
                            className="px-4 lg:px-7 xl:px-4 2xl:px-7"
                            paddingTop={3}
                            fontWeight={400}
                          >
                            Vs
                          </Box>
                        ) : (
                          <></>
                        )}
                      </React.Fragment>
                    );
                  })}
              </Box>
            </div>
          </Box>
        </Box>
      </Grid>

      <Grid className="mt-5 grid-cols-1 lg:grid-cols-2 gap-5">
        <Box
          border={"1px solid #E0E1EC33"}
          bg={"#21232E"}
          borderRadius={"6px"}
          padding={"18px 24px 18px 28px"}
          minHeight={"346px"}
        >
          <div className="flex items-center justify-between">
            <Text fontSize={18} fontWeight={700} color={"#fff"}>
              Rate competitiveness
            </Text>
            <button onClick={onRateMapOpen}>
              <ExpandIcon />
            </button>
          </div>
          <Box marginTop={22}>
            {rateCompetitivenessLoading ? (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                height={"300px"}
              >
                <Spinner />
              </Box>
            ) : (
              <Map data={rateCompetitiveness} height={"300px"} zoom={11} />
            )}
          </Box>
        </Box>

        <Box
          border={"1px solid #E0E1EC33"}
          bg={"#21232E"}
          borderRadius={"6px"}
          padding={"18px 24px 18px 28px"}
          minHeight={"346px"}
        >
          <Text fontSize={18} fontWeight={700} color={"#fff"}>
            Insights
          </Text>
          <Box className="mt-[22px]" display={"flex"}>
            <ul style={{ listStyleType: "disc", marginLeft: "28px" }}>
              {analysisResult?.insights?.value.map((val, i) => (
                <li className="text-[#fff] font-medium mb-6" key={i}>
                  {val}
                </li>
              ))}
            </ul>
          </Box>
        </Box>
      </Grid>

      <MapModal
        open={isOpenCoverageMap}
        onClose={onCoverageMapClose}
        data={{
          label: analysisResult?.coverageMap.label,
          value: coverageMap,
        }}
        filterOptions={coverageMapFilterOptions}
        isCarrierAddition={true}
      />
      <MapModal
        open={isOpenRateMap}
        onClose={onRateMapClose}
        data={{
          label: analysisResult?.rateCompetitiveness?.label,
          value: rateCompetitiveness,
        }}
        filterOptions={rateCompetitivenessFilterOptions}
        legends={rateCompetitivenessLegends}
        isCarrierAddition={true}
      />
    </LoadScript>
  );
};

export default CarrierWhatIfScenario;

CarrierWhatIfScenario.defaultProps = {
  analysisResult: {},
};

CarrierWhatIfScenario.propTypes = {
  analysisResult: PropTypes.object,
};
