import { useContext } from "react";
import { Link } from "react-router-dom";
import { Button, Flex, Heading } from "@chakra-ui/react";

import { Page, UserContext } from "../interfaces";

function Demo({ step }) {
	const { createAlert } = useContext(UserContext);

	return (
		<Page>
			<Heading>Demo controls</Heading>
			<Flex gap={5} my={5}>
				<Button as={Link} to={`rate-discovery/start`}>
					Start Rate Discovery
				</Button>
				<Button onClick={createAlert}>
					Create Alert
				</Button>
			</Flex>
		</Page>
	);
}

export default Demo;