import React from "react";

const Popup = ({
  activeButton,
  buttonDetails,
  deselectButton,
  isFirstChip,
}) => {
  return (
    <div
      id="popup"
      className="absolute bg-[#1d1f28] text-white p-4 pb-20 rounded-lg z-50 2xl:w-[550px] md:w-[470px] 2xl:left-[45vw] md:left-[45vw] h-auto"
      style={{
        top: "10vh",
        //transform: "translate(-50%, -50%)",
        display: "flex",
        alignItems: "stretch",
        boxShadow: "4px 4px 4px #000000, -4px -4px 4 rgba(0, 0, 0, .25)",
      }}
    >
      {/* Content */}
      <div className="flex-grow relative left-[5px]">
        <div className="flex justify-between items-start">
          <div>
            <h3 className="text-[1.125rem] font-semibold pb-2 border-b border-[#292A35]">
              {activeButton
                .replace(/_/g, " ")
                .replace(/\b\w/g, (char) => char.toUpperCase())}
            </h3>

            <div className="max-h-[370px] overflow-y-auto scrollbar">
              <div className="w-full mt-5">
                <div className="w-full">
                  <table className="w-full text-[12px] text-left">
                    <tbody className="space-y-2">
                      <tr>
                        <td className="text-[#858698] w-[140px] align-top py-1">
                          Occurance date
                        </td>
                        <td className="text-white align-top py-1">
                          June 6, 2024
                        </td>
                      </tr>
                      <tr>
                        <td className="text-[#858698] w-[140px] align-top py-1">
                          Parties involved
                        </td>
                        <td className="text-white align-top py-1">
                          {/* {buttonDetails.parties.map((party, index) => (
                            <span key={index}>
                              {party}
                              {index !== buttonDetails.parties.length - 1 && (
                                <span> &nbsp;&nbsp;</span>
                              )}
                            </span>
                          ))} */}
                          Carrier 1: SecureShip Courier&nbsp;&nbsp; Carrier 2:
                          DeliverIt Logistics
                        </td>
                      </tr>
                      <tr>
                        <td className="text-[#858698] w-[140px] align-top pt-4 pb-1">
                          Status
                        </td>
                        <td className="text-white align-top pt-4 pb-1">Open</td>
                      </tr>
                      <tr>
                        <td className="text-[#858698] w-[140px] align-top py-1">
                          Location
                        </td>
                        <td className="text-white align-top py-1">
                          City, State
                        </td>
                      </tr>
                      <tr>
                        <td className="text-[#858698] w-[140px] align-top py-1">
                          Network Track Codes
                        </td>
                        <td className="text-white align-top py-1">LCRT</td>
                      </tr>
                      <tr>
                        <td className="text-[#858698] w-[140px] align-top py-1">
                          Comments
                        </td>
                        <td className="text-white align-top py-1">
                          Label Created
                        </td>
                      </tr>
                      <tr>
                        <td className="text-[#858698] w-[140px] align-top py-1">
                          Surcharge
                        </td>
                        <td className="text-white align-top py-1">$4</td>
                      </tr>
                      <tr>
                        <td className="text-[#858698] w-[140px] align-top py-1">
                          Time of Occurance
                        </td>
                        <td className="text-white align-top py-1">
                          12:00:00 am
                        </td>
                      </tr>
                      <tr>
                        <td className="text-[#858698] w-[140px] align-top py-1">
                          Impact
                        </td>
                        <td className="text-white align-top py-1">Cost: $4</td>
                      </tr>
                      <tr>
                        <td className="text-[#858698] w-[140px] align-top py-1">
                          Description
                        </td>
                        <td className="text-white align-top py-1">
                          {buttonDetails.description}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <button
            className="text-[1.5rem] text-[#A1A1B5] font-semibold mt-[-0.5rem] relative right-[30px]"
            onClick={deselectButton}
          >
            &times;
          </button>
        </div>
      </div>
    </div>
  );
};

export default Popup;
