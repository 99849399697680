import { useEffect, useState, useContext } from "react";
import { EditIcon } from "@chakra-ui/icons";
import {
  Alert,
  Card,
  Box,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Select,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

import { AccountTabs, Page, UserContext } from "../interfaces";
import { CARRIER_CODES, SHIPPER_CODES } from "../constants";
import { renderDate, renderTime } from "../utilities";

const coreServiceUrl = process.env.REACT_APP_CORE_SERVICES_URL;
const coreServicePath = process.env.REACT_APP_CORE_SERVICES_PATH;
const coreServiceToken = process.env.REACT_APP_CORE_SERVICES_TOKEN;
const apiUrl = `${coreServiceUrl}${coreServicePath}`;

function Preferences() {
  //   const [preferenceData, setPreferenceData] = useState(null);
  const { user } = useContext(UserContext);
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const isCarrier = user.Groups.find((group) => CARRIER_CODES.includes(group));
  const isShipper = user.Groups.find((group) => SHIPPER_CODES.includes(group));
  const dummyLabelCompatOptions = [
    {
      label: "Standard Orchestro Label",
      value: "SOL",
    },
    {
      label: "Unified Label",
      value: "UL",
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const userCode = user.Groups.find((group) =>
          [...CARRIER_CODES, ...SHIPPER_CODES].includes(group)
        );

        if (!userCode) return;
        const response = await fetch(apiUrl + "/get-preference", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: coreServiceToken,
          },
          body: JSON.stringify({ carrierCode: userCode }),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        let responseData = await response.json();

        if (!responseData.preferredCarriers) {
          // Temp dummy data
          responseData.preferredCarriers = [
            {
              serviceType: "-",
              carrier: "-",
              defaultPickupResponsibility: "-",
              useAllSortHubsOfCarrier: "-",
            },
          ];
        }

        setData(responseData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [user]);

  return (
    <>
      <AccountTabs />
      <div>
        <Page>
          <h2 className="text-xl my-5">
            Preferences
          </h2>
          <Alert status="info" mb={8} borderColor="rgba(133, 134, 152, 0.2)">
            <EditIcon />
            <Text>
              To edit preferences please contact{" "}
              <a href="mailto:carrier.integration@orchestro.ai">
                carrier.integration@orchestro.ai
              </a>
            </Text>
          </Alert>

          {isLoading ? (
            <Box display="flex" justifyContent="center" my={"10em"}>
              <Spinner />
            </Box>
          ) : !data ? (
            <Text>No preferences information</Text>
          ) : (
            <>
              {/********************* General ***********************/}
              <Card
                mb={7}
                borderRadius="20px"
                borderWidth="1px"
                borderColor="rgba(133, 134, 152, 0.2)"
                p={8}
              >
                <Heading size="md">General</Heading>
                <Flex gap={7} my={5}>
                  <FormControl>
                    <FormLabel fontSize={"sm"}>Language</FormLabel>
                    <Select disabled>
                      <option value={data.language}>{data.language}</option>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize={"sm"}>Currency</FormLabel>
                    <Select disabled>
                      <option value={data.currency}>{data.currency}</option>
                    </Select>
                  </FormControl>
                </Flex>
                <Flex gap={7} my={5}>
                  <FormControl>
                    <FormLabel fontSize={"sm"}>Time Zone</FormLabel>
                    <Select disabled>
                      <option value={data.timezone}>{data.timezone}</option>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize={"sm"}>Time Format</FormLabel>
                    <Select disabled>
                      <option value={data.timeFormat}>{data.timeFormat}</option>
                    </Select>
                  </FormControl>
                </Flex>
                <Flex gap={7} my={5}>
                  <FormControl>
                    <FormLabel fontSize={"sm"}>Unit of Length</FormLabel>
                    <Select disabled>
                      <option value={data.unitOfLength}>{data.unitOfLength}</option>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize={"sm"}>Unit of Weight</FormLabel>
                    <Select disabled>
                      <option value={data.unitOfWeight}>{data.unitOfWeight}</option>
                    </Select>
                  </FormControl>
                </Flex>

                <Flex gap={7} my={5}>
                  <FormControl>
                    <FormLabel fontSize={"sm"}>Number Format</FormLabel>
                    <Select disabled>
                      <option value={data.numberFormat}>{data.numberFormat}</option>
                    </Select>
                  </FormControl>

                  <FormControl>
                    <FormLabel fontSize={"sm"}>Dimensional Factor</FormLabel>
                    <Input disabled value={data.dimFactor ?? ""} />
                  </FormControl>
                </Flex>

                <Flex gap={7} my={5} width={"calc(50% - 1rem)"}>
                  <FormControl>
                    <FormLabel fontSize={"sm"}>
                      Last Mile Fuel Surcharge Region
                    </FormLabel>
                    <Select disabled>
                      <option value={data.fuelSurchargeRegion}>
                        {data.fuelSurchargeRegion}
                      </option>
                    </Select>
                  </FormControl>
                </Flex>
              </Card>

              {/********************* Invoicing *********************/}
              <Card
                mb={7}
                borderRadius="20px"
                borderWidth="1px"
                borderColor="rgba(133, 134, 152, 0.2)"
                p={8}
              >
                <Heading size="md">Invoicing</Heading>
                <Flex gap={7} my={5}>
                  <FormControl>
                    <FormLabel fontSize={"sm"}>Invoice Frequency</FormLabel>
                    <Select disabled>
                      <option value={data.invoiceFrequency}>
                        {data.invoiceFrequency}
                      </option>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize={"sm"}>Payment Terms (Days)</FormLabel>
                    <Select disabled>
                      <option value={data.invoiceDueDate}>
                        {data.invoiceDueDate}
                      </option>
                    </Select>
                  </FormControl>
                </Flex>
                {isCarrier && (
                  <Flex gap={7} my={5}>
                    <FormControl>
                      <FormLabel fontSize={"sm"}>Payment Preference</FormLabel>
                      <Select disabled>
                        <option value={data.settlementPreference}>
                          {data.settlementPreference}
                        </option>
                      </Select>
                    </FormControl>
                    <FormControl>
                      <FormLabel fontSize={"sm"}>
                        Quote Validity Period (Days)
                      </FormLabel>
                      <Input disabled value={data.quoteValidity} />
                    </FormControl>
                  </Flex>
                )}
              </Card>

              {/********************* Integration *********************/}
              <Card
                mb={7}
                borderRadius="20px"
                borderWidth="1px"
                borderColor="rgba(133, 134, 152, 0.2)"
                p={8}
              >
                <Heading size="md">Integration</Heading>
                {isShipper && (
                  <>
                    <Box mt={5}>
                      <Heading size={"sm"}>Inbound (To Orchestro)</Heading>
                    </Box>
                    <Flex gap={7} my={5} width={"calc(50% - 1rem)"}>
                      <FormControl>
                        <FormLabel fontSize={"sm"}>Inbound Manifest Transit Method</FormLabel>
                        <Select disabled>
                          <option value={data.manifestTransmitMethod}>
                            {data.manifestTransmitMethod}
                          </option>
                        </Select>
                      </FormControl>
                    </Flex>
                  </>
                )}
                {isCarrier && (
                  <>
                    <Box mt={5}>
                      <Heading size={"sm"}>Outbound</Heading>
                    </Box>
                    <Flex gap={7} my={5} width={"calc(50% - 1rem)"}>
                      <FormControl>
                        <FormLabel fontSize={"sm"}>Outbound Manifest Transmit Method</FormLabel>
                        <Select disabled>
                          <option value={data.manifestTransmitMethod}>
                            {data.manifestTransmitMethod}
                          </option>
                        </Select>
                      </FormControl>
                    </Flex>
                    <Flex gap={7} my={5}>
                      <FormControl>
                        <FormLabel fontSize={"sm"}>
                          Outbound Manifest Trigger Schedule Time
                        </FormLabel>
                        <Input disabled value={data.triggerScheduleTime} />
                      </FormControl>
                      <FormControl>
                        <FormLabel fontSize={"sm"}>
                          Outbound Manifest Trigger Track Codes
                        </FormLabel>
                        <Select disabled>
                          <option value={data.outboundTriggerTrackCodes}>
                            {data.outboundTriggerTrackCodes}
                          </option>
                        </Select>
                      </FormControl>
                    </Flex>
                  </>
                )}
              </Card>


              {/********************* Label *********************/}
              {isCarrier && (
                <Card
                  mb={7}
                  borderRadius="20px"
                  borderWidth="1px"
                  borderColor="rgba(133, 134, 152, 0.2)"
                  p={8}
                >
                  <Heading size="md">Label</Heading>
                  <Flex gap={7} my={5} width={"calc(50% - 1rem)"}>
                    <FormControl>
                      <FormLabel fontSize={"sm"}>Label Style</FormLabel>
                      <Select disabled value={dummyLabelCompatOptions[0].value}>
                        {dummyLabelCompatOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </Flex>
                </Card>
              )}

              {/********************* Network Preferences *********************/}
              {/* {isShipper && (
                <Card
                  mb={7}
                  borderRadius="20px"
                  borderWidth="1px"
                  borderColor="rgba(133, 134, 152, 0.2)"
                  p={8}
                >
                  <Heading size="md">Network Preferences</Heading>
                  <Flex gap={7} my={5} width={"calc(50% - 1rem)"}>
                    <FormControl>
                      <FormLabel fontSize={"sm"}>Use Open Network</FormLabel>
                      <Select disabled>
                        <option value="Yes">
                          {data.subNetworkConfigured === "true" ? "Yes" : "No"}
                        </option>
                      </Select>
                    </FormControl>
                  </Flex>
                  <Text fontSize={"sm"}>Preferred Carriers</Text>
                  <TableContainer>
                    <Table>
                      <Thead>
                        <Tr>
                          <Th>Service Type</Th>
                          <Th>Carrier</Th>
                          <Th>Default Pickup Responsibility</Th>
                          <Th>Use All Sort Hubs of Carrier?</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {data?.preferredCarriers?.map((carrier) => (
                          <Tr>
                            <Td>{carrier.serviceType}</Td>
                            <Td>{carrier.carrier}</Td>
                            <Td>{carrier.defaultPickupResponsibility}</Td>
                            <Td>{carrier.useAllSortHubsOfCarrier}</Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Card>
              )} */}

              {/********************* API Configuration *********************/}
              {/* {isCarrier && (
                <Card
                  mb={7}
                  borderRadius="20px"
                  borderWidth="1px"
                  borderColor="rgba(133, 134, 152, 0.2)"
                  p={8}
                >
                  <Heading size="md">API Configuration</Heading>
                  <Flex gap={7} my={5} width={"calc(50% - 1rem)"}>
                    <FormControl>
                      <FormLabel fontSize={"sm"}>
                        API Endpoint Configuration
                      </FormLabel>
                      <Input disabled />
                    </FormControl>
                  </Flex>
                </Card>
              )} */}

              <Text as={"small"} size={"xs"} mb={2}>
                Last Modified
              </Text>
              <Text size={"sm"}>
                {data?.updatedAt
                  ? renderDate(data?.updatedAt) + ", " + renderTime(data?.updatedAt)
                  : "No data"}
              </Text>
            </>
          )}
        </Page>
      </div>
    </>
  );
}

export default Preferences;
