import React from "react";
import Modal from "../../../componentLibrary/components/Modal";

const TrackingModal = ({
	open,
	onClose,
	onOpenChange,
	type,
	children,
	classProp,
}) => {
	return (
		<Modal
			open={open}
			onClose={onClose}
			type={type}
			className={`!bg-[#21232E] ${classProp || "large-modal"}`}
			onOpenChange={onOpenChange}
		>
			{children}
		</Modal>
	);
};

export default TrackingModal;
