import { StepCounter, StepNav, StepPage } from ".";
export function Step3({ data, setData, options }) {
  return (
    <StepPage>
      <div className="flex flex-col w-full text-white font-['Montserrat']">
        <StepCounter step={2} />
        <div className=" flex flex-col items-center justify-center mt-12">
          <div className="w-[759px]">
            <p className="text-xl leading-8 tracking-wide">
              Select your average daily <strong>parcel volume</strong> range?
            </p>
            <p className="text-xl leading-8 tracking-wide">
              Unlock tailored pricing and cost-efficient solutions tailored to
              your needs!
            </p>
          </div>
          <div className=" flex justify-start w-[759px] mt-3 flex-col">
            {options.map((option) => (
              <label
                key={`vol-${option.value}`}
                htmlFor={option.value}
                className="inline-flex items-center border w-[564px] border-[#C3C3C3] rounded-md gap-4 p-2 my-3 cursor-pointer"
              >
                <div className="relative flex items-center p-3 rounded-full cursor-pointer">
                  <input
                    name="type"
                    type="radio"
                    className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-[#E0E1EC] text-[#3BA0E6] transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-[#3BA0E6] checked:before:bg-[#3BA0E6]"
                    id={option.value}
                    checked={data.parcelVolume === option.value}
                    onChange={() =>
                      setData({ ...data, parcelVolume: option.value })
                    }
                  />
                  <span className="absolute text-[#3BA0E6] transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-3.5 w-3.5"
                      viewBox="0 0 16 16"
                      fill="currentColor"
                    >
                      <circle data-name="ellipse" cx="8" cy="8" r="8"></circle>
                    </svg>
                  </span>
                </div>
                <span className="mt-px text-white text-xl font-normal font-['Montserrat'] tracking-wide cursor-pointer select-none">
                  {option.label}
                </span>
              </label>
            ))}
          </div>
        </div>
      </div>

      <StepNav
        prevLink="../step/2"
        nextLink="../step/4"
        nextLinkDisabled={!data.parcelVolume}
      />
    </StepPage>
  );
}
