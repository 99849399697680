import { Link, NavLink } from "react-router-dom";
import { Box, Text } from "@chakra-ui/react";

import classes from "./Nav.module.css";
import { RateDiscoveryIcon } from "../";

import Logo from "../../componentLibrary/components/Logo";

export const DemoNav = () => {
  const linkClass = ({ isActive }) =>
    isActive ? `${classes.links} ${classes.active}` : classes.links;

  return (
    <Box className={classes.sidebar}>
      <div className={classes.logo}>
        <Link to={"/app/dashboard"}>
          <Logo width="98px" mode="light" />
        </Link>
      </div>
      <nav className={classes.scrollBox} role="navigation">
        <ul className={classes.navList}>
          <li>
            <NavLink className={linkClass} to={"/demo/rate-discovery"}>
              <RateDiscoveryIcon />
              <Text>Rate Discovery</Text>
            </NavLink>
          </li>
        </ul>
      </nav>
    </Box>
  );
};
