import React, { useState } from "react";

const NavLink = ({ href, text, target, isMenuOpen, selected }) => (
  <a
    href={href}
    target={target}
    className={`${selected ? "text-[#fbb03b]" : "text-white"} py-2 no-underline pl-6 transition-opacity duration-300 ${isMenuOpen ? "opacity-100 delay-300" : "opacity-0 delay-275"}`}
  >
    {text}
  </a>
);

const MobileNav = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSubListOpen, setIsSubListOpen] = useState(false);

  const handleMenubarClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMembersClick = () => {
    setIsSubListOpen(!isSubListOpen);
  };

  const navLinks = [
    { href: "https://www.orchestro.ai", target: "_top", text: "Home" },
    { href: "https://www.orchestro.ai/#about", target: "_top", text: "About" },
    {
      href: "https://www.orchestro.ai/#contact",
      target: "_top",
      text: "Contact",
    },
    {
      href: "https://www.orchestro.ai/partner",
      target: "_top",
      text: "Log In",
    },
    { href: "/track", target: "_top", text: "Track", selected: true },
    {
      isSubList: true,
      text: "Members",
      subLinks: [
        {
          href: "https://www.orchestro.ai/carriers",
          target: "_top",
          text: "Carriers",
        },
        {
          href: "https://www.orchestro.ai/shipper",
          target: "_top",
          text: "Shippers",
        },
        {
          href: "https://developer.orchestro.ai",
          target: "_top",
          text: "Developers",
        },
      ],
    },
    {
      href: "https://www.orchestro.ai/partner",
      target: "_top",
      text: "Create Account",
    },
  ];

  return (
    <header className="w-full flex flex-col relative">
      <div className="flex w-full items-center justify-between">
        <div className="flex flex-1 justify-center">
          <img
            src="images/tracking/Light_logo.svg"
            alt="Logo"
            className="h-[90px] ml-[40px]"
          />
        </div>
        <button
          onClick={handleMenubarClick}
          className="text-white mr-3 focus:outline-none ml-auto"
        >
          <svg
            className="w-8 h-8"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d={isMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m0 6H4"}
            ></path>
          </svg>
        </button>
      </div>

      <div
        id="mobile-header"
        className={`absolute top-full flex flex-col w-full items-center justify-evenly bg-cover bg-black transition-height duration-1000 ${isMenuOpen ? "h-auto" : "h-0"}`}
        style={{
          backgroundImage:
            "url(images/nav-bg.png), linear-gradient(to bottom, #000000, #000000, #343990)",
        }}
      >
        {navLinks.map((link, index) =>
          link.isSubList ? (
            <div
              key={index}
              className={`w-full transition-opacity ${isMenuOpen ? "opacity-100 delay-300" : "opacity-0 delay-275"}`}
            >
              <button
                onClick={handleMembersClick}
                className="w-full text-center text-white no-underline pl-6"
              >
                {link.text}
                <svg
                  className={`inline-block ml-2 transition-transform duration-300 ${isSubListOpen ? "rotate-180" : "rotate-0"}`}
                  width="20"
                  height="20"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  ></path>
                </svg>
              </button>
              <div
                className={`flex flex-col transition-max-height duration-300 overflow-hidden ${isSubListOpen ? "max-h-40" : "max-h-0"} text-center`}
              >
                {link.subLinks.map((subLink, subIndex) => (
                  <NavLink
                    key={subIndex}
                    href={subLink.href}
                    text={subLink.text}
                    target={subLink.target}
                    isMenuOpen={isMenuOpen}
                  />
                ))}
              </div>
            </div>
          ) : (
            <NavLink
              key={index}
              href={link.href}
              text={link.text}
              target={link.target}
              isMenuOpen={isMenuOpen}
              selected={link.selected}
            />
          )
        )}
      </div>
    </header>
  );
};

export default MobileNav;
